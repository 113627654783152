import "./VideoModal.scss";
import "plyr-react/plyr.css";

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import React, { useEffect, useRef, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Plyr from "plyr-react";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

interface VideoModalProps {
	show: boolean;
	onClose?: any;
	url: string;
}

const VideoModal = ({ show, onClose, url }: VideoModalProps) => {
	const [modal, setModal] = useState<boolean>(false);
	const ref = useRef(null);

	useEffect(() => {
		if (show) {
			setModal(show);
		}
	}, [show]);

	const toggle = () => setModal(!modal);
	const handleOnClose = () => onClose(false);

	return (
		<Modal
			centered
			scrollable
			size="lg"
			isOpen={modal}
			toggle={toggle}
			onClosed={handleOnClose}
			className="VideoModal"
		>
			<ModalHeader toggle={toggle}>Assista ao video abaixo:</ModalHeader>
			<ModalBody>
				<div className="video-container">
					<Plyr
						id="plyr"
						ref={ref}
						source={{
							type: "video",
							sources: [
								{
									src: url,
									provider: "youtube",
								},
							],
						}}
						options={{
							controls: [
								"play-large",
								"play",
								"progress",
								"current-time",
								"duration",
								"mute",
								"volume",
								"settings",
								"pip",
								"airplay",
								"fullscreen",
							],
							// @ts-ignore
							autoplay: `${show}`,
						}}
					/>
				</div>
			</ModalBody>
			<ModalFooter>
				<Button type="button" onClick={toggle}>
					<FontAwesomeIcon icon={faXmark} />
					<span>Fechar</span>
				</Button>
			</ModalFooter>
		</Modal>
	);
};

// @ts-ignore
export default VideoModal;
