// CSS
import "./NotesEditModal.scss";

import {
	Button,
	Col,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row,
} from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";

// API
import MacroGoalsService from "../../services/MacroGoalsService";
import NotesService from "../../services/NotesService";
import PurposeService from "../../services/PurposeService";
import Select from "react-select";
import { customModalStyles } from "../../models/selectConfig";
import { schema } from "./Validate/Validate";
import { toastSettings } from "../../models/toastConfig";
import { yupResolver } from "@hookform/resolvers/yup";

type FormValues = {
	idRecurso: string;
	idObjetivo: string;
	humor: string;
	anotacao: string;
	isUelf: number;
};

interface INotesEditModal {
	show: boolean;
	onClose?: any;
	setFunction?: any;
	note: any;
	isFeedback: boolean;
	showGoal?: boolean;
}

const NotesEditModal = ({
	show,
	onClose,
	setFunction,
	isFeedback,
	note,
	showGoal,
}: INotesEditModal) => {
	const [modal, setModal] = useState<boolean>(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [idRecursoSelected, setidRecursoSelected] = useState<any>(null);
	const [idHumorSelected, setidHumorSelected] = useState<any>(null);
	const [objetivoSelected, setObjetivoSelected] = useState<any>(null);

	const [recursos, setRecursos] = useState<any>([]);
	const [objetivos, setObjetivos] = useState<any>([]);
	const [isUelf, setIsUelf] = useState<boolean>(false);
	const [isUelfs, setIsUelfs] = useState<any>({});
	const [updateNote, setUpdateNote] = useState<any | null>(null);
	const [idNote, setIdNote] = useState<any>({});

	const setEditNote = () => {
		setUpdateNote(note);
	};

	let defaultValues = {
		idRecurso: "",
		idObjetivo: "",
		humor: "",
		anotacao: "",
	} as FormValues;

	let arrHumor: any[] = [
		{
			label: "Insatisfeito",
			value: 1,
		},
		{
			label: "Neutro",
			value: 2,
		},
		{
			label: "Alegre",
			value: 3,
		},
	];

	const {
		register,
		control,
		handleSubmit,
		reset,
		setValue,
		formState: { errors },
	} = useForm<FormValues>({
		mode: "onChange",
		defaultValues,
		resolver: yupResolver(schema),
	});

	const toggle = () => setModal(!modal);

	const handleOnClose = () => {
		onClose(false);
		reset();
	};

	const verifyIsUelf = (idObjetivo: any) => {
		isUelfs.forEach((value: any) => {
			if (value.id === idObjetivo.value) {
				setIsUelf(value.isUELF);
			}
		});
	};

	const getRecursos = async () => {
		try {
			const [_Response, _Error] = await new MacroGoalsService().listResources();

			if (!!_Error || !_Response?.status) {
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
				return;
			}

			let arrayRecursos = _Response.resultSet?.map((item: any) => {
				return { label: item.descricao, value: item.id };
			});

			setRecursos(arrayRecursos);
		} catch (err) {
			console.warn(err);
		}
	};

	const getObjetivos = async () => {
		try {
			const [_Response, _Error] = await new PurposeService().listAll();

			if (!!_Error || !_Response?.status) {
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
				return;
			}

			let arrayObjetivos = _Response.resultSet?.map((item: any) => {
				return { label: item.objetivo, value: item.id };
			});

			setIsUelfs(_Response.resultSet);

			setObjetivos(arrayObjetivos);
		} catch (e: any) {
			toastSettings("Algo deu errado!" || e?.message, "bottom-center", "error");
		}
	};

	const editNote = async (data: any) => {
		try {
			data = { ...data, isUelf: isUelf };
			data = { ...data, idObjetivo: data.idObjetivo.value };
			data = { ...data, id: idNote };

			const [_Response, _Error] = await new NotesService().create(data);

			if (!!_Error || !_Response?.status) {
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
				return;
			}

			toastSettings(
				"Anotação atualizada com sucesso!",
				"bottom-center",
				"success",
			);

			/**
			 * Está Função está vindo da tela de Feedback ou da tela de Notes
			 * Se for Feedback é a função getNotes(idObjetivo e isuelf)
			 * Se for notes é a handlelist() e não passa parametro
			 * Quem faz os controle de qual função chamar é a
			 * variavel de estado ISFEEDBACK se for true está
			 * vindo a função da tela de Feedback.tsx, se for false Notes.tsx
			 */
			setFunction(
				isFeedback ? data.idObjetivo : "",
				isFeedback ? data.isUelf : "",
			);

			toggle();
		} catch (e: any) {
			toastSettings("Algo deu errado!" || e?.message, "bottom-center", "error");
		}
	};

	useEffect(() => {
		if (show) {
			setModal(show);
			setIsSubmitting(false);
			getRecursos().then(() => {
				getObjetivos().then(() => {
					setEditNote();
				});
			});
		}
	}, [show]);

	useEffect(() => {
		setValue("idRecurso", idRecursoSelected || null);
	}, [idRecursoSelected]);

	useEffect(() => {
		setValue("idObjetivo", objetivoSelected || null);
	}, [objetivoSelected]);

	useEffect(() => {
		setValue("humor", idHumorSelected || null);
	}, [idHumorSelected]);

	useEffect(() => {
		if (updateNote) {
			setValue("anotacao", updateNote.anotacao || null);
			setIsUelf(updateNote.isUelf);

			let moodSelected = arrHumor.find((a: any) => a.value === updateNote.humor);
			setValue("humor", moodSelected);
			setidHumorSelected(moodSelected);

			let resourceSelected = recursos.find(
				(x: any) => x.value === updateNote.idRecurso,
			);
			setValue("idRecurso", resourceSelected);
			setidRecursoSelected(resourceSelected);

			let objSelect = objetivos.find(
				(x: any) => x.value === updateNote?.idObjetivo,
			);
			setValue("idObjetivo", objSelect);
			setObjetivoSelected(objSelect);

			setIdNote(updateNote.id);
		}
	}, [updateNote]);

	return (
		<Modal
			centered
			scrollable
			size="lg"
			isOpen={modal}
			toggle={toggle}
			onClosed={handleOnClose}
			className="modal-notes-add"
			data-testid="NotesAddModal"
		>
			<form key={1} onSubmit={handleSubmit(editNote)}>
				<ModalHeader toggle={toggle}>Editar Anotação</ModalHeader>
				<ModalBody className="px-4">
					<Row>
						<Col className="col-12">
							<div className="fieldset">
								<label htmlFor="idRecurso">Escolha o recurso:</label>

								<Controller
									name="idRecurso"
									control={control}
									render={({ field: { onChange, name, ref } }) => (
										<Select
											ref={ref}
											name={name}
											isSearchable={false}
											isClearable={false}
											options={recursos}
											placeholder="Selecione..."
											className={`select ${!errors.idRecurso || "invalid"}`}
											value={idRecursoSelected}
											onChange={(val: any) => {
												onChange(val || null);
												setidRecursoSelected(val);
											}}
											noOptionsMessage={() => "Não há registros"}
											styles={customModalStyles}
											menuPosition={"fixed"}
										/>
									)}
								/>
								{errors.idRecurso?.type === "required" && (
									<p className="error">Recurso é obrigatório</p>
								)}
							</div>
						</Col>
					</Row>
					<Row className={showGoal === false ? "hidden" : ""}>
						<Col className="col-12">
							<div className="fieldset">
								<label htmlFor="idObjetivo">Escolha o objetivo:</label>
								<Controller
									name="idObjetivo"
									control={control}
									render={({ field: { onChange, name, ref } }) => (
										<Select
											ref={ref}
											name={name}
											isSearchable={false}
											isClearable={false}
											options={objetivos}
											placeholder="Selecione..."
											className={`select ${!errors.idObjetivo || "invalid"}`}
											value={objetivoSelected}
											onChange={(val: any) => {
												onChange(val || null);
												setObjetivoSelected(val);
												verifyIsUelf(val);
											}}
											noOptionsMessage={() => "Não há registros"}
											styles={customModalStyles}
											menuPosition={"fixed"}
										/>
									)}
								/>
								{errors.idObjetivo?.type === "required" && (
									<p className="error">Objetivo é obrigatório</p>
								)}
							</div>
						</Col>
					</Row>

					<Row className="mt-2">
						<Col className="col-12">
							<div className="fieldset">
								<label htmlFor="humor">Escolha o humor:</label>
								<Controller
									name="humor"
									control={control}
									render={({ field: { onChange, name, ref } }) => (
										<Select
											ref={ref}
											name={name}
											isSearchable={false}
											isClearable={false}
											options={arrHumor}
											placeholder="Selecione..."
											className={`select ${!errors.humor || "invalid"}`}
											value={idHumorSelected}
											onChange={(val: any) => {
												onChange(val || null);
												setidHumorSelected(val);
											}}
											noOptionsMessage={() => "Não há registros"}
											styles={customModalStyles}
											menuPosition={"fixed"}
										/>
									)}
								/>

								{errors.humor?.type === "required" && (
									<p className="error">Humor é obrigatório</p>
								)}
							</div>
						</Col>
					</Row>

					<Row className="mt-2">
						<Col className="col-12">
							<div className="fieldset">
								<Label for="anotacao">Descreva a anotação:</Label>
								<input
									id="anotacao"
									type="text"
									className={`${!errors.anotacao || "invalid"}`}
									{...register("anotacao")}
									maxLength={100}
								/>

								{errors.anotacao?.type === "required" && (
									<p className="error">Anotação Correta é obrigatório</p>
								)}
								{errors.anotacao?.type === "min" && (
									<p className="error">Deve conter no mínimo 3 caracteres</p>
								)}
								{errors.anotacao?.type === "max" && (
									<p className="error">Deve conter no máximo 50 caracteres</p>
								)}
							</div>
						</Col>
					</Row>
				</ModalBody>
				<ModalFooter>
					<Button type="button" disabled={isSubmitting} onClick={toggle}>
						Cancelar
					</Button>
					<Button disabled={isSubmitting} color="primary">
						Concluir
					</Button>
				</ModalFooter>
			</form>
		</Modal>
	);
};

export default NotesEditModal;
