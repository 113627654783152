import React, { useEffect, useState } from "react";
import { Input } from "reactstrap";
import { useSurvey } from "../../providers/SurveyProvider";
import "./SurveyRadioButton.scss";

interface IRadioButton {
	id: string;
	idName: string;
	name: string;
	questionID: number;
	cat: string;
	value: string;
	label: string;
}

const SurveyRadioButton = ({
	id,
	idName,
	name,
	questionID,
	cat,
	value,
	label,
}: IRadioButton): JSX.Element => {
	const { selfEvaluation, setSelfEvaluation, habits, setHabits } = useSurvey();

	return (
		<div className="input-wrapper">
			{cat === "sa" ? (
				<Input
					type="radio"
					id={idName}
					name={name}
					checked={selfEvaluation?.idSubRecursoQuestaoAlternativa == id}
					disabled={false}
					onChange={(e: any) => {
						let response = {
							idQuestao: questionID,
							idSubRecursoQuestaoAlternativa: id,
							resposta: value,
						};

						setSelfEvaluation(response);
					}}
					value={value}
				/>
			) : (
				<Input
					type="radio"
					id={idName}
					name={name}
					checked={habits?.idSubRecursoQuestaoAlternativa == id}
					disabled={false}
					onChange={(e: any) => {
						let response = {
							idQuestao: questionID,
							idSubRecursoQuestaoAlternativa: id,
							resposta: value,
						};

						setHabits(response);
					}}
					value={value}
				/>
			)}
			<label htmlFor={idName}>
				<span>{label}</span>
			</label>
		</div>
	);
};

export default SurveyRadioButton;
