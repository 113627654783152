import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Button } from "reactstrap";
import { toastSettings } from "../../models/toastConfig";
import AccountRecoveryService from "../../services/AccountRecoveryService";
import "./AccountRecovery.scss";

interface Inputs {
	recoveryCode: string;
	newPass: string;
	confirmPass: string;
}

const AccountRecovery = () => {
	const [checkedToken, setCheckedToken] = useState(false);

	const [userID, setUserID] = useState<number>();
	const [userName, setUserName] = useState<string>();
	const [userEmail, setUserEmail] = useState<string>();

	const [codeWasSent, setCodeWasSent] = useState(false);
	const { token } = useParams();

	const [isSubmitting, setIsSubmitting] = useState(false);

	const {
		register,
		watch,
		handleSubmit,
		formState: { errors },
	} = useForm<Inputs>();

	const newPass = useRef({});
	newPass.current = watch("newPass", "");

	useEffect(() => {
		if (token) {
			checkTokenValidity();
		}
	}, [token]);

	const checkTokenValidity = async () => {
		const accountToken = {
			token: token,
		};

		try {
			const [_Response, _Error] = await new AccountRecoveryService().validateToken(
				accountToken,
			);

			if (!!_Error || !_Response?.status) {
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
				return false;
			} else {
				let res = _Response.resultSet;
				res.nome = res.nome.split(" ")[0];

				setUserID(res.idUsuario);
				setUserName(res.nome);
				setUserEmail(res.email);

				setCheckedToken(true);
				return true;
			}
		} catch (err) {
			console.warn(err);
		}
	};

	const handleNewPass = async (data: {
		newPass: string;
		confirmPass: string;
		recoveryCode?: string;
	}) => {
		let postData: any = {};

		if (codeWasSent) {
			postData = {
				idUsuario: userID,
				novaSenha: data.newPass,
				confirmacaoSenha: data.confirmPass,
				codigoRecuperacao: data.recoveryCode?.toUpperCase(),
			};
		} else {
			postData = {
				idUsuario: userID,
				novaSenha: data.newPass,
				confirmacaoSenha: data.confirmPass,
			};
		}

		try {
			setIsSubmitting(true);
			const [_Response, _Error] =
				await new AccountRecoveryService().sendRecoveryCode(postData);

			if (!!_Error || !_Response?.status) {
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
				setIsSubmitting(false);
				return false;
			} else {
				if (codeWasSent) {
					toastSettings("Nova senha cadastrada!", "bottom-center", "success");
					setTimeout(() => {
						window.location.href = "/login";
					}, 3000);
				} else {
					toastSettings("Código enviado.", "bottom-center", "success");
					setCodeWasSent(true);
				}

				setIsSubmitting(false);
				return true;
			}
		} catch (err) {
			console.warn(err);
		}
	};

	return (
		<div className="account-recovery">
			<div className="card">
				{checkedToken ? (
					<>
						<h1 className="title">Recuperação de conta</h1>
						<span className="badge">{userEmail}</span>
						<div className="text">
							<p>Olá {userName}! Vamos redefinir sua senha abaixo:</p>
						</div>
						<form key={0} autoComplete="off" onSubmit={handleSubmit(handleNewPass)}>
							<div className="fieldset">
								<label htmlFor="newPass">Nova senha:</label>
								<input
									type="password"
									id="newPass"
									{...register("newPass", { required: true, maxLength: 20 })}
								/>
								{errors.newPass && (
									<span className="error">Este campo é necessário.</span>
								)}
							</div>
							<div className="fieldset">
								<label htmlFor="confirmPass">Confirme sua senha:</label>
								<input
									type="password"
									id="confirmPass"
									{...register("confirmPass", {
										required: true,
										maxLength: 20,
										validate: (value) => value === newPass.current,
									})}
								/>
								{errors.confirmPass && (
									<span className="error">Este campo têm de ser igual ao anterior.</span>
								)}
							</div>
							{codeWasSent ? (
								<div className="fieldset">
									<label htmlFor="recoveryCode">Digite o codigo de recuperação:</label>
									<input
										type="text"
										id="recoveryCode"
										{...register("recoveryCode", {
											required: true,
											maxLength: 6,
										})}
									/>
									{errors.recoveryCode && (
										<span className="error">Este campo é necessário.</span>
									)}
								</div>
							) : (
								""
							)}
							<div className="row">
								{/* verificar @lucas.franca */}
								<Link
									to="/login"
									// disabled={isSubmitting}
									className="btn btn-secondary"
								>
									Cancelar
								</Link>
								<Button type="submit" disabled={isSubmitting} color="primary">
									Redefinir senha
								</Button>
							</div>
						</form>
					</>
				) : (
					<>
						<h1 className="title">Parece que algo deu errado.</h1>
						<div className="text">
							<p>Este link não está mais ativo, seu período de validade expirou.</p>
						</div>
						<Link to="/login" className="btn btn-secondary">
							Receber novo email
						</Link>
					</>
				)}
			</div>
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</div>
	);
};

export default AccountRecovery;
