import "./EPPayment.scss";
import "tippy.js/dist/tippy.css";

import { Controller, useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import { faCircleQuestion, faLock } from "@fortawesome/free-solid-svg-icons";

import { Button } from "reactstrap";
import CpfCnpj from "../CpfCnpj/CpfCnpj";
import ExternalPaymentService from "../../services/ExternalPaymentService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputMask from "react-input-mask";
import Tippy from "@tippyjs/react";
import moment from "moment";
import { schema } from "./Validate/Validate";
import { toastSettings } from "../../models/toastConfig";
import { useExternalPayment } from "../../providers/ExternalPaymentProvider";
import { yupResolver } from "@hookform/resolvers/yup";
import LoaderPayment from "../LoaderPayment/LoaderPayment";

type FormValues = {
	block01: string;
	block02: string;
	block03: string;
	block04: string;
	expirationDate: string;
	cvc: string;
	cardName: string;
	cpf: string;
	paymentOption: string;
	numberOfInstallments: string;
};

const EPPayment = () => {
	const {
		setStep,

		loading,
		setLoading,

		name,
		CPF,
		phone,
		birthDate,
		occupation,
		email,
		password,

		plan,

		valueBlock01,
		setValueBlock01,
		valueBlock02,
		setValueBlock02,
		valueBlock03,
		setValueBlock03,
		valueBlock04,
		setValueBlock04,
		expirationDate,
		setExpirationDate,
		cardName,
		setCardName,
		cvc,
		setCvc,
		cpf,
		setCpf,

		paymentOption,
		setPaymentOption,

		cardData,
		setCardData,

		setSuccessfullyPaid,
	} = useExternalPayment();

	let defaultValues = {
		block01: valueBlock01 ? valueBlock01 : "",
		block02: valueBlock02 ? valueBlock02 : "",
		block03: valueBlock03 ? valueBlock03 : "",
		block04: valueBlock04 ? valueBlock04 : "",
		expirationDate: expirationDate ? expirationDate : "",
		cvc: cvc ? cvc : "",
		cardName: cardName ? cardName : "",
		cpf: CPF ? CPF : "",
		paymentOption: "",
		numberOfInstallments: "",
	} as FormValues;

	const {
		register,
		handleSubmit,
		control,
		setValue,
		formState: { errors },
	} = useForm<FormValues>({
		mode: "onChange",
		defaultValues,
		resolver: yupResolver(schema),
	});

	useEffect(() => {
		if (valueBlock01 || valueBlock02 || valueBlock03 || valueBlock04) {
			checkCardFlag().then();

			setValue("block01", valueBlock01);
			setValue("block02", valueBlock02);
			setValue("block03", valueBlock03);
			setValue("block04", valueBlock04);
		}
	}, [valueBlock01, valueBlock02, valueBlock03, valueBlock04]);

	useEffect(() => {
		if (cvc) {
			setValue("cvc", cvc);
		}
	}, [cvc]);

	useEffect(() => {
		if (expirationDate) {
			setValue("expirationDate", expirationDate);
		}
	}, [expirationDate]);

	useEffect(() => {
		if (cpf) {
			setValue("cpf", cpf);
		}
	}, [cpf]);

	const handleClipboardEvent = (e: any) => {
		e.stopPropagation();
		e.preventDefault();

		let clipboardData, pastedData, filteredData;

		clipboardData = e.clipboardData;
		pastedData = clipboardData.getData("Text");

		filteredData = pastedData.replace(/\D/g, "");

		if (filteredData.length >= 16) {
			setValueBlock01(filteredData.substring(0, 4));
			setValueBlock02(filteredData.substring(4, 8));
			setValueBlock03(filteredData.substring(8, 12));
			setValueBlock04(filteredData.substring(12, 16));
		} else {
			toastSettings(
				"Digite ou cole as credenciais de um cartão valido.",
				"bottom-center",
				"error",
			);
		}
	};

	const checkCardFlag = async () => {
		try {
			let card = valueBlock01 + valueBlock02 + valueBlock03 + valueBlock04;

			if (card.length >= 16) {
				const [_Response, _Error] =
					await new ExternalPaymentService().consultCardFlag(card);

				if (!!_Error || !_Response?.status) {
					toastSettings(_Error || _Response?.message, "bottom-center", "error");
					return false;
				}

				setCardData(_Response.resultSet);
			}

			return null;
		} catch (err) {
			console.warn(err);
		}
	};

	const onSubmit = async (data: FormValues) => {
		setLoading(true);

		let planData;

		if (paymentOption === "recurrent") {
			planData = {
				idPlano: plan,
				numeroParcelas: 1,
				tipoAssinatura: "Monthly",
				idTipoPagamento: 1,
			};
		} else {
			planData = {
				idPlano: plan,
				numeroParcelas: parseInt(data.numberOfInstallments),
				tipoAssinatura: "Monthly",
				idTipoPagamento: 2,
			};
		}

		const parameters = {
			dadosUsuario: {
				celular: phone,
				confirmarSenha: password,
				cpfCnpj: CPF,
				dataNascimentoAbertura: moment(birthDate).format("YYYY-MM-DD"),
				email: email,
				nomeRazao: name,
				ocupacao: occupation,
				senha: password,
			},
			dadosPlano: planData,
			dadosCartao: {
				cardNumber: valueBlock01 + valueBlock02 + valueBlock03 + valueBlock04,
				brand: cardData?.brand,
				securityCode: data.cvc,
				expirationDate: data.expirationDate,
				holder: data.cardName,
			},
		};

		try {
			const [_Response, _Error] =
				await new ExternalPaymentService().tryMakePayment(parameters);

			if (!!_Error || !_Response?.status) {
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
				setLoading(false);
				return false;
			}

			setSuccessfullyPaid(true);
			setStep("Confirmation");
			setLoading(false);
		} catch (err) {
			console.warn(err);
			setSuccessfullyPaid(false);
			setStep("Confirmation");
			setLoading(false);
		}
	};

	return (
		<>
			{loading ? (
				<LoaderPayment />
			) : (
				<div className="ep-payment step-card">
					<h1 className="title">Detalhes do pagamento</h1>
					<form className="form" onSubmit={handleSubmit(onSubmit)}>
						<div className="fieldset">
							<label htmlFor="block01">Número do cartão:</label>
							<div className="form-group">
								<div className="fieldset">
									<input
										type="text"
										id="block01"
										className={`${!errors.block01 || "invalid"}`}
										maxLength={4}
										{...register("block01", { required: true })}
										value={valueBlock01}
										onPaste={(e: any) => {
											handleClipboardEvent(e);
										}}
										onChange={(e: any) => {
											setValueBlock01(e.target.value);
										}}
										placeholder="0000"
										required
									/>
									{errors.block01?.type === "required" && (
										<span className="error">Digite valores validos.</span>
									)}
									{errors.block01?.type === "max" && (
										<span className="error">
											É nescessario ter no minimo 4 caraceteres.
										</span>
									)}
								</div>
								<div className="fieldset">
									<input
										type="text"
										id="block02"
										className={`${!errors.block02 || "invalid"}`}
										maxLength={4}
										{...register("block02", { required: true })}
										value={valueBlock02}
										onPaste={(e: any) => {
											handleClipboardEvent(e);
										}}
										onChange={(e: any) => {
											setValueBlock02(e.target.value);
										}}
										placeholder="0000"
										required
									/>
									{errors.block02?.type === "required" && (
										<span className="error">Digite valores validos.</span>
									)}
									{errors.block02?.type === "max" && (
										<span className="error">
											É nescessario ter no minimo 4 caraceteres.
										</span>
									)}
								</div>
								<div className="fieldset">
									<input
										type="text"
										id="block03"
										className={`${!errors.block03 || "invalid"}`}
										maxLength={4}
										{...register("block03", { required: true })}
										value={valueBlock03}
										onPaste={(e: any) => {
											handleClipboardEvent(e);
										}}
										onChange={(e: any) => {
											setValueBlock03(e.target.value);
										}}
										placeholder="0000"
										required
									/>
									{errors.block03?.type === "required" && (
										<span className="error">Digite valores validos.</span>
									)}
									{errors.block03?.type === "max" && (
										<span className="error">
											É nescessario ter no minimo 4 caraceteres.
										</span>
									)}
								</div>
								<div className="fieldset">
									<input
										type="text"
										id="block04"
										className={`${!errors.block04 || "invalid"}`}
										maxLength={4}
										{...register("block04", { required: true })}
										value={valueBlock04}
										onPaste={(e: any) => {
											handleClipboardEvent(e);
										}}
										onChange={(e: any) => {
											setValueBlock04(e.target.value);
										}}
										placeholder="0000"
										required
									/>
									{errors.block04?.type === "required" && (
										<span className="error">Digite valores validos.</span>
									)}
									{errors.block04?.type === "max" && (
										<span className="error">
											É nescessario ter no minimo 4 caraceteres.
										</span>
									)}
								</div>
								<div className="fieldset flag">
									<div className="image">
										{cardData && cardData?.brand?.length && (
											<img
												src={
													"./assets/img/icons/cards/" +
													cardData?.brand?.toString().toLowerCase() +
													".png"
												}
												className="band-card"
												alt="Bandeira do cartão"
											/>
										)}
									</div>
								</div>
							</div>
						</div>
						<div className="form-group">
							<div className="fieldset">
								<label htmlFor="expiration-date">Data de Expiração</label>
								<Controller
									name="expirationDate"
									control={control}
									render={({ field: { name, ref } }) => (
										<InputMask
											ref={ref}
											name={name}
											id="expiration-date"
											className={`input ${!errors.expirationDate || "invalid"}`}
											mask="99/9999"
											value={expirationDate}
											placeholder="00/00"
											onChange={(e: any) => {
												setExpirationDate(e.target.value);
											}}
										/>
									)}
								/>
								{errors.expirationDate?.type === "required" && (
									<span className="error">A data de expiração é obrigatória.</span>
								)}
								{errors.expirationDate?.type === "max" && (
									<span className="error">A data de expiração é obrigatória.</span>
								)}
							</div>
							<div className="fieldset cvc">
								<label htmlFor="cvc">CVC</label>
								<input
									type="number"
									id="cvc"
									maxLength={3}
									className={`${!errors.cvc || "invalid"}`}
									{...register("cvc", { required: true })}
									value={cvc}
									onChange={(e: any) => {
										setCvc(e.target.value);
									}}
									placeholder="***"
								/>
								{errors.cvc?.type === "required" && (
									<span className="error">Digite a CVC!</span>
								)}
								{errors.cvc?.type === "max" && (
									<span className="error">
										O CVC precisa ter no minimo 5 caraceteres
									</span>
								)}
							</div>
						</div>
						<div className="fieldset">
							<label htmlFor="name-on-card">Nome no cartão</label>
							<input
								type="text"
								id="name-on-card"
								maxLength={50}
								className={`text-uppercase ${!errors.cardName || "invalid"}`}
								{...register("cardName", { required: true })}
								value={cardName}
								onChange={(e: any) => {
									setCardName(e.target.value);
								}}
								placeholder="Nome no cartão"
							/>
							{errors.cardName?.type === "required" && (
								<span className="error">Digite o Nome do cartão!</span>
							)}
							{errors.cardName?.type === "max" && (
								<span className="error">
									O Nome do cartão precisa ter no minimo 50 caraceteres
								</span>
							)}
						</div>
						<div className="fieldset">
							<label htmlFor="CPF">CPF do Titular:</label>
							<Controller
								name="cpf"
								control={control}
								render={({ field: { name } }) => (
									<CpfCnpj
										id="CPF"
										name={name}
										type="text"
										value={cpf}
										className={`${!errors.cpf || "invalid"}`}
										onChange={(event: any) => {
											setCpf(event.target.value);
										}}
										placeholder="CPF do Titular"
									/>
								)}
							/>
							{errors.cpf?.type === "required" && (
								<span className="error">CPF/CNPJ é obrigatório</span>
							)}
							{errors.cpf?.type === "CPF" && (
								<span className="error">CPF/CNPJ é inválido</span>
							)}
						</div>
						<Button>
							<FontAwesomeIcon icon={faLock} className="mr-2" />
							<span>Pagar</span>
						</Button>
					</form>
					<div className="link-wrapper">
						<Button className="link" onClick={() => setStep("Plan")}>
							<span>Voltar para a etapa anterior.</span>
						</Button>
					</div>
					<div className="text legal-notice">
						<p>
							<span>
								<FontAwesomeIcon icon={faLock} />
							</span>
							&nbsp;
							<span>Este pagamento está salvo e seguro.</span>
						</p>
					</div>
				</div>
			)}
		</>
	);
};

export default EPPayment;
