import CardMapJourney from "../../components/CardMapJourney/CardMapJourney";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PainelMasterPage from "../../components/PainelMasterPage/PainelMasterPage";
import React, { useEffect } from "react";
import { faMap } from "@fortawesome/free-solid-svg-icons";
import { useStages } from "../../providers/StagesProvider";
import GoogleTagManager, { EventEnum } from "../../services/GoogleTagManager";

const JourneyMap = () => {
	const { currentStage } = useStages();

	useEffect(() => {
		GoogleTagManager.event(EventEnum.Marketing, { action: "mapa_da_jornada" });
	}, []);

	return (
		<>
			<PainelMasterPage
				title={"Mapa da Jornada"}
				subtitle={
					"Escolha agora se prefere ir para o UELF Evolution ou para o UELF Education"
				}
				subtitleclass={""}
				icon={<FontAwesomeIcon icon={faMap} />}
				onClick={() => {}}
			>
				<CardMapJourney />
			</PainelMasterPage>
		</>
	);
};

export default JourneyMap;
