import React, { FC } from "react";
import { Link } from "react-router-dom";
import "./Breadcrumb.scss";

interface BreadcrumbProps {
	namePage: string | undefined;
}

const Breadcrumb: FC<BreadcrumbProps> = ({ namePage = "" }) => {
	return (
		<nav className="Breadcrumb" data-testid="Breadcrumb">
			<ol className="breadcrumb">
				<li className="breadcrumb-item">
					<Link to={"/home"}>Home</Link>
				</li>
				<li className="breadcrumb-item">
					<span>...</span>
				</li>
				<li className="breadcrumb-item active" aria-current="page">
					{namePage}
				</li>
			</ol>
		</nav>
	);
};

export default Breadcrumb;
