import api from "./api";

class SuccessRoadmapService {
	async list() {
		try {
			const response = await api.get(`app/plano/performance/roteirosucesso`);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}

	async statusList() {
		try {
			const response = await api.get(`app/status/roteiro/sucesso`);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}

	async statusListSecondary() {
		try {
			const response = await api.get(`app/status/rastreamento/progresso`);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}

	async updateStatusUELF(data: { id: number; status: number }) {
		try {
			const response = await api.post(`app/objetivo/uelf/update/status`, data);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}

	async updateStatusUELFSecondary(data: any) {
		try {
			const response = await api.post(`app/subobjetivo/uelf/update/status`, data);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}

	async updateStatus(data: { id: number; status: number }) {
		try {
			const response = await api.post(`app/metas/secundarias/update/status`, data);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}

	async updateStatusSecondary(data: any) {
		try {
			const response = await api.post(`app/metas/terciarias/update/status`, data);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}

	async updateRatingUELF(data: { id: number; prioridade: number }) {
		try {
			const response = await api.post(`app/objetivo/uelf/update/prioridade`, data);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}

	async updateRating(data: { id: number; prioridade: number }) {
		try {
			const response = await api.post(
				`app/metas/secundarias/update/prioridade`,
				data,
			);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}

	async updateDateUELF(data: { id: number; dataFinal: string }) {
		try {
			const response = await api.post(`app/objetivo/uelf/update/revisao`, data);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}

	async updateDate(data: { id: number; dataFinal: string }) {
		try {
			const response = await api.post(
				`app/metas/secundarias/update/revisao`,
				data,
			);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}

	async createSubObjectiveUELF(data: {
		id?: number;
		idObjetivoUelf: number;
		descricao: string;
		alvo: string;
		importancia: number;
		motivacao: number;
		esforcoMental: number;
		esforcoFisico: number;
		tempoNecessario: number;
		dataInicial: string;
		dataFinal: string;
	}) {
		try {
			const response = await api.post(`app/subobjetivo/uelf/create`, data);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}

	async createSubObjective(data: {
		id?: number;
		idMetasSecundarias: number;
		descricao: string;
		alvo: string;
		importancia: number;
		motivacao: number;
		esforcoMental: number;
		esforcoFisico: number;
		tempoNecessario: number;
		dataInicial: string;
		dataFinal: string;
	}) {
		try {
			const response = await api.post(`app/metas/terciarias/create`, data);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}

	async updateGoalUELF(data: any) {
		try {
			const response = await api.post(`app/objetivo/uelf/update/resposta/meta`, {
				...data,
			});

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}

	async updateGoal(data: any) {
		try {
			const response = await api.post(
				`app/metas/secundarias/update/resposta/meta`,
				{
					...data,
				},
			);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}
}

export default SuccessRoadmapService;
