import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
// import "./App.scss";
import { AuthProvider } from "./providers/AuthProvider";
import RootRoute from "./routes/RootRoute";

function App() {
	return (
		<Router basename={`${process.env.REACT_APP_BASE}`}>
			<AuthProvider>
				<RootRoute />
			</AuthProvider>
		</Router>
	);
}

export default App;
