import "./CardFinishPayments.scss";

import { Controller, useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";

import { Button } from "reactstrap";
import CardPaymentsService from "../../services/CardPaymentsService";
import CpfCnpj from "../CpfCnpj/CpfCnpj";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputMask from "react-input-mask";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { schema } from "./Validate/Validate";
import { toastSettings } from "../../models/toastConfig";
import { usePayments } from "../../providers/PaymentsProviders";
import { yupResolver } from "@hookform/resolvers/yup";
import LoaderPayment from "../LoaderPayment/LoaderPayment";

type FormValues = {
	block01: string;
	block02: string;
	block03: string;
	block04: string;
	expirationDate: string;
	cvc: string;
	cardName: string;
	cpf: string;
	installments: string;
};

const CardFinishPayments = () => {
	const {
		isSubmitting,
		setIsSubmitting,
		create,
		planoEscolhido,
		cardData,
		setCardData,
		data,
	} = usePayments();

	const [valueBlock01, setValueBlock01] = useState("");
	const [valueBlock02, setValueBlock02] = useState("");
	const [valueBlock03, setValueBlock03] = useState("");
	const [valueBlock04, setValueBlock04] = useState("");

	const [expirationDate, setExpirationDate] = useState("");
	const [cardName, setCardName] = useState("");
	const [cvc, setCvc] = useState("");
	const [cpf, setCpf] = useState("");
	const [installments, setInstallments] = useState("49,90-3");
	const [amount, setAmount] = useState("");
	const [test, setTest] = useState([]);

	const {
		register,
		handleSubmit,
		control,
		setValue,
		formState: { errors },
	} = useForm<FormValues>({
		mode: "onChange",
		resolver: yupResolver(schema),
	});

	useEffect(() => {
		const newData = data.filter((p: any) => p.id !== 1);
		setTest(newData);
	}, [data]);

	useEffect(() => {
		if (valueBlock01 || valueBlock02 || valueBlock03 || valueBlock04) {
			checkCardFlag().then();

			setValue("block01", valueBlock01);
			setValue("block02", valueBlock02);
			setValue("block03", valueBlock03);
			setValue("block04", valueBlock04);
		}
	}, [valueBlock01, valueBlock02, valueBlock03, valueBlock04]);

	useEffect(() => {
		if (cvc) {
			setValue("cvc", cvc);
		}
	}, [cvc]);

	useEffect(() => {
		if (expirationDate) {
			setValue("expirationDate", expirationDate);
		}
	}, [expirationDate]);

	useEffect(() => {
		if (cpf) {
			setValue("cpf", cpf);
		}
	}, [cpf]);

	useEffect(() => {
		if (installments) {
			setValue("installments", installments);
		}
	}, [installments]);

	const getValueFormated = (value: string) => {
		const [amount, _] = value.split("-");
		return amount;
	};

	const handleClipboardEvent = (e: any) => {
		e.stopPropagation();
		e.preventDefault();

		let clipboardData, pastedData, filteredData;

		clipboardData = e.clipboardData;
		pastedData = clipboardData.getData("Text");

		filteredData = pastedData.replace(/\D/g, "");

		if (filteredData.length >= 16) {
			setValueBlock01(filteredData.substring(0, 4));
			setValueBlock02(filteredData.substring(4, 8));
			setValueBlock03(filteredData.substring(8, 12));
			setValueBlock04(filteredData.substring(12, 16));
		} else {
			toastSettings(
				"Digite ou cole as credenciais de um cartão valido.",
				"bottom-center",
				"error",
			);
		}
	};

	const checkCardFlag = async () => {
		try {
			let card = valueBlock01 + valueBlock02 + valueBlock03 + valueBlock04;

			if (card.length >= 16) {
				const [_Response, _Error] = await new CardPaymentsService().consultBrand(
					card,
				);

				if (!!_Error || !_Response?.status) {
					toastSettings(_Error || _Response?.message, "bottom-center", "error");
					return false;
				}

				setCardData(_Response.resultSet);
				setIsSubmitting(false);
			}
			
			setIsSubmitting(false);
			return null;
		} catch (err) {
			console.warn(err);
		}
	};

	return (
		<>
			{isSubmitting ? 
				<LoaderPayment/> :
				<div className="CardFinishPayments">
					<div className="row">
						<div className="col col-12 col-md-5 d-none d-md-flex justify-content-center align-items-center">
							<img
								className="mr-9"
								src="./assets/img/pag.png"
								alt="Logo UELF"
								width="100%"
							/>
						</div>
						<div className="col col-12 col-md-7">
							<div className="card">
								<div className="card-header">
									<div className="row">
										<div className="col col-12">
											<h2 className="title">Detalhes do cartão</h2>
										</div>
									</div>
								</div>
								<div className="card-body">
									<form onSubmit={handleSubmit(create)}>
										<div className="container-fluid p-0">
											<div className="row">
												<div className="col col-12 col-md-10">
													<div className="row">
														<div className="col col-12">
															<label>Número do cartão</label>
														</div>
														<div className="col col-12 col-md-3 form-group">
															<div className="fieldset">
																<input
																	type="text"
																	id="block01"
																	className={`${!errors.block01 || "invalid"}`}
																	maxLength={4}
																	{...register("block01", { required: true })}
																	value={valueBlock01}
																	onPaste={(e: any) => {
																		handleClipboardEvent(e);
																	}}
																	onChange={(e: any) => {
																		setValueBlock01(e.target.value);

																		if (e.target.value.length === 4) {
																			// @ts-ignore
																			document.getElementById("block02").focus();
																		}
																	}}
																	required
																/>
																{errors.block01?.type === "required" && (
																	<p className="error">Digite valores validos.</p>
																)}
																{errors.block01?.type === "max" && (
																	<p className="error">
																		É nescessario ter no minimo 4 caraceteres.
																	</p>
																)}
															</div>
														</div>
														<div className="col col-12 col-md-3 form-group">
															<div className="fieldset">
																<input
																	type="text"
																	id="block02"
																	className={`${!errors.block02 || "invalid"}`}
																	maxLength={4}
																	{...register("block02", { required: true })}
																	value={valueBlock02}
																	onPaste={(e: any) => {
																		handleClipboardEvent(e);
																	}}
																	onChange={(e: any) => {
																		setValueBlock02(e.target.value);

																		if (e.target.value.length === 4) {
																			// @ts-ignore
																			document.getElementById("block03").focus();
																		}
																	}}
																	required
																/>
																{errors.block02?.type === "required" && (
																	<p className="error">Digite valores validos.</p>
																)}
																{errors.block02?.type === "max" && (
																	<p className="error">
																		É nescessario ter no minimo 4 caraceteres.
																	</p>
																)}
															</div>
														</div>
														<div className="col col-12 col-md-3 form-group">
															<div className="fieldset">
																<input
																	type="text"
																	id="block03"
																	className={`${!errors.block03 || "invalid"}`}
																	maxLength={4}
																	{...register("block03", { required: true })}
																	value={valueBlock03}
																	onPaste={(e: any) => {
																		handleClipboardEvent(e);
																	}}
																	onChange={(e: any) => {
																		setValueBlock03(e.target.value);

																		if (e.target.value.length === 4) {
																			// @ts-ignore
																			document.getElementById("block04").focus();
																		}
																	}}
																	required
																/>
																{errors.block03?.type === "required" && (
																	<p className="error">Digite valores validos.</p>
																)}
																{errors.block03?.type === "max" && (
																	<p className="error">
																		É nescessario ter no minimo 4 caraceteres.
																	</p>
																)}
															</div>
														</div>
														<div className="col col-12 col-md-3 form-group">
															<div className="fieldset">
																<input
																	type="text"
																	id="block04"
																	className={`${!errors.block04 || "invalid"}`}
																	maxLength={4}
																	{...register("block04", { required: true })}
																	value={valueBlock04}
																	onPaste={(e: any) => {
																		handleClipboardEvent(e);
																	}}
																	onChange={(e: any) => {
																		setValueBlock04(e.target.value);
																	}}
																	required
																/>
																{errors.block04?.type === "required" && (
																	<p className="error">Digite valores validos.</p>
																)}
																{errors.block04?.type === "max" && (
																	<p className="error">
																		É nescessario ter no minimo 4 caraceteres.
																	</p>
																)}
															</div>
														</div>
													</div>

													<div className="row">
														<div className="col col-12 col-md-6 form-group">
															<label htmlFor="expirationDate">Data de Expiração</label>
															<div className="fieldset">
																<Controller
																	name="expirationDate"
																	control={control}
																	render={({ field: { name, ref } }) => (
																		<InputMask
																			ref={ref}
																			name={name}
																			id="expirationDate"
																			className={`input ${!errors.expirationDate || "invalid"}`}
																			mask="99/9999"
																			value={expirationDate}
																			onChange={(e: any) => {
																				setExpirationDate(e.target.value);
																			}}
																		/>
																	)}
																/>
																{errors.expirationDate?.type === "required" && (
																	<p className="error">A data de expiração é obrigatória.</p>
																)}
																{errors.expirationDate?.type === "max" && (
																	<p className="error">A data de expiração é obrigatória.</p>
																)}
															</div>
														</div>
														<div className="col col-12 col-md-4 form-group">
															<label htmlFor="cvc">CVC</label>
															<div className="fieldset">
																<input
																	type="text"
																	id="cvc"
																	maxLength={3}
																	className={`${!errors.cvc || "invalid"}`}
																	{...register("cvc", { required: true })}
																	value={cvc}
																	onChange={(e: any) => {
																		setCvc(e.target.value);
																	}}
																/>
																{errors.cvc?.type === "required" && (
																	<p className="error">Digite a CVC!</p>
																)}
																{errors.cvc?.type === "max" && (
																	<p className="error">
																		O CVC precisa ter no minimo 5 caraceteres
																	</p>
																)}
															</div>
														</div>
														<div className="col col-12 col-md-2 d-none d-md-flex align-items-end justify-content-center form-group">
															<div className="image">
																<img src="assets/img/duvida.png" alt="Duvidas" />
															</div>
														</div>
													</div>
													<div className="row">
														<div className="col col-12 form-group">
															<label htmlFor="cardName">Nome no cartão</label>
															<div className="fieldset">
																<input
																	type="text"
																	id="cardName"
																	maxLength={50}
																	className={`text-uppercase ${!errors.cardName || "invalid"}`}
																	{...register("cardName", { required: true })}
																	value={cardName}
																	onChange={(e: any) => {
																		setCardName(e.target.value);
																	}}
																/>
																{errors.cardName?.type === "required" && (
																	<p className="error">Digite o Nome do cartão!</p>
																)}
																{errors.cardName?.type === "max" && (
																	<p className="error">
																		O Nome do cartão precisa ter no minimo 50 caraceteres
																	</p>
																)}
															</div>
														</div>
													</div>
													<div className="row">
														<div className="col col-12 form-group">
															<label htmlFor="cpf">CPF</label>
															<div className="fieldset">
																<Controller
																	name="cpf"
																	control={control}
																	render={({ field: { name } }) => (
																		<CpfCnpj
																			name={name}
																			type="text"
																			value={cpf}
																			className={`${!errors.cpf || "invalid"}`}
																			onChange={(event: any) => {
																				setCpf(event.target.value);
																			}}
																		/>
																	)}
																/>
																{errors.cpf?.type === "required" && (
																	<p className="error">CPF/CNPJ é obrigatório</p>
																)}
																{errors.cpf?.type === "CPF" && (
																	<p className="error">CPF/CNPJ é inválido</p>
																)}
															</div>
														</div>
													</div>
													<div className="fieldset">
														<label htmlFor="installments">planos:</label>
														<select
															id="installments"
															{...register("installments", { required: true })}
															onChange={(e) => {
																setAmount(e.target.value);
															}}
														>
															{test?.map((p: any, index: number) => (
																<option
																	selected={index === 0}
																	key={index}
																	value={`${p.valor}-${p.id}`}
																>
																	{p.id === 3
																		? "Mensal"
																		: "À Vista (equivalente a 39,90 por mês , 1 ano de acesso)"}
																</option>
															))}
														</select>
														{errors.installments && (
															<span className="error">Este campo é obrigatório</span>
														)}
													</div>
													<div className="row">
														<div className="col col-12 form-group">
															<p className="price-wrapper">
																<span>Total a pagar:</span>
																<span className="price">
																	<b>R$ {amount ? getValueFormated(amount) : "49,90"}</b>
																</span>
															</p>
														</div>
													</div>
												</div>
												<div className="col col-12 col-md-2 text-center d-none d-md-inline-block">
													{cardData && cardData?.brand?.length && (
														<img
															src={
																"./assets/img/icons/cards/" +
																cardData?.brand?.toString().toLowerCase() +
																".png"
															}
															className="band-card"
															alt="logo cartão"
														/>
													)}
												</div>
											</div>
											<div className="row">
												<div className="col col-12 d-flex justify-content-end">
													<Button type="submit" disabled={isSubmitting}>
														<FontAwesomeIcon icon={faLock} className="mr-2" />
														<span>Pagar agora</span>
													</Button>
												</div>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div> 
			}		
		</>
	);
};

export default CardFinishPayments;
