import api from "./api";

class CardMapJourneyService {
	async list() {
		try {
			const response = await api.get("app/mapa/jornada/lista");

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}
}

export default CardMapJourneyService;
