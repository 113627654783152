import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import CardSurveyFinish from "../../components/CardSurveyFinish/CardSurveyFinish";
import PainelMasterPage from "../../components/PainelMasterPage/PainelMasterPage";
import { useStages } from "../../providers/StagesProvider";
import GoogleTagManager, { EventEnum } from "../../services/GoogleTagManager";
import Slugify from "../../utils/Slugify";
import "./SurveyFinish.scss";

const SurveyFinish = () => {
	const { currentStage } = useStages();

	useEffect(() => {
		GoogleTagManager.event(EventEnum.Marketing, { action: Slugify("conclusao") });
	}, [])
	
	return (
		<PainelMasterPage
			title={"Conclusão da sua avaliação"}
			subtitle={
				"Tenha o controle da sua vida, verifique os pontos para você evoluir mais!"
			}
			subtitleclass={""}
			icon={<FontAwesomeIcon icon={faCheck} />}
			onClick={() => {}}
		>
			<>
				<CardSurveyFinish />
			</>
		</PainelMasterPage>
	);
};

export default SurveyFinish;
