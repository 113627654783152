import "./EPPlan.scss";

import React, { useEffect, useState } from "react";

import { Button } from "reactstrap";
import ExternalPaymentService from "../../services/ExternalPaymentService";
import { toastSettings } from "../../models/toastConfig";
import { useExternalPayment } from "../../providers/ExternalPaymentProvider";

const EPPlan = () => {
	const [data, setData] = useState<any>([]);
	const { setStep, setPlan, loading, setLoading } = useExternalPayment();

	useEffect(() => {
		handleList().then();
	}, []);

	const handleList = async () => {
		try {
			const [_Response, _Error] = await new ExternalPaymentService().listPlans();

			if (!!_Error || !_Response?.status) {
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
				return;
			}

			setData(_Response.resultSet);
		} catch (err) {
			console.warn(err);
		}
	};

	const selectPlan = async (plan: number) => {
		setPlan(plan);
		setStep("Payment");
		setLoading(false);
	};

	const renderPlans = () => {
		return (
			<>
				<h1 className="title">{data?.length} planos criados para você:</h1>
				{data?.map((item: any, index: number) => {
					return (
						<div className="plan-op-wrapper" key={index}>
							<div className="content">
								<span className="title">{item.nomePlano}</span>
								<div className="text">
									{item.detalhes.map((detail: any, index: number) => {
										return <p key={index}>{detail.descricao}</p>;
									})}
								</div>
							</div>
							<div className="actions-wrapper">
								<div className="price">
									<span className="value">{item?.valor ? item.valor : "00,00"}</span>
									<span className="label">
										{item.descricaoTipoPlano === "Mensal"
											? item.descricaoTipoPlano
											: "À vista"}
									</span>
								</div>
								<Button
									disabled={loading}
									onClick={() => {
										setLoading(true);
										selectPlan(item.id);
									}}
								>
									Escolher plano
								</Button>
							</div>
						</div>
					);
				})}
			</>
		);
	};

	return (
		<div className="ep-plan step-card">
			{data && renderPlans()}
			<div className="link-wrapper">
				<Button className="link" onClick={() => setStep("Registration")}>
					<span>Voltar para a etapa anterior.</span>
				</Button>
			</div>
			<div className="text legal-notice">
				<p>
					Ao preencher o formulário, você permite que a UELF salve suas informações.
				</p>
			</div>
		</div>
	);
};

export default EPPlan;
