import api from "./api";

class CardSurveyFinishService {
	async finish() {
		try {
			const response = await api.get(`app/subrecurso/finalizar/questionario`);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}
}

export default CardSurveyFinishService;
