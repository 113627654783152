import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { toastSettings } from "../../models/toastConfig";
import CardSurveyFinishService from "../../services/CardSurveyFinishService";
import "./CardSurveyFinish.scss";

interface CardSurveyFinishProps {}

const CardSurveyFinish: FC<CardSurveyFinishProps> = () => {
	const navigate = useNavigate();

	const finishSurvey = async () => {
		try {
			const [_Response, _Error] = await new CardSurveyFinishService().finish();

			if (!!_Error || !_Response?.status) {
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
				return false;
			}

			navigate("/diagnose");
		} catch (err) {
			console.warn(err);
		}
	};

	return (
		<div className="CardSurveyFinish" data-testid="CardSurveyFinish">
			<div className="wrapper">
				<div className="content text">
					<div className="icon">
						<img src="https://imgur.com/Ca7e86y.png" alt="Positivo" />
					</div>
					<div className="title">
						<span>Parabéns, você acaba de concluir a sua avaliação!</span>
					</div>
					<div className="text">
						<p>
							A partir daqui iremos lhe apresentar alguns pontos em que você deve tomar
							atenção e uma lista com atividades que irão ajudar a destravar o seu
							potencial.
						</p>
					</div>
					<div className="actions">
						<button
							type="button"
							className="form-control"
							onClick={() => {
								finishSurvey();
							}}
						>
							Ver Resultado
						</button>
					</div>
				</div>
				<div className="content image"></div>
			</div>
		</div>
	);
};

export default CardSurveyFinish;
