import api from "./api";

class ToDoAvoidService {
	async list() {
		try {
			const response = await api.get(`app/lista/evitar/fazer/lista`);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}

	async create(data: any) {
		try {
			const response = await api.post(
				`app/lista/evitar/fazer/usuario/create`,
				data,
			);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}

	async post(data: any) {
		try {
			const response = await api.post(
				`app/lista/evitar/fazer/create/resposta`,
				data,
			);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}
}

export default ToDoAvoidService;
