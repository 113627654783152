import "./RadioButton.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import { Input } from "reactstrap";

interface IRadioButton {
	id: string;
	name: string;
	state: boolean;
	value: string;
	label: string;
	dataQuestionId: number;
}

const RadioButton = ({
	id,
	name,
	state,
	value,
	label,
	dataQuestionId,
}: IRadioButton): JSX.Element => {
	const [checked, setChecked] = useState(state ?? false);

	return (
		<div className="radio-wrapper">
			<Input
				type="radio"
				id={id}
				name={name}
				defaultChecked={checked}
				data-question-id={dataQuestionId}
				onChange={(e: any) => {
					setChecked(e.target.value);
				}}
				value={value}
			/>

			<label htmlFor={id}>
				<div className="radio">
					<FontAwesomeIcon icon={faCheck} className="icon" />
				</div>
				<span>{label}</span>
			</label>
		</div>
	);
};

export default RadioButton;
