import React, { useState } from "react";

export interface GoalState {
	id: number;
	isSelectedEdit: boolean;
	isSelectedDelete: boolean;
}

interface ForEditGoal {
	id: number;
	idRecurso: number;
	pergunta: string;
	resposta: string;
}

export const MacroGoalsContext = React.createContext<any>({});

export const MacroGoalsProvider = (props: any) => {
	const [goals, setGoals] = useState<GoalState>();

	const [editGoal, setEditGoal] = useState<ForEditGoal | null>(null);

	const [viewDelete, setViewDelete] = useState<boolean>(false);
	const [wasConfirmDeletion, setWasConfirmDeletion] = useState<boolean>(false);

	const [isMultipleSelected, setIsMultipleSelected] = useState<boolean>(false);

	return (
		<MacroGoalsContext.Provider
			value={{
				goals,
				setGoals,
				viewDelete,
				setViewDelete,
				wasConfirmDeletion,
				setWasConfirmDeletion,
				editGoal,
				setEditGoal,
				isMultipleSelected,
				setIsMultipleSelected,
			}}
		>
			{props.children}
		</MacroGoalsContext.Provider>
	);
};

export const useMacroGoals = () => React.useContext(MacroGoalsContext);
