import "./ChartEvolutionComfortZonesItem.scss";

import * as am5 from "@amcharts/amcharts5";
import * as am5hierarchy from "@amcharts/amcharts5/hierarchy";

import React, { useEffect } from "react";

import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

const ChartEvolutionComfortZonesItem = ({ item, index }: any) => {
	let root: any = null;

	useEffect(() => {
		initChart();
	}, []);

	const initChart = () => {
		am5.ready(() => {
			if (root !== null) {
				return false;
			}

			root = am5.Root.new(`chart-wrapper-${index}`);

			root.setThemes([am5themes_Animated.new(root)]);

			let container = root.container.children.push(
				am5.Container.new(root, {
					width: am5.percent(100),
					height: am5.percent(100),
					layout: root.verticalLayout,
				}),
			);

			let series = container.children.push(
				am5hierarchy.ForceDirected.new(root, {
					singleBranchOnly: false,
					downDepth: 1,
					initialDepth: 2,
					valueField: "value",
					categoryField: "name",
					childDataField: "children",
					centerStrength: 0.5,
					minRadius: am5.percent(6),
					maxRadius: am5.percent(10),
				}),
			);

			series.nodes.template.set("tooltipText", "{tooltipText}: [bold]{value}[/]");
			series.circles.template.setAll({ templateField: "nodeSettings" });

			let data = {
				name: item.descricao,
				value: item.prioridade,
				tooltipText: "Prioridade",
				nodeSettings: {
					fill: am5.color("#AECD58"),
				},
				children: new Array(),
			};

			item.tarefas.forEach((item: any, index: number) => {
				data.children.push({
					name: item.descricao,
					value: item.prioridade,
					tooltipText: "Prioridade",
					nodeSettings: {
						fill: am5.color("#7CA683"),
					},
				});
			});

			series.data.setAll([data]);
			series.appear(1000, 100);
		});
	};

	return <div id={`chart-wrapper-${index}`} className="chart-wrapper"></div>;
};

export default ChartEvolutionComfortZonesItem;
