export default function FormatNote(num: any) {
	let note: any = parseFloat(num);

	if (isNaN(note)) {
		return "--";
	}

	if (Number.isInteger(note)) {
		// note = note.toFixed(1);
		note = note.toFixed(2);
	} else {
		note = note.toFixed(2);
	}

	return note;
}
