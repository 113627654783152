import moment from "moment";
import React, { FC } from "react";
import { Table } from "reactstrap";
import "./CalendarTableComponent.scss";

interface CalendarTableComponentProps {
	data: any[];
}

const CalendarTableComponent: FC<CalendarTableComponentProps> = ({ data }) => {
	return (
		<div className="CalendarTableComponent" data-testid="CalendarTableComponent">
			<Table>
				<thead>
					<tr>
						<th>Dia</th>
						<th>Tarefa</th>
						<th>Evolução</th>
					</tr>
				</thead>
				<tbody>
					{data.length > 0 ? (
						data.map((item: any, key: number) => {
							return (
								<>
									<tr key={key}>
										<td>{moment(item.dataInicial).format("DD/MM/YYYY")}</td>
										<td>{item.descricao}</td>
										<td>{item.descricaoStatus}</td>
									</tr>
								</>
							);
						})
					) : (
						<>
							<tr>
								<td colSpan={3} className="text-center">
									Não há registros disponíveis!
								</td>
							</tr>
						</>
					)}
				</tbody>
			</Table>
		</div>
	);
};

export default CalendarTableComponent;
