import "./CheckBox.scss";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Input } from "reactstrap";

interface ICheckBox {
	id: string;
	name: string;
	state: boolean;
	value: string;
	label: string;
	dataQuestionId: number;
	onChange?: () => void;
	changeOpt?: boolean;
}

const CheckBox = ({
	id,
	name,
	state,
	value,
	label,
	dataQuestionId,
	onChange,
	changeOpt = false,
	...rest
}: ICheckBox): JSX.Element => {
	const [checked, setChecked] = useState(state ?? false);

	return (
		<div className="checkbox-wrapper">
			<Input
				type="checkbox"
				id={id}
				name={name}
				defaultChecked={checked}
				data-question-id={dataQuestionId}
				onChange={(e: any) => {
					if (changeOpt) {
						// @ts-ignore
						onChange();
					}
					setChecked(e.target.value);
				}}
				value={value}
				{...rest}
			/>
			<label htmlFor={id}>
				<FontAwesomeIcon icon={faCheck} className="icon" />
				<span>{label}</span>
			</label>
		</div>
	);
};

export default CheckBox;
