import React, { useEffect, useState } from "react";
import { toastSettings } from "../models/toastConfig";
import ToDoAvoidService from "../services/ToDoAvoidService";
import { useStages } from "./StagesProvider";

export const ToDoAvoidContext = React.createContext<any>({});

export const ToDoAvoidProvider = (props: any) => {
	const [data, setData] = useState<any>([]);
	const [itemSelected, setItemSelected] = useState<any | null>(null);
	const [showModalAdd, setShowModalAdd] = useState<boolean>(false);
	const { setCurrentStage } = useStages();

	const handleList = async () => {
		try {
			const [_Response, _Error] = await new ToDoAvoidService().list();

			if (!!_Error || !_Response?.status) {
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
				return;
			}

			setData(_Response.resultSet);
		} catch (err) {
			console.warn(err);
		}
	};

	useEffect(() => {
		if (data) {
			for (let i = 0; i < data?.subrecursos?.length; i++) {
				if (!data?.subrecursos[i].status) {
					setCurrentStage(i);
					break;
				}
			}
		}
	}, [data]);

	useEffect(() => {
		setItemSelected(null);
		setShowModalAdd(false);
	}, []);

	return (
		<ToDoAvoidContext.Provider
			value={{
				data,
				setData,
				handleList,
				showModalAdd,
				setShowModalAdd,
				itemSelected,
				setItemSelected,
			}}
		>
			{props.children}
		</ToDoAvoidContext.Provider>
	);
};

export const useToDoAvoid = () => React.useContext(ToDoAvoidContext);
