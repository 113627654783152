import api from "./api";

class JourneyBackpackService {
	async list() {
		try {
			const response = await api.get(`app/mochila/jornada/lista`);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}

	async listObjetivos() {
		try {
			const response = await api.get(`app/objetivo/uelf/lista/todos`);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}

	async create(data: any) {
		try {
			data.isUelf = data.idObjetivo.isUelf;
			data.idObjetivo = data.idObjetivo.value;

			const response = await api.post(`app/mochila/jornada/create`, data);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Os dados informados não conferem, por favor verique os dados e tente novamente.",
			];
		}
	}

	async remove(id: number) {
		try {
			const response = await api.post(`app/mochila/jornada/delete`, {
				itens: [
					{
						id,
					},
				],
			});

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Os dados informados não conferem, por favor verique os dados e tente novamente.",
			];
		}
	}
}

export default JourneyBackpackService;
