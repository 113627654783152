import { toast, ToastPosition, TypeOptions } from "react-toastify";

export const toastSettings = (
	message: string,
	position: ToastPosition = "bottom-center",
	type: TypeOptions = "warning",
	onClose?: () => void,
) => {
	toast(message, {
		position: position,
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		type,
		theme: "dark",
		onClose: () => onClose,
	});
};
