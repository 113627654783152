import { faChartLine } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CardEvolutionJourney from "../../components/CardEvolutionJourney/CardEvolutionJourney";
import PainelMasterPage from "../../components/PainelMasterPage/PainelMasterPage";
import { useActiveJourney } from "../../providers/ActiveJourneyProvider";
import "./EvolutionJourney.scss";

const EvolutionJourney = () => {
	const { dataUser } = useActiveJourney();
	const navigate = useNavigate();

	useEffect(() => {
		if (dataUser && dataUser?.idPlano <= 1) {
			navigate("/home");
		}
	}, [dataUser]);

	return (
		<>
			<PainelMasterPage
				title={"UELF Evolution"}
				subtitle={""}
				subtitleclass={""}
				icon={<FontAwesomeIcon icon={faChartLine} />}
				onClick={() => {}}
			>
				<CardEvolutionJourney />
			</PainelMasterPage>
		</>
	);
};

export default EvolutionJourney;
