import "./CardPerformancePanel.scss";

import React, { FC, useEffect, useState } from "react";

import CardPerformancePainelService from "../../services/CardPerfomancePanelService";
import { Link } from "react-router-dom";
import { toastSettings } from "../../models/toastConfig";

interface CardPerformancePanelProps {}

const CardPerformancePanel: FC<CardPerformancePanelProps> = () => {
	const [data, setData] = useState([]);

	useEffect(() => {
		list().then();
	}, []);

	const list = async () => {
		try {
			const [_Response, _Error] = await new CardPerformancePainelService().list();

			if (!!_Error || !_Response?.status) {
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
				return false;
			}

			setData(_Response.resultSet);
		} catch (err) {
			console.warn(err);
		}
	};

	const handleMenu = () => {
		if (data) {
			return (
				<div className="CardPerformancePanel" data-testid="CardPerformancePanel">
					{data?.length > 0 &&
						data.map((item: any, index: number) => (
							<div
								key={index}
								className={"group" + (!item.status ? " opacity-disabled" : "")}
							>
								<div className="title">
									<span>{item.descricao}</span>
								</div>
								<div className="text">
									<p>{item.subTitulo}</p>
								</div>
								<div className="button">
									<Link to={item.status ? item.link : ""}>
										<button type="submit" disabled={!item.status} className="btn-button">
											<span>Visualizar</span>
										</button>
									</Link>
								</div>
							</div>
						))}
				</div>
			);
		}
	};

	return <>{handleMenu()}</>;
};

export default CardPerformancePanel;
