import "./ProgressTrackerItem.scss";

import { Card, Collapse, FormGroup, Label } from "reactstrap";
import { FC, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormataData from "../../utils/FormataDate";
import ProgressTrackerSubItem from "../ProgressTrackerSubItem/ProgressTrackerSubItem";
import { faShareSquare } from "@fortawesome/free-solid-svg-icons";

interface ProgressTrackerItemProps {
	data: any;
	isUelf: boolean;
}

const ProgressTrackerItem: FC<ProgressTrackerItemProps> = ({
	data,
	isUelf,
}) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);

	return (
		<div
			id={"item-" + data?.id}
			className={`progress-tracker-item card ${isUelf ? "uelf" : ""}`}
			data-testid="ProgressTrackerItem"
			key={data?.id}
		>
			<FormGroup check inline>
				<Label>
					<Card>
						<div className="progress-tracker-item-row title-row">
							<div className="progress-tracker-item-col">
								<span className="title">Meta</span>
							</div>
							<div className="progress-tracker-item-col">
								<span className="title">Objetivo {isUelf ? "UELF" : ""}</span>
							</div>
							<div className="progress-tracker-item-col">
								<span className="title">Revisão</span>
							</div>
							<div className="progress-tracker-item-col">
								<span className="title">Status</span>
							</div>
							<div className="progress-tracker-item-col"></div>
						</div>
						<div className="progress-tracker-item-row content-row">
							<div className="progress-tracker-item-col">
								<span className="mb-label">Meta:</span>
								<span className="content">{data?.pergunta}</span>
							</div>
							<div className="progress-tracker-item-col">
								<span className="mb-label">Objetivo {isUelf ? "UELF" : ""}:</span>
								<span className="content">{data?.respostaMeta}</span>
							</div>
							<div className="progress-tracker-item-col">
								<span className="mb-label">Revisão:</span>
								<span className="content">{FormataData(data?.dataInicial)}</span>
							</div>
							<div className="progress-tracker-item-col">
								<span className="mb-label">Status:</span>
								<span className="content">{data?.descricaoStatus}</span>
							</div>
							<div className="progress-tracker-item-col">
								<span className="content">
									<div className="btn-open" onClick={() => setIsOpen(!isOpen)}>
										<FontAwesomeIcon icon={faShareSquare} />
									</div>
								</span>
							</div>
						</div>
					</Card>
				</Label>
			</FormGroup>

			<Collapse isOpen={isOpen}>
				<ProgressTrackerSubItem
					data={data?.subobjetivos}
					id={data?.id}
					objetivo={data?.resposta}
					percentual={parseInt(data?.percentualProgresso)}
					isUelf={isUelf}
				/>
			</Collapse>
		</div>
	);
};

export default ProgressTrackerItem;
