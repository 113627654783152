import api from "./api";

class MenuService {
	async getStop(idJourney: number) {
		try {
			const response = await api.get(
				`app/jornada/controleConclusaoJornada/${idJourney}`,
			);
			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}
}

export default MenuService;
