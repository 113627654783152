import RemoveAcento from "./RemoveAcento";

const Slugify = (str: string | number): string => {
    return RemoveAcento(
        str.toString()
    )
        .trim()
        .toLowerCase()
        .replace(/\s+/g, "_")
        .replace(/[^\w\-]+/g, "")
        .replace(/\-\-+/g, "_")
        .replace(/^-+/, "")
        .replace(/-+$/, "");
};

export default Slugify;