import "./DashboardTrackScore.scss";

const DashboardTrackScore = ({ data }: any) => {
	const renderStatus = (status: string) => {
		switch (status) {
			case "COMPLETED":
				return "Concluido.";
			case "NOT_STARTED":
				return "Não iniciado.";
			default:
				return "Em andamento.";
		}
	};

	return (
		<div className="DashboardTrackScore card" data-testid="DashboardTrackScore">
			<div className="list-wrapper">
				<div className="title-wrapper">
					<span className="title">Education - Trilhas de aprendizado</span>
					<span className="subtitle">Veja todos os seus cursos:</span>
				</div>
				{data.cursos.map((item: any, index: number) => (
					<div className="wrapper" key={index}>
						<div className="item">
							<span className="title">Nome da Trilha:</span>
							<span>{item.course_title}</span>
						</div>
						<div className="item">
							<span className="title">Status:</span>
							<span>{renderStatus(item.course_status)}</span>
						</div>
					</div>
				))}
			</div>
			<div className="note-wrapper">
				<div className="note">
					<span className="title">{data.notaAluno}</span>
					<span className="subtitle">Pontos</span>
				</div>
			</div>
		</div>
	);
};

export default DashboardTrackScore;
