import api from "./api";

class PurposeService {
	async list(id: number) {
		try {
			const response = await api.get(`app/objetivo/lista/${id}`);
			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}

	async listAll() {
		try {
			const response = await api.get(`app/objetivo/uelf/lista/todos`);
			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}

	async post(data: any, imagem: File | undefined) {
		try {
			let formdata = new FormData();

			formdata.append("idJornadaObjetivo", data?.idJornadaObjetivo);
			formdata.append("respostas", JSON.stringify(data?.respostas));

			if (imagem) {
				formdata.append("anexo", imagem);
			}

			const response = await api.post(`app/objetivo/create/resposta`, formdata);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}
}

export default PurposeService;
