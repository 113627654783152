import "./Survey.scss";

import React, { useEffect, useState } from "react";
import { faCheck, faFileLines } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PainelMasterPage from "../../components/PainelMasterPage/PainelMasterPage";
import { Progress } from "reactstrap";
import SurveyRadioButton from "../../components/SurveyRadioButton/SurveyRadioButton";
import SurveyService from "../../services/SurveyService";
import { ToastContainer } from "react-toastify";
import { toastSettings } from "../../models/toastConfig";
import { useNavigate } from "react-router-dom";
import { useSurvey } from "../../providers/SurveyProvider";
import GoogleTagManager, { EventEnum } from "../../services/GoogleTagManager";
import Slugify from "../../utils/Slugify";

const Survey = () => {
	const [data, setData] = useState<any>();
	const [idJourneyProcess, setIdJourneyProcess] = useState<number>();
	const [responseSent, setResponseSent] = useState<boolean>(false);

	const {
		selfEvaluation,
		setSelfEvaluation,
		habits,
		setHabits,
		setCurrentStage,
		setCurrentStageName,
		setCurrentStageIcon,
	} = useSurvey();

	const navigate = useNavigate();

	useEffect(() => {
		handleList().then();
	}, [responseSent]);

	useEffect(() => {
		checkStatus().then((response) => {
			if (response) {
				navigate("/feelings");
			}
		});
	}, [data]);

	useEffect(() => {
		if (data) {
			data.questoes.questoes.autoavaliacao.alternativas.forEach((item: any) => {
				if (item.respostas) {
					setSelfEvaluation({
						idQuestao: data.questoes.questoes.autoavaliacao.idQuestao,
						idSubRecursoQuestaoAlternativa: item.id,
						resposta: item.valor,
					});
				}
			});

			data.questoes.questoes.habitos.alternativas.forEach((item: any) => {
				if (item.respostas) {
					setHabits({
						idQuestao: data.questoes.questoes.habitos.idQuestao,
						idSubRecursoQuestaoAlternativa: item.id,
						resposta: item.valor,
					});
				}
			});
		}
	}, [data]);

	const findId = (array: any) => {
		return array.findIndex((item: any) => item.status === false);
	};

	const checkStatus = async () => {
		if (data) {
			let currentStageIndex = data.recursos.findIndex(
				(item: any) => item.sentimentoRespondido === false,
			);

			if (currentStageIndex === -1) {
				navigate("/survey-finish");
				return;
			}

			let answered = data.recursos[currentStageIndex].sentimentoRespondido;
			let currentStageStatus = data.recursos[currentStageIndex].status;

			setCurrentStage(data.recursos[currentStageIndex].id);

			if (currentStageStatus === true && answered === false) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	};

	const handleList = async (idResource?: any, idJourneyProcess?: any) => {
		if (!idResource && !idJourneyProcess) {
			try {
				const [_Response, _Error] = await new SurveyService().list();

				if (!!_Error || !_Response?.status) {
					toastSettings(_Error || _Response?.message, "bottom-center", "error");
					return;
				}

				setData(_Response.resultSet);
				setIdJourneyProcess(_Response.resultSet.questoes.idProcessoJornada);
			} catch (err) {
				console.warn(err);
			}
		} else if (idResource) {
			const data = {
				idRecurso: idResource,
			};

			try {
				const [_Response, _Error] = await new SurveyService().list(data);

				if (!!_Error || !_Response?.status) {
					toastSettings(_Error || _Response?.message, "bottom-center", "error");
					return;
				}

				setData(_Response.resultSet);
				setIdJourneyProcess(_Response.resultSet.questoes.idProcessoJornada);
			} catch (err) {
				console.warn(err);
			}
		} else if (idJourneyProcess) {
			const data = {
				idProcessoJornada: idJourneyProcess,
			};

			try {
				const [_Response, _Error] = await new SurveyService().list(data);

				if (!!_Error || !_Response?.status) {
					toastSettings(_Error || _Response?.message, "bottom-center", "error");
					return;
				}

				setData(_Response.resultSet);
				setIdJourneyProcess(_Response.resultSet.questoes.idProcessoJornada);
			} catch (err) {
				console.warn(err);
			}
		}
	};

	const renderIndicators = () => {
		let progressMax = data.recursos.length;
		let progressValue = data.recursos.filter(
			(item: any) => item.status === true && item.sentimentoRespondido === true,
		).length;

		return (
			<div className="nav-indicators">
				<div className="progress-bar-status">
					<span>
						Completo: {progressValue}/{progressMax}
					</span>
					<Progress max={progressMax} value={progressValue} />
				</div>
				{data.recursos.map((item: any, index: number) => {
					return (
						<button
							className="indicator"
							key={index}
							onClick={() => {
								handleList(item.id);
							}}
						>
							<div
								className="indicator-icon"
								style={{
									backgroundImage: item.icone,
									backgroundColor: item.corFundo,
									borderColor: item.corFundo,
								}}
							>
								<img src={`${item.icone}`} alt="" />
							</div>
							<div className="indicator-description">
								<span>{item.descricao}</span>
							</div>
							<div
								className={`indicator-check-icon${
									item.status && item.sentimentoRespondido ? " completed" : ""
								}`}
							>
								<FontAwesomeIcon icon={faCheck} />
							</div>
						</button>
					);
				})}
			</div>
		);
	};

	const renderQuestionsNav = () => {
		return (
			<div className="questions-nav">
				<div className="items-wrapper">
					{data.subRecursos.map((item: any, index: number) => {
						data.questoes.idSubRecurso === item.idSubRecurso && GoogleTagManager.event(EventEnum.Marketing, { action: Slugify("questionario_" + item.descricaoSubRecurso) });
						return (
							<button
								className={`item${
									data.questoes.idSubRecurso === item.idSubRecurso ? " is-active" : ""
								}`}
								key={index}
								onClick={() => {
									handleList(null, item.idProcessoJornada);
								}}
							>
								<span>{item.descricaoSubRecurso}</span>
							</button>
						);
					})}
				</div>
			</div>
		);
	};

	const renderAlternatives = (
		questionId: number,
		cat: string,
		type: string,
		alternatives: [any],
	) => {
		if (type === "radio") {
			return (
				<div className={`alternatives-wrapper ${cat}`}>
					{alternatives.map((item: any, index: number) => (
						<SurveyRadioButton
							key={index}
							id={item.id}
							idName={`${cat}-alternative-${item.id}`}
							name={`${cat}-alternative`}
							questionID={questionId}
							cat={cat}
							value={item.valor}
							label={item.descricao}
						/>
					))}
				</div>
			);
		} else {
			return (
				<div className="alternatives-wrapper">
					<span className="error-message">
						Não foi possivel carregar as alternativas.
					</span>
				</div>
			);
		}
	};

	const recordResponses = async () => {
		const data = {
			idProcessoJornada: idJourneyProcess,
			respostas: {
				autoavaliacao: selfEvaluation,
				habito: habits,
			},
		};

		try {
			const [_Response, _Error] = await new SurveyService().recordResponses(data);

			if (!!_Error || !_Response?.status) {
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
				return false;
			}

			setSelfEvaluation(null);
			setHabits(null);
			setResponseSent(!responseSent);
			return true;
		} catch (err) {
			console.warn(err);
		}
	};

	const recordResponsesRedirect = async () => {
		const data = {
			idProcessoJornada: idJourneyProcess,
			respostas: {
				autoavaliacao: selfEvaluation,
				habito: habits,
			},
		};

		try {
			const [_Response, _Error] = await new SurveyService().recordResponses(data);

			if (!!_Error || !_Response?.status) {
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
				navigate("/survey-finish");
			}
		} catch (err) {
			console.warn(err);
		}
	};

	const endStep = async () => {
		recordResponses().then((response) => {
			if (response) {
				navigate("/feelings");
			}
		});
	};

	const renderBtn = () => {
		let totalStages = data.recursos.length;
		let currentStage = data.recursos.filter(
			(item: any) => item.status === true,
		).length;

		if (currentStage === totalStages) {
			return (
				<button
					className="main-btn"
					onClick={() => {
						recordResponsesRedirect();
					}}
				>
					<span>Continuar minha jornada</span>
				</button>
			);
		}

		let totalSteps = data.subRecursos.length;
		let currentStep =
			data.subRecursos.filter((item: any) => item.status === true).length + 1;
		let currentStepStatus = data.questoes.status;

		let currentStageStatus = findId(data.recursos);
		let answered = data.recursos[currentStageStatus].sentimentoRespondido;

		setCurrentStageName(data.recursos[currentStageStatus].descricao);
		setCurrentStageIcon(data.recursos[currentStageStatus].icone);

		if (
			currentStep === totalSteps &&
			currentStepStatus === false &&
			answered === false
		) {
			return (
				<button
					className="main-btn"
					onClick={() => {
						setCurrentStage(currentStageStatus + 1);
						endStep();
					}}
				>
					<span>Finalizar</span>
				</button>
			);
		} else {
			return (
				<button
					className="main-btn"
					onClick={() => {
						recordResponses();
					}}
				>
					<span>Avançar</span>
				</button>
			);
		}
	};

	const renderQuestions = () => {
		const style = {
			"--active-color": data.questoes.corTexto,
			"--active-bg": data.questoes.corFundo,
		} as React.CSSProperties;

		return (
			<div className="questions-wrapper" style={style}>
				{renderQuestionsNav()}
				<div className="description">
					<span>{data.questoes.descricaoSubRecurso}</span>
				</div>
				<div className="question">
					<div className="label">
						<span>{data.questoes.questoes.autoavaliacao.descQuestao}</span>
					</div>
				</div>
				{renderAlternatives(
					data.questoes.questoes.autoavaliacao.idQuestao,
					"sa",
					data.questoes.questoes.autoavaliacao.tipoquestao,
					data.questoes.questoes.autoavaliacao.alternativas,
				)}
				<br />
				<div className="description">
					<span>Hábitos</span>
				</div>
				<div className="question">
					<div className="label">
						<span>{data.questoes.questoes.habitos.descQuestao}</span>
					</div>
				</div>
				{renderAlternatives(
					data.questoes.questoes.habitos.idQuestao,
					"habits",
					data.questoes.questoes.habitos.tipoquestao,
					data.questoes.questoes.habitos.alternativas,
				)}
				{renderBtn()}
			</div>
		);
	};

	return (
		<PainelMasterPage
			title={"Questionário"}
			subtitle={
				"Assuma o controle da sua vida, através da sua pontuação, conseguiremos identificar onde você precisa melhorar"
			}
			subtitleclass={""}
			icon={<FontAwesomeIcon icon={faFileLines} />}
			onClick={() => {}}
		>
			<div className="survey">
				{data ? renderIndicators() : ""}
				{data ? renderQuestions() : ""}
			</div>
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</PainelMasterPage>
	);
};

export default Survey;
