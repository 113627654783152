import "./SuccessRoadmap.scss";

import React, { useEffect, useState } from "react";

import ButtonNextScreen from "../../components/ButtonNextScreen/ButtonNextScreen";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loading from "../../components/Loading/Loading";
import PainelMasterPage from "../../components/PainelMasterPage/PainelMasterPage";
import SuccessRoadmapItem from "../../components/SuccessRoadmapItem/SuccessRoadmapItem";
import SuccessRoadmapService from "../../services/SuccessRoadmapService";
import SuccessRoadmapSubObjectiveModal from "../../components/SuccessRoadmapSubObjectiveModal/SuccessRoadmapSubObjectiveModal";
import { ToastContainer } from "react-toastify";
import { faListCheck } from "@fortawesome/free-solid-svg-icons";
import { toastSettings } from "../../models/toastConfig";
import { useActiveJourney } from "../../providers/ActiveJourneyProvider";
import { useNavigate } from "react-router-dom";
import { useSuccessRoadmap } from "../../providers/SuccessRoadmapProvider";

export interface IData {
	objetivoUELF?: ObjetivoUELF;
	objetivos?: Objetivo[];
}

export interface ObjetivoUELF {
	id: number;
	idRecurso: number;
	descricaoRecurso: string;
	idJornada: number;
	idUsuario: number;
	pergunta: string;
	resposta: string;
	prioridade: number;
	status: number;
	dataInicial: string;
	dataFinal: string;
	descricaoProximoPasso: string;
	descricaoStatus: string;
	idProximoPasso: number;
	proximoPasso: string;
	acaoCorreta: string;
	opiniaoAmigos: string;
	avalicaoGeral: number;
	subobjetivos: Subobjetivo[];
	modify: boolean;
	percentualProgresso: number;
}

export interface Objetivo {
	id: number;
	idRecurso: number;
	descricaoRecurso: string;
	idUsuario: number;
	pergunta: string;
	resposta: string;
	prioridade?: any;
	idStatus: number;
	ativo: number;
	dataInicial: string;
	dataFinal?: any;
	subobjetivos: any[];
	percentualProgresso: number;
}

export interface Subobjetivo {
	id: number;
	idObjetivoUelf: number;
	descricao: string;
	alvo: string;
	importancia: number;
	motivacao: number;
	esforcoMental: number;
	esforcoFisico: number;
	idTempoNecessario: number;
	descricaoTempoNecessario: string;
	dataInicial: string;
	dataFinal: string;
	status: number;
}

const SuccessRoadmap = () => {
	const [data, setData] = useState<IData>();
	const [isLoading, setIsLoading] = useState<boolean>(true);

	const { showModal, setShowModal } = useSuccessRoadmap();
	const { dataUser } = useActiveJourney();
	const navigate = useNavigate();

	useEffect(() => {
		if (dataUser && dataUser?.idPlano <= 1) {
			navigate("/home");
		} else {
			handleList().then();
		}
	}, [dataUser]);

	const handleList = async () => {
		try {
			setIsLoading(true);

			const [_Response, _Error] = await new SuccessRoadmapService().list();

			if (!!_Error || !_Response?.status) {
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
				setIsLoading(false);
				return;
			}

			setData(_Response.resultSet);
			setIsLoading(false);
		} catch (err) {
			console.warn(err);
		}
	};

	const renderItems = () => {
		return (
			<div className="success-roadmap">
				{data?.objetivoUELF && (
					<SuccessRoadmapItem data={data.objetivoUELF} uelfGoal={true} index={0} />
				)}
				{data?.objetivos?.map((goal: Objetivo, index: number) => {
					return (
						<SuccessRoadmapItem
							key={index}
							uelfGoal={false}
							data={goal}
							index={index + 1}
						/>
					);
				})}
			</div>
		);
	};

	return (
		<PainelMasterPage
			title={"Metas e Tarefas"}
			subtitle={"Consulte os seus objetivos e defina suas metas e seus atributos"}
			icon={<FontAwesomeIcon icon={faListCheck} />}
			onClick={() => {}}
		>
			{!isLoading ? (
				<>
					{data ? (
						renderItems()
					) : (
						<h1 className="title">Tente novamente mais tarde.</h1>
					)}
				</>
			) : (
				<Loading margin="my-6" />
			)}
			<ButtonNextScreen text={"Voltar"} linkScreen={"/performance-painel"} />
			<SuccessRoadmapSubObjectiveModal
				show={showModal}
				onClose={() => {
					handleList().then();
					setShowModal(false);
				}}
			/>
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</PainelMasterPage>
	);
};

export default SuccessRoadmap;
