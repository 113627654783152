import "./ExternalPayment.scss";

import React, { useState } from "react";

import EPConfirmation from "../../components/EPConfirmation/EPConfirmation";
import EPPayment from "../../components/EPPayment/EPPayment";
import EPPlan from "../../components/EPPlan/EPPlan";
import EPRegistration from "../../components/EPRegistration/EPRegistration";
import { ToastContainer } from "react-toastify";
import { useExternalPayment } from "../../providers/ExternalPaymentProvider";

const ExternalPayment = () => {
	const { step, successfullyPaid } = useExternalPayment();

	const renderStep = () => {
		switch (step) {
			case "Registration":
				return <EPRegistration />;
			case "Plan":
				return <EPPlan />;
			case "Payment":
				return <EPPayment />;
			case "Confirmation":
				return <EPConfirmation />;
			default:
				return (
					<div className="error-message">
						<span>Não foi possivel carregar no momento.</span>
					</div>
				);
		}
	};

	const renderImage = () => {
		switch (step) {
			case "Registration":
				return <img src="/assets/img/ep-payment/step-01.png" alt="Cadastro" />;
			case "Plan":
				return <img src="/assets/img/ep-payment/step-02.png" alt="Planos" />;
			case "Payment":
				return <img src="/assets/img/ep-payment/step-03.png" alt="Pagamento" />;
			case "Confirmation":
				if (successfullyPaid) {
					return (
						<img src="/assets/img/ep-payment/step-04-success.png" alt="Sucesso" />
					);
				} else {
					return (
						<img src="/assets/img/ep-payment/step-04-failure.png" alt="Fracasso" />
					);
				}
			default:
				return <img src="/assets/img/ep-payment/step-01.png" alt="Cadastro" />;
		}
	};

	return (
		<div className="external-payment">
			<div className="image-wrapper">
				<div className="image">{renderImage()}</div>
			</div>
			<div className="content-wrapper">
				<div className="progress-indicator">
					<div className={`step${step === "Registration" ? " is-active" : ""}`}>
						<span className="number">1</span>
						<span className="label">Pessoal</span>
					</div>
					<div className={`step${step === "Plan" ? " is-active" : ""}`}>
						<span className="number">2</span>
						<span className="label">Dados do plano</span>
					</div>
					<div className={`step${step === "Payment" ? " is-active" : ""}`}>
						<span className="number">3</span>
						<span className="label">Pagamento</span>
					</div>
					<div className={`step${step === "Confirmation" ? " is-active" : ""}`}>
						<span className="number">4</span>
						<span className="label">Confirmação</span>
					</div>
				</div>
				{renderStep()}
			</div>
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</div>
	);
};

export default ExternalPayment;
