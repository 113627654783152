import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { toastSettings } from "../../models/toastConfig";
import MacroGoalsService from "../../services/MacroGoalsService";
import "./MacroGoalsAddModal.scss";

interface ModalNewsProps {
	show: boolean;
	onClose?: any;
}

interface Inputs {
	resourceID: string;
	goal: string;
	why: string;
}

const MacroGoalsAddModal = ({ show, onClose }: ModalNewsProps) => {
	const [resources, setResources] = useState<any>({});

	const [modal, setModal] = useState<boolean>(false);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<Inputs>();

	const toggle = () => setModal(!modal);
	const handleOnClose = () => onClose(false);

	useEffect(() => {
		handleList().then();

		if (show) {
			reset();
			setIsSubmitting(false);
			setModal(show);
		}
	}, [show]);

	const handleList = async () => {
		try {
			const [_Response, _Error] = await new MacroGoalsService().listResources();

			if (!!_Error || !_Response?.status) {
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
				return;
			}

			setResources(_Response.resultSet);
		} catch (err) {
			console.warn(err);
		}
	};

	const createNewGoal = async (data: Inputs) => {
		const newGoal = {
			idRecurso: parseInt(data.resourceID),
			pergunta: data.goal,
			resposta: data.why,
		};

		try {
			setIsSubmitting(true);
			const [_Response, _Error] = await new MacroGoalsService().create(newGoal);

			if (!!_Error || !_Response?.status) {
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
				setTimeout(() => setIsSubmitting(false), 1000);
				return false;
			} else {
				toastSettings(
					"Criado novo objetivo com sucesso!",
					"bottom-center",
					"success",
				);
			}
		} catch (err) {
			console.warn(err);
		}

		toggle();
		setIsSubmitting(false);
	};

	return (
		<Modal
			centered
			scrollable
			size="lg"
			isOpen={modal}
			toggle={toggle}
			onClosed={handleOnClose}
			className="modal-macro-goals-add"
			data-testid="MacroGoalsAddModal"
		>
			<form key={1} onSubmit={handleSubmit(createNewGoal)}>
				<ModalHeader toggle={toggle}>Adicionar um Objetivo Macro</ModalHeader>
				<ModalBody>
					<h2 className="title s">Vamos abaixo adicionar um objetivo macro.</h2>
					<div className="text">
						<p className="text">Pense e preencha os campos abaixo.</p>
					</div>
					<div className="fieldset">
						<label htmlFor="resource-id">Escolha a categoria:</label>
						<select id="resource-id" {...register("resourceID", { required: true })}>
							<option value={1}>Dinheiro</option>
							<option value={2}>Saúde</option>
							<option value={3}>Conhecimento</option>
							<option value={4}>Tempo</option>
							<option value={5}>Emoções</option>
						</select>
						{errors.resourceID && (
							<span className="error">Este campo é obrigatório</span>
						)}
					</div>
					<div className="fieldset">
						<label htmlFor="goal">Qual é o objetivo?</label>
						<input
							type="text"
							id="goal"
							maxLength={100}
							{...register("goal", { required: true })}
						/>
						{errors.goal && <span className="error">Este campo é obrigatório</span>}
					</div>
					<div className="fieldset">
						<label htmlFor="why">Porque isso é importante para você?</label>
						<input
							type="text"
							id="why"
							maxLength={100}
							{...register("why", { required: true })}
						/>
						{errors.why && <span className="error">Este campo é obrigatório</span>}
					</div>
				</ModalBody>
				<ModalFooter>
					<Button type="button" disabled={isSubmitting} onClick={toggle}>
						Cancelar
					</Button>
					<Button type="submit" disabled={isSubmitting} color="primary">
						Criar novo objetivo
					</Button>
				</ModalFooter>
			</form>
		</Modal>
	);
};

export default MacroGoalsAddModal;
