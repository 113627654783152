import "./NotesAddModal.scss";

import {
	Button,
	Col,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row,
} from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";

// API
import MacroGoalsService from "../../services/MacroGoalsService";
import NotesService from "../../services/NotesService";
import PurposeService from "../../services/PurposeService";
import Select from "react-select";
import { customModalStyles } from "../../models/selectConfig";
import { schema } from "./Validate/Validate";
import { toastSettings } from "../../models/toastConfig";
import { yupResolver } from "@hookform/resolvers/yup";

type FormValues = {
	idRecurso: number;
	idObjetivo: number;
	humor: number;
	anotacao: string;
};

interface INotesAddModal {
	show: boolean;
	onClose?: any;
	setfunction?: any;
	isObjUelf?: boolean;
	idObjetivo?: number;
}

const NotesAddModal = ({
	show,
	onClose,
	setfunction,
	isObjUelf,
	idObjetivo,
}: INotesAddModal) => {
	const [modal, setModal] = useState<boolean>(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [idRecursoSelected, setidRecursoSelected] = useState<any>(null);
	const [idHumorSelected, setidHumorSelected] = useState<any>(null);
	const [recursos, setRecursos] = useState<any>([]);
	const [objetivos, setObjetivos] = useState<any>([]);
	const [objetivoSelected, setObjetivoSelected] = useState<any>([]);
	const [isUelf, setIsUelf] = useState<boolean>(false);
	const [isUelfs, setIsUelfs] = useState<any>({});

	let defaultValues = {
		idRecurso: 0,
		idObjetivo: 0,
		humor: 0,
		anotacao: "",
	} as FormValues;

	let arrHumor: any = [
		{
			label: "Insatisfeito",
			value: 1,
		},
		{
			label: "Neutro",
			value: 2,
		},
		{
			label: "Alegre",
			value: 3,
		},
	];

	const {
		register,
		control,
		handleSubmit,
		reset,
		setValue,
		formState: { errors },
	} = useForm<FormValues>({
		mode: "onChange",
		defaultValues,
		resolver: yupResolver(schema),
	});

	const toggle = () => setModal(!modal);

	const handleOnClose = () => {
		onClose(false);
		reset();
	};

	const verifyIsUelf = (idObjetivo: any) => {
		isUelfs.forEach((value: any) => {
			if (value.id === idObjetivo.value) {
				setIsUelf(value.isUELF);
			}
		});
	};

	const getRecursos = async () => {
		try {
			const [_Response, _Error] = await new MacroGoalsService().listResources();

			if (!!_Error || !_Response?.status) {
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
				return;
			}

			let arrayRecursos = _Response.resultSet?.map((item: any) => {
				return { label: item.descricao, value: item.id };
			});

			setRecursos(arrayRecursos);
		} catch (err) {
			console.warn(err);
		}
	};

	const createNotes = async (data: any) => {
		try {
			setIsSubmitting(true);

			data = { ...data, isUelf: isUelf };
			data = { ...data, idObjetivo: data.idObjetivo.value };

			const [_Response, _Error] = await new NotesService().create(data);

			if (!!_Error || !_Response?.status) {
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
				return;
			}

			toastSettings("Anotação inserida com sucesso!", "bottom-center", "success");

			setfunction();

			toggle();
		} catch (e: any) {
			toastSettings("Algo deu errado!" || e?.message, "bottom-center", "error");
		}
	};

	const getObjetivos = async () => {
		try {
			const [_Response, _Error] = await new PurposeService().listAll();

			if (!!_Error || !_Response?.status) {
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
				return;
			}

			let arrayObjetivos = _Response.resultSet?.map((item: any) => {
				return { label: item.objetivo, value: item.id };
			});

			setIsUelfs(_Response.resultSet);

			setObjetivos(arrayObjetivos);
		} catch (e: any) {
			toastSettings("Algo deu errado!" || e?.message, "bottom-center", "error");
		}
	};

	useEffect(() => {
		if (show) {
			setModal(show);
			setIsSubmitting(false);
			getRecursos().then();
			getObjetivos().then();
		}
	}, [show]);

	useEffect(() => {
		setValue("idRecurso", idRecursoSelected || null);
	}, [idRecursoSelected]);

	useEffect(() => {
		setValue("idObjetivo", objetivoSelected || null);
	}, [objetivoSelected]);

	useEffect(() => {
		setValue("humor", idHumorSelected || null);
	}, [idHumorSelected]);

	return (
		<Modal
			centered
			scrollable
			size="lg"
			isOpen={modal}
			toggle={toggle}
			onClosed={handleOnClose}
			className="modal-notes-add"
			data-testid="NotesAddModal"
		>
			<form key={1} onSubmit={handleSubmit(createNotes)}>
				<ModalHeader toggle={toggle}>Adicionar uma Anotação</ModalHeader>
				<ModalBody className="px-4">
					<Row>
						<Col className="col-12">
							<div className="fieldset">
								<label htmlFor="idRecurso">Escolha a categoria:</label>
								<Controller
									name="idRecurso"
									control={control}
									render={({ field: { onChange, name, ref } }) => (
										<Select
											ref={ref}
											name={name}
											isSearchable={false}
											isClearable={false}
											options={recursos}
											placeholder="Selecione..."
											className={`select ${!errors.idRecurso || "invalid"}`}
											onChange={(val: any) => {
												onChange(val || null);
												setidRecursoSelected(val);
											}}
											noOptionsMessage={() => "Não há registros"}
											styles={customModalStyles}
											menuPosition={"fixed"}
										/>
									)}
								/>

								{errors.idRecurso?.type === "required" && (
									<p className="error">Recurso é obrigatório</p>
								)}
							</div>
						</Col>
					</Row>
					<Row>
						<Col className="col-12">
							<div className="fieldset">
								<label htmlFor="idObjetivo">Escolha o objetivo:</label>
								<Controller
									name="idObjetivo"
									control={control}
									render={({ field: { onChange, name, ref } }) => (
										<Select
											ref={ref}
											name={name}
											isSearchable={false}
											isClearable={false}
											options={objetivos}
											placeholder="Selecione..."
											className={`select ${!errors.idObjetivo || "invalid"}`}
											onChange={(val: any) => {
												onChange(val || null);
												setObjetivoSelected(val);
												verifyIsUelf(val);
											}}
											noOptionsMessage={() => "Não há registros"}
											styles={customModalStyles}
											menuPosition={"fixed"}
										/>
									)}
								/>

								{errors.idObjetivo?.type === "required" && (
									<p className="error">Objetivo é obrigatório</p>
								)}
							</div>
						</Col>
					</Row>

					<Row className="mt-2">
						<Col className="col-12">
							<div className="fieldset">
								<label htmlFor="humor">Escolha o sentimento:</label>
								<Controller
									name="humor"
									control={control}
									render={({ field: { onChange, name, ref } }) => (
										<Select
											ref={ref}
											name={name}
											isSearchable={false}
											isClearable={false}
											options={arrHumor}
											placeholder="Selecione..."
											className={`select ${!errors.humor || "invalid"}`}
											onChange={(val: any) => {
												onChange(val || null);
												setidHumorSelected(val);
											}}
											noOptionsMessage={() => "Não há registros"}
											styles={customModalStyles}
											menuPosition={"fixed"}
										/>
									)}
								/>

								{errors.humor?.type === "required" && (
									<p className="error">Humor é obrigatório</p>
								)}
							</div>
						</Col>
					</Row>

					<Row className="mt-2">
						<Col className="col-12">
							<div className="fieldset">
								<Label for="anotacao">Descreva a anotação:</Label>
								<input
									id="anotacao"
									type="text"
									className={`${!errors.anotacao || "invalid"}`}
									{...register("anotacao")}
									maxLength={100}
								/>

								{errors.anotacao?.type === "required" && (
									<p className="error">Anotação Correta é obrigatório</p>
								)}
								{errors.anotacao?.type === "min" && (
									<p className="error">Deve conter no mínimo 3 caracteres</p>
								)}
								{errors.anotacao?.type === "max" && (
									<p className="error">Deve conter no máximo 50 caracteres</p>
								)}
							</div>
						</Col>
					</Row>
				</ModalBody>
				<ModalFooter>
					<Button type="button" disabled={isSubmitting} onClick={toggle}>
						Cancelar
					</Button>
					<Button disabled={isSubmitting} color="primary">
						Criar Anotação
					</Button>
				</ModalFooter>
			</form>
		</Modal>
	);
};

export default NotesAddModal;
