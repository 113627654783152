import React, { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import "./ModalNews.scss";

interface ModalNewsProps {
	show: boolean;
	onClose?: any;
}

const ModalNews = ({ show, onClose }: ModalNewsProps) => {
	const [modal, setModal] = useState<boolean>(false);

	const toggle = () => setModal(!modal);
	const handleOnClose = () => onClose(false);

	useEffect(() => {
		if (show) {
			setModal(show);
		}
	}, [show]);

	return (
		<Modal
			isOpen={modal}
			toggle={toggle}
			className="ModalNews"
			data-testid="ModalNews"
			onClosed={handleOnClose}
			size="xl"
			centered
		>
			<ModalBody className="d-flex p-0">
				<iframe
					width="560"
					height="315"
					src="https://www.youtube-nocookie.com/embed/fgZKvA5dc3I?controls=0"
					title="YouTube video player"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				></iframe>
			</ModalBody>
		</Modal>
	);
};

export default ModalNews;
