import React, { useEffect, useState } from "react";
import { toastSettings } from "../models/toastConfig";
import JourneyBackpackService from "../services/JourneyBackpackService";

export const JourneyBackpackContext = React.createContext<any>({});

export const JourneyBackpackProvider = (props: any) => {
	const [data, setData] = useState<any>({});
	const [itemSelected, setItemSelected] = useState<any | null>(null);
	const [itemSelectedRem, setItemSelectedRem] = useState<number>(0);
	const [showModalAdd, setShowModalAdd] = useState<boolean>(false);
	const [showModalRemove, setShowModalRemove] = useState<boolean>(false);

	useEffect(() => {
		setItemSelected(null);
		setShowModalAdd(false);
		setShowModalRemove(false);
	}, []);

	const handleList = async () => {
		try {
			const [_Response, _Error] = await new JourneyBackpackService().list();

			if (!!_Error || !_Response?.status) {
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
				return;
			}

			setData(_Response.resultSet);
		} catch (err) {
			console.warn(err);
		}
	};

	const remove = async () => {
		try {
			if (itemSelectedRem > 0) {
				const [_Response, _Error] = await new JourneyBackpackService().remove(
					itemSelectedRem,
				);

				if (!!_Error || !_Response?.status) {
					toastSettings(_Error || _Response?.message, "bottom-center", "error");
					return;
				}

				toastSettings(`Item removido com sucesso!`, "bottom-center", "success");

				handleList();
			}
		} catch (err) {
			console.warn(err);
		}
	};

	return (
		<JourneyBackpackContext.Provider
			value={{
				data,
				showModalAdd,
				setShowModalAdd,
				itemSelected,
				setItemSelected,
				handleList,
				remove,
				showModalRemove,
				setShowModalRemove,
				itemSelectedRem,
				setItemSelectedRem,
			}}
		>
			{props.children}
		</JourneyBackpackContext.Provider>
	);
};

export const useJourneyBackpack = () =>
	React.useContext(JourneyBackpackContext);
