import api from "./api";

class TimelineService {
	async list(data: object = {}) {
		try {
			const response = await api.post("/app/plano/performance/cronograma", data);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}
}

export default TimelineService;
