import "./EditProfile.scss";

import { Card, CardBody, Col, Form, Row } from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import React, { useEffect, useState } from "react";

import AuthService from "../../services/AuthService";
import CardProfile from "../../components/CardProfile/CardProfile";
import CpfCnpj from "../../components/CpfCnpj/CpfCnpj";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputMask from "react-input-mask";
import Loading from "../../components/Loading/Loading";
import PainelMasterPage from "../../components/PainelMasterPage/PainelMasterPage";
import RemoveAcento from "../../utils/RemoveAcento";
import Select from "react-select";
import SignUpService from "../../services/SignUpService";
import { ToastContainer } from "react-toastify";
import { customStyles } from "../../models/selectConfig";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import ptBR from "date-fns/locale/pt-BR";
import { schema } from "./Validate/Validate";
import { toastSettings } from "../../models/toastConfig";
import { useAuth } from "../../providers/AuthProvider";
import { validateCep } from "validations-br";
import { yupResolver } from "@hookform/resolvers/yup";

type FormValues = {
	nomeRazao: string;
	cpfCnpj: string;
	email: string;
	ocupacao: string;
	dataNascimentoAbertura: Date | null;
	celular: string;
	cep: string;
	logradouro: string;
	numero: string;
	complemento: string;
	bairro: string;
	cidade: string;
	estado: string;
	imagem: File | null;
};

const EditProfile = () => {
	const { updateProfile, setUser } = useAuth();
	const [data, setData] = useState<any>();
	const [disabled, setDisabled] = useState<boolean>(true);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isLoadingCity, setIsLoadingCity] = useState<boolean>(false);
	const [isLoadingPhone, setIsLoadingPhone] = useState<boolean>(true);
	const [isLoadingCep, setIsLoadingCep] = useState<boolean>(true);

	const [selectedCep, setSelectedCep] = useState<string>("");
	const [selectedState, setSelectedState] = useState<any>();
	const [selectedCity, setSelectedCity] = useState<any>();
	const [selectedPhone, setSelectedPhone] = useState<string>("");
	const [selectedDate, setSelectedDate] = useState<Date | null>(null);
	const [selectedCpfCnpj, setSelectedCpfCnpj] = useState<string>("");
	const [selectedImagem, setSelectedImagem] = useState<File>();

	const [states, setStates] = useState<any[]>([]);
	const [cities, setCities] = useState<any[]>([]);
	const [dateNow, setDateNow] = useState<Date>(new Date());

	let defaultValues = {
		nomeRazao: "",
		cpfCnpj: "",
		email: "",
		ocupacao: "",
		dataNascimentoAbertura: null,
		celular: "",
		cep: "",
		logradouro: "",
		numero: "",
		complemento: "",
		bairro: "",
		cidade: "",
		estado: "",
		imagem: null,
	} as FormValues;

	const {
		register,
		handleSubmit,
		control,
		setValue,
		formState: { errors },
	} = useForm<FormValues>({
		mode: "onChange",
		defaultValues,
		resolver: yupResolver(schema),
	});

	useEffect(() => {
		registerLocale("pt-br", ptBR);
		setDefaultLocale("pt-br");
		populateStateSelect();
		getDados();
	}, []);

	useEffect(() => {
		if (data) {
			setValue("nomeRazao", data?.nome || "");
			setValue("email", data?.email || "");
			setValue("ocupacao", data?.ocupacao || "");
			setValue("complemento", data?.complemento || "");
			setValue("numero", data?.numero || "");

			let _cpfCnpj: string = data.cpfCnpj !== null ? data.cpfCnpj.toString().replace(/-/g, "/") : "";
			_cpfCnpj =
				_cpfCnpj.length === 11
					? _cpfCnpj.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")
					: _cpfCnpj.replace(
							/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
							"$1 $2 $3/$4-$5",
					  );

			setSelectedCpfCnpj(_cpfCnpj);

			if (data?.dataNascimentoAbertura) {
				setSelectedDate(
					new Date(data?.dataNascimentoAbertura?.toString().replace(/-/g, "/")),
				);
			}

			setSelectedPhone(data?.celular || "");

			if (data?.cep) {
				let cep = "";
				const cepArray = String(data.cep).split("");
				if (cepArray.length !== 8) {
					cepArray.unshift("0");
					cep = cepArray.join("");
				}

				let _cep = cep
					? cep
					: data.cep
							?.toString()
							.replace(/\D/g, "")
							.replace(/(\d{5})(\d)/, "$1-$2");
				handleCEP(_cep);
			}

			setUser({
				id: data?.idUsuario,
				nome: data?.nome,
				dataUsuario: data?.dataNascimentoAbertura,
				estado: data?.estado,
				cidade: data?.cidade,
				idade: moment().diff(data?.dataNascimentoAbertura, "years", false),
				imagem: data?.imagem,
				ocupacao: data?.ocupacao,
				tipoPessoa: data?.tipoPessoa,
			});

			setIsLoadingPhone(false);
			setIsLoadingCep(false);
		}
	}, [data]);

	useEffect(() => {
		setValue("estado", selectedState?.value?.toUpperCase() || "");
		setValue("cidade", selectedCity?.value?.toUpperCase() || "");
		setValue("cep", selectedCep);
		setValue("celular", selectedPhone);
		setValue("dataNascimentoAbertura", selectedDate || null);
		setValue("cpfCnpj", selectedCpfCnpj);
	}, [
		selectedState,
		selectedCity,
		selectedCep,
		selectedPhone,
		selectedDate,
		selectedCpfCnpj,
	]);

	const getDados = async () => {
		try {
			const [_Response, _Error] = await new AuthService().getDados();
			if (!!_Error || !_Response?.status) {
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
				return;
			}

			setData(_Response.resultSet);

			setIsLoading(false);
		} catch (err) {
			console.warn(err);
		}
	};

	const handleUpdate = async (data: any) => {
		setIsLoading(true);
		
		await updateProfile(data, selectedImagem).then((ret: any) => {
			if (ret) {
				getDados();
			} else {
				setIsLoading(false);
			}
		});
	};

	const handleCEP = async (cep: string) => {
		setSelectedCep(cep);

		if (cep === "") {
			setValue("logradouro", "");
			setValue("bairro", "");
			setValue("numero", "");
			setValue("complemento", "");
			setSelectedCep("");
			setSelectedState("");
			setSelectedCity("");
			setDisabled(true);
			return;
		}
		// if (!validateCep(cep)) {
		// 	return;
		// }
		const [_Response, _Error] = await new SignUpService().getCep(cep);

		if (!!_Error || _Response?.errors) {
			setDisabled(false);

			setValue("logradouro", "");
			setValue("bairro", "");
			setValue("numero", "");
			setValue("complemento", "");
			setSelectedCep("");
			setSelectedState("");
			setSelectedCity("");
			setCities([]);

			toastSettings("Seu CEP não foi encontrado, favor preencher manualmente o endereço", "bottom-center", "error");
			return;
		}

		setDisabled(true);

		if (_Response?.cep) {
			setSelectedCep(_Response.cep);
		}

		if (_Response?.street) {
			setValue("logradouro", _Response.street);
		}

		if (_Response?.neighborhood) {
			setValue("bairro", _Response.neighborhood);
		}

		if (_Response?.city) {
			setValue("cidade", _Response.city.toUpperCase());
		}

		if (_Response?.state) {
			setIsLoadingCity(true);

			setSelectedState(
				states.find(
					(c: any) => RemoveAcento(c.value) === RemoveAcento(_Response.state),
				),
			);

			populateCitiesSelect(_Response.state, _Response.city);
		}
	};

	const populateStateSelect = async () => {
		const [_Response, _Error] = await new SignUpService().getStates();

		if (!!_Error || _Response?.errors) {
			toastSettings(_Error || _Response?.message, "bottom-center", "error");
			return;
		}

		let estados = _Response?.map((state: any) => {
			return { label: state.nome.toUpperCase(), value: state.sigla };
		});

		estados = estados.sort((a: any, b: any) => (a.label > b.label ? 1 : -1));

		setStates(estados);
	};

	const populateCitiesSelect = async (state: string, city: string) => {
		const [_Response, _Error] = await new SignUpService().getCities(state);

		if (!!_Error || _Response?.errors) {
			toastSettings(_Error || _Response?.message, "bottom-center", "error");
			return;
		}

		let cidades = _Response?.map((city: any) => {
			return { label: city.nome.toUpperCase(), value: city.nome.toUpperCase() };
		});

		// adicionado por conta da api não trazer essa cidade
		if (state?.toUpperCase() === "SP") {
			cidades.push({ label: "São Paulo", value: "São Paulo" });
		}

		cidades = cidades.sort((a: any, b: any) => (a.label > b.label ? 1 : -1));

		setCities(cidades);

		if (city) {
			setSelectedCity(
				cidades.find((c: any) => RemoveAcento(c.value) === RemoveAcento(city)),
			);
		}

		setIsLoadingCity(false);
	};

	const onImageChange = (event: any) => {
		if (event.target.files && event.target.files[0]) {
			let img = event.target.files[0];
			setSelectedImagem(img);
		}
	};

	return (
		<PainelMasterPage
			title={"Editar o seu perfil"}
			subtitle={""}
			subtitleclass="text-white"
			icon={<></>}
			onClick={() => {}}
		>
			<>
				<div className="EditProfile">
					<div className="col profile">
						<CardProfile editprofile={false} />
					</div>
					<div className="col content">
						<div className="form-default" data-testid="EditProfile">
							<Card>
								<CardBody>
									<div className="tabs">
										<div className="tabcontent is-active">
											{!isLoading && (
												<>
													<Form onSubmit={handleSubmit(handleUpdate)}>
														<Row>
															<Col md={8}>
																<div className="fieldset">
																	<label htmlFor="nomeRazao">Nome Completo *</label>
																	<input
																		type="text"
																		id="nomeRazao"
																		className={`${!errors.nomeRazao || "invalid"}`}
																		{...register("nomeRazao")}
																	/>
																	{errors.nomeRazao?.type === "required" && (
																		<p className="error">Nome completo é obrigatório</p>
																	)}
																	{errors.nomeRazao?.type === "min" && (
																		<p className="error">Deve conter no mínimo 3 caracteres</p>
																	)}
																	{errors.nomeRazao?.type === "max" && (
																		<p className="error">Deve conter no máximo 100 caracteres</p>
																	)}
																</div>
															</Col>
															<Col md={4}>
																<div className="fieldset">
																	<label htmlFor="cpfcnpj">CPF/CNPJ</label>
																	<Controller
																		name="cpfCnpj"
																		control={control}
																		render={({ field: { name } }) => (
																			<>
																				<CpfCnpj
																					name={name}
																					type="tel"
																					value={selectedCpfCnpj}
																					// disabled={true}
																					className={`${!errors.cpfCnpj || "invalid"}`}
																					onChange={(event: any, type: string) => {
																						setSelectedCpfCnpj(event.target.value);
																					}}
																				/>
																			</>
																		)}
																	/>
																	{errors.cpfCnpj?.type === "required" && (
																		<p className="error">CPF/CNPJ é obrigatório</p>
																	)}
																	{errors.cpfCnpj?.type === "cpfCnpj" && (
																		<p className="error">CPF/CNPJ é inválido</p>
																	)}
																</div>
															</Col>
														</Row>
														<Row>
															<Col md={12}>
																<div className="fieldset">
																	<label htmlFor="email">E-mail *</label>
																	<input
																		type="email"
																		id="email"
																		disabled={true}
																		className={`${!errors.email || "invalid"}`}
																		{...register("email")}
																	/>
																	{errors.email?.type === "required" && (
																		<p className="error">Email é obrigatório</p>
																	)}
																	{errors.email?.type === "email" && (
																		<p className="error">Email é inválido</p>
																	)}
																	{errors.email?.type === "max" && (
																		<p className="error">Deve conter no máximo 150 caracteres</p>
																	)}
																</div>
															</Col>
														</Row>
														<Row>
															<Col md={4}>
																<div className="fieldset">
																	<label htmlFor="ocupacao">Ocupação</label>
																	<input
																		type="text"
																		id="ocupacao"
																		className={`${!errors.ocupacao || "invalid"}`}
																		{...register("ocupacao")}
																	/>
																	{errors.ocupacao?.type === "max" && (
																		<p className="error">Deve conter no máximo 100 caracteres</p>
																	)}
																</div>
															</Col>
															<Col md={4}>
																<div className="fieldset">
																	<label htmlFor="dataNascimentoAbertura">
																		Data de Nascimento/Abertura
																	</label>
																	<Controller
																		name="dataNascimentoAbertura"
																		control={control}
																		render={({ field: { name, ref } }) => (
																			<DatePicker
																				ref={ref}
																				name={name}
																				maxDate={dateNow}
																				selected={selectedDate}
																				className={`${!errors.dataNascimentoAbertura || "invalid"}`}
																				onChange={(date) => setSelectedDate(date || null)}
																				dateFormat="dd/MM/yyyy"
																				locale="pt-br"
																			/>
																		)}
																	/>
																	{errors.dataNascimentoAbertura?.type === "required" && (
																		<p className="error">
																			Data de Nascimento/Abertura é obrigatória
																		</p>
																	)}
																</div>
															</Col>
															<Col md={4}>
																<div className="fieldset">
																	<label htmlFor="celular">Celular/Whatsapp</label>
																	{!isLoadingPhone ? (
																		<Controller
																			name="celular"
																			control={control}
																			render={({ field: { name, ref } }) => (
																				<InputMask
																					ref={ref}
																					name={name}
																					id="celular"
																					className={`input ${!errors.celular || "invalid"}`}
																					mask="(99) 99999-9999"
																					defaultValue={selectedPhone}
																					onBlur={(e) => setSelectedPhone(e.target.value)}
																				/>
																			)}
																		/>
																	) : (
																		<FontAwesomeIcon
																			icon={faSpinner}
																			spin
																			style={{ fontSize: 26 }}
																		/>
																	)}
																	{errors.celular?.type === "required" && (
																		<p className="error">Celular/Telefone é obrigatório</p>
																	)}
																	{errors.celular?.type === "phone" && (
																		<p className="error">Celular/Telefone é inválido</p>
																	)}
																</div>
															</Col>
														</Row>
														<Row>
															<Col md={3}>
																<div className="fieldset">
																	<label htmlFor="cep">CEP</label>
																	{!isLoadingCep ? (
																		<Controller
																			name="cep"
																			control={control}
																			render={({ field: { name, ref } }) => (
																				<InputMask
																					ref={ref}
																					name={name}
																					id="cep"
																					className={`input ${!errors.cep || "invalid"}`}
																					mask="99999-999"
																					defaultValue={selectedCep}
																					onBlur={(e) => handleCEP(e.target.value)}
																				/>
																			)}
																		/>
																	) : (
																		<FontAwesomeIcon
																			icon={faSpinner}
																			spin
																			style={{ fontSize: 26 }}
																		/>
																	)}
																	{errors.cep?.type === "cep" && (
																		<p className="error">CEP é inválido</p>
																	)}
																</div>
															</Col>
															<Col md={7}>
																<div className="fieldset">
																	<label htmlFor="logradouro">Rua</label>
																	<input
																		type="text"
																		id="public-place"
																		disabled={disabled}
																		className={`${!errors.logradouro || "invalid"}`}
																		{...register("logradouro")}
																	/>
																	{errors.logradouro?.type === "max" && (
																		<p className="error">Deve conter no máximo 150 caracteres</p>
																	)}
																</div>
															</Col>
															<Col md={2}>
																<div className="fieldset">
																	<label htmlFor="numero">Número</label>
																	<input
																		type="text"
																		id="numero"
																		className={`${!errors.numero || "invalid"}`}
																		{...register("numero")}
																	/>
																	{errors.numero?.type === "max" && (
																		<p className="error">Deve conter no máximo 10 caracteres</p>
																	)}
																</div>
															</Col>
														</Row>
														<Row>
															<Col md={8}>
																<div className="fieldset">
																	<label htmlFor="bairro">Bairro</label>
																	<input
																		type="text"
																		id="bairro"
																		disabled={disabled}
																		className={`${!errors.bairro || "invalid"}`}
																		{...register("bairro")}
																	/>
																	{errors.bairro?.type === "max" && (
																		<p className="error">Deve conter no máximo 150 caracteres</p>
																	)}
																</div>
															</Col>
															<Col md={4}>
																<div className="fieldset">
																	<label htmlFor="complemento">Complemento</label>
																	<input
																		type="text"
																		id="complemento"
																		{...register("complemento")}
																	/>
																</div>
															</Col>
														</Row>
														<Row>
															<Col md={6}>
																<div className="fieldset">
																	<label htmlFor="estado">Estado</label>
																	<Controller
																		name="estado"
																		control={control}
																		render={({ field: { onChange, name, ref } }) => (
																			<Select
																				ref={ref}
																				name={name}
																				isClearable
																				isSearchable={false}
																				options={states}
																				placeholder="Selecione..."
																				className={`select ${disabled && "disabled"} ${
																					!errors.estado || "invalid"
																				}`}
																				value={selectedState || ""}
																				onChange={(val: any) => {
																					onChange(val?.value || "");
																					setSelectedState(val);
																					setSelectedCity("");
																					populateCitiesSelect(val?.value, "");
																				}}
																				isDisabled={disabled}
																				noOptionsMessage={() => "Não há registros"}
																				styles={customStyles}
																			/>
																		)}
																	/>
																</div>
															</Col>
															<Col md={6}>
																<div className="fieldset">
																	<label htmlFor="cidade">Cidade</label>
																	{!isLoadingCity ? (
																		<Controller
																			name="cidade"
																			control={control}
																			render={({ field: { onChange, name, ref } }) => (
																				<Select
																					ref={ref}
																					name={name}
																					isClearable
																					isSearchable={false}
																					options={cities}
																					placeholder="Selecione..."
																					className={`select ${disabled && "disabled"} ${
																						!errors.cidade || "invalid"
																					}`}
																					value={selectedCity || ""}
																					onChange={(val: any) => {
																						onChange(val?.value || "");
																						setSelectedCity(val);
																					}}
																					isDisabled={disabled}
																					noOptionsMessage={() => "Não há registros"}
																					styles={customStyles}
																				/>
																			)}
																		/>
																	) : (
																		<FontAwesomeIcon
																			icon={faSpinner}
																			spin
																			style={{ fontSize: 26 }}
																		/>
																	)}
																</div>
															</Col>
														</Row>
														<Row>
															<Col md={12}>
																<div className="fieldset d-flex-inline">
																	<label htmlFor="imagem">Envie uma selfie</label>
																	<div className="content-selfie d-flex">
																		<input
																			type="file"
																			accept="image/*"
																			className={`${!errors.imagem || "invalid"}`}
																			{...register("imagem")}
																			onChange={onImageChange}
																		/>
																		{selectedImagem && (
																			<div className="view-selfie">
																				<img
																					alt="not fount"
																					src={URL.createObjectURL(selectedImagem)}
																				/>
																			</div>
																		)}
																	</div>
																	{errors.imagem?.type === "size" && (
																		<p className="error">Tamanho máximo permitido 1mb</p>
																	)}
																</div>
															</Col>
														</Row>
														<Row>
															<Col md={12}>
																<button id="save" type="submit" className="btndefault mt-4 w-100">
																	Salvar
																</button>
															</Col>
														</Row>
													</Form>
												</>
											)}
											{isLoading && <Loading />}
										</div>
									</div>
								</CardBody>
							</Card>
						</div>
					</div>
				</div>
				<ToastContainer
					position="top-right"
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
			</>
		</PainelMasterPage>
	);
};

export default EditProfile;
