import React from "react";
import { Link } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
// CSS
import "./ButtonNextScreen.scss";

interface IButtonNextScreen {
	text: string;
	linkScreen: string;
}

const ButtonNextScreen = ({
	text,
	linkScreen,
}: IButtonNextScreen): JSX.Element => {
	return (
		<>
			<div className="buttonNextScreen">
				<Row>
					<Col className="text-right">
						<Link to={linkScreen}>
							<Button className="btn-rastreador"> {text} </Button>
						</Link>
					</Col>
				</Row>
			</div>
		</>
	);
};

export default ButtonNextScreen;
