import "./ToDoAvoid.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PainelMasterPage from "../../components/PainelMasterPage/PainelMasterPage";
import ToDoAvoidAddModal from "../../components/ToDoAvoidAddModal/ToDoAvoidAddModal";
import ToDoAvoidStage from "../../components/ToDoAvoidStage/ToDoAvoidStage";
import { ToastContainer } from "react-toastify";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import { useToDoAvoid } from "../../providers/ToDoAvoidProvider";
import GoogleTagManager, { EventEnum } from "../../services/GoogleTagManager";
import Slugify from "../../utils/Slugify";

const ToDoAvoid = () => {
	const { handleList, showModalAdd, setShowModalAdd } = useToDoAvoid();

	useEffect(() => {
		setShowModalAdd(false);
		GoogleTagManager.event(EventEnum.Marketing, { action: "evitar_fazer" });
	}, []);

	return (
		<PainelMasterPage
			title={"Lista de evitar e fazer"}
			subtitle={
				"Selecione abaixo as atividades que você prefere evitar e as atividades que prefere fazer."
			}
			subtitleclass="text-white"
			icon={<FontAwesomeIcon icon={faFileAlt} />}
			onClick={() => {}}
		>
			<div className="to-do-avoid">
				<ToDoAvoidStage />
			</div>
			<ToDoAvoidAddModal
				show={showModalAdd}
				onClose={() => {
					setShowModalAdd(false);
					handleList().then();
				}}
			/>
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</PainelMasterPage>
	);
};

export default ToDoAvoid;
