import { faFrown, faSmile } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Input } from "reactstrap";
import "./RangeSlider.scss";

interface IRangeSlider {
	id: string;
	min: number;
	max: number;
	value: number;
	qNum: string;
	label: string;
	dataQuestionId: number;
}

const RangeSlider = ({
	id,
	min,
	max,
	value,
	qNum,
	label,
	dataQuestionId,
}: IRangeSlider): JSX.Element => {
	const [sliderValue, setSliderValue] = useState(value ?? 1);
	const sliderProps = {
		fill: "var(--range-handle-color)",
		background: "var(--range-track-color)",
	};
	const [percentage, setPercentage] = useState(
		(100 * (sliderValue - min)) / (max - min),
	);

	useEffect(() => {
		setPercentage((100 * (sliderValue - min)) / (max - min));
	}, [sliderValue]);

	return (
		<div className="range-row">
			<label className="range-label" htmlFor={id}>
				<b>{qNum}</b>
				{label}
			</label>
			<div className="input-wrapper">
				<div className="input">
					<div className="icon">
						<FontAwesomeIcon icon={faFrown} />
					</div>
					<Input
						type="range"
						id={"range-" + id}
						min={min}
						max={max}
						data-length={sliderValue}
						value={sliderValue}
						step="1"
						data-question-id={dataQuestionId}
						onChange={(e: any) => {
							setSliderValue(e.target.value);
						}}
						style={{
							background: `linear-gradient(90deg, ${
								sliderProps.fill
							} ${percentage}%, ${sliderProps.background} ${percentage + 0.1}%)`,
						}}
					/>
					<div className="icon">
						<FontAwesomeIcon icon={faSmile} />
					</div>
				</div>
				{sliderValue ? (
					<output>
						{sliderValue}/{max}
					</output>
				) : (
					<output>
						{sliderValue}/{max}
					</output>
				)}
			</div>
		</div>
	);
};

export default RangeSlider;
