import "./Sidebar.scss";

import {
	Collapse,
	Nav,
	NavItem,
	NavLink,
	Navbar,
	NavbarBrand,
} from "reactstrap";
import { Link, NavLink as NavLinkRRD, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";

import MenuRoute from "../../routes/MenuRoute";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useActiveJourney } from "../../providers/ActiveJourneyProvider";
import { useMenu } from "../../providers/MenuProvider";

interface Props {
	// routes: any;
	logo: any;
	rtlActive: boolean;
}

const Sidebar = ({ logo, rtlActive }: Props) => {
	const [activeMenu, setActiveMenu] = useState<any>(null);

	const { dataUser, activeJourney } = useActiveJourney();
	const { rules } = useMenu();
	const location = useLocation();

	useEffect(() => {
		ruleStepJorney();
	}, [rules]);

	// verifies if routeName is the one active (in browser input)
	const activeRoute = (routeName: string) => {
		return location.pathname.indexOf(routeName) > -1 ? "active" : "";
	};

	let navbarBrandProps;
	if (logo && logo.innerLink) {
		navbarBrandProps = {
			to: logo.innerLink,
			tag: Link,
		};
	} else if (logo && logo.outterLink) {
		navbarBrandProps = {
			href: logo.outterLink,
			target: "_blank",
		};
	}

	// função para verificar se o link está permitido de acordo com a jornada
	const ruleStepJorney = () => {
		for (var i = 0; i < rules.length; i++) {
			if (
				!rules[i].status &&
				rules[i].nome !== "diagnose" &&
				rules[i].nome !== "performance" &&
				rules[i].nome !== "education" &&
				rules[i].nome !== "evolution"
			) {
				switch (rules[i].nome) {
					case "purpose":
						setActiveMenu(MenuRoute[i]);
						break;
					case "survey":
						setActiveMenu(MenuRoute[i]);
						break;
					case "survey-finish":
						setActiveMenu(MenuRoute[i]);
						break;
					case "todoavoid":
						setActiveMenu(MenuRoute[i]);
						break;
					case "goals":
						setActiveMenu(MenuRoute[i]);
						break;
				}

				return;
			} else {
				if (rules[i].nome === "performance") {
					setActiveMenu(MenuRoute[i]);
				}

				if (rules[i].nome === "education" || rules[i].nome === "evolution") {
					setActiveMenu(MenuRoute[i]);
				}
			}
		}
	};

	const handleClose = () => {
		const overlay = document.querySelector(".overlay-sidenav");
		const sidenav = document.querySelector(".sidenav");

		if (overlay?.classList.contains("is-active")) {
			overlay?.classList.remove("is-active");
		}

		if (sidenav?.classList.contains("is-active")) {
			sidenav?.classList.remove("is-active");
		}
	};

	const ruleJourneyDisable = (menu: string) => {
		if (menu !== "home") {
			if (menu === "diagnose") {
				if (activeMenu?.icon === "todoavoid") {
					return "";
				}
			}

			if (menu === "performance") {
				return "";
			}
			// CASO PRECISE VOLTAR || menu === "evolution"
			if (menu === "education" && activeJourney) {
				return "";
			}

			if (activeMenu?.icon !== menu) {
				return "disabled";
			}
		}

		return "";
	};

	const renderItemMenu = (prop: any, key: number) => {
		return (
			<NavItem className={ruleJourneyDisable(prop.icon)} key={key}>
				<NavLink to={prop.layout + prop.path} tag={NavLinkRRD}>
					{prop.icon !== undefined ? (
						<>
							<img
								src={`assets/img/icons/menu/${prop.icon}${
									location.pathname === prop.layout ? "-active" : ""
								}.svg`}
								className={`${location.pathname === prop.layout && "active"}`}
								alt=""
							/>
						</>
					) : prop.miniName !== undefined ? (
						<>
							<span className="sidenav-mini-icon text-primary"> {prop.miniName} </span>
							<span className="sidenav-normal"> {prop.name} </span>
						</>
					) : (
						prop.name
					)}
				</NavLink>
			</NavItem>
		);
	};

	const createLinks = () => {
		return MenuRoute?.map((prop: any, key: number) => {
			if (dataUser?.idPlano > 1) {
				return (
					<div key={key} title={prop.name}>
						{renderItemMenu(prop, key)}
					</div>
				);
			} else {
				return (
					<div key={key} title={prop.name}>
						{prop.icon !== "education" && prop.icon !== "evolution" && (
							<>{renderItemMenu(prop, key)}</>
						)}
					</div>
				);
			}
		});
	};

	const scrollBarInner = (
		<div className="scrollbar-inner">
			<div className="sidenav-header">
				{logo ? (
					<>
						<NavbarBrand {...navbarBrandProps}>
							<img
								alt={logo.imgAlt}
								src="assets/img/favicon.png"
								className="navbar-brand-img"
							/>
						</NavbarBrand>
					</>
				) : null}
			</div>
			<div className="navbar-inner">
				<Collapse navbar isOpen={true}>
					<Nav navbar>{createLinks()}</Nav>
				</Collapse>
			</div>
		</div>
	);

	return (
		<>
			<div className="overlay-sidenav" onClick={handleClose}></div>
			<Navbar className="sidenav">
				{navigator.platform.indexOf("Win") > -1 ? (
					<PerfectScrollbar>{scrollBarInner}</PerfectScrollbar>
				) : (
					scrollBarInner
				)}
			</Navbar>
		</>
	);
};

export default Sidebar;
