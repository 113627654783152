import "./ToDoAvoidAddModal.scss";

import * as yup from "yup";

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import React, { FC, useEffect, useState } from "react";

import ToDoAvoidService from "../../services/ToDoAvoidService";
import { toastSettings } from "../../models/toastConfig";
import { useForm } from "react-hook-form";
import { useToDoAvoid } from "../../providers/ToDoAvoidProvider";
import { yupResolver } from "@hookform/resolvers/yup";

interface ToDoAvoidAddModalProps {
	show: boolean;
	onClose?: any;
}

type FormValues = {
	descricao: string;
	idProcessoJornada: number;
	etapaLista: number;
};

const ToDoAvoidAddModal: FC<ToDoAvoidAddModalProps> = ({ show, onClose }) => {
	const [modal, setModal] = useState<boolean>(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const { handleList, itemSelected } = useToDoAvoid();

	const toggle = () => setModal(!modal);
	const handleOnClose = () => onClose(false);

	let defaultValues = {
		descricao: "",
		idProcessoJornada: 0,
		etapaLista: 0,
	} as FormValues;

	const {
		register,
		handleSubmit,
		setValue,
		reset,
		formState: { errors },
	} = useForm<FormValues>({
		mode: "onChange",
		defaultValues,
		resolver: yupResolver(
			yup.object().shape({
				idProcessoJornada: yup.number().required(),
				etapaLista: yup.number().required(),
				descricao: yup.string().required().min(5).max(100),
			}),
		),
	});

	const createNewItem = async (data: any) => {
		try {
			setIsSubmitting(true);

			const [_Response, _Error] = await new ToDoAvoidService().create(data);

			if (!!_Error || !_Response?.status) {
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
				setTimeout(() => setIsSubmitting(false), 1000);
				return false;
			}

			toastSettings("Criado novo item com sucesso!", "bottom-center", "success");

			handleList();
		} catch (err) {
			console.warn(err);
		}

		setIsSubmitting(false);
		toggle();
	};

	useEffect(() => {
		if (show) {
			reset();
			setIsSubmitting(false);
			setModal(show);
		}
	}, [show]);

	useEffect(() => {
		if (itemSelected) {
			setValue("idProcessoJornada", itemSelected?.idProcessoJornada || 0);
			setValue("etapaLista", itemSelected?.etapaLista || 0);
		}
	}, [itemSelected]);

	return (
		<Modal
			centered
			scrollable
			size="lg"
			isOpen={modal}
			toggle={toggle}
			onClosed={handleOnClose}
			className="ToDoAvoidAddModal"
			data-testid="ToDoAvoidAddModal"
		>
			<form onSubmit={handleSubmit(createNewItem)}>
				<ModalHeader toggle={toggle}>
					Adicionar um Item na Lista de{" "}
					{itemSelected?.etapaLista === 3 ? "Evitar" : "Fazer"}
				</ModalHeader>

				<ModalBody>
					<h2 className="title s">
						Vamos abaixo adicionar um item na lista de{" "}
						{itemSelected?.etapaLista === 3 ? "evitar" : "fazer"}.
					</h2>

					<div className="fieldset">
						<label htmlFor="descricao">Qual é a descrição da atividade?</label>
						<input
							type="text"
							id="descricao"
							className={`${!errors.descricao || "invalid"}`}
							{...register("descricao")}
							minLength={5}
							maxLength={100}
						/>

						{errors.descricao?.type === "required" && (
							<p className="error">Link do item é obrigatório</p>
						)}
						{errors.descricao?.type === "min" && (
							<p className="error">Deve conter no mínimo 5 caracteres</p>
						)}
						{errors.descricao?.type === "max" && (
							<p className="error">Deve conter no máximo 25 caracteres</p>
						)}
					</div>
				</ModalBody>

				<ModalFooter>
					<Button type="button" disabled={isSubmitting} onClick={toggle}>
						Cancelar
					</Button>
					<Button type="submit" disabled={isSubmitting} color="primary">
						Criar novo item
					</Button>
				</ModalFooter>
			</form>
		</Modal>
	);
};

export default ToDoAvoidAddModal;
