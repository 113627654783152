import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC } from "react";
import { Col, Row } from "reactstrap";
import "./Loading.scss";

interface LoadingProps {
	margin?: string;
}

const Loading: FC<LoadingProps> = ({ margin = "" }) => (
	<Row className={`Loading ${margin}`} data-testid="Loading">
		<Col className="text-center">
			<FontAwesomeIcon icon={faSpinner} spin style={{ fontSize: 26 }} />
		</Col>
	</Row>
);

export default Loading;
