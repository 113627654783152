import React, { useState } from "react";
import { toastSettings } from "../models/toastConfig";
import ProgressTrackerService from "../services/ProgressTrackerService";

export const ProgressTrackerContext = React.createContext<any>({});

export const ProgressTrackerProvider = (props: any) => {
	const [data, setData] = useState<any>({});
	const [chartOptions, setChartOptions] = useState<any>([]);
	const [dataStatus, setDataStatus] = useState<any[]>([]);
	const [dataStatusProgresso, setDataStatusProgresso] = useState<any[]>([]);

	const handleList = async () => {
		try {
			const [_Response, _Error] = await new ProgressTrackerService().list();

			if (!!_Error || !_Response?.status) {
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
				return;
			}

			setData(_Response.resultSet);
		} catch (err) {
			console.warn(err);
		}
	};

	const handleListStatus = async () => {
		try {
			const [_Response, _Error] = await new ProgressTrackerService().listStatus();

			if (!!_Error || !_Response?.status) {
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
				return;
			}

			let arratStatus = _Response.resultSet?.map((item: any) => {
				return { label: item.descricao, value: item.id };
			});

			setDataStatus(arratStatus);
		} catch (err) {
			console.warn(err);
		}
	};

	const handleListStatusProgresso = async () => {
		try {
			const [_Response, _Error] =
				await new ProgressTrackerService().listStatusProgresso();

			if (!!_Error || !_Response?.status) {
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
				return;
			}

			let arratStatus = _Response.resultSet?.map((item: any) => {
				return { label: item.descricao, value: item.id };
			});

			setDataStatusProgresso(arratStatus);
		} catch (err) {
			console.warn(err);
		}
	};

	const updateStatus = async (id: number, status: number) => {
		try {
			const [_Response, _Error] = await new ProgressTrackerService().updateStatus(
				id,
				status,
			);

			if (!!_Error || !_Response?.status) {
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
				return;
			}

			handleList();
		} catch (err) {
			console.warn(err);
		}
	};

	const updateStatusUelf = async (id: number, status: number) => {
		try {
			const [_Response, _Error] =
				await new ProgressTrackerService().updateStatusUelf(id, status);

			if (!!_Error || !_Response?.status) {
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
				return;
			}

			handleList();
		} catch (err) {
			console.warn(err);
		}
	};

	const updateProgresso = async (
		id: number,
		idMetasSecundarias: number,
		progresso: number,
	) => {
		try {
			const [_Response, _Error] =
				await new ProgressTrackerService().updateProgresso(
					id,
					idMetasSecundarias,
					progresso,
				);

			if (!!_Error || !_Response?.status) {
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
				return;
			}

			handleList();
		} catch (err) {
			console.warn(err);
		}
	};

	const updateProgressoUelf = async (
		id: number,
		idObjetivoUelf: number,
		progresso: number,
	) => {
		try {
			const [_Response, _Error] =
				await new ProgressTrackerService().updateProgressoUelf(
					id,
					idObjetivoUelf,
					progresso,
				);

			if (!!_Error || !_Response?.status) {
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
				return;
			}

			handleList();
		} catch (err) {
			console.warn(err);
		}
	};

	const handleGrafico = () => {
		setChartOptions({
			options: {
				indexAxis: "y",
				scales: {
					x: {
						display: false,
						min: 0,
						max: 100,
						grid: {
							display: false,
						},
					},
					y: {
						display: false,
					},
				},
				plugins: {
					autocolors: false,
					legend: {
						display: false,
					},
				},
			},
		});
	};

	return (
		<ProgressTrackerContext.Provider
			value={{
				chartOptions,
				handleListStatus,
				handleListStatusProgresso,
				dataStatus,
				setDataStatus,
				dataStatusProgresso,
				setDataStatusProgresso,
				handleGrafico,
				data,
				setData,
				handleList,
				updateStatus,
				updateStatusUelf,
				updateProgresso,
				updateProgressoUelf,
			}}
		>
			{props.children}
		</ProgressTrackerContext.Provider>
	);
};

export const useProgressTracker = () =>
	React.useContext(ProgressTrackerContext);
