import React, { useState } from "react";
import { Input } from "reactstrap";
import "./FileInput.scss";

interface IFileInput {
	id: string;
	num: string;
	label: string;
	dataQuestionId: number;
	onImageChange: any;
}

const FileInput = ({
	id,
	num,
	label,
	dataQuestionId,
	onImageChange,
}: IFileInput): JSX.Element => {
	const [file, setFile] = useState<string>("");

	return (
		<div className="input-file-row">
			<label className="input-text-label" htmlFor={id}>
				<b>{num}</b>
				{label}
			</label>
			<div>
				<small>* Para agilizar o envio da sua imagem, envie imagens de até 1Mb.</small>
			</div>
			<div>
				<label htmlFor={id} className="btn btn-image m-0">
					<span>Escolher imagem</span>
				</label>
				<span className="ml-3">{file}</span>
			</div>

			<Input
				type="file"
				id={id}
				accept="image/*"
				data-question-id={dataQuestionId}
				onChange={(e: any) => {
					setFile(e?.target?.files[0].name);
					onImageChange(e);
				}}
				required
			/>
		</div>
	);
};

export default FileInput;
