import api from "./api";

class MacroGoalsService {
	async list() {
		try {
			const response = await api.get(`app/plano/performance/objetivos`);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}

	async listResources() {
		try {
			const response = await api.get(`app/recurso/lista`);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}

	async create(data: { idRecurso: number; pergunta: string; resposta: string }) {
		try {
			const response = await api.post(`app/metas/secundarias/create`, data);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}

	async edit(data: {
		id: number;
		idRecurso: number;
		pergunta: string;
		resposta: string;
	}) {
		try {
			const response = await api.post(`app/metas/secundarias/create`, data);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}

	async delete(data: any) {
		try {
			const response = await api.post(`app/metas/secundarias/delete`, data);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}

	async updateResposta(data: any) {
		try {
			const response = await api.post(`app/objetivo/uelf/update/resposta`, data);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}
}

export default MacroGoalsService;
