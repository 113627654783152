import "./ChartEvolutionComfortZones.scss";
import "swiper/css";
import "swiper/css/navigation";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";

import ChartEvolutionComfortZonesItem from "../ChartEvolutionComfortZonesItem/ChartEvolutionComfortZonesItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { faBug } from "@fortawesome/free-solid-svg-icons";

SwiperCore.use([Navigation]);

const ChartEvolutionComfortZones = ({ data }: any) => {
	return (
		<div
			className="ChartEvolutionComfortZones card"
			data-testid="ChartEvolutionComfortZones"
		>
			{data.tarefasTotaisConcluidas !== 0 ? (
				<>
					<div className="title-wrapper">
						<span className="title">Evolution - Zona de Conforto</span>
						<span className="subtitle">Total de tarefas: {data.tarefasTotais}</span>
						<span className="subtitle">
							Total concluídas: {data.tarefasTotaisConcluidas}/{data.tarefasTotais}
						</span>
					</div>
					{data?.detalhesObjetivos?.length && (
						<Swiper
							navigation={true}
							observeParents={true}
							observer={true}
							pagination={false}
							parallax={true}
							slidesPerView={1}
							spaceBetween={100}
						>
							{data.detalhesObjetivos.map((item: any, index: number) => (
								<SwiperSlide key={index}>
									<ChartEvolutionComfortZonesItem
										key={index}
										item={item}
										index={index}
									/>
								</SwiperSlide>
							))}
						</Swiper>
					)}
				</>
			) : (
				<>
					<div className="title-wrapper">
						<span className="title">Evolution - Zona de Conforto</span>
					</div>
					<div className="empty-message">
						<FontAwesomeIcon icon={faBug} />
						<span>Você ainda não têm uma pontuação em zonas de conforto.</span>
					</div>
				</>
			)}
		</div>
	);
};

export default ChartEvolutionComfortZones;
