import "./Dashboard.scss";

import { useEffect, useState } from "react";

import ChartEvolutionComfortZones from "../../components/ChartEvolutionComfortZones/ChartEvolutionComfortZones";
import ChartPerformance from "../../components/ChartPerformance/ChartPerformance";
import ComfortZoneChart from "../../components/ComfortZoneChart/ComfortZoneChart";
import DashboardResourceScore from "../../components/DashboardResourceScore/DashboardResourceScore";
import DashboardService from "../../services/DashboardService";
import DashboardTrackScore from "../../components/DashboardTrackScore/DashboardTrackScore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loading from "../../components/Loading/Loading";
import PainelMasterPage from "../../components/PainelMasterPage/PainelMasterPage";
import { ToastContainer } from "react-toastify";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";
import { toastSettings } from "../../models/toastConfig";

const Dashboard = () => {
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [data, setData] = useState<any>([]);

	useEffect(() => {
		handleList().then();
	}, []);

	const handleList = async () => {
		try {
			const [_Response, _Error] = await new DashboardService().list();

			if (!!_Error || !_Response?.status) {
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
				return;
			}

			setData(_Response.resultSet);
			setIsLoading(false);
		} catch (err) {
			console.warn(err);
		}
	};

	const render = (data: any) => {
		return (
			<div className="dashboard">
				<div className="card">
					<span className="title">As notas dos comparativos dos seus recursos</span>
					<ChartPerformance items={data.graficoRadar} />
				</div>
				<ComfortZoneChart data={data.graficoZona} />
				<ChartEvolutionComfortZones data={data.graficoEvolution} />
				{data.plusoft?.cursos?.length && data.plusoft?.notaAluno > 0 && (
					<DashboardTrackScore data={data.plusoft} />
				)}
				{data.recursos.length &&
					data.recursos.map((item: any, key: number) => (
						<DashboardResourceScore key={key} data={item} />
					))}
			</div>
		);
	};

	return (
		<PainelMasterPage
			title={"Dashboard"}
			subtitle={
				"Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt, ex."
			}
			subtitleclass="text-white"
			icon={<FontAwesomeIcon icon={faChartLine} />}
			onClick={() => {}}
		>
			{!isLoading ? <>{data && render(data)}</> : <Loading margin="my-6" />}
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</PainelMasterPage>
	);
};

export default Dashboard;
