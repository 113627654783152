import api from "./api";

class SurveyService {
	async list(data?: { idRecurso?: number; idProcessoJornada?: number }) {
		if (data) {
			if (data.idRecurso) {
				data = {
					idRecurso: data.idRecurso,
				};

				try {
					const response = await api.post(`app/subrecurso/questoes`, { ...data });

					return [response.data, undefined];
				} catch (e: any) {
					return [
						undefined,
						e?.response?.data?.message ||
							"Não foi possível realizar essa ação por favor tente mais tarde.",
					];
				}
			} else {
				data = {
					idProcessoJornada: data.idProcessoJornada,
				};

				try {
					const response = await api.post(`app/subrecurso/questoes`, { ...data });

					return [response.data, undefined];
				} catch (e: any) {
					return [
						undefined,
						e?.response?.data?.message ||
							"Não foi possível realizar essa ação por favor tente mais tarde.",
					];
				}
			}
		} else {
			try {
				const response = await api.post(`app/subrecurso/questoes`);

				return [response.data, undefined];
			} catch (e: any) {
				return [
					undefined,
					e?.response?.data?.message ||
						"Não foi possível realizar essa ação por favor tente mais tarde.",
				];
			}
		}
	}

	async recordResponses(data: any) {
		try {
			const response = await api.post(`app/subrecurso/create/resposta`, {
				...data,
			});

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}
}

export default SurveyService;
