import "./LoaderJourneyEducation.scss";

import React, { useEffect, useState } from "react";

const LoaderJourneyEducation = () => {
	const [count, setCount] = useState(10);

	const decrementCounter = () => {
		if (count <= 0) {
			setCount(10);
		} else {
			setCount(count - 1);
		}
	};

	useEffect(() => {
		setTimeout(decrementCounter, 1000);
	}, [count]);

	return (
		<div className="LoaderJourneyEducation" data-testid="LoaderJourneyEducation">
			<div className="counter">
				<div className="counter-loader">{count}</div>
				<div className="loader">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100"
						fill="currentColor"
					>
						<circle cx="50" cy="50" r="4.5" />
					</svg>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100"
						fill="currentColor"
					>
						<circle cx="50" cy="50" r="4.5" />
					</svg>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100"
						fill="currentColor"
					>
						<circle cx="50" cy="50" r="4.5" />
					</svg>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100"
						fill="currentColor"
					>
						<circle cx="50" cy="50" r="4.5" />
					</svg>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100"
						fill="currentColor"
					>
						<circle cx="50" cy="50" r="4.5" />
					</svg>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100"
						fill="currentColor"
					>
						<circle cx="50" cy="50" r="4.5" />
					</svg>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100"
						fill="currentColor"
					>
						<circle cx="50" cy="50" r="4.5" />
					</svg>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100"
						fill="currentColor"
					>
						<circle cx="50" cy="50" r="4.5" />
					</svg>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100"
						fill="currentColor"
					>
						<circle cx="50" cy="50" r="4.5" />
					</svg>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100"
						fill="currentColor"
					>
						<circle cx="50" cy="50" r="4.5" />
					</svg>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100"
						fill="currentColor"
					>
						<circle cx="50" cy="50" r="4.5" />
					</svg>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100"
						fill="currentColor"
					>
						<circle cx="50" cy="50" r="4.5" />
					</svg>
				</div>
			</div>
			<div className="message">
				<span>Estamos preparando o seu ambiente.</span>
			</div>
		</div>
	);
};

export default LoaderJourneyEducation;
