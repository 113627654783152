import api from "./api";

class CardEducationJourneyService {
	async getUrl() {
		try {
			const response = await api.get(`app/plusoft/login/sso`);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}
}

export default CardEducationJourneyService;
