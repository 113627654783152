import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const StagesContext = React.createContext<any>({});

export const StagesProvider = (props: any) => {
	const [currentStage, setCurrentStage] = useState<number>(0);
	const [currentStageName, setCurrentStageName] = useState<string>("");
	const [stages, setStages] = useState<any[]>([]);

	const navigate = useNavigate();
	const location = useLocation();

	const handlePrevStage = () => {
		if (currentStage === 0) return;
		setCurrentStage(currentStage - 1);
	};

	const handleNextStage = (stages: number) => {
		if (currentStage === stages - 1) {
			if (location.pathname === "/todoavoid") {
				navigate("/goals");
			}
			return;
		}

		setCurrentStage(currentStage + 1);
	};

	useEffect(() => {
		setCurrentStage(0);
		setCurrentStageName("");
	}, []);

	useEffect(() => {
		if (stages.length > 0) {
			setCurrentStageName(stages[currentStage].descObjetivo);
		}
	}, [currentStage, stages]);

	return (
		<StagesContext.Provider
			value={{
				currentStage,
				setCurrentStage,
				currentStageName,
				setCurrentStageName,
				handlePrevStage,
				handleNextStage,
				stages,
				setStages,
			}}
		>
			{props.children}
		</StagesContext.Provider>
	);
};

export const useStages = () => React.useContext(StagesContext);
