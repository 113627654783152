export const MonthsEnum = [
	{
		number: "01",
		name: "Janeiro",
		short: "Jan",
	},
	{
		number: "02",
		name: "Fevereiro",
		short: "Fev",
	},
	{
		number: "03",
		name: "Março",
		short: "Mar",
	},
	{
		number: "04",
		name: "Abril",
		short: "Abr",
	},
	{
		number: "05",
		name: "Maio",
		short: "Mai",
	},
	{
		number: "06",
		name: "Junho",
		short: "Jun",
	},
	{
		number: "07",
		name: "Julho",
		short: "Jul",
	},
	{
		number: "08",
		name: "Agosto",
		short: "Ago",
	},
	{
		number: "09",
		name: "Setembro",
		short: "Set",
	},
	{
		number: "10",
		name: "Outubro",
		short: "Out",
	},
	{
		number: "11",
		name: "Novembro",
		short: "Nov",
	},
	{
		number: "12",
		name: "Dezembro",
		short: "Dez",
	},
];
