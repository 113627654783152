import * as yup from "yup";

import {
    validateCNPJ,
    validateCPF,
    validateCep
} from "validations-br";

export const schema = yup.object().shape({
    cpfCnpj: yup
        .string()
        .required()
        .test(
            "cpfCnpj",
            "CPF é inválido",
            (value: string | undefined) =>
                validateCPF(value || "") || validateCNPJ(value || ""),
        ),
    occupation: yup.string().required().max(50),
    birthdate: yup.mixed().required(),
    cep: yup
        .string()
        .notRequired()
        // @ts-ignore
        .test("cep", "CEP é inválido", (value: any) => {
            if (value) {
                return validateCep(
                    value?.replace(/\D/g, "").replace(/(\d{5})(\d)/, "$1-$2") || "",
                );
            } else {
                return true;
            }
        }),
    street: yup.string().required().max(50),
    neighborhood: yup.string().required().max(50),
    addressNumber: yup.string().required().max(15),
    complement: yup.string().max(50),
    state: yup.string().required().max(50),
    city: yup.string().required().max(50)
});