import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { customModalStyles } from "../../models/selectConfig";
import { toastSettings } from "../../models/toastConfig";
import SuccessRoadmapService from "../../services/SuccessRoadmapService";
import "./SuccessRoadmapStatusSelect.scss";
import { schema } from "./Validate/Validate";

export interface Props {
	level: number;
	isUELF: boolean;
	status: number;
	primaryID: number;
	secondaryID?: number;
}

export interface FormValues {
	itemStatus: number;
}

const SuccessRoadmapStatusSelect = ({
	level,
	isUELF = false,
	status,
	primaryID,
	secondaryID,
}: Props) => {
	const [options, setOptions] = useState<any>();
	const [optionSelected, setOptionSelected] = useState<any>();

	const {
		control,
		formState: { errors },
	} = useForm<FormValues>({
		mode: "onChange",
		resolver: yupResolver(schema),
	});

	useEffect(() => {
		handleStatusList().then();
	}, []);

	useEffect(() => {
		if (optionSelected) {
			updateStatus().then();
		}
	}, [optionSelected]);

	const handleStatusList = async () => {
		if (level === 1) {
			try {
				const [_Response, _Error] = await new SuccessRoadmapService().statusList();

				if (!!_Error || !_Response?.status) {
					toastSettings(_Error || _Response?.message, "bottom-center", "error");
					return;
				}

				let response = _Response.resultSet;
				let options: any = [];

				response.forEach((op: any) => {
					options.push({ label: op.descricao, value: op.id });
				});

				setOptions(options);
			} catch (err) {
				console.warn(err);
			}
		} else {
			try {
				const [_Response, _Error] =
					await new SuccessRoadmapService().statusListSecondary();

				if (!!_Error || !_Response?.status) {
					toastSettings(_Error || _Response?.message, "bottom-center", "error");
					return;
				}

				let response = _Response.resultSet;
				let options: any = [];

				response.forEach((op: any) => {
					options.push({ label: op.descricao, value: op.id });
				});

				setOptions(options);
			} catch (err) {
				console.warn(err);
			}
		}
	};

	const updateStatus = async () => {
		if (optionSelected) {
			if (isUELF) {
				if (level === 1) {
					let data = {
						id: primaryID,
						status: optionSelected?.value,
					};

					try {
						const [_Response, _Error] =
							await new SuccessRoadmapService().updateStatusUELF(data);

						if (!!_Error || !_Response?.status) {
							toastSettings(_Error || _Response?.message, "bottom-center", "error");
							return;
						}

						toastSettings(
							"O status foi alterado com sucesso!",
							"bottom-center",
							"success",
						);
					} catch (err) {
						console.warn(err);
					}
				} else {
					let data = {
						id: primaryID,
						idObjetivoUelf: secondaryID,
						status: optionSelected?.value,
					};

					try {
						const [_Response, _Error] =
							await new SuccessRoadmapService().updateStatusUELFSecondary(data);

						if (!!_Error || !_Response?.status) {
							toastSettings(_Error || _Response?.message, "bottom-center", "error");
							return;
						}

						toastSettings(
							"O status foi alterado com sucesso!",
							"bottom-center",
							"success",
						);
					} catch (err) {
						console.warn(err);
					}
				}
			} else {
				if (level === 1) {
					let data = {
						id: primaryID,
						status: optionSelected?.value,
					};

					try {
						const [_Response, _Error] =
							await new SuccessRoadmapService().updateStatus(data);

						if (!!_Error || !_Response?.status) {
							toastSettings(_Error || _Response?.message, "bottom-center", "error");
							return;
						}

						toastSettings(
							"O status foi alterado com sucesso!",
							"bottom-center",
							"success",
						);
					} catch (err) {
						console.warn(err);
					}
				} else {
					let data = {
						id: primaryID,
						idMetasSecundarias: secondaryID,
						status: optionSelected?.value,
					};

					try {
						const [_Response, _Error] =
							await new SuccessRoadmapService().updateStatusSecondary(data);

						if (!!_Error || !_Response?.status) {
							toastSettings(_Error || _Response?.message, "bottom-center", "error");
							return;
						}

						toastSettings(
							"O status foi alterado com sucesso!",
							"bottom-center",
							"success",
						);
					} catch (err) {
						console.warn(err);
					}
				}
			}
		}
	};

	const renderSelect = () => {
		return (
			<Controller
				name="itemStatus"
				control={control}
				render={({ field: { onChange, name, ref } }) => (
					<Select
						ref={ref}
						name={name}
						isSearchable={false}
						isClearable={false}
						options={options}
						placeholder="Selecione..."
						className={`select ${!errors.itemStatus || "invalid"}`}
						defaultValue={options?.find((x: any) => x.value === status) || ""}
						onChange={(val: any) => {
							onChange(val || null);
							setOptionSelected(val);
						}}
						noOptionsMessage={() => "Não há registros"}
						styles={customModalStyles}
						menuPortalTarget={document.body}
						menuPosition={"absolute"}
					/>
				)}
			/>
		);
	};

	return <>{options && <>{renderSelect()}</>}</>;
};

export default SuccessRoadmapStatusSelect;
