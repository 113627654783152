import "./MacroGoalsItem.scss";

import { Card, FormGroup, Input, Label } from "reactstrap";
import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MacroGoalsService from "../../services/MacroGoalsService";
import { faEdit } from "@fortawesome/free-regular-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { toastSettings } from "../../models/toastConfig";
import { useMacroGoals } from "../../providers/MacroGoalsProvider";

export interface Props {
	data: {
		id: number;
		resourceId: number;
		category: string;
		goal: string;
		why: string;
		icone: string;
	};
	uelfGoal?: boolean;
}

const MacroGoalsItem = ({ data, uelfGoal }: Props) => {
	const { goals, setGoals, viewDelete, setIsMultipleSelected } = useMacroGoals();
	const [isDisabled, setIsDisabled] = useState<boolean>(true);

	const handleClick = () => {
		setIsDisabled(!isDisabled);
	};

	const setForEdit = (id: number) => {
		setGoals(
			[...goals].map((goal: any) => {
				if (goal.id === id) {
					goal.isSelectedEdit = true;
				}

				return goal;
			}),
		);
	};

	const setEditMacroGoals = async (id: number, dataValue: string) => {
		try {
			if (dataValue === "") {
				return;
			}

			let data: any = { id: id, resposta: dataValue };

			const [_Response, _Error] = await new MacroGoalsService().updateResposta(
				data,
			);

			if (!!_Error || !_Response?.status) {
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
			}

			setIsDisabled(true);

			toastSettings(`Dados inseridos com sucesso!`, "bottom-center", "success");

			return _Response.resultSet;
		} catch (err) {
			console.warn(err);
		}
	};

	const setForDeletion = (id: number, isMultipleSelected?: boolean) => {
		setGoals(
			[...goals].map((goal: any) => {
				if (goal.id === id) {
					goal.isSelectedDelete = true;
				}

				if (isMultipleSelected) {
					setIsMultipleSelected(true);
				}

				return goal;
			}),
		);
	};

	if (uelfGoal) {
		return (
			<div
				id={"item-" + data.id}
				className="macro-goals-item uelf"
				data-testid="MacroGoalsItem"
			>
				<FormGroup check inline>
					<Label check htmlFor={"cbox-" + data.id}>
						<Card>
							<div className="macro-goals-item-row title-row">
								<div className="macro-goals-item-col">
									<span className="title">Categoria UELF</span>
								</div>
								<div className="macro-goals-item-col">
									<span className="title">Objetivos</span>
								</div>
								<div className="macro-goals-item-col">
									<span className="title">Porque isso é importante para mim...</span>
								</div>
							</div>
							<div className="macro-goals-item-row content-row">
								<div className="macro-goals-item-col">
									<span className="mb-label">Categoria UELF:</span>
									<div className="data-icons">
										<img src={data.icone} alt={data.category} width="35px" />
										<span className="content ml-2"> {data.category} </span>
									</div>
								</div>
								<div className="macro-goals-item-col">
									<span className="mb-label">Objetivos:</span>
									<span className="content">{data.why}</span>
								</div>
								<div className="macro-goals-item-col">
									<span className="mb-label">Porque isso é importante para mim:</span>
									<Input
										autoFocus
										type="text"
										name="objetivo-uelf"
										defaultValue={data.goal}
										data-idObjetivo={data.id}
										onBlur={(e: any) => {
											setEditMacroGoals(data.id, e.target.value);
										}}
										maxLength={100}
										disabled={isDisabled}
										required
									/>
								</div>
								<div onClick={handleClick} style={{ cursor: "pointer" }}>
									<FontAwesomeIcon icon={faEdit} />
								</div>
							</div>
						</Card>
					</Label>
				</FormGroup>
			</div>
		);
	} else {
		return (
			<div
				id={"item-" + data.id}
				className={`macro-goals-item ${viewDelete ? "enable-selection" : ""}`}
				data-testid="MacroGoalsItem"
			>
				<FormGroup check inline>
					<div className="input-wrapper">
						<Input
							type="checkbox"
							id={"cbox-" + data.id}
							className="input"
							disabled={!viewDelete}
							onChange={() => setForDeletion(data.id, true)}
						/>
					</div>
					<Label check htmlFor={"cbox-" + data.id}>
						<Card>
							<div className="macro-goals-item-row title-row">
								<div className="macro-goals-item-col">
									<span className="title">Categoria</span>
								</div>
								<div className="macro-goals-item-col">
									<span className="title">Objetivos</span>
								</div>
								<div className="macro-goals-item-col">
									<span className="title">Porque isso é importante para mim...</span>
								</div>
							</div>
							<div className="macro-goals-item-row content-row">
								<div className="macro-goals-item-col">
									<div className="data-icons">
										<img src={data.icone} alt={data.category} width="35px" />
										<span className="content ml-2"> {data.category} </span>
									</div>
								</div>
								<div className="macro-goals-item-col">
									<span className="content">{data.why}</span>
								</div>
								<div className="macro-goals-item-col d-inline-block">
									<div className="d-flex justify-content-center align-items-center">
										<div className="col-text-question">
											<span className="content">{data.goal}</span>
										</div>

										<div className="col-icons">
											<button onClick={() => setForEdit(data.id)}>
												<FontAwesomeIcon icon={faEdit} />
											</button>
											<button
												className="mt-1"
												onClick={() => setForDeletion(data.id, false)}
											>
												<FontAwesomeIcon icon={faTrash} />
											</button>
										</div>
									</div>
								</div>
							</div>
						</Card>
					</Label>
				</FormGroup>
			</div>
		);
	}
};

export default MacroGoalsItem;
