import React, { useEffect, useState } from "react";

import AuthService from "../services/AuthService";
import Cookies from "js-cookie";
import { User } from "../models/user";
import moment from "moment";
import { toastSettings } from "../models/toastConfig";
import { useNavigate } from "react-router-dom";

export const AuthContext = React.createContext<User | any>({});

export const AuthProvider = (props: any) => {
	const [user, setUser] = useState<User | null>(null);
	const [planoActive, setPlanoActive] = useState<number | null>(null);
	const [error, setError] = useState<string>("");
	const [activeTab, setActiveTab] = useState("login");
	const [isLoadingScreen, setIsLoadingScreen] = useState<boolean>(false);
	const [slide, setSlide] = useState<any>(0);

	const navigate = useNavigate();

	useEffect(() => {
		const disposer = () => {
			const userStorage = localStorage.getItem("@uelf:user");
			const planoStorage = localStorage.getItem("plano");
			setUser(!!userStorage ? JSON.parse(userStorage) : null);
			setPlanoActive(!!planoStorage ? JSON.parse(planoStorage) : null);
		};
		disposer();
	}, []);

	useEffect(() => {
		if (user) {
			localStorage.setItem("@uelf:user", JSON.stringify(user));
		}
	}, [user]);

	useEffect(() => {
		if (planoActive !== null) {
			setIsLoadingScreen(true);

			localStorage.setItem("plano", JSON.stringify(planoActive));

			if (planoActive > 0) {
				setIsLoadingScreen(false);
			}
		}
	}, [planoActive]);

	const login = async (data: {
		email: string;
		password: string;
		toggle: boolean;
	}) => {
		try {
			window.localStorage.setItem("lembreme", JSON.stringify(data));

			const [_Response, _Error] = await new AuthService().login(data);

			if (!!_Error || !_Response?.status) {
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
				return;
			}

			setUser({ ...user, ..._Response?.resultSet?.usuario });

			Cookies.set("access_token", _Response?.resultSet.token, {
				path: "/",
				expires: 1,
			});

			setPlanoActive(_Response?.resultSet.idPlano);

			if (_Response?.resultSet.idPlano === 0) {
				setIsLoadingScreen(true);
				return "payments";
			}

			setSlide(
				_Response?.resultSet?.jornadasConcluidas?.length > 0 ||
					_Response?.resultSet?.jornadaEmAndamento
					? 1
					: 0,
			);

			return "home";
		} catch (err) {
			return err;
		}
	};

	const signUp = async (data: any, file: File) => {
		try {
			let enderecos = [];

			data.cpfCnpj = data.cpfCnpj.replace(/\D/g, "");
			data.celular = data.celular.replace(/\D/g, "");
			data.dataNascimentoAbertura =
				data.dataNascimentoAbertura !== null
					? moment(data.dataNascimentoAbertura).format("YYYY-MM-DD")
					: "";
			data.tipoPessoa =
				data.cpfCnpj.length === 0 ? "F" : data.cpfCnpj.length === 11 ? "F" : "J";
			data.sendWhats = data.sendWhats ? 1 : 0;
			enderecos.push({
				cep: data.cep,
				logradouro: data.logradouro,
				numero: data.numero,
				complemento: data.complemento,
				bairro: data.bairro,
				estado: data.estado,
				cidade: data.cidade,
			});

			if (!enderecos[0].cep) {
				const [_Response, _Error] = await new AuthService().signUp(
					{ ...data },
					file,
				);

				if (!!_Error || !_Response?.status) {
					toastSettings(_Error || _Response?.message, "bottom-center", "error");
					return false;
				}

				toastSettings("Cadastro realizado com sucesso", "bottom-center", "success");
				setActiveTab("login");

				return true;
			} else {
				const [_Response, _Error] = await new AuthService().signUp(
					{ ...data, ...{ enderecos } },
					file,
				);

				if (!!_Error || !_Response?.status) {
					toastSettings(_Error || _Response?.message, "bottom-center", "error");
					return false;
				}

				toastSettings("Cadastro realizado com sucesso", "bottom-center", "success");
				setActiveTab("login");

				return true;
			}
		} catch (err) {
			return false;
		}
	};

	const updateProfile = async (data: any, file: File) => {
		try {
			let enderecos = [];

			data.cpfCnpj = data.cpfCnpj.replace(/\D/g, "");
			data.celular = data.celular.replace(/\D/g, "");
			data.dataNascimentoAbertura = data.dataNascimentoAbertura ? moment(data.dataNascimentoAbertura).format(
				"YYYY-MM-DD",
			) : null;
			data.tipoPessoa = !data.cpfCnpj.length ? 'F' : data.cpfCnpj.length === 11 ? "F" : "J";

			enderecos.push({
				cep: data.cep,
				logradouro: data.logradouro,
				numero: data.numero,
				complemento: data.complemento,
				bairro: data.bairro,
				estado: data.estado,
				cidade: data.cidade,
			});

			const [_Response, _Error] = await new AuthService().updateProfile(
				{ ...data, ...{ enderecos } },
				file,
			);

			if (!!_Error || !_Response?.status) {
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
				return;
			}

			toastSettings("Cadastro atualizado com sucesso", "bottom-center", "success");
			setActiveTab("login");

			return true;
		} catch (err) {
			return false;
		}
	};

	const updatePartialProfile = async (data: any) => {
		try {
			let enderecos = [];
			data.cpfCnpj = data.cpfCnpj.replace(/\D/g, "");

			if (data.dataNascimentoAbertura !== undefined) {
				data.dataNascimentoAbertura = moment(data?.birthdate).format("YYYY-MM-DD");
			}

			data.tipoPessoa = data.cpfCnpj.length === 11 ? "F" : "J";

			enderecos.push({
				cep: data.cep,
				logradouro: data.logradouro,
				numero: data.numero,
				complemento: data.complemento,
				bairro: data.bairro,
				estado: data.estado,
				cidade: data.cidade,
			});

			const [_Response, _Error] = await new AuthService().updatePartialProfile({
				...data,
				enderecos,
			});

			if (!!_Error || !_Response?.status) {
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
				return;
			}

			toastSettings("Cadastro atualizado com sucesso", "bottom-center", "success");
			setActiveTab("login");

			return true;
		} catch (err) {
			return false;
		}
	};

	const logout = async () => {
		try {
			await new AuthService().logout();

			Cookies.remove("access_token");

			setUser(null);
			localStorage.clear();
			navigate("/login");

			return true;
		} catch (err) {
			return err;
		}
	};

	return (
		<AuthContext.Provider
			value={{
				user,
				setUser,
				error,
				setError,
				login,
				logout,
				signUp,
				updateProfile,
				updatePartialProfile,
				activeTab,
				setActiveTab,
				isLoadingScreen,
				setIsLoadingScreen,
				planoActive,
				setPlanoActive,
				slide,
				setSlide,
			}}
		>
			{props.children}
		</AuthContext.Provider>
	);
};

export const useAuth = () => React.useContext(AuthContext);
