import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { toastSettings } from "../../models/toastConfig";
import NotesService from "../../services/NotesService";
import "./NotesConfirmModal.scss";

interface ModalNewsProps {
	show: boolean;
	onClose?: any;
	idNote: any;
	setFunction: any;
	idObjetivo?: number;
	isUelf?: boolean;
	isFeedback: boolean;
}

const NotesConfirmModal = ({
	show,
	onClose,
	idNote,
	setFunction,
	idObjetivo,
	isUelf,
	isFeedback,
}: ModalNewsProps) => {
	const [modal, setModal] = useState<boolean>(false);

	const toggle = () => setModal(!modal);
	const handleOnClose = () => onClose(false);

	const deleteNote = async () => {
		try {
			let data = {
				anotacoes: [
					{
						id: idNote,
					},
				],
			};

			const [_Response, _Error] = await new NotesService().delete(data);

			if (!!_Error || !_Response?.status) {
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
				return;
			}

			/**
			 * Está Função está vindo da tela de Feedback ou da tela de Notes
			 * Se for Feedback é a função getNotes(idObjetivo e isuelf)
			 * Se for notes é a handlelist() e não passa parametro
			 * Quem faz os controle de qual função chamar é a
			 * variavel de estado ISFEEDBACK se for true está
			 * vindo a função da tela de Feedback.tsx, se for false Notes.tsx
			 */
			setFunction(isFeedback ? idObjetivo : "", isFeedback ? isUelf : "");

			toggle();
		} catch (err) {
			console.warn(err);
		}
	};

	useEffect(() => {
		if (show) {
			setModal(show);
		}
	}, [show]);

	return (
		<Modal
			centered
			scrollable
			size="lg"
			isOpen={modal}
			toggle={toggle}
			onClosed={handleOnClose}
			className="modal-macro-goals-confirm"
			data-testid="NotesConfirmModal"
		>
			<ModalHeader toggle={toggle}>Excluir Anotacoes</ModalHeader>
			<ModalBody>
				<div className="text">
					<p>Você realmente deseja excluir a anotacao?</p>
				</div>
			</ModalBody>
			<ModalFooter>
				<Button type="button" onClick={toggle}>
					Cancelar
				</Button>
				<Button
					type="submit"
					onClick={() => {
						deleteNote();
					}}
					color="primary"
				>
					Sim, excluir
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default NotesConfirmModal;
