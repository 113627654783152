import React, { useEffect, useState } from "react";

export const SurveyContext = React.createContext<any>({});

export const SurveyProvider = (props: any) => {
	const [selfEvaluation, setSelfEvaluation] = useState<any>();
	const [habits, setHabits] = useState<any>();
	const [currentStage, setCurrentStage] = useState<any>();
	const [currentStageName, setCurrentStageName] = useState<string>("");
	const [currentStageIcon, setCurrentStageIcon] = useState<string>("");

	useEffect(() => {
		const savedStage = localStorage.getItem("savedStage");
		savedStage && setCurrentStage(savedStage);

		const savedStageName = window.localStorage.getItem("savedStageName");
		savedStageName && setCurrentStageName(savedStageName);
	}, []);

	useEffect(() => {
		currentStage && window.localStorage.setItem("savedStage", currentStage);
	}, [currentStage]);

	useEffect(() => {
		currentStageName &&
			window.localStorage.setItem("savedStageName", currentStageName);
	}, [currentStageName]);

	return (
		<SurveyContext.Provider
			value={{
				selfEvaluation,
				setSelfEvaluation,
				habits,
				setHabits,
				currentStage,
				setCurrentStage,
				currentStageName,
				setCurrentStageName,
				currentStageIcon,
				setCurrentStageIcon,
			}}
		>
			{props.children}
		</SurveyContext.Provider>
	);
};

export const useSurvey = () => React.useContext(SurveyContext);
