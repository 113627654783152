import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useMacroGoals } from "../../providers/MacroGoalsProvider";
import "./MacroGoalsConfirmModal.scss";

interface ModalNewsProps {
	show: boolean;
	onClose?: any;
}

const MacroGoalsConfirmModal = ({ show, onClose }: ModalNewsProps) => {
	const [modal, setModal] = useState<boolean>(false);

	const toggle = () => setModal(!modal);
	const handleOnClose = () => onClose(false);

	const { setWasConfirmDeletion } = useMacroGoals();

	useEffect(() => {
		if (show) {
			setModal(show);
		}
	}, [show]);

	return (
		<Modal
			centered
			scrollable
			size="lg"
			isOpen={modal}
			toggle={toggle}
			onClosed={handleOnClose}
			className="modal-macro-goals-confirm"
			data-testid="MacroGoalsConfirmModal"
		>
			<ModalHeader toggle={toggle}>Excluir Objetivos Macros</ModalHeader>
			<ModalBody>
				<div className="text">
					<p>Você realmente deseja excluir todos esses objetivos?</p>
				</div>
			</ModalBody>
			<ModalFooter>
				<Button type="button" onClick={toggle}>
					Cancelar
				</Button>
				<Button
					type="submit"
					onClick={() => {
						setWasConfirmDeletion(true);
						toggle();
					}}
					color="primary"
				>
					Sim, excluir
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default MacroGoalsConfirmModal;
