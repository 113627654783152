import "./CardPurchaseConfirmation.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { usePayments } from "../../providers/PaymentsProviders";

const CardPurchaseConfirmation = () => {
	const { planoEscolhido, cardData, setCardData } = usePayments();
	const navegate = useNavigate();

	let date = new Date();

	return (
		<>
			<div className="CardPurchaseConfirmation">
				<div className="row">
					<div className="col col-12 col-md-5 d-none d-md-flex justify-content-center align-items-center">
						<img src="./assets/img/pag.png" alt="Logo UELF" width="100%" />
					</div>

					<div className="col col-12 col-md-7">
						<div className="row">
							<div className="col col-12">
								<div
									className="cards"
									style={{
										backgroundImage: `url('./assets/img/backgroundpayments.svg')`,
									}}
								>
									<div className="row">
										<div className="col col-12 col-md-12 text-center justify-content-center align-items-center">
											<div className="title">
												<span>Obrigado!</span>
											</div>
										</div>
									</div>
									<div className="row mt-4 top-subdate">
										<div className="col col-6 col-md-6">
											<div className="text">
												<span>Data</span>
											</div>
											<div className="info">
												<span> {date.toLocaleDateString()} </span>
											</div>
										</div>
										<div className="col col-6 col-md-6">
											<div className="text">
												<span>Horário</span>
											</div>
											<div className="info">
												<span> {date.toLocaleTimeString()} </span>
											</div>
										</div>
									</div>
									<div className="row mt-2 mt-md-5">
										<div className="col col-12 col-md-12">
											<div className="text">
												<span>Compra</span>
											</div>
											<div className="info">
												{planoEscolhido && planoEscolhido?.id === 4 ? (
													<span>
														{" "}
														Plano {planoEscolhido.nomePlano} - UELF Valuation + Evolution +
														Education{" "}
													</span>
												) : (
													<span> Plano {planoEscolhido.nomePlano} </span>
												)}
											</div>
										</div>
									</div>
									<div className="row mt-2 mt-md-5">
										<div className="col col-12 col-md-12">
											<div className="text">
												<span>Valor Total</span>
											</div>
											<div className="info">
												<span>R$ {planoEscolhido?.valor || "0,00"} </span>
												<span className="badge badge-success ml-2 text-lowercase">
													aprovado
												</span>
											</div>
										</div>
									</div>
									<div className="row d-flex align-items-center mt-2 mt-md-5">
										<div className="col col-5 col-md-5">
											<div className="text">
												<span>Método de pagamento</span>
											</div>
										</div>
										<div className="col col-7 col-md-7 d-flex justify-content-start align-items-center">
											{cardData && cardData?.brand?.length && (
												<img
													src={
														"./assets/img/icons/cards/" +
														cardData?.brand?.toString().toLowerCase() +
														".png"
													}
													className="band-card"
													alt="logo cartão"
												/>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col col-12 text-center">
								<button
									className="btn"
									onClick={() => {
										navegate("/journey-education");
									}}
								>
									Continuar
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default CardPurchaseConfirmation;
