import { useNavigate } from "react-router-dom";
import "./PageNotFound.scss";

const PageNotFound = () => {
	const navigate = useNavigate();

	return (
		<div className="PageNotFound">
			<div className="content">
				<h1>
					<span>404. Isso é um erro.</span>
				</h1>
				<div className="text">
					<p>A URL solicitada não foi encontrada.</p>
				</div>
				<button
					onClick={() => {
						navigate("/home");
					}}
				>
					<span>Voltar</span>
				</button>
			</div>
			<div className="image">
				<img src="./assets/img/404.png" alt="404 - Error" />
			</div>
		</div>
	);
};

export default PageNotFound;
