import React, { FC } from "react";
import "./Footer.scss";

interface FooterProps {}

const Footer: FC<FooterProps> = () => (
	<div className="Footer" data-testid="Footer">
		<span>Zeneconomics 2022 - Todos os direitos reservados</span>
	</div>
);

export default Footer;
