import "./HeaderPage.scss";

import { Col, Container, Row } from "reactstrap";
import React, { MouseEventHandler } from "react";
import ReactPixel from "react-facebook-pixel";

import Breadcrumb from "../Breadcrumb/Breadcrumb";

interface Props {
	title: string;
	subtitle: string;
	titleclass: string;
	subtitleclass: string;
	icon: JSX.Element;
	onClick: MouseEventHandler<HTMLButtonElement>;
}

const HeaderPage = ({
	title,
	subtitle = "",
	titleclass = "",
	subtitleclass = "",
	icon = <></>,
	onClick,
}: Props) => {
	ReactPixel.init("744591990099086");
	ReactPixel.pageView();
	ReactPixel.track("track");
	return (
		<>
			<div className="header">
				<Container fluid>
					<div className="header-body">
						<Row className="align-items-center">
							<Col xs="12" lg="6">
								<h1 className="title">
									{icon}
									{title}
								</h1>
								<div className="text">
									<p>{subtitle}</p>
								</div>
							</Col>
							<Col lg="6" xs="5"></Col>
						</Row>
						{title.toLowerCase() !== "home" && <Breadcrumb namePage={title} />}
					</div>
				</Container>
			</div>
		</>
	);
};

export default HeaderPage;
