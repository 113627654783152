import React, { useState } from "react";
import { Input } from "reactstrap";
import "./InputText.scss";

interface IInputText {
	id: string;
	num: string;
	answer: string;
	label: string;
	dataQuestionId: number;
}

const InputText = ({
	id,
	num,
	answer,
	label,
	dataQuestionId,
}: IInputText): JSX.Element => {
	const [value, setValue] = useState(answer ?? "");

	return (
		<div className="input-text-row">
			<label className="input-text-label" htmlFor={id}>
				<b>{num}</b>
				{label}
			</label>
			<Input
				type="text"
				id={id}
				value={value ?? ""}
				data-question-id={dataQuestionId}
				onChange={(e: any) => {
					setValue(e.target.value);
				}}
				maxLength={150}
				required
			/>
		</div>
	);
};

export default InputText;
