import "./EPRegistration.scss";

import { Controller, useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";

import { Button } from "reactstrap";
import CpfCnpj from "../CpfCnpj/CpfCnpj";
import DatePicker from "react-datepicker";
import ExternalPaymentService from "../../services/ExternalPaymentService";
import InputMask from "react-input-mask";
import { schema } from "./Validate/Validate";
import { toastSettings } from "../../models/toastConfig";
import { useExternalPayment } from "../../providers/ExternalPaymentProvider";
import { yupResolver } from "@hookform/resolvers/yup";

type FormValues = {
	name: string;
	CPF: string;
	phone: string;
	birthDate: Date | null;
	occupation: string;
	email: string;
	password: string;
	passwordConfirmation: string;
};

const EPRegistration = () => {
	const {
		setStep,
		loading,
		setLoading,

		name,
		setName,
		CPF,
		setCPF,
		phone,
		setPhone,
		birthDate,
		setBirthDate,
		occupation,
		setOccupation,
		email,
		setEmail,
		password,
		setPassword,
	} = useExternalPayment();

	const [dateNow, setDateNow] = useState<Date>(new Date());

	let defaultValues = {
		name: name ? name : "",
		CPF: CPF ? CPF : "",
		phone: phone ? phone : "",
		birthDate: birthDate ? birthDate : null,
		occupation: occupation ? occupation : "",
		email: email ? email : "",
		password: "",
		passwordConfirmation: "",
	} as FormValues;

	const {
		register,
		handleSubmit,
		control,
		setValue,
		formState: { errors },
	} = useForm<FormValues>({
		mode: "onChange",
		defaultValues,
		resolver: yupResolver(schema),
	});

	const onSubmit = async (data: any) => {
		setLoading(true);
		setName(data.name);
		setOccupation(data.occupation);
		setEmail(data.email);
		setPassword(data.password);

		let parameters = {
			email: data.email,
			cpf: data.CPF,
		};

		try {
			const [_Response, _Error] = await new ExternalPaymentService().createToken(
				parameters,
			);

			if (!!_Error || !_Response?.status) {
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
				setLoading(false);
				return;
			}

			window.localStorage.setItem(
				"EPAuthorizationToken",
				_Response.resultSet.token,
			);

			setLoading(false);
			setStep("Plan");
		} catch (err) {
			setLoading(false);
			console.warn(err);
		}
	};

	useEffect(() => {
		if (CPF) {
			setValue("CPF", CPF);
		}
	}, [CPF]);

	useEffect(() => {
		if (phone) {
			setValue("phone", phone);
		}
	}, [phone]);

	useEffect(() => {
		if (birthDate) {
			setValue("birthDate", birthDate);
		}
	}, [birthDate]);

	return (
		<div className="ep-registration step-card">
			<span className="title">Detalhes da sua compra</span>
			<form className="form" onSubmit={handleSubmit(onSubmit)}>
				<div className="fieldset">
					<label htmlFor="name">Nome completo:</label>
					<input
						type="text"
						id="name"
						className={`${!errors.name || "invalid"}`}
						placeholder="Nome completo"
						{...register("name")}
					/>
					{errors.name?.type === "required" && (
						<span className="error">Este campo é obrigatório.</span>
					)}
					{errors.name?.type === "min" && (
						<span className="error">O mínimo de caracteres é de 3.</span>
					)}
					{errors.name?.type === "max" && (
						<span className="error">O máximo de caracteres é de 100.</span>
					)}
				</div>
				<div className="form-group">
					<div className="fieldset">
						<label htmlFor="CPF">CPF:</label>
						<Controller
							name="CPF"
							control={control}
							render={({ field: { name } }) => (
								<CpfCnpj
									name={name}
									type="text"
									id="CPF"
									className={`${!errors.CPF || "invalid"}`}
									value={CPF}
									onChange={(event: any) => {
										setCPF(event.target.value);
									}}
									placeholder="CPF"
								/>
							)}
						/>
						{errors.CPF?.type === "required" && (
							<span className="error">Este campo é obrigatório.</span>
						)}
						{errors.CPF?.type === "CPF" && (
							<p className="error">O valor digitado é invalido.</p>
						)}
					</div>
					<div className="fieldset">
						<label htmlFor="phone">Celular:</label>
						<Controller
							name="phone"
							control={control}
							render={({ field: { name, ref } }) => (
								<InputMask
									ref={ref}
									name={name}
									id="phone"
									className={`input ${!errors.phone || "invalid"}`}
									mask="(99) 99999-9999"
									onBlur={(e) => setPhone(e.target.value)}
									placeholder="Número de celular"
								/>
							)}
						/>
						{errors.phone?.type === "required" && (
							<p className="error">Este campo é obrigatório.</p>
						)}
						{errors.phone?.type === "phone" && (
							<p className="error">O valor digitado é invalido.</p>
						)}
					</div>
				</div>
				{/* <div className="form-group">
					<div className="fieldset">
						<label htmlFor="birth-date">Data de nascimento:</label>
						<Controller
							name="birthDate"
							control={control}
							render={({ field: { name, ref } }) => (
								<DatePicker
									ref={ref}
									id="birth-date"
									name={name}
									maxDate={dateNow}
									selected={birthDate}
									className={`${!errors.birthDate || "invalid"}`}
									onChange={(date) => setBirthDate(date || null)}
									dateFormat="dd/MM/yyyy"
									locale="pt-BR"
								/>
							)}
						/>
						{errors.birthDate?.type === "required" && (
							<span className="error">Este campo é obrigatório.</span>
						)}
						{errors.birthDate?.type === "birthDate" && (
							<span className="error">{errors.birthDate?.message}</span>
						)}
					</div>
					<div className="fieldset">
						<label htmlFor="occupation">Ocupação:</label>
						<input
							type="text"
							id="occupation"
							className={`${!errors.occupation || "invalid"}`}
							placeholder="Ocupação"
							{...register("occupation")}
						/>
						{errors.occupation?.type === "min" && (
							<span className="error">O mínimo de caracteres é de 3.</span>
						)}
						{errors.occupation?.type === "max" && (
							<span className="error">O máximo de caracteres é de 10.</span>
						)}
					</div>
				</div> */}
				<div className="fieldset">
					<label htmlFor="email">E-mail:</label>
					<input
						type="email"
						id="email"
						className={`${!errors.email || "invalid"}`}
						placeholder="E-mail"
						{...register("email")}
					/>
					{errors.email?.type === "required" && (
						<span className="error">Este campo é obrigatório.</span>
					)}
					{errors.email?.type === "email" && (
						<span className="error">O valor digitado é invalido.</span>
					)}
					{errors.email?.type === "max" && (
						<span className="error">O máximo de caracteres é de 150.</span>
					)}
				</div>
				<div className="form-group">
					<div className="fieldset">
						<label htmlFor="password">Senha:</label>
						<input
							type="password"
							id="password"
							className={`${!errors.password || "invalid"}`}
							placeholder="******"
							{...register("password", { required: true })}
						/>
						{errors.password?.type === "required" && (
							<span className="error">Este campo é obrigatório.</span>
						)}
						{errors.password?.type === "matches" && (
							<span className="error">O valor digitado é invalido.</span>
						)}
						{errors.password?.type === "min" && (
							<span className="error">O mínimo de caracteres é de 8.</span>
						)}
						{errors.password?.type === "max" && (
							<span className="error">O máximo de caracteres é de 50.</span>
						)}
					</div>
					<div className="fieldset">
						<label htmlFor="password-confirmation">Confirmação de senha:</label>
						<input
							type="password"
							id="password-confirmation"
							className={`${!errors.passwordConfirmation || "invalid"}`}
							placeholder="******"
							{...register("passwordConfirmation")}
						/>
						{errors.passwordConfirmation?.type === "required" && (
							<span className="error">Este campo é obrigatório.</span>
						)}
						{errors.passwordConfirmation?.type === "min" && (
							<span className="error">O mínimo de caracteres é de 8.</span>
						)}
						{errors.passwordConfirmation?.type === "max" && (
							<span className="error">O máximo de caracteres é de 25.</span>
						)}
						<span className="error">{errors.passwordConfirmation?.message}</span>
					</div>
				</div>
				<div className="fieldset">
					<span className="help-text">
						Use 8 ou mais caracteres com uma combinação de letras maiúsculas, letras
						minúsculas e números sem caracteres especiais.
					</span>
				</div>
				<Button type="submit" disabled={loading}>
					<span>Continuar</span>
				</Button>
			</form>
		</div>
	);
};

export default EPRegistration;
