import "./ComfortZoneChart.scss";

import { faBug, faCaretRight } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const ComfortZoneChart = ({ data }: any) => {
	return (
		<div className="ComfortZoneChart card" data-testid="ComfortZoneChart">
			<div className="title-wrapper">
				<span className="title">A zona de conforto</span>
				<span className="subtitle">Atividades concluídas em metas e tarefas</span>
			</div>
			{data ? (
				<div className="wrapper">
					<div className="graph">
						<div className="graph-wrapper">
							<div className="box green">
								<div className="content">
									<span className="number-of-tasks">
										<b>{data.zonaCrescimento}</b> tarefas
									</span>
									<span className="zone-title">Zona de Crescimento</span>
								</div>
							</div>
							<div className="box blue">
								<div className="content">
									<span className="number-of-tasks">
										<b>{data.zonaAprendizado}</b> tarefas
									</span>
									<span className="zone-title">Zona de Aprendizado</span>
								</div>
							</div>
							<div className="box orange">
								<div className="content">
									<span className="number-of-tasks">
										<b>{data.zonaMedo}</b> tarefas
									</span>
									<span className="zone-title">Zona de Medo</span>
								</div>
							</div>
							<div className="box purple">
								<div className="content">
									<span className="number-of-tasks">
										<b>{data.zonaConforto}</b> tarefas
									</span>
									<span className="zone-title">Zona de Conforto</span>
								</div>
							</div>
							<div className="box red">
								<div className="content">
									<span className="number-of-tasks">
										<b>{data.zonaEstagnacao}</b> tarefas
									</span>
									<span className="zone-title">Zona de Estagnação</span>
								</div>
							</div>
							<div className="line">
								<FontAwesomeIcon icon={faCaretRight} className="icon" />
							</div>
						</div>
					</div>
					<div className="graph-label">
						<div className="text">
							<p>
								Você conseguiu fazer <b>{data.tarefasTotaisConcluidas}</b> tarefas,
								sendo que a maior parte você se manteve na zona de conforto.
							</p>
							<p>
								Dentro das <b>zona de conforto</b> e <b>zona do medo</b> não há
								aprendizagem.
							</p>
						</div>
					</div>
				</div>
			) : (
				<div className="empty-message">
					<FontAwesomeIcon icon={faBug} />
					<span>Você ainda não têm uma pontuação em zonas de conforto.</span>
				</div>
			)}
		</div>
	);
};

export default ComfortZoneChart;
