import api from "./api";

class GoalsService {
	async list(id: number) {
		try {
			const response = await api.get(`app/lista/evitar/fazer/${id}/prioridades`);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}

	async post(data: any) {
		try {
			const response = await api.post(`app/objetivo/uelf/save`, data);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}

	async get(id: number) {
		try {
			const response = await api.get(`app/prioridademeta/lista/${id}`);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}

	async update(data: any) {
		try {
			const response = await api.post(`app/prioridademeta/update`, data);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}
}

export default GoalsService;
