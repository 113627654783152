import "./PurposeStage.scss";

import React, { useEffect } from "react";

import PurposeService from "../../services/PurposeService";
import PurposeStep from "../PurposeSteps/PurposeStep";
import { toastSettings } from "../../models/toastConfig";
import { useActiveJourney } from "../../providers/ActiveJourneyProvider";
import { useStages } from "../../providers/StagesProvider";
import GoogleTagManager, { EventEnum } from "../../services/GoogleTagManager";
import Slugify from "../../utils/Slugify";

const PurposeStage = () => {
	const { currentStage, stages, setStages } = useStages();
	const { activeJourney } = useActiveJourney();

	useEffect(() => {
		if (activeJourney) {
			handleList().then(() => {});
		}
	}, [activeJourney]);

	const handleList = async () => {
		try {
			const [_Response, _Error] = await new PurposeService().list(activeJourney);

			if (!!_Error || !_Response?.status) {
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
				return;
			}

			setStages(_Response.resultSet);
		} catch (err) {
			console.warn(err);
		}
	};

	const renderStages = () => {
		if (stages) {	
			return <PurposeStep steps={stages} />;
		} else {
			return (
				<div className="step">
					<div>Algo deu errado.</div>
				</div>
			);
		}
	};

	useEffect(() => {
		if(stages[currentStage]?.descObjetivo !== undefined) {
			GoogleTagManager.event(EventEnum.Marketing, { action: Slugify(stages[currentStage]?.descObjetivo) });
		}
	}, [stages, currentStage])

	const renderIndicators = () => {
		return (
			<>
				{stages.map((item: any, key: number) => (
					<div
						key={key}
						className={`stage-indicator${" key-" + key}${
							currentStage === key ? " is-active" : ""
						}`}
					>
						<span>{item.descObjetivo}</span>
					</div>
				))}
			</>
		);
	};

	return (
		<div className="self-knowledge-wrapper">
			{stages && <div className="stages-container">{renderIndicators()}</div>}
			{stages && renderStages()}
		</div>
	);
};

export default PurposeStage;
