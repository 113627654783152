import { useEffect, useState } from "react";
import { Input } from "reactstrap";
import { toastSettings } from "../../models/toastConfig";
import SuccessRoadmapService from "../../services/SuccessRoadmapService";
import "./SuccessRoadmapDate.scss";

export interface Props {
	isUELF: boolean;
	id: number;
	date: string;
	canModify: boolean;
}

const SuccessRoadmapDate = ({ isUELF, id, date, canModify }: Props) => {
	const [value, setValue] = useState<string>("");

	useEffect(() => {
		if (value) {
			updateDate().then();
		}
	}, [value]);

	const updateDate = async () => {
		if (value) {
			let data = {
				id: id,
				dataFinal: value,
			};

			if (isUELF) {
				try {
					const [_Response, _Error] =
						await new SuccessRoadmapService().updateDateUELF(data);

					if (!!_Error || !_Response?.status) {
						toastSettings(_Error || _Response?.message, "bottom-center", "error");
						return;
					}

					toastSettings(
						"A data final foi alterada com sucesso!",
						"bottom-center",
						"success",
					);
				} catch (err) {
					console.warn(err);
				}
			} else {
				try {
					const [_Response, _Error] = await new SuccessRoadmapService().updateDate(
						data,
					);

					if (!!_Error || !_Response?.status) {
						toastSettings(_Error || _Response?.message, "bottom-center", "error");
						return;
					}

					toastSettings(
						"A data final foi alterada com sucesso!",
						"bottom-center",
						"success",
					);
				} catch (err) {
					console.warn(err);
				}
			}
		}
	};

	if (canModify) {
		return (
			<Input
				type="date"
				id={"final-date-" + id}
				name={"final-date-" + id}
				className="date-input"
				placeholder="Modifique aqui a data final"
				defaultValue={date}
				onChange={(e) => {
					let value: any = e.target as HTMLInputElement;
					value = value.value;
					setValue(value);
				}}
			/>
		);
	} else {
		return (
			<span>
				{date && new Intl.DateTimeFormat("pt-BR").format(new Date(date))}
			</span>
		);
	}
};

export default SuccessRoadmapDate;
