import "./EducationJourney.scss";

import React, { useEffect } from "react";

import CardEducationJourney from "../../components/CardEducationJourney/CardEducationJourney";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PainelMasterPage from "../../components/PainelMasterPage/PainelMasterPage";
import { ToastContainer } from "react-toastify";
import { faGraduationCap } from "@fortawesome/free-solid-svg-icons";
import { useActiveJourney } from "../../providers/ActiveJourneyProvider";
import { useNavigate } from "react-router-dom";

const EducationJourney = () => {
	const { dataUser } = useActiveJourney();
	const navigate = useNavigate();

	useEffect(() => {
		if (dataUser && dataUser?.idPlano <= 1) {
			navigate("/home");
		}
	}, [dataUser]);

	return (
		<>
			<PainelMasterPage
				title={"UELF Education"}
				subtitle={""}
				subtitleclass={""}
				icon={<FontAwesomeIcon icon={faGraduationCap} />}
				onClick={() => {}}
			>
				<CardEducationJourney />
				<ToastContainer
					position="top-right"
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
			</PainelMasterPage>
		</>
	);
};

export default EducationJourney;
