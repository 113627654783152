import "./CardEducationJourney.scss";

import React, { useEffect, useState } from "react";

import { Button } from "reactstrap";
import CardEducationJourneyService from "../../services/CardEducationJourneyService";
import DashboardService from "../../services/DashboardService";
import DashboardTrackScore from "../DashboardTrackScore/DashboardTrackScore";
import { Link } from "react-router-dom";
import LoaderJourneyEducation from "../LoaderJourneyEducation/LoaderJourneyEducation";
import VideoModal from "../VideoModal/VideoModal";
import { toastSettings } from "../../models/toastConfig";

const CardEducationJourney = () => {
	const [attempts, setAttempts] = useState(1);
	const [hasLoadedURL, setHasLoadedURL] = useState(false);
	const [dataURL, setDataURL] = useState<any>();
	const [data, setData] = useState<any>();
	const [errorOnLoading, setErrorOnLoading] = useState(false);
	const [showModalVideo, setShowModalVideo] = useState<boolean>(false);

	useEffect(() => {
		if (!dataURL && !data) {
			if (attempts <= 2) {
				setTimeout(async () => {
					await getURL().then();
					await handleList().then();
					setAttempts(attempts + 1);
				}, 10000);
			} else {
				setErrorOnLoading(!errorOnLoading);
			}
		} else {
			setHasLoadedURL(!hasLoadedURL);
		}
	}, [attempts]);

	const getURL = async () => {
		try {
			const [_Response, _Error] = await new CardEducationJourneyService().getUrl();

			if (!!_Error || !_Response?.status) {
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
				return false;
			}

			setDataURL(_Response.resultSet.URL);
		} catch (err) {
			console.warn(err);
		}
	};

	const handleList = async () => {
		try {
			const [_Response, _Error] = await new DashboardService().list();

			if (!!_Error || !_Response?.status) {
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
				return;
			}

			setData(_Response.resultSet);
		} catch (err) {
			console.warn(err);
		}
	};

	return (
		<div className="CardEducationJourney" data-testid="CardEducationJourney">
			{!errorOnLoading ? (
				<>
					{hasLoadedURL ? (
						<div className="CardEvolutionJourney" data-testid="CardEvolutionJourney">
							<div className="group">
								<div className="title">
									<span>Seja bem-vindo a UELF Education</span>
								</div>
								<div className="content">
									<div>
										<p className="text mt-5">
											O seu módulo de aprendizado experiencial da plataforma UELF. Aqui que
											você terá uma curadoria dos principais conceitos de grandes autores,
											voltada para a sua evolução, através de cada um dos seus 5 recursos.
										</p>
										<p className="text">
											O Aprendizado experiencial UELF é a mais nova combinação de trilhas
											de ensino conceituais com dinâmicas voltadas para as suas
											necessidades pessoais, ou seja, aquelas avaliadas pelo UELF
											Valuation.
										</p>
										<p>
											Ao ter contato com esses conceitos valiosos, crie novos objetivos,
											metas e os transforme em hábitos e modelos mentais para a tomada de
											decisões. Este é o segredo de transformação que o{" "}
											<b>UELF Education</b> leva até você.
										</p>
									</div>
									<div className="graphic">
										<img src="assets/img/education.png" alt="" />
									</div>
								</div>
							</div>
							{data?.plusoft?.cursos?.length && data?.plusoft?.notaAluno > 0 && (
								<DashboardTrackScore data={data?.plusoft} />
							)}
							<div className="video">
								<span className="title">Próximos Passos</span>
								<Button
									className="btn-video-modal"
									onClick={() => {
										setShowModalVideo(true);
									}}
								>
									<div className="image">
										<img src="assets/video-icon.png" alt="Video Icon" />
									</div>
									<span>ASSISTA AO VÍDEO</span>
								</Button>
								{/* <div className="video-container">
									<div className="player">
										<video id="player" playsInline controls>
											<source
												src="https://deved.app.br/uelfhomologacao/api-v2/videos/uelf-education.mp4"
												type="video/mp4"
											/>
										</video>
									</div>
								</div> */}
							</div>
							<div className="button">
								<Link to={"/journey-map"}>
									<button type="button" className="a">
										<span>Voltar</span>
									</button>
								</Link>
								{dataURL && (
									<a
										href={`${dataURL}`}
										className="two"
										target="_blank"
										rel="noreferrer"
									>
										<span>Visualizar</span>
									</a>
								)}
							</div>
						</div>
					) : (
						<LoaderJourneyEducation />
					)}
				</>
			) : (
				<div className="text">
					<p>
						<span>
							Não foi possivel carregar suas trilhas. Entre em contato com o
						</span>
						<a href="mailto:contato@uelf.com.br">atendimento</a>
						<span>.</span>
					</p>
				</div>
			)}
			<VideoModal
				show={showModalVideo}
				onClose={setShowModalVideo}
				url={"https://www.youtube.com/watch?v=6E3Vd8iZELY"}
			/>
		</div>
	);
};

export default CardEducationJourney;
