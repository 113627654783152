import "./ProgressTrackerSubItem.scss";

import { Card, CardBody, CardHeader, FormGroup } from "reactstrap";
import React, { FC, useEffect } from "react";

import { Bar } from "react-chartjs-2";
import Select from "react-select";
import { customStyles } from "../../models/selectConfig";
import { progressoEnum } from "../../models/progressoEnum";
import { useProgressTracker } from "../../providers/ProgressTrackerProvider";

interface ProgressTrackerSubItemProps {
	id: number;
	objetivo: string;
	percentual: number;
	data: any[];
	isUelf: boolean;
}

const ProgressTrackerSubItem: FC<ProgressTrackerSubItemProps> = ({
	data,
	id,
	objetivo,
	percentual,
	isUelf,
}) => {
	const { chartOptions, handleGrafico } = useProgressTracker();

	useEffect(() => {
		handleGrafico(data);
	}, [data]);

	const renderList = () => {
		return (
			<>
				{data?.length > 0 && (
					<div className="progress-tracker-item-row p-0">
						<div className="progress-tracker-item-col">
							{data?.map((item: any, index: number) => {
								return (
									<div
										className="progress-tracker-item-row content-row border-bottom"
										key={index}
									>
										<div className="progress-tracker-item-col">
											<span className="content">{item?.descricao}</span>
										</div>
										<div className="progress-tracker-item-col">
											<span className="content">{item?.alvo}</span>
										</div>
										<div className="progress-tracker-item-col">
											<span className="content">{item?.descricaoStatus}</span>
										</div>
									</div>
								);
							})}
						</div>

						<div className="progress-tracker-item-col">
							<>
								<span className="mb-label">Indicador de Evolução {objetivo}</span>
								<span className="content charts">
									<div className="items-wrapper">
										<div className="text-center">
											<h1>{percentual}%</h1>
											<p className="m-0">
												<b>EM PROGRESSO</b>
											</p>
										</div>
										<div className="chart" style={{ height: "auto" }}>
											<Bar data={renderGrafico()} options={chartOptions.options} />
										</div>
									</div>
								</span>
							</>
						</div>
					</div>
				)}

				{data?.length === 0 && (
					<p className="py-4 m-0 text-center w-100 message">
						Não há registros disponíveis!
					</p>
				)}
			</>
		);
	};

	const renderGrafico = () => {
		let concluido = 0;
		let emprogresso = 0;
		let atrasado = 0;

		data?.forEach((item: any) => {
			switch (item.status) {
				case progressoEnum.EM_PROGRESSO:
					emprogresso++;
					break;
				case progressoEnum.CONCLUIDO:
					concluido++;
					break;
				case progressoEnum.ATRASADO:
					atrasado++;
					break;
			}
		});

		return {
			labels: ["Concluído", "Em progresso", "Atrasado"],
			datasets: [
				{
					axis: "y",
					data: [
						parseInt(((concluido / data?.length) * 100).toString()),
						parseInt(((emprogresso / data?.length) * 100).toString()),
						parseInt(((atrasado / data?.length) * 100).toString()),
					],
					fill: false,
					backgroundColor: [
						"rgba(134, 235, 168, 1)",
						"rgba(245, 224, 149, 1)",
						"rgba(242, 122, 122, 1)",
					],
				},
			],
		};
	};

	return (
		<div
			id={"item-" + id}
			className={`ProgressTrackerSubItem progress-tracker-item`}
			data-testid="ProgressTrackerSubItem"
		>
			<FormGroup check inline>
				<div className="sub-item">
					<Card>
						<div className="progress-tracker-item-row title-row p-0">
							<div className="progress-tracker-item-col">
								<div className="progress-tracker-item-row title-row">
									<div className="progress-tracker-item-col">
										<span className="title">Tarefa</span>
									</div>
									<div className="progress-tracker-item-col">
										<span className="title">Alvo</span>
									</div>
									<div className="progress-tracker-item-col">
										<span className="title">Status</span>
									</div>
								</div>
							</div>
							<div className="progress-tracker-item-col">
								<div className="progress-tracker-item-row title-row">
									<div className="progress-tracker-item-col">
										<span className="title text-center">
											Indicador de Evolução {objetivo}
										</span>
									</div>
								</div>
							</div>
						</div>

						{renderList()}
					</Card>
				</div>
			</FormGroup>
		</div>
	);
};

export default ProgressTrackerSubItem;
