import "./PerformancePainel.scss";

import React, { useEffect } from "react";

import CardPerformancePanel from "../../components/CardPerformancePanel/CardPerformancePanel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PainelMasterPage from "../../components/PainelMasterPage/PainelMasterPage";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";
import { useActiveJourney } from "../../providers/ActiveJourneyProvider";
import { useNavigate } from "react-router-dom";

const PerformancePainel = () => {
	const { dataUser } = useActiveJourney();
	const navigate = useNavigate();

	useEffect(() => {
		if (dataUser && dataUser?.idPlano <= 1) {
			navigate("/home");
		}
	}, [dataUser]);

	return (
		<PainelMasterPage
			title={"Performance"}
			subtitle={""}
			subtitleclass={""}
			icon={<FontAwesomeIcon icon={faChartLine} />}
			onClick={() => {}}
		>
			<CardPerformancePanel />
		</PainelMasterPage>
	);
};

export default PerformancePainel;
