import { useEffect } from "react";
import CardFeeling from "../../components/CardFeeling/CardFeeling";
import PainelMasterPage from "../../components/PainelMasterPage/PainelMasterPage";
import { useSurvey } from "../../providers/SurveyProvider";
import GoogleTagManager, { EventEnum } from "../../services/GoogleTagManager";
import Slugify from "../../utils/Slugify";
import "./Feelings.scss";

const Feelings = () => {
	const { currentStageName, currentStageIcon } = useSurvey();

	useEffect(() => {
		GoogleTagManager.event(EventEnum.Marketing, { action: Slugify("sentimento_" + currentStageName) });
	}, [currentStageName])

	return (
		<PainelMasterPage
			title={`Indicador de Sentimentos - ${currentStageName}`}
			subtitle={""}
			subtitleclass={""}
			icon={<img src={currentStageIcon} alt={currentStageName} />}
			onClick={() => {}}
		>
			<CardFeeling name={currentStageName} />
		</PainelMasterPage>
	);
};

export default Feelings;
