import "./CardMapJourney.scss";

import React, { useEffect, useState } from "react";

import CardMapJourneyService from "../../services/CardMapJourneyService";
import { Link } from "react-router-dom";
import { toastSettings } from "../../models/toastConfig";
import { useActiveJourney } from "../../providers/ActiveJourneyProvider";
import { usePayments } from "../../providers/PaymentsProviders";

const CardMapJourney = () => {
	const [data, setData] = useState([]);
	const { dataUser } = useActiveJourney();
	const { setPlano } = usePayments();

	useEffect(() => {
		list().then();
	}, []);

	const list = async () => {
		try {
			const [_Response, _Error] = await new CardMapJourneyService().list();

			if (!!_Error || !_Response?.status) {
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
				return false;
			}

			setData(_Response.resultSet);
		} catch (err) {
			console.warn(err);
		}
	};

	const renderButton = (item: any) => {
		if (item.id === 2) {
			return (
				<div className="button-wrapper">
					<button type="button" disabled>
						Em breve!
					</button>
				</div>
			);
		} else if (dataUser.idPlano > 1 || item.id === 1) {
			return (
				<Link to={item.redirectBotao}>
					<div className="button-wrapper">
						<button type="button">{item.descricaoBotao}</button>
					</div>
				</Link>
			);
		} else {
			return (
				<Link to="/payments">
					<div
						className="button-wrapper"
						onClick={() => {
							setPlano(item.id);
						}}
					>
						<button type="button">Contratar Plano</button>
					</div>
				</Link>
			);
		}
	};

	const handleList = () => {
		if (data) {
			return (
				<>
					{data?.length > 0 &&
						data.map((item: any, index: number) => {
							return (
								<div key={index} className="card">
									<div className="image">
										<img src={`${item.imagem}`} alt="" />
									</div>
									<div className="content">
										<div className="description">
											<div className="title">
												<span>{item.titulo}</span>
											</div>
											<div className="text">{item.subtitulo}</div>
										</div>
										<div className="actions">
											<div className="percentage">
												<span className="color-letter">
													{item.percentual || 0}% Completado
												</span>
											</div>
											{renderButton(item)}
										</div>
									</div>
								</div>
							);
						})}
				</>
			);
		}
	};

	return (
		<div className="CardMapJourney" data-testid="CardMapJourney">
			<div className="wrapper">{handleList()}</div>
		</div>
	);
};

export default CardMapJourney;
