import api from "./api";

class ExternalPaymentService {
	async createToken(data: { email: string; cpf: string }) {
		try {
			const response = await api.post(`app/acesso/externo/token/create`, data);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}

	async listPlans() {
		try {
			const response = await api.get(`app/acesso/externo/planos`, {
				headers: {
					token: `${localStorage?.getItem("EPAuthorizationToken")}`,
				},
			});

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}

	async consultCardFlag(cardNumber: string) {
		try {
			const response = await api.post(
				`app/acesso/externo/consulta/cartao`,
				{ cardNumber },
				{
					headers: {
						token: `${localStorage?.getItem("EPAuthorizationToken")}`,
					},
				},
			);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}

	async tryMakePayment(data: any) {
		try {
			const response = await api.post(
				`app/acesso/externo/payment`,
				{ ...data },
				{
					headers: {
						token: `${localStorage?.getItem("EPAuthorizationToken")}`,
					},
				},
			);

			return [response, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}
}

export default ExternalPaymentService;
