import axios from "axios";
import Cookies from "js-cookie";

const headers = {
	Accept: "application/json",
	"Content-Type": "application/json",
	"Cache-Control": "no-cache",
	Pragma: "no-cache",
	Expires: "0",
};

const responseErrorHandler = async (error: any) => {
	let pathname = window.location.pathname;

	if (error.response.status === 401) {
		if (pathname !== "/login") {
			localStorage?.clear();
			Cookies.remove("access_token");
			window.location.href = `${process.env.PUBLIC_URL}/login`;
		}
	}

	// se caso não houver plano ativo
	if (error.response.status === 501) {
		if (pathname !== "/login" && pathname !== "/payments") {
			window.location.href = `${process.env.PUBLIC_URL}/payments`;
		}
	}

	return Promise.reject(error);
};

const api = axios.create({
	baseURL: process.env.REACT_APP_UELF_API,
	headers: headers,
});

api.interceptors.request.use(async (request) => {
	const token = (await Cookies.get("access_token")) || "";

	if (token && request.headers) {
		request.headers.token = `${token}`;
	}

	return request;
});

api.interceptors.response.use(
	async (request) => {
		return request;
	},
	(error) => responseErrorHandler(error),
);

export default api;
