import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toastSettings } from "../models/toastConfig";
import MenuService from "../services/MenuService";
import { useActiveJourney } from "./ActiveJourneyProvider";

export const MenuContext = React.createContext<any>({});

export const MenuProvider = (props: any) => {
	const [rules, setRules] = useState<any>([]);

	const { activeJourney, dataUser } = useActiveJourney();
	const navigate = useNavigate();
	const { pathname } = useLocation();

	const getStop = async () => {
		try {
			if (dataUser && dataUser?.idPlano > 0) {
				const [_Response, _Error] = await new MenuService().getStop(activeJourney);

				if (!!_Error || !_Response?.status) {
					toastSettings(_Error || _Response?.message, "bottom-center", "error");
					return false;
				}

				setRules(_Response.resultSet);
			}
		} catch (err) {
			console.warn(err);
		}
	};

	useEffect(() => {
		if (activeJourney > 0) {
			if (pathname !== "/login") {
				getStop();
			}
		}
	}, [activeJourney, navigate]);

	return (
		<MenuContext.Provider
			value={{
				rules,
			}}
		>
			{props.children}
		</MenuContext.Provider>
	);
};

export const useMenu = () => React.useContext(MenuContext);
