import React, { FC, useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useJourneyBackpack } from "../../providers/JourneyBackpackProvider";
import "./JourneyBackpackConfirmModal.scss";

interface JourneyBackpackConfirmModalProps {
	show: boolean;
	onClose?: any;
}

const JourneyBackpackConfirmModal: FC<JourneyBackpackConfirmModalProps> = ({
	show,
	onClose,
}) => {
	const [modal, setModal] = useState<boolean>(false);
	const { remove, setItemSelectedRem } = useJourneyBackpack();

	const toggle = () => {
		setModal(!modal);
		setItemSelectedRem(0);
	};

	const handleOnClose = () => onClose(false);

	useEffect(() => {
		if (show) {
			setModal(show);
		}
	}, [show]);

	return (
		<Modal
			centered
			scrollable
			size="lg"
			isOpen={modal}
			toggle={toggle}
			onClosed={handleOnClose}
			className="JourneyBackpackConfirmModal"
			data-testid="JourneyBackpackConfirmModal"
		>
			<ModalHeader toggle={toggle}>Excluir um Item da Mochila</ModalHeader>
			<ModalBody>
				<div className="text">
					<p>Você realmente deseja excluir?</p>
				</div>
			</ModalBody>
			<ModalFooter>
				<Button type="button" onClick={toggle}>
					Cancelar
				</Button>
				<Button
					type="submit"
					onClick={() => {
						remove();
						toggle();
					}}
					color="primary"
				>
					Sim, excluir
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default JourneyBackpackConfirmModal;
