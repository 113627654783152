import moment from "moment";
import { AgendaModel } from "./AgendaModel";

export class WidgetEventsModel {
	title?: string;
	start?: string;
	end?: string;
	status?: number;

	constructor({ descricao, dataInicial, dataFinal, status }: AgendaModel) {
		this.title = descricao;
		this.start = moment(dataInicial + "00:00:00", "YYYY/MM/DD hh:mm:ss").format(
			"YYYY-MM-DD",
		);
		this.end = moment(dataFinal + "23:59:59", "YYYY/MM/DD hh:mm:ss").format(
			"YYYY-MM-DD",
		);
		this.status = status;
	}
}
