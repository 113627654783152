import "./Timeline.scss";

import {
	Button,
	Card,
	CardBody,
	Col,
	Container,
	Nav,
	NavItem,
	Row,
	TabContent,
	TabPane,
	Table,
} from "reactstrap";
import React, { useEffect, useState } from "react";
import {
	faFileMedical,
	faStar,
	faStarHalfAlt,
} from "@fortawesome/free-solid-svg-icons";

import ButtonNextScreen from "../../components/ButtonNextScreen/ButtonNextScreen";
import CalendarComponent from "../../components/CalendarComponent/CalendarComponent";
import CalendarTableComponent from "../../components/CalendarTableComponent/CalendarTableComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loading from "../../components/Loading/Loading";
import ModalFilterTimeline from "../../components/ModalFilterTimeline/ModalFilterTimeline";
import PainelMasterPage from "../../components/PainelMasterPage/PainelMasterPage";
// @ts-ignore
import ReactStars from "react-rating-stars-component";
import TimelineService from "../../services/TimelineService";
import { ToastContainer } from "react-toastify";
import { toastSettings } from "../../models/toastConfig";
import { useActiveJourney } from "../../providers/ActiveJourneyProvider";
import { useNavigate } from "react-router-dom";

const Timeline = () => {
	const [dataTimeline, setDataTimeline] = useState<Array<any>>([]);
	const [showModal, setShowModal] = useState<boolean>(false);
	const [activeTab, setActiveTab] = useState<string>("1");
	const [loading, setLoading] = useState<boolean>(false);

	const { dataUser } = useActiveJourney();
	const navigate = useNavigate();

	useEffect(() => {
		if (dataUser && dataUser?.idPlano <= 1) {
			navigate("/home");
		} else {
			getTimeline().then(() => {
				setLoading(false);
			});
		}
	}, [dataUser]);

	const getTimeline = async (form?: object) => {
		try {
			setLoading(true);

			const [_Response, _Error] = await new TimelineService().list(form || {});

			if (!!_Error || !_Response?.status) {
				setLoading(false);
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
				return;
			}

			setDataTimeline(_Response?.resultSet);

			setLoading(false);
		} catch (e: any) {
			toastSettings("Algo deu errado!" || e?.message, "bottom-center", "error");
			setLoading(false);
		}
	};

	const renderStars = (value: any) => {
		return (
			<div className="stars">
				<ReactStars
					count={5}
					size={24}
					isHalf={false}
					emptyIcon={<FontAwesomeIcon icon={faStar} />}
					halfIcon={<FontAwesomeIcon icon={faStarHalfAlt} />}
					fullIcon={<FontAwesomeIcon icon={faStar} />}
					activeColor="#99dc3b"
					value={value}
					edit={false}
				/>
			</div>
		);
	};

	const renderButtonsHeader = () => {
		return (
			<div className="timeline-actions">
				<div className="actions">
					<NavItem>
						<Button
							className={`${activeTab === "1" && "active"}`}
							onClick={() => setActiveTab("1")}
						>
							<span>Meu calendário de tarefas</span>
						</Button>
					</NavItem>
					<NavItem>
						<Button
							className={`${activeTab === "2" && "active"}`}
							onClick={() => setActiveTab("2")}
						>
							<span>Minha lista de tarefas</span>
						</Button>
					</NavItem>
				</div>
			</div>
		);
	};

	const renderCalendarTimeline = () => {
		return (
			<Container className="mt-4 mx-0" style={{ maxWidth: "100%" }}>
				<Row>
					<Col className="col col-md-6 pl-0">
						<CalendarComponent data={dataTimeline} />
					</Col>
					<Col className="col col-md-6 pr-0">
						<CalendarTableComponent data={dataTimeline} />
					</Col>
				</Row>
			</Container>
		);
	};

	const renderTableTimeline = () => {
		return (
			<Container className="mt-4 mx-0" style={{ maxWidth: "100%" }}>
				<Row>
					<Col className="col col-12 p-0">
						<div className="actions justify-content-end mb-4">
							<Button onClick={() => setShowModal(true)}>
								<FontAwesomeIcon icon={faFileMedical} />
								<span>Organizar minhas tarefas</span>
							</Button>
						</div>
					</Col>
				</Row>
				<Row>
					<Col className="col col-12 p-0">
						<Card className="mb-0 p-0">
							<CardBody className="p-0 table-responsive">
								<Table className="table-cronograma">
									<thead>
										<tr>
											<th className="border-top-left-radius"></th>
											<th>Tarefas</th>
											<th>Tempo Necessário</th>
											<th>Zona Motivação</th>
											<th className="text-center">Importância</th>
											<th className="text-center">Urgência</th>
											<th className="text-center">Prioridade</th>
											<th className="text-center">Motivação</th>
											<th className="text-center">Esforço Físico</th>
											<th className="text-center border-top-right-radius">
												Esforço Mental
											</th>
										</tr>
									</thead>
									<tbody>
										{dataTimeline.length > 0 ? (
											dataTimeline.map((item: any, key: number) => {
												return (
													<tr key={key}>
														<td>
															<span className="circle-index futura-bold">{key + 1}</span>
														</td>
														<td>{item.descricao}</td>
														<td>{item.descricaoTempoNecessario}</td>
														<td>{item.zonaMotivacao}</td>
														<td className="text-center">{renderStars(item.importancia)}</td>
														<td className="text-center">{renderStars(item.urgencia)}</td>
														<td className="text-center">{renderStars(item.prioridade)}</td>
														<td className="text-center">{renderStars(item.motivacao)}</td>
														<td className="text-center">{renderStars(item.esforcoFisico)}</td>
														<td className="text-center">{renderStars(item.esforcoMental)}</td>
													</tr>
												);
											})
										) : (
											<tr>
												<td colSpan={10} className="text-center">
													Não há registros disponíveis!
												</td>
											</tr>
										)}
									</tbody>
								</Table>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		);
	};

	return (
		<PainelMasterPage
			title={"Agenda de Tarefas - consulta"}
			subtitle={
				"Consulte o calendário e a lista de tarefas que você definiu para seus objetivos. Use o organizador de tarefas UELF e ele vai te ajudar a realizar tudo priorizando tudo com base na sua vontade de evoluir."
			}
			icon={<img src={`assets/img/timeline-icon.svg`} alt="" />}
			onClick={() => {}}
		>
			<div className="Timeline" data-testid="Timeline">
				<Nav tabs>{renderButtonsHeader()}</Nav>
				{!loading ? (
					<>
						<TabContent activeTab={activeTab}>
							<TabPane tabId="1">{renderCalendarTimeline()}</TabPane>
							<TabPane tabId="2">{renderTableTimeline()}</TabPane>
						</TabContent>

						<ButtonNextScreen text={"Voltar"} linkScreen={"/performance-painel"} />
					</>
				) : (
					<Loading margin="my-6" />
				)}
				<ModalFilterTimeline
					show={showModal}
					onClose={setShowModal}
					functionGetTimeline={getTimeline}
				/>
			</div>
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</PainelMasterPage>
	);
};

export default Timeline;
