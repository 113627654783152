import React, { FC } from "react";
import GaugeChart from "react-gauge-chart";
import { Card } from "reactstrap";
import { useActiveJourney } from "../../../providers/ActiveJourneyProvider";
import "./CardGrades.scss";

interface CardGradesProps {
	pontuacao: string;
	fraseNotaVida?: string;
	status?: string;
}

const CardGrades: FC<CardGradesProps> = ({
	pontuacao = "",
	fraseNotaVida = "",
	status = ""
}) => {
	const { activeJourney } = useActiveJourney();
	let pontuacaoFormat = parseInt(pontuacao).toLocaleString("pt-BR");

	return (
		<Card className="note-of-life" data-testid="CardGrades">
			{pontuacao ? (
				<>
					<div className="chart-wrapper">
						<div className="chart-label">
							<span className="title">{pontuacaoFormat}/100.000</span>
							<span className="subtitle">Pontos</span>
							<span className="status">{status}</span>
						</div>
					</div>

					<div className="text">
						<p className="m-0">{fraseNotaVida || ""}</p>
					</div>
				</>
			) : (
				<div className="items-wrapper">
					<p className="help-text">
						{!activeJourney ? "Não há" : ""} Jornada em Andamento
					</p>
				</div>
			)}
		</Card>
	);
};

export default CardGrades;
