import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import "./ModalTerm.scss";

interface ModalTermProps {
	show: boolean;
	onClose?: any;
}

const MordalTerm = ({ show, onClose }: ModalTermProps) => {
	const [modal, setModal] = useState<boolean>(false);

	useEffect(() => {
		if (show) {
			setModal(show);
		}
	}, [show]);

	const toggle = () => setModal(!modal);
	const handleOnClose = () => onClose(false);

	return (
		<Modal
			centered
			scrollable
			size="lg"
			isOpen={modal}
			toggle={toggle}
			onClosed={handleOnClose}
			className="container-termo"
		>
			<ModalBody>
				<div style={{ color: "white !important" }}>
					<p>1. ESCOPO DOS TERMOS E CONDI&Ccedil;&Otilde;ES</p>
					<p>
						<br />
						1. O UELF &eacute; um servi&ccedil;o da ZenEconomics, empresa que atua na
						presta&ccedil;&atilde;o de servi&ccedil;os na &aacute;rea de
						desenvolvimento pessoal e financeiro concomitantes. Estes Termos e
						Condi&ccedil;&otilde;es de Uso (&ldquo;Termos e
						Condi&ccedil;&otilde;es&rdquo;) aplicam-se a todos os usu&aacute;rios
						(&ldquo;Usu&aacute;rios&rdquo;, &ldquo;voc&ecirc;&rdquo; e
						&ldquo;seu&rdquo;) do site UELF.com.br (o &ldquo;Site&rdquo;),
						aplicativo(s) de telefone celular do UELF (o(s) &ldquo;Aplicativo(s) para
						dispositivos m&oacute;veis&rdquo;) e seu uso e/ou acesso a qualquer um de
						nossos conte&uacute;dos ou informa&ccedil;&otilde;es e outras tecnologias
						on-line ou habilitadas para dispositivos m&oacute;veis, ferramentas
						digitais, postagens de m&iacute;dia social, conte&uacute;do, sites,
						cursos, treinamento , dados, outros sites de nossa propriedade e
						controlados por n&oacute;s, aplicativos m&oacute;veis fornecidos por
						n&oacute;s e outros servi&ccedil;os e produtos fornecidos por n&oacute;s
						(juntamente com nosso(s) site(s) e o(s) aplicativo(s) m&oacute;vel(is),
						coletivamente, os (&ldquo;Servi&ccedil;os UELF&rdquo;).
					</p>
					<p>
						<br />
						2. Voc&ecirc; deve aceitar os nossos Termos para criar uma conta UELF e
						acessar ou usar os Servi&ccedil;os UELF. Se voc&ecirc; n&atilde;o tiver
						uma conta no UELF, voc&ecirc; precisar&aacute; cri&aacute;-la e
						dever&aacute; aceitar os Termos. Sem a aceita&ccedil;&atilde;o dos termos,
						n&atilde;o ser&aacute; poss&iacute;vel criar uma conta ou usar os
						Servi&ccedil;os UELF.
					</p>
					<p>
						<br />
						3. SE VOC&Ecirc; N&Atilde;O DESEJA OU N&Atilde;O PODE SE VINCULAR A TODOS
						OS TERMOS E CONDI&Ccedil;&Otilde;ES, INCLUINDO, SEM
						LIMITA&Ccedil;&Atilde;O, O SEGUINTE CONTRATO E RECONHECIMENTO, N&Atilde;O
						ACESSE, NAVEGUE OU USE OS SERVI&Ccedil;OS UELF. AL&Eacute;M DISSO, O UELF
						N&Atilde;O CONCEDE E N&Atilde;O CONCEDER&Aacute; A VOC&Ecirc; NENHUM
						DIREITO OU LICEN&Ccedil;A DE ACESSO, NAVEGA&Ccedil;&Atilde;O OU
						SERVI&Ccedil;OS SEM A SUA VONTADE E POSSIBILIDADE DE SE VINCULAR A TODOS
						OS TERMOS E CONDI&Ccedil;&Otilde;ES.
					</p>
					<p>
						<br />
						4. AO ACEITAR OS NOSSOS TERMOS E CONDI&Ccedil;&Otilde;ES, VOC&Ecirc;
						CONCORDA QUE PODEMOS DESATIVAR OU EXCLUIR IMEDIATAMENTE SUA CONTA DE
						USU&Aacute;RIO E TODAS AS INFORMA&Ccedil;&Otilde;ES E ARQUIVOS
						RELACIONADOS EM SUA CONTA DE USU&Aacute;RIO E/OU RESTRINGIR QUALQUER
						ACESSO ADICIONAL A TAIS INFORMA&Ccedil;&Otilde;ES E/OU ARQUIVOS, OU AOS
						NOSSOS SERVI&Ccedil;OS, COM OU SEM AVISO, BASTANDO PARA ISSO O DESCUMPRIR
						AS DISPOSI&Ccedil;&Otilde;ES DESTES TERMOS E CONDI&Ccedil;&Otilde;ES,
						EXCETO QUANDO N&Oacute;S EFETUARMOS ALGUMA ATUALIZA&Ccedil;&Atilde;O OU
						ALTERA&Ccedil;&Atilde;O.
					</p>
					<p>
						<br />
						5. Ao usar os Servi&ccedil;os UELF, voc&ecirc; assume todos os riscos e,
						portanto, deve ter cautela e bom senso. Voc&ecirc; est&aacute; proibido de
						violar ou tentar violar a seguran&ccedil;a dos servi&ccedil;os. Quaisquer
						viola&ccedil;&otilde;es podem resultar em penalidades criminais e/ou civis
						contra voc&ecirc;. A UELF investigar&aacute; quaisquer
						viola&ccedil;&otilde;es alegadas ou suspeitas e, se houver suspeita de
						viola&ccedil;&atilde;o criminal, poderemos entrar em contato e/ou cooperar
						com entidades legais em suas investiga&ccedil;&otilde;es.
					</p>
					<p>
						<br />
						2. ELEGIBILIDADE PARA USAR OS SERVI&Ccedil;OS UELF
					</p>
					<p>
						<br />
						1. Pessoas com idade inferior a 18 anos, ou qualquer idade m&iacute;nima
						superior na jurisdi&ccedil;&atilde;o onde essa pessoa reside, n&atilde;o
						t&ecirc;m permiss&atilde;o para acessar ou usar os Servi&ccedil;os UELF, a
						menos que seus pais tenham consentido de acordo com a lei
						aplic&aacute;vel. Al&eacute;m disso, voc&ecirc; n&atilde;o pode acessar ou
						usar os Servi&ccedil;os UELF se estiver impedido de receber
						servi&ccedil;os sob a lei aplic&aacute;vel ou se tiver sido suspenso ou
						removido anteriormente de qualquer uma de nossas plataformas de
						Servi&ccedil;os da ZenEconomics.
					</p>
					<p>
						<br />
						3. ACORDO E RECONHECIMENTO
					</p>
					<p>
						<br />
						1. Ao usar os Servi&ccedil;os UELF, voc&ecirc; aceita estes Termos e
						Condi&ccedil;&otilde;es e concorda em estar legalmente vinculado e cumprir
						os termos, condi&ccedil;&otilde;es e avisos contidos ou mencionados neste
						documento, como se voc&ecirc; os tivesse assinado. De tempos em tempos,
						podemos, a nosso exclusivo crit&eacute;rio, alterar, modificar,
						complementar ou remover partes destes Termos e Condi&ccedil;&otilde;es
						(&ldquo;Termos Adicionais&rdquo;). Esses Termos Adicionais entrar&atilde;o
						em vigor ap&oacute;s a publica&ccedil;&atilde;o pela Empresa no Site, no
						Aplicativo M&oacute;vel, por meio dos Servi&ccedil;os UELF ou no envio de
						um e-mail ou outra notifica&ccedil;&atilde;o. Ser&aacute; considerado que
						voc&ecirc; concordou com esses Termos Adicionais por sua decis&atilde;o de
						continuar acessando os Servi&ccedil;os UELF, incluindo nosso Aplicativo
						M&oacute;vel ou o Site, ou de outra forma usando qualquer um dos
						Servi&ccedil;os ap&oacute;s a data em que tais Termos Adicionais entrarem
						em vigor.
					</p>
					<p>
						<br />
						2. A nosso exclusivo crit&eacute;rio e sem aviso pr&eacute;vio ou
						responsabilidade, podemos descontinuar, modificar ou alterar qualquer
						aspecto dos Servi&ccedil;os UELF fornecidos a voc&ecirc;, incluindo, mas
						n&atilde;o limitado a (i) restringir o tempo de disponibilidade dos
						Servi&ccedil;os UELF, (ii) restringir a quantidade de uso permitido, (iii)
						restringir ou encerrar o direito de qualquer usu&aacute;rio de usar os
						Servi&ccedil;os UELF e (iv) alterar qualquer recurso ou funcionalidade
						fornecida pelos Servi&ccedil;os UELF. Voc&ecirc; concorda que qualquer
						rescis&atilde;o ou cancelamento de seu acesso ou uso dos Servi&ccedil;os
						UELF pode ser efetuado sem aviso pr&eacute;vio. Al&eacute;m disso,
						voc&ecirc; concorda que n&atilde;o seremos respons&aacute;veis ​​perante
						voc&ecirc; ou terceiros por qualquer rescis&atilde;o ou cancelamento de
						seu acesso ou uso de nossos Servi&ccedil;os UELF.
					</p>
					<p>
						<br />
						3. T&atilde;o logo esteja disponibilizado em lojas de aplicativos ou
						plataformas de distribui&ccedil;&atilde;o de aplicativos (o "Fornecedor de
						aplicativos m&oacute;veis"), a exclusivo crit&eacute;rio do UELF, nosso
						aplicativo m&oacute;vel poder&aacute; ser baixado. Voc&ecirc; reconhece e
						concorda que: (i) estes Termos e Condi&ccedil;&otilde;es s&atilde;o
						celebrados entre voc&ecirc; e a UELF, e n&atilde;o com o Provedor de
						Aplicativos M&oacute;veis ou qualquer outro terceiro; (ii) o Provedor de
						Aplicativos M&oacute;veis n&atilde;o tem obriga&ccedil;&atilde;o com
						voc&ecirc; de fornecer quaisquer servi&ccedil;os de
						manuten&ccedil;&atilde;o e suporte com rela&ccedil;&atilde;o ao Aplicativo
						M&oacute;vel; (iii) o Provedor de Aplicativos M&oacute;veis n&atilde;o
						ser&aacute; contatado por voc&ecirc; para tratar de quaisquer
						reivindica&ccedil;&otilde;es que voc&ecirc; tenha ou quaisquer
						reivindica&ccedil;&otilde;es de terceiros relacionadas ao Aplicativo
						M&oacute;vel ou sua posse e uso do Aplicativo M&oacute;vel, incluindo, mas
						n&atilde;o limitado a: (a ) reivindica&ccedil;&otilde;es de
						responsabilidade do produto, (b) qualquer reivindica&ccedil;&atilde;o de
						que o aplicativo m&oacute;vel n&atilde;o esteja em conformidade com
						qualquer requisito legal ou regulat&oacute;rio aplic&aacute;vel, e (c)
						reclama&ccedil;&otilde;es decorrentes de legisla&ccedil;&atilde;o de
						prote&ccedil;&atilde;o ao consumidor ou legisla&ccedil;&atilde;o similar;
						(iv) no caso de qualquer terceiro alegar que o aplicativo m&oacute;vel ou
						sua posse e uso desse aplicativo m&oacute;vel infringe os direitos de
						propriedade intelectual desse terceiro, nos reservamos o direito de
						investigar qualquer reivindica&ccedil;&atilde;o de viola&ccedil;&atilde;o
						de propriedade intelectual na medida exigida por estes Termos e
						Condi&ccedil;&otilde;es; (v) o Provedor de Aplicativos M&oacute;veis e
						suas subsidi&aacute;rias s&atilde;o terceiros benefici&aacute;rios destes
						Termos e Condi&ccedil;&otilde;es no que se refere &agrave; sua
						licen&ccedil;a do Aplicativo M&oacute;vel e que, mediante sua
						aceita&ccedil;&atilde;o destes Termos e Condi&ccedil;&otilde;es, o
						Provedor de Aplicativos M&oacute;veis ter&aacute; o direito (e ser&aacute;
						considerado como tendo aceitado o direito) de fazer valer estes Termos e
						Condi&ccedil;&otilde;es relacionados &agrave; sua licen&ccedil;a do
						Aplicativo M&oacute;vel contra voc&ecirc; como um terceiro
						benefici&aacute;rio; e (vi) voc&ecirc; tamb&eacute;m deve cumprir todos os
						termos de servi&ccedil;o de terceiros aplic&aacute;veis ​​ao usar os
						Servi&ccedil;os UELF. Voc&ecirc; reconhece, entende e concorda, salvo
						disposi&ccedil;&atilde;o expressa em contr&aacute;rio, que n&atilde;o
						haver&aacute; terceiros benefici&aacute;rios destes Termos e
						Condi&ccedil;&otilde;es.
					</p>
					<p>
						<br />
						4. ASSINATURAS; SERVI&Ccedil;OS DE PAGAMENTO
					</p>
					<p>
						<br />
						1. Nossos Servi&ccedil;os podem incluir uma vers&atilde;o gratuita e
						limitada do Aplicativo M&oacute;vel, mas nos reservamos o direito de
						adicionar e adicionaremos Servi&ccedil;os adicionais com diferentes
						funcionalidades com base no n&iacute;vel de assinatura
						(&ldquo;Servi&ccedil;os Pagos&rdquo;). Os Servi&ccedil;os Pagos
						exigir&atilde;o que nossos Usu&aacute;rios paguem uma taxa, que geralmente
						ser&aacute; paga pelo Usu&aacute;rio ou em nome do Usu&aacute;rio por
						terceiros. Voc&ecirc; n&atilde;o precisa se tornar um usu&aacute;rio do
						Servi&ccedil;o Pago para usar os Servi&ccedil;os. No entanto, se
						voc&ecirc; n&atilde;o for um usu&aacute;rio do Servi&ccedil;o Pago,
						poder&aacute; ser impedido de usar certas partes, componentes,
						conte&uacute;do, recursos ou recursos de nossos Servi&ccedil;os.
						Reservamo-nos o direito de eliminar ou modificar qualquer ou todas as
						funcionalidades de nossos Servi&ccedil;os a qualquer momento, sem aviso
						pr&eacute;vio.
					</p>
					<p>
						<br />
						2. Independentemente de voc&ecirc; usar ou n&atilde;o os Servi&ccedil;os
						Pagos, voc&ecirc; &eacute; respons&aacute;vel por todas as
						cobran&ccedil;as e taxas associadas &agrave; conex&atilde;o e uso dos
						Servi&ccedil;os UELF, incluindo, sem limita&ccedil;&atilde;o, todas as
						linhas de acesso telef&ocirc;nico (incluindo dados m&oacute;veis e tarifas
						de roaming de dados, quando aplic&aacute;vel), telefone e/ou taxas de
						provedor de servi&ccedil;os de Internet, equipamentos de telefone e
						computador, impostos sobre vendas e quaisquer outras taxas e encargos
						necess&aacute;rios para acessar nossos Servi&ccedil;os, incluindo nosso
						Site e Aplicativo M&oacute;vel.
					</p>
					<p>
						<br />
						5. TAXAS, CANCELAMENTOS E POL&Iacute;TICA DE REEMBOLSO
					</p>
					<p>
						<br />
						1. Voc&ecirc; ser&aacute; respons&aacute;vel pelas cobran&ccedil;as e
						taxas fixas e peri&oacute;dicas (incluindo taxas do plano de
						pr&eacute;-pagamento por v&aacute;rios per&iacute;odos ou taxas mensais
						recorrentes) associadas aos Servi&ccedil;os de Pagamento no momento da
						assinatura. Impostos aplic&aacute;veis ​​e outros encargos e taxas
						incorridos para acessar os Servi&ccedil;os de Pagamento tamb&eacute;m
						podem ser aplicados. Sua associa&ccedil;&atilde;o aos Servi&ccedil;os
						Pagos ser&aacute; automaticamente estendida por per&iacute;odos de
						renova&ccedil;&atilde;o sucessivos com a mesma dura&ccedil;&atilde;o
						selecionada no momento da compra, de acordo com a taxa de assinatura
						n&atilde;o promocional vigente. At&eacute; que voc&ecirc; cancele,
						cobraremos ou debitaremos sua forma de pagamento no in&iacute;cio de sua
						assinatura.
					</p>
					<p>
						<br />
						2. Seu n&atilde;o cancelamento dos Servi&ccedil;os Pagos com ou sem o uso
						continuado dos recursos de assinatura paga em nosso Site e/ou Aplicativo
						M&oacute;vel reafirmar&aacute; que estamos autorizados a cobrar de
						voc&ecirc;. Se sua conta de cr&eacute;dito ou d&eacute;bito tiver sido
						encerrada ou seu m&eacute;todo de pagamento for inv&aacute;lido, sua
						assinatura poder&aacute; n&atilde;o ser renovada e sua assinatura dos
						Servi&ccedil;os de Pagamento ser&aacute; encerrada no final do seu ciclo
						de cobran&ccedil;a atual acarretando a perda de todos os dados atuais do
						sistema atrelados a sua conta. A cobran&ccedil;a de
						renova&ccedil;&atilde;o geralmente ser&aacute; a mesma do per&iacute;odo
						anterior, a menos que o notifiquemos com anteced&ecirc;ncia no momento da
						inscri&ccedil;&atilde;o ou antes do in&iacute;cio do per&iacute;odo de
						renova&ccedil;&atilde;o, conforme descrito acima. Se (i) voc&ecirc;
						comprou um plano de pr&eacute;-pagamento de v&aacute;rios per&iacute;odos
						ou (ii) estava qualificado para uma tarifa promocional, mas n&atilde;o
						est&aacute; mais qualificado para essa tarifa, ent&atilde;o sua assinatura
						ser&aacute; oferecida para renovar sua assinatura com nossas taxas de
						assinatura n&atilde;o promocionais vigentes no in&iacute;cio do
						per&iacute;odo de renova&ccedil;&atilde;o. Se voc&ecirc; deseja renovar e
						atualmente estamos oferecendo tarifas promocionais nesse momento,
						voc&ecirc; deve renovar sua assinatura antes do t&eacute;rmino do seu
						plano atual. Se voc&ecirc; n&atilde;o renovar sua assinatura de qualquer
						Servi&ccedil;o Pago antes da data de vencimento programada, a taxa de
						assinatura n&atilde;o promocional atual ser&aacute; aplicada.
					</p>
					<p>
						<br />
						3. Voc&ecirc; pode cancelar sua assinatura de nossos Servi&ccedil;os a
						qualquer momento, notificando-nos por escrito ou por outros meios que
						possamos fornecer de tempos em tempos. Tentaremos processar todos os
						pedidos de cancelamento dentro de 3 (tr&ecirc;s) dias &uacute;teis
						ap&oacute;s recebermos o seu pedido. Reservamo-nos o direito de cobrar
						taxas, sobretaxas ou custos incorridos antes que o cancelamento entre em
						vigor. Se voc&ecirc; encerrar sua assinatura paga dos Servi&ccedil;os
						Pagos, sua assinatura permanecer&aacute; ativa at&eacute; o final do
						per&iacute;odo de assinatura atual. Os dados s&atilde;o retidos
						ap&oacute;s o cancelamento por um per&iacute;odo de pelo menos 30 dias. Se
						voc&ecirc; se inscrever novamente nos Servi&ccedil;os Pagos ap&oacute;s
						esse per&iacute;odo, seus dados anteriores n&atilde;o estar&atilde;o
						dispon&iacute;veis.
					</p>
					<p>
						<br />
						4. Todas as taxas e encargos avaliados pela UELF n&atilde;o s&atilde;o
						reembols&aacute;veis.
					</p>
					<p>
						<br />
						5. &Agrave;s vezes, podemos oferecer determinados Servi&ccedil;os UELF em
						uma base de avalia&ccedil;&atilde;o gratuita. Podemos oferecer
						avalia&ccedil;&otilde;es gratuitas de determinadas assinaturas por
						per&iacute;odos de tempo especificados sem pagamento. Se lhe oferecermos
						uma avalia&ccedil;&atilde;o gratuita, os termos espec&iacute;ficos da
						avalia&ccedil;&atilde;o gratuita ser&atilde;o fornecidos nos materiais de
						marketing que descrevem a avalia&ccedil;&atilde;o espec&iacute;fica. Assim
						que sua avalia&ccedil;&atilde;o gratuita terminar, n&oacute;s (ou nosso
						processador de pagamento terceirizado) come&ccedil;aremos a cobrar seu
						m&eacute;todo de pagamento designado de forma recorrente para sua
						assinatura (mais quaisquer impostos e outras cobran&ccedil;as
						aplic&aacute;veis) enquanto sua assinatura continuar, a menos que
						voc&ecirc; cancele sua assinatura antes do final de sua
						avalia&ccedil;&atilde;o gratuita. Se voc&ecirc; optar por cancelar sua
						assinatura dentro de um per&iacute;odo de avalia&ccedil;&atilde;o que
						podemos oferecer, voc&ecirc; n&atilde;o ser&aacute; cobrado pela
						assinatura. Sua conta de Servi&ccedil;os de Pagamento expirar&aacute; em
						72 horas a partir da data de cancelamento, e sua conta de Servi&ccedil;os
						Pagos ser&aacute; encerrada, mas voc&ecirc; poder&aacute; acessar as
						fun&ccedil;&otilde;es gratuitas de nossos Servi&ccedil;os. Se voc&ecirc;
						encerrar sua assinatura paga dos Servi&ccedil;os Pagos ap&oacute;s o
						per&iacute;odo de avalia&ccedil;&atilde;o, sua assinatura
						permanecer&aacute; ativa at&eacute; o final do per&iacute;odo de
						assinatura e nenhuma cobran&ccedil;a subsequente ser&aacute; processada.
					</p>
					<p>
						<br />
						6. A UELF reserva-se ao direito de revisar os termos das pol&iacute;ticas
						de cobran&ccedil;a, cancelamento e reembolso de taxas, mediante aviso
						pr&eacute;vio razo&aacute;vel comunicado a voc&ecirc; por meio de uma
						postagem no Site e/ou Aplicativo M&oacute;vel ou outros meios que
						julgarmos apropriados de tempos em tempos (incluindo correio
						eletr&ocirc;nico, correio convencional ou contato atrav&eacute;s dos dados
						cadastrais do usu&aacute;rio). Quaisquer altera&ccedil;&otilde;es feitas
						ser&atilde;o aplicadas a todas as associa&ccedil;&otilde;es criadas ou
						renovadas ap&oacute;s a data em que tal altera&ccedil;&atilde;o foi
						implementada.
					</p>
					<p>
						<br />
						7. Todos os pre&ccedil;os incluem os impostos aplic&aacute;veis no
						respectivo pa&iacute;s do Utilizador. Taxas poder&atilde;o ser aplicadas
						pelas plataformas de cobran&ccedil;a.
					</p>
					<p>
						<br />
						8. Reservamo-nos o direito de conceder descontos com efeito futuro e
						definir termos e condi&ccedil;&otilde;es espec&iacute;ficos para descontos
						no servi&ccedil;o sem aviso pr&eacute;vio aos usu&aacute;rios existentes.
					</p>
					<p>
						<br />
						9. Reservamo-nos o direito de ajustar os pre&ccedil;os de nossos
						Servi&ccedil;os Pagos ou de quaisquer componentes deles de qualquer
						maneira e a qualquer momento. Quaisquer altera&ccedil;&otilde;es de
						pre&ccedil;o entrar&atilde;o em vigor ap&oacute;s
						notifica&ccedil;&atilde;o a voc&ecirc;.
					</p>
					<p>
						<br />
						6. PRIVACIDADE E SEGURAN&Ccedil;A DO USU&Aacute;RIO
					</p>
					<p>
						<br />
						1. Para usar os Servi&ccedil;os UELF, voc&ecirc; pode ser solicitado a nos
						fornecer informa&ccedil;&otilde;es sobre voc&ecirc; por meio de um
						formul&aacute;rio de registro. Voc&ecirc; declara e garante que todas as
						informa&ccedil;&otilde;es do usu&aacute;rio fornecidas no
						formul&aacute;rio de registro ou de outra forma relacionadas ao uso dos
						Servi&ccedil;os UELF ser&atilde;o atuais, completas e precisas. Como
						usu&aacute;rio registrado dos Servi&ccedil;os, voc&ecirc; &eacute;
						inteiramente respons&aacute;vel por manter a confidencialidade das
						informa&ccedil;&otilde;es da sua conta de usu&aacute;rio. Voc&ecirc;
						n&atilde;o pode usar a conta ou o nome de usu&aacute;rio de qualquer outro
						membro a qualquer momento. Voc&ecirc; deve nos notificar imediatamente no
						caso de qualquer uso n&atilde;o autorizado conhecido ou suspeito de sua
						conta de usu&aacute;rio, ou qualquer viola&ccedil;&atilde;o de
						seguran&ccedil;a conhecida ou suspeita, incluindo perda, roubo ou
						divulga&ccedil;&atilde;o n&atilde;o autorizada de suas
						informa&ccedil;&otilde;es de conta de usu&aacute;rio ou de qualquer outra
						pessoa.
					</p>
					<p>
						<br />
						2. Voc&ecirc; tamb&eacute;m &eacute; inteiramente respons&aacute;vel por
						toda e qualquer atividade que ocorra em sua conta de usu&aacute;rio.
						N&atilde;o seremos respons&aacute;veis ​​por qualquer perda que voc&ecirc;
						incorra como resultado de outra pessoa usar sua conta, com ou sem seu
						conhecimento. Voc&ecirc; tamb&eacute;m pode ser responsabilizado por
						quaisquer perdas incorridas pela UELF, suas afiliadas, executivos,
						diretores, funcion&aacute;rios, consultores, agentes e representantes
						devido ao uso de sua conta por outra pessoa.
					</p>
					<p>
						<br />
						3. Para usu&aacute;rios de nossos Servi&ccedil;os Pagos, voc&ecirc;
						concorda em nos fornecer informa&ccedil;&otilde;es verdadeiras, precisas e
						completas, conforme exigido pelo processo de cadastro e assinatura do
						servi&ccedil;o (&ldquo;Dados de Assinatura&rdquo;). Voc&ecirc; concorda em
						manter e atualizar prontamente os Dados de Assinatura e qualquer outra
						informa&ccedil;&atilde;o que voc&ecirc; nos forne&ccedil;a para
						mant&ecirc;-los precisos. Sem limitar qualquer outra
						disposi&ccedil;&atilde;o destes Termos e Condi&ccedil;&otilde;es, se
						voc&ecirc; fornecer qualquer informa&ccedil;&atilde;o que seja falsa,
						imprecisa ou incompleta, ou se tivermos motivos razo&aacute;veis ​​para
						suspeitar que seja esse o caso, nos reservamos o direito de suspender ou
						encerrar sua conta de usu&aacute;rio ou assinatura e recusar todo e
						qualquer uso atual ou futuro por voc&ecirc; de nossos Servi&ccedil;os (ou
						qualquer parte deles). Voc&ecirc; &eacute; obrigado a verificar se seus
						Dados de Assinatura s&atilde;o atuais e precisos e, se n&atilde;o
						estiverem, a corrigir ou atualizar seus Dados de Assinatura.
					</p>
					<p>
						<br />
						4. Voc&ecirc; tamb&eacute;m concorda em n&atilde;o registrar ou assinar
						mais de uma conta, criar uma conta em nome de outra pessoa ou criar uma
						identidade falsa ou enganosa ao usar os Servi&ccedil;os UELF. Se seu
						registro ou assinatura for revogado por qualquer motivo, voc&ecirc;
						concorda em n&atilde;o se registrar ou se inscrever novamente em nossos
						Servi&ccedil;os usando outro nome de usu&aacute;rio ou por qualquer outro
						meio. Se tivermos motivos para suspeitar, a nosso exclusivo
						crit&eacute;rio, que sua conta foi encerrada anteriormente, nos reservamos
						o direito de encerrar quaisquer novas contas que voc&ecirc; tenha
						registrado sem aviso pr&eacute;vio ou de exercer quaisquer outros recursos
						dispon&iacute;veis para n&oacute;s sob estes Termos e
						Condi&ccedil;&otilde;es ou por lei.
					</p>
					<p>
						<br />
						5. Seu uso dos Servi&ccedil;os Pagos do UELF requer que voc&ecirc;
						forne&ccedil;a informa&ccedil;&otilde;es &agrave; UELF, incluindo
						informa&ccedil;&otilde;es sobre seu cart&atilde;o de cr&eacute;dito. Todas
						as informa&ccedil;&otilde;es coletadas pela UELF como parte de nossos
						Servi&ccedil;os de Pagamento est&atilde;o sujeitas &agrave; nossa
						Pol&iacute;tica de Privacidade. Embora a UELF implemente medidas para
						ajudar a proteger suas informa&ccedil;&otilde;es, voc&ecirc; &eacute; o
						&uacute;nico respons&aacute;vel por manter o sigilo de suas
						informa&ccedil;&otilde;es de login e por manter a posse de seu dispositivo
						m&oacute;vel. Se voc&ecirc; perder seu dispositivo m&oacute;vel contendo
						suas informa&ccedil;&otilde;es de login UELF, voc&ecirc; deve nos
						notificar imediatamente e suspender sua conta UELF atrav&eacute;s do
						Aplicativo M&oacute;vel ou desativar os Servi&ccedil;os de Pagamento
						atrav&eacute;s do Site, Aplicativo M&oacute;vel ou outro m&eacute;todo que
						possa estar dispon&iacute;vel para voc&ecirc;.
					</p>
					<p>
						<br />
						7. CONSENTIMENTO PARA RECEBER COMUNICA&Ccedil;&Otilde;ES ELETR&Ocirc;NICAS
						DA UELF, AFILIADOS E PARCEIROS TERCEIROS
					</p>
					<p>
						<br />
						1. A UELF, nossos Afiliados e nossos parceiros terceirizados podem enviar
						comunica&ccedil;&otilde;es a voc&ecirc; por meio de qualquer um dos
						Servi&ccedil;os, incluindo o Site e o Aplicativo M&oacute;vel, incluindo,
						mas n&atilde;o limitado a atualiza&ccedil;&otilde;es destes Termos e
						Condi&ccedil;&otilde;es, divulga&ccedil;&otilde;es legais,
						altera&ccedil;&otilde;es futuras a estes Termos e Condi&ccedil;&otilde;es,
						Pol&iacute;tica de Privacidade, avisos, comunica&ccedil;&otilde;es legais
						ou divulga&ccedil;&otilde;es e informa&ccedil;&otilde;es relacionadas aos
						Servi&ccedil;os. Tais comunica&ccedil;&otilde;es fazem parte dos
						Servi&ccedil;os que voc&ecirc; n&atilde;o pode optar por n&atilde;o
						receber. &ldquo;Afiliadas&rdquo; significa qualquer entidade ou pessoa,
						direta ou indiretamente, que detenha uma participa&ccedil;&atilde;o de
						controle, de propriedade ou sob controle de propriedade comum com a UELF.
						Nossos &ldquo;parceiros terceirizados&rdquo; podem incluir qualquer um de
						nossos patrocinadores, treinadores, celebridades e quaisquer terceiros com
						os quais tenhamos um relacionamento comercial de tempos em tempos, a nosso
						crit&eacute;rio.
					</p>
					<p>
						<br />
						2. Como parte do uso dos Servi&ccedil;os UELF, voc&ecirc; pode receber
						notifica&ccedil;&otilde;es, mensagens de texto, alertas, e-mails e outras
						comunica&ccedil;&otilde;es eletr&ocirc;nicas. Voc&ecirc; concorda com o
						recebimento dessas comunica&ccedil;&otilde;es. Podemos precisar fornecer a
						voc&ecirc; determinadas comunica&ccedil;&otilde;es, como an&uacute;ncios
						de servi&ccedil;o e mensagens administrativas. Voc&ecirc; &eacute;
						respons&aacute;vel por quaisquer taxas de mensagens ou dados que possam
						ser cobradas pela sua operadora sem fio. Quaisquer avisos, acordos,
						divulga&ccedil;&otilde;es ou outras comunica&ccedil;&otilde;es que
						enviarmos a voc&ecirc; eletronicamente atender&atilde;o a quaisquer
						requisitos legais de comunica&ccedil;&atilde;o, incluindo que a
						comunica&ccedil;&atilde;o seja por escrito.
					</p>
					<p>
						<br />
						3. Voc&ecirc; pode optar por n&atilde;o receber essas mensagens
						promocionais a qualquer momento (a) seguindo as instru&ccedil;&otilde;es
						de cancelamento de inscri&ccedil;&atilde;o contidas em cada mensagem; ou
						(b) alterar as prefer&ecirc;ncias de mensagens em sua conta. Se voc&ecirc;
						decidir que n&atilde;o deseja receber comunica&ccedil;&otilde;es futuras
						eletronicamente ap&oacute;s encerrar seu relacionamento com a UELF, envie
						um e-mail para contato@uelf.com.br. Sua exclus&atilde;o entrar&aacute; em
						vigor dentro de um prazo razo&aacute;vel ap&oacute;s recebermos o aviso
						descrito acima. Sua exclus&atilde;o n&atilde;o afetar&aacute; a validade
						legal ou aplicabilidade dos Termos e Condi&ccedil;&otilde;es que
						sobrevivem ao t&eacute;rmino de seu relacionamento conosco.
					</p>
					<p>
						<br />
						8. POSTAR SEU CONTE&Uacute;DO NOS SERVI&Ccedil;OS UELF
					</p>
					<p>
						<br />
						1. A UELF pode permitir que voc&ecirc; publique, carregue, armazene,
						compartilhe, envie ou exiba fotos, imagens, v&iacute;deos, dados, texto,
						coment&aacute;rios e outras informa&ccedil;&otilde;es e conte&uacute;do
						("Conte&uacute;do") para e atrav&eacute;s dos Servi&ccedil;os UELF. Ao
						disponibilizar o seu Conte&uacute;do ou atrav&eacute;s dos Servi&ccedil;os
						UELF, voc&ecirc; concede a UELF uma licen&ccedil;a n&atilde;o exclusiva,
						transfer&iacute;vel, sublicenci&aacute;vel, mundial, isenta de royalties
						para usar, copiar, modificar, exibir publicamente, executar publicamente,
						reproduzir, traduzir, criar derivados, trabalhar e distribui seu
						Conte&uacute;do, no todo ou em parte, incluindo seu nome e imagem, em
						qualquer m&iacute;dia.
					</p>
					<p>
						<br />
						2. Voc&ecirc; declara e garante que seu Conte&uacute;do, o uso e o
						fornecimento de seu Conte&uacute;do nos Servi&ccedil;os UELF e seu uso dos
						Servi&ccedil;os UELF n&atilde;o (a) infringir&atilde;o, se
						apropriar&atilde;o indevidamente ou violar&atilde;o patentes, direitos
						autorais, marcas registradas, segredos comerciais, direitos morais, ou
						outros direitos de propriedade intelectual, ou direitos de publicidade ou
						privacidade; (b) violar ou incentivar qualquer conduta que viole qualquer
						lei ou regulamento aplic&aacute;vel ou d&ecirc; origem a responsabilidade
						civil; (c) ser fraudulento, falso ou enganoso; (d) ser difamat&oacute;rio,
						obsceno, pornogr&aacute;fico, vulgar ou ofensivo; (e) promover
						discrimina&ccedil;&atilde;o, intoler&acirc;ncia, racismo, &oacute;dio,
						ass&eacute;dio ou dano contra qualquer indiv&iacute;duo ou grupo; (f) ser
						violento ou amea&ccedil;ador ou promover viol&ecirc;ncia ou
						a&ccedil;&otilde;es que ameacem qualquer pessoa ou entidade.
					</p>
					<p>
						<br />
						3. Voc&ecirc; tamb&eacute;m concorda em n&atilde;o (1) fazer upload de
						qualquer conte&uacute;do que contenha v&iacute;rus de software ou seja
						projetado para interromper, destruir ou limitar a funcionalidade de
						qualquer equipamento ou servi&ccedil;o, ou que contenha outros arquivos ou
						conte&uacute;dos prejudiciais, perturbadores ou destrutivos; (2) usar ou
						tentar usar a conta de outro usu&aacute;rio sem autoriza&ccedil;&atilde;o,
						ou se passar por qualquer pessoa ou entidade; (3) colher, solicitar ou
						coletar informa&ccedil;&otilde;es de outros usu&aacute;rios por qualquer
						motivo, incluindo, sem limita&ccedil;&atilde;o, o envio de
						comunica&ccedil;&otilde;es n&atilde;o solicitadas; (4) publicar, anunciar
						ou promover produtos ou servi&ccedil;os comercialmente, ou carregar
						qualquer conte&uacute;do que seja publicidade, material promocional, lixo
						eletr&ocirc;nico, spam ou um concurso ou sorteio, ou que promova
						atividades criminosas; ou (5) usar os Servi&ccedil;os UELF de qualquer
						maneira que, a nosso exclusivo crit&eacute;rio, seja censur&aacute;vel ou
						restrinja ou iniba qualquer outra pessoa de usar ou desfrutar dos
						Servi&ccedil;os UELF, ou que possa expor a n&oacute;s ou nossos
						usu&aacute;rios a qualquer dano ou responsabilidade de qualquer tipo. A
						UELF pode, a seu exclusivo crit&eacute;rio, alterar, remover ou se recusar
						a exibir qualquer conte&uacute;do seu, e pode proibir voc&ecirc; de
						postar, carregar, armazenar, compartilhar, enviar ou exibir seu
						Conte&uacute;do para e atrav&eacute;s dos Servi&ccedil;os UELF. A UELF
						tamb&eacute;m se reserva o direito de encerrar sua conta UELF por violar
						qualquer parte desta Se&ccedil;&atilde;o ou exibir seu Conte&uacute;do
						para e atrav&eacute;s dos Servi&ccedil;os UELF. A UELF tamb&eacute;m se
						reserva o direito de encerrar sua conta UELF por violar qualquer parte
						desta Se&ccedil;&atilde;o.
					</p>
					<p>
						<br />
						4. A UELF reserva-se o direito de (i) remover, suspender, editar ou
						modificar qualquer Conte&uacute;do a seu exclusivo crit&eacute;rio a
						qualquer momento, sem aviso pr&eacute;vio e por qualquer motivo
						(incluindo, mas n&atilde;o limitado a, ap&oacute;s o recebimento de
						reclama&ccedil;&otilde;es ou alega&ccedil;&otilde;es de terceiros ou
						autoridades relacionadas a tal Conte&uacute;do ou se a UELF estiver
						preocupada que voc&ecirc; possa ter violado estes Termos e
						Condi&ccedil;&otilde;es), ou sem motivo algum e (ii) remover, suspender ou
						bloquear qualquer Conte&uacute;do. A UELF tamb&eacute;m se reserva o
						direito de acessar, ler, preservar e divulgar qualquer
						informa&ccedil;&atilde;o que a UELF razoavelmente acredite ser
						necess&aacute;ria para (i) satisfazer qualquer lei, regulamento, processo
						legal ou solicita&ccedil;&atilde;o governamental aplic&aacute;vel, (ii)
						fazer cumprir estes Termos e Condi&ccedil;&otilde;es, incluindo
						investiga&ccedil;&atilde;o de poss&iacute;veis viola&ccedil;&otilde;es
						deste instrumento, (iii) detectar, prevenir ou resolver de outra forma
						fraude, seguran&ccedil;a ou problemas t&eacute;cnicos.
					</p>
					<p>
						<br />
						5. Nossos parceiros comerciais, fornecedores, anunciantes, patrocinadores,
						licenciadores, contratados e outros terceiros tamb&eacute;m podem ter
						direitos de propriedade adicionais sobre o Conte&uacute;do que
						disponibilizam em nossos Servi&ccedil;os. Voc&ecirc; n&atilde;o pode
						modificar, publicar, transmitir, distribuir, executar, participar da
						transfer&ecirc;ncia ou venda, criar trabalhos derivados ou de qualquer
						forma explorar qualquer Conte&uacute;do, no todo ou em parte. Quando o
						Conte&uacute;do &eacute; baixado em seu computador, telefone, tablet ou
						qualquer outro dispositivo m&oacute;vel, voc&ecirc; n&atilde;o
						obt&eacute;m nenhuma participa&ccedil;&atilde;o acion&aacute;ria em tal
						Conte&uacute;do. A modifica&ccedil;&atilde;o do Conte&uacute;do ou o uso
						do Conte&uacute;do para qualquer outra finalidade, incluindo, mas
						n&atilde;o se limitando ao uso de qualquer Conte&uacute;do em formato
						impresso ou em qualquer outro aplicativo ou ambiente de computador em
						rede, &eacute; estritamente proibido, a menos que voc&ecirc; receba nosso
						consentimento pr&eacute;vio por escrito.
					</p>
					<p>
						<br />
						6. Podemos, a qualquer momento, suspender seu uso dos Servi&ccedil;os UELF
						e/ou remover ou desabilitar qualquer Conte&uacute;do que razoavelmente e
						de boa f&eacute; acreditemos estar violando qualquer um destes Termos e
						Condi&ccedil;&otilde;es. Iremos notific&aacute;-lo sobre tal
						suspens&atilde;o ou desativa&ccedil;&atilde;o antes de sua
						implementa&ccedil;&atilde;o, a menos que tal suspens&atilde;o ou
						desativa&ccedil;&atilde;o seja necess&aacute;ria para cumprir o processo
						legal, regulamento, ordem ou evitar danos iminentes aos Servi&ccedil;os
						UELF ou a terceiros, caso em que notificaremos voc&ecirc; na medida
						permitida pela lei aplic&aacute;vel de tal suspens&atilde;o ou
						desativa&ccedil;&atilde;o assim que for razoavelmente pratic&aacute;vel a
						partir de ent&atilde;o.
					</p>
					<p>
						<br />
						7. VOC&Ecirc; SER&Aacute; O &Uacute;NICO RESPONS&Aacute;VEL POR QUAISQUER
						VIOLA&Ccedil;&Otilde;ES DE QUAISQUER LEIS E POR QUAISQUER
						VIOLA&Ccedil;&Otilde;ES DE DIREITOS DE TERCEIROS CAUSADAS PELO SEU USO DOS
						SERVI&Ccedil;OS. VOC&Ecirc; &Eacute; O &Uacute;NICO RESPONS&Aacute;VEL
						PELO &Ocirc;NUS DE PROVAS QUE O CONTE&Uacute;DO, INFORMA&Ccedil;&Otilde;ES
						OU OUTROS MATERIAIS N&Atilde;O VIOLAM QUAISQUER LEIS OU DIREITOS DE
						TERCEIROS.
					</p>
					<p>
						<br />
						8. A UELF SE ISENTA DE QUALQUER DEVER PERCEBIDO, IMPL&Iacute;CITO OU REAL
						DE MONITORAR O CONTE&Uacute;DO POSTADO POR USU&Aacute;RIOS, E
						ESPECIFICAMENTE SE ISENTA DE QUALQUER RESPONSABILIDADE POR
						INFORMA&Ccedil;&Otilde;ES AQUI FORNECIDAS. SOB NENHUMA CIRCUNST&Acirc;NCIA
						N&Oacute;S SEREMOS RESPONS&Aacute;VEIS DE QUALQUER FORMA PELO
						CONTE&Uacute;DO DE QUALQUER USU&Aacute;RIO (SEM SER PELO CONTE&Uacute;DO
						DESENVOLVIDO POR N&Oacute;S), INCLUINDO, MAS N&Atilde;O SE LIMITANDO A,
						POR QUAISQUER ERROS OU OMISS&Otilde;ES NO CONTE&Uacute;DO DE QUALQUER
						USU&Aacute;RIO, OU POR QUALQUER PERDA OU DANO DE QUALQUER TIPO OCORRIDO
						COMO RESULTADO DO USO DE QUALQUER CONTE&Uacute;DO DO USU&Aacute;RIO
						PUBLICADO, ENVIADO POR E-MAIL OU DE OUTRA FORMA TRANSMITIDO PARA OU
						ATRAV&Eacute;S DOS SERVI&Ccedil;OS.
					</p>
					<p>
						<br />
						9. Como parte da funcionalidade dos Servi&ccedil;os UELF, podemos oferecer
						a op&ccedil;&atilde;o de permitir que voc&ecirc; vincule sua conta a
						contas online que voc&ecirc; possui com provedores de servi&ccedil;os
						terceirizados (cada uma dessas contas, uma &ldquo;Conta de
						Terceiros&rdquo;) por: ( 1) fornecer as informa&ccedil;&otilde;es de login
						da sua conta de terceiros por meio dos servi&ccedil;os UELF; ou (2)
						permitindo-nos acessar sua Conta de Terceiros, conforme permitido pelos
						termos e condi&ccedil;&otilde;es aplic&aacute;veis ​​que regem seu uso de
						cada Conta de Terceiros. Voc&ecirc; declara e garante que tem o direito de
						divulgar as informa&ccedil;&otilde;es de login da sua conta de terceiros
						para n&oacute;s e/ou nos conceder acesso &agrave; sua conta de terceiros,
						sem viola&ccedil;&atilde;o por voc&ecirc; de qualquer um dos termos e
						condi&ccedil;&otilde;es que regem seu uso do Conta de terceiros, e sem nos
						obrigar a pagar quaisquer taxas ou nos sujeitar a quaisquer
						limita&ccedil;&otilde;es de uso impostas pelo provedor de servi&ccedil;os
						terceirizado da Conta de Terceiros.
					</p>
					<p>
						<br />
						10. Ao nos conceder acesso a quaisquer Contas de Terceiros, voc&ecirc;
						entende que (1) podemos acessar, disponibilizar e armazenar (se
						aplic&aacute;vel) qualquer conte&uacute;do que voc&ecirc; tenha fornecido
						e armazenado em sua Conta de Terceiros (a &ldquo;Rede Social
						Conte&uacute;do&rdquo;) para que esteja dispon&iacute;vel em e
						atrav&eacute;s dos Servi&ccedil;os UELF por meio de sua conta, incluindo,
						sem limita&ccedil;&atilde;o, quaisquer listas de amigos e (2) podemos
						enviar e receber de sua Conta de Terceiros informa&ccedil;&otilde;es
						adicionais na medida em que voc&ecirc; for notificado ao vincular sua
						conta com a Conta de Terceiros. Dependendo das contas de terceiros que
						voc&ecirc; escolher e sujeito &agrave;s configura&ccedil;&otilde;es de
						privacidade que voc&ecirc; definiu nessas contas de terceiros,
						informa&ccedil;&otilde;es de identifica&ccedil;&atilde;o pessoal que
						voc&ecirc; publica em suas contas de terceiros podem estar
						dispon&iacute;veis em e por meio de sua conta nos Servi&ccedil;os UELF.
						Observe que, se uma Conta de Terceiros ou servi&ccedil;o associado ficar
						indispon&iacute;vel ou nosso acesso a tal Conta de Terceiros for encerrado
						pelo provedor de servi&ccedil;os terceirizado, o Conte&uacute;do da Rede
						Social poder&aacute; n&atilde;o estar mais dispon&iacute;vel nos
						Servi&ccedil;os UELF. Voc&ecirc; poder&aacute; desativar a conex&atilde;o
						entre sua conta no UELF Services e suas contas de terceiros a qualquer
						momento.
					</p>
					<p>
						<br />
						11. OBSERVE QUE SEU RELACIONAMENTO COM OS PRESTADORES DE SERVI&Ccedil;OS
						DE TERCEIROS ASSOCIADOS A SUAS CONTAS DE TERCEIROS &Eacute; REGIDO
						EXCLUSIVAMENTE POR SEU(S) CONTRATO(S) COM TAIS PRESTADORES DE
						SERVI&Ccedil;OS DE TERCEIROS. N&atilde;o fazemos nenhum esfor&ccedil;o
						para revisar qualquer Conte&uacute;do de Rede Social para qualquer
						finalidade, incluindo, mas n&atilde;o limitado a, por precis&atilde;o,
						legalidade ou n&atilde;o viola&ccedil;&atilde;o, e n&atilde;o somos
						respons&aacute;veis ​​por qualquer Conte&uacute;do de Rede Social.
					</p>
					<p>
						<br />
						12. Voc&ecirc; reconhece e concorda que podemos acessar seu
						cat&aacute;logo de endere&ccedil;os de e-mail associado a uma conta de
						terceiros e sua lista de contatos armazenada em seu dispositivo
						m&oacute;vel ou tablet apenas para fins de identifica&ccedil;&atilde;o e
						informa&ccedil;&atilde;o sobre os contatos que tamb&eacute;m se
						registraram para usar os Servi&ccedil;os UELF. Voc&ecirc; pode desativar a
						conex&atilde;o entre os Servi&ccedil;os UELF e sua Conta de Terceiros
						entrando em contato conosco ou atrav&eacute;s das
						configura&ccedil;&otilde;es da sua conta (se aplic&aacute;vel). Tentaremos
						excluir qualquer informa&ccedil;&atilde;o armazenada em nossos servidores
						que tenha sido obtida por meio de tal Conta de Terceiros, exceto o nome de
						usu&aacute;rio e a foto do perfil que forem associados &agrave; sua conta.
						Voc&ecirc; reconhece e concorda que podemos acessar seu cat&aacute;logo de
						endere&ccedil;os de e-mail associado a uma conta de terceiros e sua lista
						de contatos armazenada em seu dispositivo m&oacute;vel ou tablet apenas
						para fins de identifica&ccedil;&atilde;o e informa&ccedil;&atilde;o sobre
						os contatos que tamb&eacute;m se registraram para usar os Servi&ccedil;os
						UELF. Voc&ecirc; pode desativar a conex&atilde;o entre os Servi&ccedil;os
						UELF e sua Conta de Terceiros entrando em contato conosco ou
						atrav&eacute;s das configura&ccedil;&otilde;es da sua conta (se
						aplic&aacute;vel). Tentaremos excluir qualquer informa&ccedil;&atilde;o
						armazenada em nossos servidores que tenha sido obtida por meio de tal
						Conta de Terceiros, exceto o nome de usu&aacute;rio e a foto do perfil que
						forem associados &agrave; sua conta. Voc&ecirc; reconhece e concorda que
						podemos acessar seu cat&aacute;logo de endere&ccedil;os de e-mail
						associado a uma conta de terceiros e sua lista de contatos armazenada em
						seu dispositivo m&oacute;vel ou tablet apenas para fins de
						identifica&ccedil;&atilde;o e informa&ccedil;&atilde;o sobre os contatos
						que tamb&eacute;m se registraram para usar os Servi&ccedil;os UELF.
						Voc&ecirc; pode desativar a conex&atilde;o entre os Servi&ccedil;os UELF e
						sua Conta de Terceiros entrando em contato conosco ou atrav&eacute;s das
						configura&ccedil;&otilde;es da sua conta (se aplic&aacute;vel). Tentaremos
						excluir qualquer informa&ccedil;&atilde;o armazenada em nossos servidores
						que tenha sido obtida por meio de tal Conta de Terceiros, exceto o nome de
						usu&aacute;rio e a foto do perfil que se tornem associados &agrave; sua
						conta.
					</p>
					<p>
						<br />
						9. NOSSA PROPRIEDADE INTELECTUAL
					</p>
					<p>
						<br />
						1. UELF &ldquo;Propriedade Intelectual&rdquo; ou &ldquo;IP&rdquo; inclui
						software; o formato, diret&oacute;rios, consultas, algoritmos, estrutura e
						organiza&ccedil;&atilde;o do nosso Site e/ou Aplicativo M&oacute;vel; o
						c&oacute;digo fonte e objeto do Site e/ou Aplicativo M&oacute;vel; obras
						de autoria de qualquer natureza; e outras informa&ccedil;&otilde;es,
						conte&uacute;do ou outros materiais publicados, gerados, fornecidos ou
						disponibilizados de outra forma por meio dos Servi&ccedil;os UELF. UELF e
						nossa tecnologia subjacente s&atilde;o protegidos por direitos autorais,
						marcas registradas, patentes, propriedade intelectual e outras leis
						p&aacute;trias e de pa&iacute;ses estrangeiros. Reservamo-nos todos os
						direitos n&atilde;o expressamente estabelecidos nestes Termos e
						Condi&ccedil;&otilde;es. Voc&ecirc; concorda em n&atilde;o remover,
						alterar ou ocultar qualquer direito autoral, marca comercial, marca de
						servi&ccedil;o ou outros avisos de direitos de propriedade incorporados ou
						que acompanhem os Servi&ccedil;os UELF. Nossos logotipos e quaisquer
						outras marcas registradas UELF que possam aparecer nos Servi&ccedil;os
						UELF, e a apar&ecirc;ncia geral dos Servi&ccedil;os UELF, incluindo
						cabe&ccedil;alhos de p&aacute;gina, gr&aacute;ficos, &iacute;cones e
						scripts, n&atilde;o podem ser copiados, imitados ou usados, no todo ou em
						parte, sem nossa permiss&atilde;o pr&eacute;via por escrito. Outras marcas
						registradas, nomes de produtos e servi&ccedil;os e nomes de empresas ou
						logotipos mencionados nos Servi&ccedil;os UELF s&atilde;o de propriedade
						de seus respectivos propriet&aacute;rios e n&atilde;o podem ser copiados,
						imitados ou usados, no todo ou em parte, sem a permiss&atilde;o do titular
						da marca registrada aplic&aacute;vel.
					</p>
					<p>
						<br />
						8. CONTE&Uacute;DO DE TERCEIROS
					</p>
					<p>
						<br />
						2. Nossos Servi&ccedil;os podem incluir links para sites de terceiros
						(&ldquo;Sites de Terceiros&rdquo;), alguns dos quais podem ter
						estabelecido relacionamentos com a UELF e outros n&atilde;o. N&atilde;o
						temos controle sobre o conte&uacute;do e o desempenho de sites de
						terceiros.
					</p>
					<p>
						<br />
						3. A UELF N&Atilde;O IR&Aacute; CONTROLAR TODO O MATERIAL, INCLUINDO
						APLICATIVO DE COMPUTADOR OU OUTROS BENS OU SERVI&Ccedil;OS,
						DISPONIBILIZADOS EM OU ATRAV&Eacute;S DE SITES DE TERCEIROS E N&Atilde;O
						ENDOSSA (E N&Atilde;O &Eacute; RESPONS&Aacute;VEL POR) QUALQUER
						CONTE&Uacute;DO, PUBLICIDADE, PRODUTOS OU OUTROS MATERIAIS EM OU
						DISPON&Iacute;VEIS EM TAIS SITES DE TERCEIROS. ASSIM, A UELF N&Atilde;O
						REPRESENTA, GARANTE OU ENDOSSA QUALQUER SITE DE TERCEIROS, OU A
						PRECIS&Atilde;O, MOEDA, CONTE&Uacute;DO, ADEQUA&Ccedil;&Atilde;O,
						LEGALIDADE OU QUALIDADE DO MATERIAL DE INFORMA&Ccedil;&Atilde;O, BENS OU
						SERVI&Ccedil;OS DISPON&Iacute;VEIS ATRAV&Eacute;S DE SITES DE TERCEIROS.
					</p>
					<p>
						<br />
						4. A UELF SE ISENTA, E VOC&Ecirc; CONCORDA EM ASSUMIR TODA A
						RESPONSABILIDADE POR QUAISQUER DANOS OU OUTROS DANOS, SEJA A VOC&Ecirc; OU
						A TERCEIROS, RESULTANTE DO SEU USO OU CONFIAN&Ccedil;A EM QUALQUER
						CONTE&Uacute;DO, AN&Uacute;NCIOS, PRODUTOS OU OUTROS RECURSOS
						DISPON&Iacute;VEIS EM QUALQUER TERCEIRO - SITES DE PARTES (INDEPENDENTE SE
						LIGAMOS DIRETA OU INDIRETAMENTE A TAIS CONTE&Uacute;DOS, AN&Uacute;NCIOS,
						PRODUTOS OU OUTROS RECURSOS).
					</p>
					<p>
						<br />
						5. VOC&Ecirc; CONCORDA QUE, AO LINKAR OU ACESSAR OU USAR UM SITE DE
						TERCEIROS, VOC&Ecirc; &Eacute; RESPONS&Aacute;VEL POR: (I) TOMAR AS
						PRECAU&Ccedil;&Otilde;ES NECESS&Aacute;RIAS PARA PROTEGER VOC&Ecirc; E
						SEUS SISTEMAS DE COMPUTADOR CONTRA V&Iacute;RUS, Worms, TROJAN HORSES,
						C&Oacute;DIGOS MALICIOSOS E OUTROS OU CONTE&Uacute;DO DESTRUTIVO; (II)
						QUALQUER DOWNLOAD, USO OU COMPRA DE MATERIAL QUE SEJA OBSCENO, INDECENTE,
						OFENSIVO OU DE OUTRA FORMA OBJETIVO OU IL&Iacute;CITO, OU QUE
						CONT&Eacute;M IMPRECIS&Otilde;ES T&Eacute;CNICAS, ERROS
						TIPOGR&Aacute;FICOS E OUTROS ERROS; (III) QUALQUER DOWNLOAD, USO OU COMPRA
						DE MATERIAL QUE VIOLE OS DIREITOS DE PRIVACIDADE OU DE PUBLICIDADE, OU
						INFRINJA PROPRIEDADE INTELECTUAL E OUTROS DIREITOS DE PROPRIEDADE DE
						TERCEIROS, OU QUE ESTEJA SUJEITO A TERMOS E CONDI&Ccedil;&Otilde;ES
						ADICIONAIS, DECLARADOS OU N&Atilde;O; (IV) TODOS OS ENCARGOS FINANCEIROS
						OU OUTRAS OBRIGA&Ccedil;&Otilde;ES PARA COM TERCEIROS RESULTANTES DE
						TRANSA&Ccedil;&Otilde;ES OU OUTRAS ATIVIDADES; E (V) LER E COMPREENDER
						QUAISQUER TERMOS DE USO OU POL&Iacute;TICAS DE PRIVACIDADE QUE SE APLICAM
						A ESSES SITES DE TERCEIROS.
					</p>
					<p>
						<br />
						11. F&Oacute;RUM P&Uacute;BLICO; INFORMA&Ccedil;&Otilde;ES FORNECIDAS
						PELOS USU&Aacute;RIOS
					</p>
					<p>
						<br />
						1. A UELF pode oferecer formul&aacute;rios p&uacute;blicos aos seus
						Usu&aacute;rios como um recurso de seus Servi&ccedil;os. Um
						&ldquo;F&oacute;rum P&uacute;blico&rdquo; &eacute; qualquer &aacute;rea,
						site ou recurso oferecido como parte dos Servi&ccedil;os (incluindo, mas
						n&atilde;o limitado a, bate-papo, quadro de mensagens, blog, grupos,
						e-mails ou recursos de mensagens instant&acirc;neas) que permite (a) fazer
						upload, enviar, publicar, exibir, executar, distribuir e/ou visualizar o
						Conte&uacute;do do Usu&aacute;rio e/ou (b) comunicar, compartilhar ou
						trocar Conte&uacute;do do Usu&aacute;rio com outros membros. Salvo
						disposi&ccedil;&atilde;o em contr&aacute;rio em nossa Pol&iacute;tica de
						Privacidade, todas as comunica&ccedil;&otilde;es do F&oacute;rum
						P&uacute;blico s&atilde;o comunica&ccedil;&otilde;es p&uacute;blicas e
						n&atilde;o privadas. Voc&ecirc; &eacute;, e permanecer&aacute;, o
						&uacute;nico respons&aacute;vel pelo Conte&uacute;do do Usu&aacute;rio que
						voc&ecirc; carregar, enviar, postar, transmitir, comunicar, compartilhar
						ou trocar por meio de qualquer F&oacute;rum P&uacute;blico e pelas
						consequ&ecirc;ncias de enviar ou postar o mesmo.
					</p>
					<p>
						<br />
						2. A UELF SE ISENTA DE RESPONSABILIDADE POR QUALQUER TIPO DE MONITORAMENTO
						DE F&Oacute;RUNS P&Uacute;BLICOS, ASSIM CONSIDERADOS AQUELES N&Atilde;O
						LIGADOS A ELA.
					</p>
					<p>
						<br />
						3. A UELF N&Atilde;O SE RESPONSABILIZA POR QUALQUER
						INFORMA&Ccedil;&Atilde;O DIVULGADA PELOS SEUS COLABORADORES EM PERFIS
						PESSOAIS DE REDES SOCIAIS.
					</p>
					<p>
						<br />
						12. LICEN&Ccedil;A LIMITADA
					</p>
					<p>
						<br />
						1. A UELF concede a voc&ecirc; uma licen&ccedil;a limitada, n&atilde;o
						exclusiva, intransfer&iacute;vel, n&atilde;o sublicenci&aacute;vel e
						revog&aacute;vel para (1) acessar e usar os Servi&ccedil;os UELF, (2)
						acessar e visualizar o Conte&uacute;do e (3) acessar e usar o software e
						Aplicativo(s) m&oacute;vel(is) fornecido(s) pelo Servi&ccedil;o UELF. Esta
						licen&ccedil;a inclui qualquer software de terceiros incorporado em
						qualquer um de nossos Servi&ccedil;os. Esta licen&ccedil;a &eacute;
						fornecida exclusivamente para seu uso pessoal e n&atilde;o comercial e
						aproveitamento dos Servi&ccedil;os UELF, conforme permitido nestes Termos
						e Condi&ccedil;&otilde;es.
					</p>
					<p>
						<br />
						2. Voc&ecirc; n&atilde;o deve usar, sublicenciar, copiar, adaptar,
						modificar, traduzir, divulgar, preparar trabalhos derivados baseados em
						distribuir, licenciar, vender, alugar, arrendar, ceder, transferir, exibir
						publicamente, transmitir, difundir ou explorar o Conte&uacute;do, nossos
						Servi&ccedil;os ou qualquer parte dele (incluindo qualquer software de
						terceiros), exceto conforme expressamente permitido nestes Termos e
						Condi&ccedil;&otilde;es. Nenhuma licen&ccedil;a ou direito &eacute;
						concedido a voc&ecirc; por implica&ccedil;&atilde;o ou de outra forma sob
						quaisquer direitos de propriedade intelectual pertencentes ou controlados
						pela UELF, suas Afiliadas ou seus licenciadores, exceto pelas
						licen&ccedil;as e direitos expressamente concedidos nestes Termos e
						Condi&ccedil;&otilde;es.
					</p>
					<p>
						<br />
						3. Exceto na medida permitida por lei, voc&ecirc; n&atilde;o pode
						executar, tentar executar, encorajar ou ajudar outras pessoas a realizar
						qualquer uma das seguintes a&ccedil;&otilde;es ao acessar ou usar os
						Servi&ccedil;os UELF: (1) usar, exibir, espelhar ou enquadrar os
						Servi&ccedil;os UELF ou qualquer elemento individual dentro dos
						Servi&ccedil;os UELF, incluindo o layout e design de qualquer
						p&aacute;gina, sem nosso consentimento expresso por escrito; (2) usar o
						nome da UELF, qualquer marca registrada ou logotipo da UELF, ou qualquer
						informa&ccedil;&atilde;o propriet&aacute;ria da UELF sem nosso
						consentimento expresso por escrito; (3) acessar ou adulterar &aacute;reas
						n&atilde;o p&uacute;blicas dos Servi&ccedil;os UELF, sistemas
						inform&aacute;ticos UELF, ou os sistemas de entrega t&eacute;cnica dos
						fornecedores UELF; (4) testar a vulnerabilidade de qualquer sistema UELF
						ou violar quaisquer medidas de seguran&ccedil;a ou
						autentica&ccedil;&atilde;o; (5) contornar qualquer medida
						tecnol&oacute;gica implementada pela UELF ou qualquer um dos provedores da
						UELF ou qualquer outro terceiro (incluindo outro Usu&aacute;rio) para
						proteger os Servi&ccedil;os da UELF; (6) acessar os Servi&ccedil;os ou
						Conte&uacute;do do UELF por meio do uso de qualquer mecanismo que
						n&atilde;o seja o uso de um dos Servi&ccedil;os do UELF, incluindo nosso
						Site e Aplicativo M&oacute;vel, exceto materiais de terceiros que sejam
						foco de nossa curadoria educacional e que estejam dispon&iacute;veis
						livremente na internet; ou (7) modificar, descompilar, desmontar, fazer
						engenharia reversa, adulterar ou tentar derivar o c&oacute;digo-fonte de
						qualquer software que a UELF forne&ccedil;a a voc&ecirc; ou qualquer outra
						parte dos Servi&ccedil;os UELF.
					</p>
					<p>
						<br />
						13. VOC&Ecirc; ASSUME O RISCO DE USAR NOSSOS SERVI&Ccedil;OS
						<br />
						1. Se voc&ecirc; confiar em quaisquer Servi&ccedil;os ou Conte&uacute;dos
						UELF ou dados neles contidos, voc&ecirc; o far&aacute; exclusivamente por
						sua conta e risco.
					</p>
					<p>
						<br />
						2. O objetivo da UELF &eacute; fornecer informa&ccedil;&otilde;es
						&uacute;teis e precisas sobre os Servi&ccedil;os UELF, mas n&atilde;o
						fazemos endosso, representa&ccedil;&atilde;o ou garantia de qualquer tipo
						sobre qualquer Conte&uacute;do, informa&ccedil;&atilde;o, dados ou
						Servi&ccedil;os UELF. A precis&atilde;o dos dados coletados e apresentados
						por meio dos Servi&ccedil;os UELF n&atilde;o &eacute; garantida e
						voc&ecirc; entende que quaisquer dados fornecidos a voc&ecirc; por meio de
						nossos Servi&ccedil;os podem n&atilde;o ser precisos ou n&atilde;o serem
						respaldados por pesquisas cient&iacute;ficas, mesmo que sejam ideias
						oriundas da experi&ecirc;ncia de vida de pessoas que possuem bestsellers
						publicados, artigos ou utilizam sua experi&ecirc;ncia em forma de
						consultoria em workshops e outros.
					</p>
					<p>
						<br />
						3. N&atilde;o somos respons&aacute;veis ​​pela precis&atilde;o,
						confiabilidade, disponibilidade, efic&aacute;cia ou uso correto das
						informa&ccedil;&otilde;es que voc&ecirc; recebe por meio dos
						Servi&ccedil;os UELF. O Conte&uacute;do do Usu&aacute;rio e os
						Servi&ccedil;os podem mudar de tempos em tempos ou variar de acordo com a
						localiza&ccedil;&atilde;o geogr&aacute;fica. O uso dos Servi&ccedil;os
						UELF n&atilde;o deve substituir seu bom senso e bom uso.
					</p>
					<p>
						<br />
						4. A UELF SE ISENTA EXPRESSAMENTE DE TODA E QUALQUER RESPONSABILIDADE QUE
						POSSA DECORRER DE OU EM CONEX&Atilde;O COM SEU USO DOS SERVI&Ccedil;OS DA
						UELF E PARTICIPA&Ccedil;&Atilde;O EM QUAISQUER OUTRAS ATIVIDADES
						OFERECIDAS NOS SERVI&Ccedil;OS, INCLUINDO, SEM LIMITA&Ccedil;&Atilde;O,
						SUA PARTICIPA&Ccedil;&Atilde;O EM QUALQUER F&Oacute;RUM P&Uacute;BLICO,
						REDES SOCIAIS OU SUAS INTERA&Ccedil;&Otilde;ES COM QUAISQUER AFILIADAS,
						TREINADORES, CELEBRIDADES, OUTROS USU&Aacute;RIOS OU OUTROS TERCEIROS.
						VOC&Ecirc; ASSUME TODO O RISCO CONSCIENTE E LIVREMENTE AO USAR OS
						SERVI&Ccedil;OS.
					</p>
					<p>
						<br />
						5. VOC&Ecirc; &Eacute; O &Uacute;NICO RESPONS&Aacute;VEL POR SUAS
						INTERA&Ccedil;&Otilde;ES COM OUTROS USU&Aacute;RIOS. VOC&Ecirc; RECONHECE
						E ENTENDE QUE A UELF N&Atilde;O EST&Aacute; OBRIGADA DE QUALQUER FORMA A
						(A) RASTREAR SEUS USU&Aacute;RIOS, (B) INFORMAR OS HIST&Oacute;RICOS DE
						SEUS USU&Aacute;RIOS, OU (C) REVISAR OU VERIFICAR AS
						DECLARA&Ccedil;&Otilde;ES DE SEUS USU&Aacute;RIOS, INCLUINDO, SEM
						LIMITA&Ccedil;&Atilde;O, INFORMA&Ccedil;&Otilde;ES OU
						REPRESENTA&Ccedil;&Otilde;ES CONTIDOS EM PERFIS P&Uacute;BLICOS.
						VOC&Ecirc; CONCORDA EM EXERCER PRECAU&Ccedil;&Otilde;ES RAZO&Aacute;VEIS
						EM TODAS AS INTERA&Ccedil;&Otilde;ES COM OUTROS USU&Aacute;RIOS,
						PARTICULARMENTE SE DECIDIR ENCONTRAR-SE PESSOALMENTE COM OUTRO
						USU&Aacute;RIO QUE N&Atilde;O SEJA RECOMENDADO, DE QUALQUER FORMA, PELA
						UELF. A UELF N&Atilde;O REPRESENTA, GARANTE, ENDOSSA OU GARANTE A CONDUTA
						DE SEUS MEMBROS OU SUA COMPATIBILIDADE COM VOC&Ecirc;.
					</p>
					<p>
						<br />
						12. DIRETRIZES DE CONDUTA DO USU&Aacute;RIO
					</p>
					<p>
						<br />
						6. Voc&ecirc; concorda que n&atilde;o ir&aacute; (e/ou n&atilde;o
						usar&aacute; o Site, ou o Aplicativo M&oacute;vel e/ou qualquer parte de
						nossos Servi&ccedil;os para) carregar, postar, enviar por e-mail ou
						transmitir qualquer Conte&uacute;do ou outros materiais que:
					</p>
					<p>
						<br />
						1. sejam ilegais, prejudiciais, amea&ccedil;adores, abusivos, assediantes,
						inflamat&oacute;rios, injuriosos, difamat&oacute;rios, vulgares,
						caluniosos, caluniosos, discriminat&oacute;rios, invasivos da privacidade
						de terceiros, odiosos ou censur&aacute;veis ​​em termos raciais,
						&eacute;tnicos ou de outra forma (a nosso exclusivo crit&eacute;rio);
						<br />
						2. contenham conte&uacute;do adulto, incluindo termos obscenos,
						pornogr&aacute;ficos e/ou sexuais, descri&ccedil;&otilde;es e/ou imagens,
						nudez, palavr&otilde;es ou viol&ecirc;ncia gr&aacute;fica;
						<br />
						3. incitem ou encorajar atividades criminosas ou terroristas ou danos
						f&iacute;sicos contra outrem;
						<br />
						4. explorem agendas pol&iacute;ticas para uso comercial;
						<br />
						5. ou que contenham discurso de &oacute;dio baseado em ra&ccedil;a, sexo,
						nacionalidade original, afilia&ccedil;&atilde;o religiosa, estado civil,
						orienta&ccedil;&atilde;o sexual, identidade de g&ecirc;nero ou idioma de
						um indiv&iacute;duo ou grupo;
						<br />
						6. n&atilde;o s&atilde;o seus;
						<br />
						7. voc&ecirc; n&atilde;o tem o direito de fazer upload, postar, enviar
						e-mail ou transmitir sob qualquer lei ou sob rela&ccedil;&otilde;es
						contratuais ou fiduci&aacute;rias (como informa&ccedil;&otilde;es
						privilegiadas, informa&ccedil;&otilde;es propriet&aacute;rias e
						confidenciais aprendidas ou divulgadas como parte de
						rela&ccedil;&otilde;es de trabalho ou sob acordos de confidencialidade);
						<br />
						8. infrinjam qualquer patente, marca registrada, segredo comercial,
						direitos autorais, direito de privacidade ou publicidade ou outros
						direitos de propriedade de qualquer parte ou que sejam enganosos,
						enganosos ou fraudulentos ou que possam ser ilegais ou dar origem a
						responsabilidade civil ou criminal;
						<br />
						9. sejam publicidade n&atilde;o autorizada, materiais promocionais,
						&ldquo;lixo eletr&ocirc;nico&rdquo;, &ldquo;spam&rdquo;,
						&ldquo;phishing&rdquo;, &ldquo;correntes&rdquo;, &ldquo;esquemas de
						pir&acirc;mide&rdquo; ou qualquer outra forma de
						solicita&ccedil;&atilde;o, opini&otilde;es ou avisos, comerciais ou
						outros;
						<br />
						10. contenham v&iacute;rus de software, spyware, adware, worms ou qualquer
						outro malware de computador ou c&oacute;digo malicioso, arquivos ou
						programas projetados para interromper, destruir ou limitar a
						funcionalidade de qualquer software ou hardware de computador ou
						equipamento de telecomunica&ccedil;&otilde;es;
						<br />
						11. prejudiquem menores de qualquer forma, ou solicitar ou tentar obter
						qualquer informa&ccedil;&atilde;o de um menor;
						<br />
						12. anunciem ou solicitem fundos ou constituam uma
						solicita&ccedil;&atilde;o de bens ou servi&ccedil;os;
						<br />
						13. Personifiquem qualquer pessoa ou entidade, incluindo, mas n&atilde;o
						limitado a qualquer Usu&aacute;rio dos Servi&ccedil;os, um diretor,
						executivo, funcion&aacute;rio, acionista, agente ou representante da UELF,
						nossas Afiliadas, Parceiros Terceiros ou qualquer outra pessoa ou
						entidade, ou declara&ccedil;&otilde;es falsas ou de outra forma deturpa
						sua afilia&ccedil;&atilde;o com a UELF, nossos Afiliados, Parceiros
						Terceiros ou qualquer outra pessoa ou entidade; ou
						<br />
						14. Forne&ccedil;am informa&ccedil;&otilde;es falsas, enganosas ou
						imprecisas ao UELF ou a quaisquer outros Usu&aacute;rios.
					</p>
					<p>
						<br />
						7. Voc&ecirc; tamb&eacute;m est&aacute; impedido de (a) criar mais de um
						perfil p&uacute;blico exclusivo ou registrar-se em mais de uma conta,
						exceto conforme especificamente permitido nestes Termos e
						Condi&ccedil;&otilde;es, (b) criar uma conta em nome de outra pessoa ou
						criar uma conta falsa ou enganosa identidade nos Servi&ccedil;os; (c)
						forjar cabe&ccedil;alhos ou de outra forma manipular identificadores para
						disfar&ccedil;ar a origem de qualquer Conte&uacute;do ou outros materiais
						transmitidos para ou atrav&eacute;s dos Servi&ccedil;os; (d) tentar
						sondar, escanear ou testar a vulnerabilidade dos Servi&ccedil;os ou
						qualquer sistema ou rede associada, ou violar medidas de seguran&ccedil;a
						ou autentica&ccedil;&atilde;o sem a devida autoriza&ccedil;&atilde;o; (e)
						usar ou afetar os Servi&ccedil;os de qualquer maneira que possa danificar,
						desativar, sobrecarregar ou prejudicar os Servi&ccedil;os ou sua
						funcionalidade, ou interromper o fluxo normal de di&aacute;logo
						(incluindo, sem limita&ccedil;&atilde;o, &ldquo;inunda&ccedil;&otilde;es,
						&rdquo; &ldquo;mail bombing&rdquo; ou &ldquo;crashing&rdquo;), ou agir de
						outra forma que afete negativamente ou diminua a qualidade da
						experi&ecirc;ncia de outro usu&aacute;rio dos Servi&ccedil;os; (f)
						interferir ou interromper os Servi&ccedil;os ou servidores ou redes
						conectadas aos Servi&ccedil;os, ou desobedecer a quaisquer requisitos,
						procedimentos, pol&iacute;ticas ou regulamentos de redes conectadas aos
						Servi&ccedil;os; (g) viola&ccedil;&atilde;o intencional ou n&atilde;o
						intencional de qualquer lei local, estadual, nacional ou internacional
						aplic&aacute;vel, incluindo, mas n&atilde;o limitado a, quaisquer
						regulamentos com for&ccedil;a de lei; (h) perseguir ou assediar outro
						Usu&aacute;rio ou funcion&aacute;rio dos Servi&ccedil;os; (i) solicitar,
						coletar ou postar dados pessoais ou tentar solicitar, coletar ou postar
						dados pessoais sobre outros Usu&aacute;rios dos Servi&ccedil;os, incluindo
						nomes de usu&aacute;rio ou senhas; (j) acessar ou tentar acessar a conta
						de outro Usu&aacute;rio sem o seu consentimento; (k) engenharia reversa,
						desmontagem ou descompila&ccedil;&atilde;o dos Aplicativos M&oacute;veis,
						ou tentativa de descobrir ou recriar o c&oacute;digo-fonte dos Aplicativos
						M&oacute;veis ou qualquer parte dos Servi&ccedil;os; ou (l) ceder,
						transferir ou sublicenciar seus direitos como Usu&aacute;rio registrado
						dos Servi&ccedil;os.
					</p>
					<p>
						<br />
						8. Seu privil&eacute;gio de usar os Servi&ccedil;os (incluindo sua
						capacidade de contribuir para discuss&otilde;es no F&oacute;rum
						P&uacute;blico ou se comunicar com treinadores ou celebridades) depende de
						sua conformidade com as diretrizes de conduta estabelecidas acima. Podemos
						revogar seus privil&eacute;gios de usar todos ou parte dos Servi&ccedil;os
						e/ou tomar quaisquer outras medidas apropriadas para fazer cumprir essas
						diretrizes de conduta se as viola&ccedil;&otilde;es forem levadas ao nosso
						conhecimento. Al&eacute;m disso, se voc&ecirc; n&atilde;o cumprir nossas
						diretrizes de conduta, ou qualquer parte destes Termos e
						Condi&ccedil;&otilde;es, podemos encerrar, a nosso exclusivo
						crit&eacute;rio, seu uso ou participa&ccedil;&atilde;o em qualquer
						F&oacute;rum P&uacute;blico ou Servi&ccedil;os. Qualquer
						viola&ccedil;&atilde;o desta se&ccedil;&atilde;o pode sujeit&aacute;-lo a
						responsabilidade civil e/ou criminal.
					</p>
					<p>
						<br />
						9. VOC&Ecirc; CONCORDA E ENTENDE QUE PODE SER LEGALMENTE
						RESPONS&Aacute;VEL POR DANOS SOFRIDOS POR OUTROS MEMBROS OU TERCEIROS COMO
						RESULTADO DE SUAS OBSERVA&Ccedil;&Otilde;ES, INFORMA&Ccedil;&Otilde;ES,
						FEEDBACK OU OUTRO CONTE&Uacute;DO PUBLICADO OU DISPONIBILIZADO NOS
						SERVI&Ccedil;OS (INCLUINDO QUALQUER F&Oacute;RUM P&Uacute;BLICO) QUE SEJA
						CONSIDERADO DIFAMAT&Oacute;RIO OU DE OUTRA FORMA LEGALMENTE
						ACION&Aacute;VEL.
					</p>
					<p>
						<br />
						13. ISEN&Ccedil;&Otilde;ES DE GARANTIA
					</p>
					<p>
						<br />
						10. A UELF, EM NOME DE SI E DE SUAS AFILIADAS E QUALQUER DE SEUS OU SEUS
						RESPECTIVOS PARCEIROS TERCEIROS, PRESTADORES DE SERVI&Ccedil;OS,
						LICENCIADORES E FORNECEDORES (E SEUS DIRETORES, DIRETORES,
						FUNCION&Aacute;RIOS, ADVOGADOS E AGENTES), POR MEIO DESTE, REJEITA TODAS
						AS GARANTIAS. OS PRODUTOS, OFERTAS, CONTE&Uacute;DOS E MATERIAIS
						FORNECIDOS NOS SERVI&Ccedil;OS S&Atilde;O FORNECIDOS &ldquo;COMO
						EST&Atilde;O&rdquo; E SEM GARANTIAS DE QUALQUER TIPO, EXPRESSAS OU
						IMPL&Iacute;CITAS. NA M&Aacute;XIMA EXTENS&Atilde;O PERMITIDA POR LEI, A
						UELF, EM NOME DE SI MESMO, E SUAS AFILIADAS E QUALQUER DE SEUS OU SEUS
						RESPECTIVOS PARCEIROS TERCEIROS, PRESTADORES DE SERVI&Ccedil;OS,
						LICENCIADORES E FORNECEDORES, ISENTAM-SE DE TODAS AS GARANTIAS, EXPRESSAS
						OU IMPL&Iacute;CITAS, INCLUINDO, MAS N&Atilde;O SE LIMITANDO A GARANTIAS
						IMPL&Iacute;CITAS DE COMERCIALIZA&Ccedil;&Atilde;O E
						ADEQUA&Ccedil;&Atilde;O A UM DETERMINADO FIM, T&Iacute;TULO,
						COMPATIBILIDADE, SEGURAN&Ccedil;A, PRECIS&Atilde;O OU N&Atilde;O
						VIOLA&Ccedil;&Atilde;O. NEM A UELF, NENHUMA DE SUAS AFILIADAS.
					</p>
					<p>
						<br />
						11. QUALQUER PRODUTO, OFERTA, CONTE&Uacute;DO E MATERIAL BAIXADO OU DE
						OUTRA FORMA OBTIDO ATRAV&Eacute;S DO USO DOS SERVI&Ccedil;OS &Eacute;
						FEITO POR SUA CONTA E RISCO E VOC&Ecirc; SER&Aacute; O &Uacute;NICO
						RESPONS&Aacute;VEL POR QUALQUER DANO AO SEU SISTEMA DE COMPUTADOR OU
						DISPOSITIVOS OU PERDA DE DADOS RESULTANTE DO DOWNLOAD DE QUALQUER TAL
						PRODUTO, OFERTA, CONTE&Uacute;DO OU MATERIAL.
					</p>
					<p>
						<br />
						12. NEM A UELF, QUALQUER UM DE NOSSOS AFILIADOS, NEM QUALQUER UM DE NOSSOS
						OU SEUS RESPECTIVOS LICENCIADORES, LICENCIADOS, CLIENTES COMERCIAIS,
						PRESTADORES DE SERVI&Ccedil;OS OU FORNECEDORES (E SEUS DIRETORES,
						DIRETORES, FUNCION&Aacute;RIOS, ADVOGADOS E AGENTES) GARANTEM OU FAZEM
						QUALQUER REPRESENTA&Ccedil;&Atilde;O COM RELA&Ccedil;&Atilde;O AO USO OU O
						RESULTADOS DO USO DOS PRODUTOS, OFERTAS, CONTE&Uacute;DOS E MATERIAIS NOS
						SERVI&Ccedil;OS EM TERMOS DE SUA CORRE&Ccedil;&Atilde;O, PRECIS&Atilde;O,
						CONFIABILIDADE OU OUTROS.
					</p>
					<p>
						<br />
						13. NENHUM CONSELHO OU INFORMA&Ccedil;&Atilde;O, OBTIDOS POR VOC&Ecirc; DE
						NOSSO PESSOAL OU ATRAV&Eacute;S DOS SERVI&Ccedil;OS, CRIAR&Aacute;
						QUALQUER GARANTIA N&Atilde;O EXPRESSAMENTE PREVISTA NESTES TERMOS E
						CONDI&Ccedil;&Otilde;ES.
					</p>
					<p>
						<br />
						15. SERVI&Ccedil;OS DE TERCEIROS
					</p>
					<p>
						<br />
						1. Os Servi&ccedil;os da UELF podem exibir ou permitir links ou outro
						acesso ou uso de conte&uacute;do, promo&ccedil;&otilde;es, sites,
						aplicativos, servi&ccedil;os e recursos de terceiros (coletivamente
						&ldquo;Servi&ccedil;os de Terceiros&rdquo;) que n&atilde;o est&atilde;o
						sob o controle da UELF. Isso pode incluir a oportunidade de vincular sua
						conta UELF a Servi&ccedil;os de terceiros. Fornecemos esses links apenas
						por conveni&ecirc;ncia e n&atilde;o somos respons&aacute;veis ​​pelos
						produtos, servi&ccedil;os ou outros conte&uacute;dos dispon&iacute;veis
						nos Servi&ccedil;os de Terceiros. Reservamo-nos o direito de usar o link
						de afiliado de um fornecedor terceirizado e receber uma
						compensa&ccedil;&atilde;o do fornecedor terceirizado quando voc&ecirc;
						compra o servi&ccedil;o ou produto de um terceiro usando o link do
						afiliado. Voc&ecirc; reconhece que quaisquer Servi&ccedil;os de Terceiros
						que voc&ecirc; usa em conex&atilde;o com os Servi&ccedil;os UELF
						n&atilde;o fazem parte dos Servi&ccedil;os UELF e n&atilde;o s&atilde;o
						controlados por UELF, e voc&ecirc; assume total responsabilidade e assume
						todos os riscos decorrentes de sua intera&ccedil;&atilde;o ou uso de
						qualquer Terceiro -Servi&ccedil;os de festas. Voc&ecirc; tamb&eacute;m
						reconhece que estes Termos e Condi&ccedil;&otilde;es e a Pol&iacute;tica
						de Privacidade n&atilde;o se aplicam a quaisquer Servi&ccedil;os de
						Terceiros. Voc&ecirc; &eacute; respons&aacute;vel por ler e entender os
						termos e condi&ccedil;&otilde;es e a pol&iacute;tica de privacidade que se
						aplica ao seu uso de quaisquer Servi&ccedil;os de Terceiros. Quaisquer
						compras que voc&ecirc; fizer por meio de sites de terceiros ser&atilde;o
						feitas por meio de outros sites e de outras empresas, e n&atilde;o
						assumimos nenhuma responsabilidade em rela&ccedil;&atilde;o a essas
						compras que s&atilde;o exclusivamente entre voc&ecirc; e o terceiro
						aplic&aacute;vel.
					</p>
					<p>
						<br />
						16. POL&Iacute;TICA DE DIREITOS AUTORAIS
					</p>
					<p>
						<br />
						1. Respeitamos os direitos de propriedade intelectual de terceiros e
						esperamos que nossos usu&aacute;rios fa&ccedil;am o mesmo. &Eacute; nossa
						pol&iacute;tica, em circunst&acirc;ncias apropriadas e a nosso
						crit&eacute;rio, desativar e/ou encerrar as contas de usu&aacute;rios que
						infringem repetidamente os direitos autorais de terceiros. Se voc&ecirc;
						&eacute; propriet&aacute;rio de direitos autorais, ou est&aacute;
						autorizado a agir em nome de um, e acredita que materiais no site da UELF
						ou atividades em seus servi&ccedil;os infringem seus direitos em seu
						trabalho protegido por direitos autorais, envie-nos uma
						notifica&ccedil;&atilde;o apropriada de viola&ccedil;&atilde;o de direitos
						autorais em:
					</p>
					<p>
						<br />
						contato@uelf.com.br
						<br />
						ou no seguinte endere&ccedil;o postal:
						<br />
						Zeneconomics
						<br />
						Rua Tenente Gomes Ribeiro, 57, cj 115 Bairro: Vila Clementino
						<br />
						Cidade: S&atilde;o Paulo Estado: SP Cep: 04038-040
					</p>
					<p>
						<br />
						2. Antes de enviar um aviso de viola&ccedil;&atilde;o de direitos
						autorais, observe que isso pode ter s&eacute;rias consequ&ecirc;ncias
						legais. Certifique-se de considerar se a doutrina do &ldquo;uso
						justo&rdquo; ou outra limita&ccedil;&atilde;o semelhante dos direitos
						autorais se aplica &agrave;s suas circunst&acirc;ncias.
					</p>
					<p>
						<br />
						17. ISEN&Ccedil;&Atilde;O DE RESPONSABILIDADE M&Eacute;DICA E
						PSICOL&Oacute;GICA
						<br />
						1. Os Servi&ccedil;os disponibilizados pelo UELF podem exercer trazer
						benef&iacute;cios &agrave; sa&uacute;de f&iacute;sica e mental, mas
						n&atilde;o s&atilde;o uma pr&aacute;tica da medicina ou psicologia. O uso
						do UELF n&atilde;o estabelece uma rela&ccedil;&atilde;o cl&iacute;nica
						m&eacute;dico-paciente. Qualquer informa&ccedil;&atilde;o sobre
						sa&uacute;de &eacute; fornecida apenas por conveni&ecirc;ncia. Caso haja
						qualquer d&uacute;vida sobre a sua condi&ccedil;&atilde;o, de usar os
						servi&ccedil;os do UELF, consulte previamente o seu m&eacute;dico ou
						psic&oacute;logo.
					</p>
					<p>
						<br />
						18. LIMITA&Ccedil;&Atilde;O DE RESPONSABILIDADE
					</p>
					<p>
						<br />
						1. Voc&ecirc; &eacute; o &uacute;nico respons&aacute;vel por garantir que
						todas as informa&ccedil;&otilde;es fornecidas a voc&ecirc; sejam precisas,
						completas e &uacute;teis. As informa&ccedil;&otilde;es fornecidas a
						voc&ecirc; por meio dos Servi&ccedil;os n&atilde;o podem ser reproduzidas,
						duplicadas, copiadas, modificadas, vendidas, revendidas,
						distribu&iacute;das, visitadas ou exploradas para qualquer finalidade
						comercial sem o consentimento expresso por escrito da UELF.
					</p>
					<p>
						<br />
						2. VOC&Ecirc; COMPREENDE E CONCORDA EXPRESSAMENTE QUE EM NENHUM CASO A
						UELF, SUAS AFILIADAS OU QUALQUER UM DE SEUS OU SEUS RESPECTIVOS PARCEIROS
						TERCEIROS, PRESTADORES DE SERVI&Ccedil;OS, LICENCIADORES E FORNECEDORES (E
						SEUS DIRETORES, DIRETORES, FUNCION&Aacute;RIOS, ADVOGADOS E AGENTES)
						SER&Atilde;O RESPONS&Aacute;VEIS POR QUALQUER DANOS DIRETOS, INDIRETOS,
						INCIDENTAIS, ESPECIAIS, CONSEQUENCIAIS, EXEMPLARES OU PUNITIVOS, OU
						QUAISQUER OUTROS DANOS, INCLUINDO, MAS N&Atilde;O SE LIMITANDO A, DANOS
						POR LUCROS CESSANTES, MA F&Eacute;, USO, DADOS OU OUTRAS PERDAS
						INTANG&Iacute;VEIS (MESMO QUE SEJAMOS AVISADOS SOBRE A POSSIBILIDADE DE
						TAIS DANOS), DECORRENTES DE, OU DECORRENTES, (A) DO USO OU DA INCAPACIDADE
						DE USAR OS SERVI&Ccedil;OS; (B) O USO DE QUALQUER CONTE&Uacute;DO OU OUTRO
						MATERIAL NO SITE, NOSSOS APLICATIVOS OU APLICATIVOS VINCULADOS A NOSSOS
						APLICATIVOS, (C) O CUSTO DE AQUISI&Ccedil;&Atilde;O DE BENS E
						SERVI&Ccedil;OS SUBSTITUTOS RESULTANTES DE QUAISQUER BENS, DADOS,
						INFORMA&Ccedil;&Otilde;ES OU SERVI&Ccedil;OS ADQUIRIDOS OU OBTIDOS OU
						MENSAGENS RECEBIDAS OU TRANSA&Ccedil;&Otilde;ES REALIZADAS ATRAV&Eacute;S
						OU A PARTIR DOS SERVI&Ccedil;OS; (D) ACESSO N&Atilde;O AUTORIZADO OU
						ALTERA&Ccedil;&Atilde;O DE SUAS TRANSMISS&Otilde;ES OU DADOS; (E)
						DECLARA&Ccedil;&Otilde;ES OU CONDUTA DE TERCEIROS EM NOSSOS
						SERVI&Ccedil;OS; OU (F) QUALQUER OUTRO ASSUNTO RELACIONADO AOS
						SERVI&Ccedil;OS.
					</p>
					<p>&nbsp;</p>
					<p>
						<br />
						3. Limita&ccedil;&atilde;o de Danos Monet&aacute;rios. N&Atilde;O OBSTANTE
						QUALQUER DISPOSI&Ccedil;&Atilde;O CONTR&Aacute;RIA NESSE CONTRATO, A
						RESPONSABILIDADE TOTAL DA UELF POR TODO E QUALQUER DANO RELACIONADO AOS
						SERVI&Ccedil;OS PRESTADOS INDEPENDENTEMENTE DA NATUREZA DA
						A&Ccedil;&Atilde;O, N&Atilde;O EXCEDER&Aacute; OS VALORES PAGOS PELO
						CLIENTE &Agrave; UELF NO PER&Iacute;ODO DE 12 (DOZE) MESES PRECEDENTES
						&Agrave; REIVINDICA&Ccedil;&Atilde;O PELOS SERVI&Ccedil;OS OU APLICATIVO
						QUE DERAM CAUSA &Agrave; REIVINDICA&Ccedil;&Atilde;O. EM NENHUM CASO A
						UELF SER&Aacute; RESPONS&Aacute;VEL POR IMPOSTOS COLETADOS DE FORMA
						INSUFICIENTE PELO CLIENTE EM FUN&Ccedil;&Atilde;O DO USO DO APLICATIVO E
						DOS SERVI&Ccedil;OS. EM NENHUMA HIP&Oacute;TESE A UELF SER&Aacute;
						RESPONS&Aacute;VEL POR A&Ccedil;&Otilde;ES, PERDAS OU DANOS, INCLUINDO
						PENALIDADES E/OU JUROS, DEVIDO &Agrave; A&Ccedil;&Otilde;ES DE TERCEIROS
						QUE N&Atilde;O ESTEJAM SOB O CONTROLE DA UELF.
					</p>
					<p>
						<br />
						4. Aus&ecirc;ncia de Danos Indiretos. N&Atilde;O OBSTANTE QUALQUER
						DISPOSI&Ccedil;&Atilde;O EM CONTR&Aacute;RIO NESSE CONTRATO, A UELF
						N&Atilde;O SER&Aacute; RESPONS&Aacute;VEL POR QUAISQUER DANOS DE
						INCID&Ecirc;NCIA INDIRETA (INCLUINDO, MAS N&Atilde;O LIMITADO A, DANOS
						MORAIS, LUCROS CESSANTES, DANOS POR INTERRUP&Ccedil;&Atilde;O DE
						NEG&Oacute;CIOS, DADOS IMPRECISOS, PERDAS DE DADOS OU CUSTOS DE COBERTURA)
						QUE O USU&Aacute;RIO EVENTUALMENTE INCORRA OU EXPERIENCIE EM
						RELA&Ccedil;&Atilde;O AOS SERVI&Ccedil;OS, SENDO QUALQUER CAUSA E SOB A
						QUALQUER TEORIA DE RESPONSABILIDADE, MESMO QUE SEJA AVISADO DA
						POSSIBILIDADE DE TAIS DANOS.
					</p>
					<p>
						<br />
						19. INDENIZA&Ccedil;&Atilde;O
					</p>
					<p>
						<br />
						1. Sem limitar quaisquer disposi&ccedil;&otilde;es de
						indeniza&ccedil;&atilde;o destes Termos e Condi&ccedil;&otilde;es,
						voc&ecirc; concorda em indenizar, isentar de responsabilidade e, a nosso
						crit&eacute;rio, defender a UELF e nossas afiliadas, e nossos e seus
						respectivos executivos, diretores, membros, funcion&aacute;rios,
						acionistas, gerentes, agentes e representantes (coletivamente, "Partes
						Indenizadas") de todas e quaisquer reivindica&ccedil;&otilde;es,
						a&ccedil;&otilde;es, demandas, causas de a&ccedil;&atilde;o,
						responsabilidade, danos e/ou custos de terceiros (incluindo, mas
						n&atilde;o limitado a, honor&aacute;rios advocat&iacute;cios e despesas
						razo&aacute;veis) (coletivamente,
						&ldquo;Reivindica&ccedil;&otilde;es&rdquo;) decorrentes de (i) seu uso
						indevido dos Servi&ccedil;os ou de nossos produtos ou ofertas, (ii) sua
						viola&ccedil;&atilde;o destes Termos e Condi&ccedil;&otilde;es, (iii) sua
						provis&atilde;o para a UELF ou qualquer uma das Partes Indenizadas de UELF
						de informa&ccedil;&otilde;es ou outros dados, (iv) sua
						viola&ccedil;&atilde;o ou suposta viola&ccedil;&atilde;o, ou a
						viola&ccedil;&atilde;o ou uso (ou suposta viola&ccedil;&atilde;o ou
						suposto uso) por qualquer outro usu&aacute;rio de sua conta, de qualquer
						propriedade intelectual ou outro direito de qualquer pessoa ou entidade,
						ou (v) sua viola&ccedil;&atilde;o ou suposta viola&ccedil;&atilde;o de
						qualquer , lei ou regulamento estadual ou local.
					</p>
					<p>
						<br />
						2. As Partes Indenizadas ter&atilde;o o direito, mas n&atilde;o a
						obriga&ccedil;&atilde;o, de participar por meio de advogado de sua escolha
						em qualquer defesa por voc&ecirc; de qualquer reivindica&ccedil;&atilde;o
						sobre a qual voc&ecirc; seja obrigado a defender, indenizar ou isentar as
						Partes Indenizadas. Voc&ecirc; n&atilde;o pode resolver qualquer
						reivindica&ccedil;&atilde;o sem o consentimento pr&eacute;vio por escrito
						das Partes Indenizadas em quest&atilde;o.
					</p>
					<p>
						<br />
						20. ALTERA&Ccedil;&Otilde;ES NOS SERVI&Ccedil;OS UELF
					</p>
					<p>
						<br />
						1. A UELF pode alterar ou descontinuar, tempor&aacute;ria ou
						permanentemente, qualquer recurso, componente ou conte&uacute;do dos
						Servi&ccedil;os UELF a qualquer momento, sem aviso pr&eacute;vio. A UELF
						n&atilde;o &eacute; respons&aacute;vel perante voc&ecirc; ou terceiros por
						qualquer modifica&ccedil;&atilde;o, suspens&atilde;o ou
						descontinua&ccedil;&atilde;o de qualquer recurso, componente ou
						conte&uacute;do dos Servi&ccedil;os UELF. Reservamo-nos o direito de
						determinar o tempo e o conte&uacute;do das atualiza&ccedil;&otilde;es de
						software, que podem ser baixadas e instaladas automaticamente por
						n&oacute;s sem aviso pr&eacute;vio.
					</p>
					<p>
						<br />
						21. LEI APLIC&Aacute;VEL E LOCAL
					</p>
					<p>
						<br />
						1. Estes Termos e Condi&ccedil;&otilde;es ser&atilde;o regidos e
						interpretados de acordo com as leis Brasileiras. Voc&ecirc; concorda que
						qualquer a&ccedil;&atilde;o em lei ou em equidade decorrente ou
						relacionada ao seu uso dos Servi&ccedil;os ou destes Termos e
						Condi&ccedil;&otilde;es, ser&atilde;o resolvidos pelo Foro Central da
						Comarca de S&atilde;o Paulo, Estado de S&atilde;o Paulo.
					</p>
					<p>
						<br />
						22. DISPOSI&Ccedil;&Otilde;ES DE RESOLU&Ccedil;&Atilde;O DE DISPUTAS
					</p>
					<p>
						<br />
						1. Queremos resolver suas preocupa&ccedil;&otilde;es sem precisar de um
						caso legal formal. Antes de registrar uma reclama&ccedil;&atilde;o contra
						a UELF, voc&ecirc; concorda em tentar resolver a Disputa informalmente
						entrando em contato com contato@uelf.com.br. Voc&ecirc; concorda em nos
						dar 30 dias para resolver suas preocupa&ccedil;&otilde;es. Se uma disputa
						n&atilde;o for resolvida dentro de 90 dias ap&oacute;s o envio, voc&ecirc;
						poder&aacute; iniciar um processo judicial. Este processo interno de
						resolu&ccedil;&atilde;o de disputas &eacute; uma condi&ccedil;&atilde;o
						precedente para qualquer a&ccedil;&atilde;o legal.
					</p>
					<p>
						<br />
						2. Voc&ecirc; concorda que qualquer disputa entre voc&ecirc; e a UELF
						decorrente ou relacionada a estes Termos e Condi&ccedil;&otilde;es, os
						Servi&ccedil;os da UELF ou quaisquer outros produtos ou servi&ccedil;os da
						UELF (coletivamente, &ldquo;Disputas&rdquo;) ser&atilde;o regidas pelo
						procedimento de arbitragem descrito abaixo. Voc&ecirc; pode recusar este
						acordo de arbitragem entrando em contato com contato@uelf.com.br dentro de
						30 dias ap&oacute;s a primeira aceita&ccedil;&atilde;o destes Termos de
						Servi&ccedil;o e declarando que voc&ecirc; (inclua seu nome e sobrenome)
						recusa este acordo de arbitragem.
					</p>
					<p>
						<br />
						3. A UELF e suas afiliadas, executivos, diretores, funcion&aacute;rios,
						consultores, agentes e representantes n&atilde;o ter&atilde;o nenhuma
						obriga&ccedil;&atilde;o legal ou outra responsabilidade para com
						voc&ecirc; ou qualquer terceiro decorrente ou relacionado a qualquer
						rescis&atilde;o destes Termos e Condi&ccedil;&otilde;es. Se formos
						obrigados a aplicar ou defender estes Termos e Condi&ccedil;&otilde;es,
						teremos direito ao reembolso de todos os honor&aacute;rios
						advocat&iacute;cios e custos razo&aacute;veis ​​para defender ou fazer
						cumprir estes Termos e Condi&ccedil;&otilde;es.
					</p>
					<p>
						<br />
						4. Podemos entrar com uma a&ccedil;&atilde;o judicial apenas para medida
						cautelar para impedir o uso n&atilde;o autorizado ou abuso dos
						Servi&ccedil;os UELF, ou viola&ccedil;&atilde;o de direitos de propriedade
						intelectual (por exemplo, marca registrada, segredo comercial, direitos
						autorais ou direitos de patente) sem primeiro entrar em arbitragem ou
						disputa informal. processo de resolu&ccedil;&atilde;o descrito acima. Este
						direito &eacute; uma exce&ccedil;&atilde;o aos requisitos de arbitragem
						aqui contidos.
					</p>
					<p>
						<br />
						5. Voc&ecirc; s&oacute; pode resolver disputas com a UELF individualmente
						e n&atilde;o pode apresentar uma reclama&ccedil;&atilde;o como autor ou
						membro de classe em uma a&ccedil;&atilde;o coletiva, consolidada ou
						representativa. Arbitragens coletivas, a&ccedil;&otilde;es coletivas,
						a&ccedil;&otilde;es de advogados particulares e consolida&ccedil;&atilde;o
						com outras arbitragens n&atilde;o s&atilde;o permitidas em nosso contrato.
					</p>
					<p>
						<br />
						6. Independentemente de qualquer estatuto ou lei em contr&aacute;rio,
						qualquer reclama&ccedil;&atilde;o ou causa de a&ccedil;&atilde;o
						decorrente ou relacionada ao seu uso dos Servi&ccedil;os UELF deve ser
						apresentada dentro de um (1) ano ap&oacute;s tal reclama&ccedil;&atilde;o
						ou causa de a&ccedil;&atilde;o surgir, ou ent&atilde;o essa
						reclama&ccedil;&atilde;o ou causa de a&ccedil;&atilde;o ser&aacute;
						barrada para sempre.
					</p>
					<p>
						<br />
						23. PRAZO E RESCIS&Atilde;O
					</p>
					<p>
						<br />
						1. Estes Termos e Condi&ccedil;&otilde;es permanecer&atilde;o em pleno
						vigor e efeito enquanto voc&ecirc; usar os Servi&ccedil;os UELF (incluindo
						nosso Site e Aplicativos M&oacute;veis). Podemos encerrar seu uso ou
						acesso ao Servi&ccedil;o de acordo com estes Termos e
						Condi&ccedil;&otilde;es. A rescis&atilde;o destes Termos e
						Condi&ccedil;&otilde;es rescindir&aacute; automaticamente todos os
						direitos e licen&ccedil;as concedidos a voc&ecirc; sob estes Termos e
						Condi&ccedil;&otilde;es, incluindo todos os direitos de uso dos
						Servi&ccedil;os UELF, exceto que todos os termos que por sua natureza
						possam sobreviver &agrave; rescis&atilde;o ser&atilde;o considerados como
						sobreviventes a tal rescis&atilde;o (incluindo, sem
						limita&ccedil;&atilde;o, propriedade intelectual, isen&ccedil;&otilde;es
						de responsabilidade, limita&ccedil;&otilde;es de responsabilidade,
						licen&ccedil;a de conte&uacute;do, lei aplic&aacute;vel e local).
						Ap&oacute;s a rescis&atilde;o, a UELF reserva-se o direito de exercer
						quaisquer meios que considere necess&aacute;rios para impedir o uso
						n&atilde;o autorizado dos Servi&ccedil;os.
					</p>
					<p>
						<br />
						24. POL&Iacute;TICA DE PRIVACIDADE
					</p>
					<p>
						<br />
						1. Os dados de registro de cada membro e v&aacute;rias outras
						informa&ccedil;&otilde;es pessoais s&atilde;o estritamente protegidos pela
						Pol&iacute;tica de Privacidade Online da UELF Corporation (consulte a
						Pol&iacute;tica de Privacidade completa em https://UELF.com/privacy-policy
						). Como um usu&aacute;rio UELF, voc&ecirc; concorda com a Pol&iacute;tica
						de Privacidade UELF incluindo e n&atilde;o limitado &agrave; coleta e uso
						das informa&ccedil;&otilde;es fornecidas, incluindo a transfer&ecirc;ncia
						de informa&ccedil;&otilde;es dentro dos Estados Unidos e/ou outros
						pa&iacute;ses para armazenamento, processamento ou uso pela UELF
						Corporation e/ou nossas subsidi&aacute;rias, parceiros e/ou afiliadas.
					</p>
					<p>
						<br />
						25. DADOS DO USU&Aacute;RIO
					</p>
					<p>
						<br />
						1. Manteremos determinados dados que voc&ecirc; transmitir aos
						Servi&ccedil;os UELF com a finalidade de gerenciar o desempenho dos
						mesmos, bem como dados relacionados ao seu uso. Embora realizemos backups
						regulares de dados de rotina, voc&ecirc; &eacute; o &uacute;nico
						respons&aacute;vel por todos os dados que transmitir ou relacionados a
						qualquer atividade que tenha realizado usando os Servi&ccedil;os UELF.
					</p>
					<p>
						<br />
						25. COMUNICA&Ccedil;&Otilde;ES ELETR&Ocirc;NICAS, TRANSA&Ccedil;&Otilde;ES
						E ASSINATURAS
					</p>
					<p>
						<br />
						2. O uso dos Servi&ccedil;os UELF, incluindo, mas n&atilde;o limitado, a
						visitar nosso(s) site(s), usar nosso(s) aplicativo(s) m&oacute;vel(ais)
						enviando-nos e-mails e preencher formul&aacute;rios online constituem
						comunica&ccedil;&otilde;es eletr&ocirc;nicas. Voc&ecirc; concorda em
						receber comunica&ccedil;&otilde;es eletr&ocirc;nicas e concorda que todos
						os acordos, avisos, divulga&ccedil;&otilde;es e outras
						comunica&ccedil;&otilde;es que fornecemos a voc&ecirc; eletronicamente,
						por e-mail e nos Servi&ccedil;os UELF, atendem a qualquer requisito legal
						de que tal comunica&ccedil;&atilde;o seja por escrito. VOC&Ecirc; CONCORDA
						COM O USO DE ASSINATURAS ELETR&Ocirc;NICAS, CONTRATOS, ENCOMENDAS E OUTROS
						REGISTROS, E COM A ENTREGA ELETR&Ocirc;NICA DE NOTIFICA&Ccedil;&Otilde;ES,
						POL&Iacute;TICAS E REGISTROS DE TRANSA&Ccedil;&Otilde;ES INICIADAS OU
						CONCLU&Iacute;DAS POR N&Oacute;S OU VIA UELF Services.
					</p>
					<p>
						<br />
						26. TERMOS DIVERSOS
					</p>
					<p>
						<br />
						3. Se qualquer disposi&ccedil;&atilde;o destes termos for considerada por
						um tribunal de jurisdi&ccedil;&atilde;o competente como ilegal,
						inv&aacute;lida, nula ou anul&aacute;vel, ou por qualquer motivo
						inexequ&iacute;vel, essa disposi&ccedil;&atilde;o ser&aacute; considerada
						separ&aacute;vel destes termos e n&atilde;o afetar&aacute; a validade e
						exequibilidade de quaisquer disposi&ccedil;&otilde;es restantes. Estes
						Termos e Condi&ccedil;&otilde;es s&atilde;o o acordo integral entre
						voc&ecirc; e n&oacute;s em rela&ccedil;&atilde;o ao assunto aqui tratado.
						Podemos ceder nossos direitos e obriga&ccedil;&otilde;es sob estes Termos
						e Condi&ccedil;&otilde;es. Estes Termos e Condi&ccedil;&otilde;es
						reverter&atilde;o em benef&iacute;cio de nossos sucessores,
						cession&aacute;rios e licenciados. A falha de qualquer uma das partes em
						insistir ou impor o cumprimento estrito da outra parte em
						rela&ccedil;&atilde;o a qualquer disposi&ccedil;&atilde;o destes Termos e
						Condi&ccedil;&otilde;es, ou em exercer qualquer direito sob os Termos e
						Condi&ccedil;&otilde;es, n&atilde;o ser&aacute; interpretado como uma
						ren&uacute;ncia ou ren&uacute;ncia em qualquer extens&atilde;o do direito
						de tal parte de reivindicar ou confiar em qualquer
						disposi&ccedil;&atilde;o ou direito nessa ou em qualquer outra
						inst&acirc;ncia; em vez disso, o mesmo ser&aacute; e permanecer&aacute; em
						pleno vigor e efeito.
					</p>
					<p>
						<br />
						28. TERMOS ADICIONAIS PODEM SER APLICADOS
					</p>
					<p>
						<br />
						1. Termos adicionais podem ser aplicados a determinados produtos ou
						servi&ccedil;os inclu&iacute;dos nos Servi&ccedil;os UELF. No caso de
						haver um conflito entre estes Termos e Condi&ccedil;&otilde;es e quaisquer
						termos adicionais, os termos adicionais prevalecer&atilde;o.
					</p>
					<p>
						<br />
						28. VIOLA&Ccedil;&Otilde;ES
					</p>
					<p>
						<br />
						2. Por favor, reporte todas e quaisquer viola&ccedil;&otilde;es destes
						Termos e Condi&ccedil;&otilde;es &agrave; UELF da seguinte forma:
						<br />
						E-mail: contato@uelf.com.br
						<br />
						ZenEconomics
					</p>
				</div>
			</ModalBody>
			<ModalFooter>
				<Button type="button" onClick={toggle}>
					<FontAwesomeIcon icon={faXmark} />
					<span>Fechar</span>
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default MordalTerm;
