import * as yup from "yup";

import {
	validateCNPJ,
	validateCPF,
	validateCep,
	validateEmail,
	validatePhone,
} from "validations-br";

import differenceInYears from "date-fns/differenceInYears";

export const schema = yup.object().shape({
	nomeRazao: yup.string().required().min(3).max(100),
	email: yup
		.string()
		.required()
		.max(150)
		.test("email", "Email é inválido", (value: string | undefined) =>
			validateEmail(value || ""),
		),
	senha: yup
		.string()
		.required()
		.matches(
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
			"Senha não atinge os requisitos mínimos",
		)
		.min(8)
		.max(50),
	repeatPass: yup
		.string()
		.oneOf([yup.ref("senha"), null], "As senhas não conferem"),
	celular: yup
		.string()
		.test("phone", "Celular/Whatsapp é inválido", (value: string | undefined) =>
			validatePhone(value || "") || value === ""
		),
	imagem: yup
		.mixed()
		.notRequired()
		.test("size", "Tamanho máximo permitido 1mb.", (file: File[]) => {
			if (file) {
				const sizeInBytes = 1000000;
				// @ts-ignore
				return file[0]?.size < sizeInBytes;
			} else return true;
		}),
});
