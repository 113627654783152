import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "preact/compat";
import { useEffect, useState } from "react";
import { Card } from "reactstrap";
import CardSurveyAssessment from "../../components/CardSurveyAssessment/CardSurveyAssessment";
import PainelMasterPage from "../../components/PainelMasterPage/PainelMasterPage";
import { useStages } from "../../providers/StagesProvider";
import GoogleTagManager, { EventEnum } from "../../services/GoogleTagManager";
import "./SurveyAssessment.scss";

const SurveyAssessment = () => {
	const { currentStage } = useStages();

	useEffect(() => {
		GoogleTagManager.event(EventEnum.Marketing, { action: "conclusao_evitar_fazer" });
	}, []);

	return (
		<>
			<PainelMasterPage
				title={"Conclusão da sua avaliação"}
				subtitle={""}
				subtitleclass={""}
				icon={<FontAwesomeIcon icon={faCheck} />}
				onClick={() => {}}
			>
				<CardSurveyAssessment />
			</PainelMasterPage>
		</>
	);
};

export default SurveyAssessment;
