import "./ToDoAvoidStage.scss";

import ToDoAvoidSteps from "../ToDoAvoidSteps/ToDoAvoidSteps";
import { useActiveJourney } from "../../providers/ActiveJourneyProvider";
import { useEffect } from "react";
import { useStages } from "../../providers/StagesProvider";
import { useToDoAvoid } from "../../providers/ToDoAvoidProvider";

const ToDoAvoidStage = () => {
	const { currentStage } = useStages();
	const { activeJourney } = useActiveJourney();
	const { data, handleList } = useToDoAvoid();

	useEffect(() => {
		if (activeJourney) {
			handleList().then();
		}
	}, [activeJourney]);

	const renderStages = () => {
		if (data) {
			return <ToDoAvoidSteps steps={data} />;
		} else {
			return (
				<div className="step">
					<div>Algo deu errado.</div>
				</div>
			);
		}
	};

	const renderIndicators = () => {
		return (
			<>
				{data?.subrecursos?.map((item: any, key: number) => (
					<div
						style={{
							backgroundColor: item.corFundo,
							color: item.corTexto,
						}}
						className={`stage-indicator ${currentStage === key ? "is-active" : ""}`}
						key={key}
					>
						<span>{item.descricao}</span>
					</div>
				))}
			</>
		);
	};

	return (
		<div className="to-do-avoid-wrapper">
			{data && <div className="stages-container">{renderIndicators()}</div>}
			{data && <div className="content-container">{renderStages()}</div>}
		</div>
	);
};

export default ToDoAvoidStage;
