import api from "./api";

class ProgressTrackerService {
	async list() {
		try {
			const response = await api.get(`app/plano/performance/roteirosucesso`);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}

	async listStatus() {
		try {
			const response = await api.get(`app/status/roteiro/sucesso`);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}

	async listStatusProgresso() {
		try {
			const response = await api.get(`app/status/rastreamento/progresso`);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}

	async updateProgresso(id: number, idMetasSecundarias: number, status: number) {
		try {
			const response = await api.post(`app/metas/terciarias/update/status`, {
				id,
				idMetasSecundarias,
				status,
			});

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}

	async updateProgressoUelf(id: number, idObjetivoUelf: number, status: number) {
		try {
			const response = await api.post(`app/subobjetivo/uelf/update/status`, {
				id,
				idObjetivoUelf,
				status,
			});

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}

	async updateStatus(id: number, status: number) {
		try {
			const response = await api.post(`app/metas/secundarias/update/status`, {
				id,
				status,
			});

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}

	async updateStatusUelf(id: number, status: number) {
		try {
			const response = await api.post(`app/objetivo/uelf/update/status`, {
				id,
				status,
			});

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}
}

export default ProgressTrackerService;
