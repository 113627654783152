export const customStyles = {
	control: (_: any, { selectProps: { width } }: any) => ({
		display: "flex",
		width: width,
		padding: "0 .375em 0 1rem",
	}),
	placeholder: (defaultStyles: any) => ({
		...defaultStyles,
		marginLeft: 0,
		padding: ".625rem .75rem",
		color: "var(--text)",
		fontWeight: 400,
		fontSize: "1rem",
	}),
	singleValue: (provided: any) => {
		return {
			...provided,
			marginLeft: 0,
			color: "var(--text)",
			fontWeight: 400,
			fontSize: "1rem",
		};
	},
	menu: (base: any) => ({
		...base,
		// override border radius to match the box
		borderRadius: 0,
		// kill the gap
		marginTop: 0,
		background: "var(--c3)",
		zIndex: 9999,
	}),
	option: (styles: any, { isFocused, isSelected }: any) => ({
		...styles,
		background: isFocused || isSelected ? "var(--c1)" : undefined,
		color: isFocused || isSelected ? "var(--c2)" : undefined,
	}),
	menuList: (styles: any) => ({
		...styles,
		background: "var(--c3)",
	}),
};

export const customLoginStyles = {
	control: (_: any, { selectProps: { width } }: any) => ({
		display: "flex",
		width: width,
		padding: "0 .375em 0 1rem",
	}),
	placeholder: (defaultStyles: any) => ({
		...defaultStyles,
		marginLeft: 0,
		padding: ".625rem .75rem",
		color: "var(--text)",
		fontWeight: 400,
		fontSize: "1rem",
	}),
	singleValue: (provided: any) => {
		return {
			...provided,
			marginLeft: 0,
			color: "var(--text)",
			fontWeight: 500,
			fontSize: "1rem",
		};
	},
	menu: (base: any) => ({
		...base,
		// override border radius to match the box
		borderRadius: 0,
		// kill the gap
		marginTop: 0,
		background: "var(--c3)",
		zIndex: 9999,
	}),
	option: (styles: any, { isFocused, isSelected }: any) => ({
		...styles,
		background: isFocused || isSelected ? "var(--c1)" : undefined,
		color: isFocused || isSelected ? "var(--c2)" : undefined,
	}),
	menuList: (styles: any) => ({
		...styles,
		background: "var(--c3)",
	}),
};

export const customModalStyles = {
	control: (_: any, { selectProps: { width } }: any) => ({
		display: "flex",
		width: width,
	}),
	placeholder: (defaultStyles: any) => ({
		...defaultStyles,
		marginLeft: ".625rem",
		color: "#fff",
		fontSize: "1rem",
	}),
	singleValue: (provided: any) => {
		return {
			...provided,
			marginLeft: ".625rem",
			color: "var(--text)",
			fontSize: "1rem",
		};
	},
	menu: (base: any) => ({
		...base,
		// kill the gap
		marginTop: 0,
		// override border radius to match the box
		borderRadius: 0,
		background: "var(--c1)",
		zIndex: "999 !important",
		boxShadow: "var(--shadow-elevation-medium)",
		fontSize: "1rem",
	}),
	option: (styles: any, { isFocused, isSelected }: any) => ({
		...styles,
		fontSize: "118",
		color: "var(--text)",
		background: isFocused || isSelected ? "var(--c1)" : undefined,
	}),
	menuList: (styles: any) => ({
		...styles,
		background: "var(--c3)",
	}),
};
