import "./MacroGoals.scss";

import {
	faCrosshairs,
	faFileCircleMinus,
	faFileCirclePlus,
	faMinusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";

import { Button } from "reactstrap";
import ButtonNextScreen from "../../components/ButtonNextScreen/ButtonNextScreen";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loading from "../../components/Loading/Loading";
import MacroGoalsAddModal from "../../components/MacroGoalsAddModal/MacroGoalsAddModal";
import MacroGoalsConfirmModal from "../../components/MacroGoalsConfirmModal/MacroGoalsConfirmModal";
import MacroGoalsEditModal from "../../components/MacroGoalsEditModal/MacroGoalsEditModal";
import MacroGoalsItem from "../../components/MacroGoalsItem/MacroGoalsItem";
import MacroGoalsService from "../../services/MacroGoalsService";
import PainelMasterPage from "../../components/PainelMasterPage/PainelMasterPage";
import { ToastContainer } from "react-toastify";
import { toastSettings } from "../../models/toastConfig";
import { useActiveJourney } from "../../providers/ActiveJourneyProvider";
import { useDashboard } from "../../providers/DashboardProvider";
import { useMacroGoals } from "../../providers/MacroGoalsProvider";
import { useNavigate } from "react-router-dom";

export interface GoalState {
	id: number;
	isSelectedEdit: boolean;
	isSelectedDelete: boolean;
}

export interface Goal {
	id: number;
	idRecurso: number;
	descricaoRecurso: string;
	idUsuario: number;
	pergunta: string;
	resposta: string;
	prioridade: string;
	status: number;
	ativo: number;
	dataInicial: string;
	dataFinal: string;
	iconeRecurso: string;
}

export interface deleteData {
	metas: Goals[];
}

export interface Goals {
	id: number;
}

const MacroGoals = () => {
	const [data, setData] = useState<any>({});

	const {
		goals,
		setGoals,
		viewDelete,
		setViewDelete,
		wasConfirmDeletion,
		setWasConfirmDeletion,
		editGoal,
		setEditGoal,
		isMultipleSelected,
	} = useMacroGoals();
	const { theme } = useDashboard();

	const [showModalAdd, setShowModalAdd] = useState<boolean>(false);
	const [showModalEdit, setShowModalEdit] = useState<boolean>(false);
	const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false);

	const [hasBeenUpdated, setHasBeenUpdated] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	const { dataUser } = useActiveJourney();
	const navigate = useNavigate();

	useEffect(() => {
		if (dataUser && dataUser?.idPlano <= 1) {
			navigate("/home");
		} else {
			handleList().then();
		}
	}, [dataUser]);

	useEffect(() => {
		if (!editGoal) {
			checkGoalsEditStatus().then();
		}

		checkGoalsDeleteStatus().then();
	}, [goals, hasBeenUpdated]);

	useEffect(() => {
		setHasBeenUpdated(!hasBeenUpdated);
	}, [showModalAdd, showModalEdit, showModalConfirm]);

	const handleList = async () => {
		try {
			setIsLoading(true);

			const [_Response, _Error] = await new MacroGoalsService().list();

			if (!!_Error || !_Response?.status) {
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
				setIsLoading(false);
				return;
			}

			let response = _Response.resultSet;
			let goals: any = [];

			response.objetivos.forEach((goal: GoalState) => {
				let item = {
					id: goal.id,
					isSelectedEdit: false,
					isSelectedDelete: false,
				};

				goals.push(item);
			});

			setGoals(goals);
			setData(response);
			setIsLoading(false);
		} catch (err) {
			console.warn(err);
		}
	};

	const checkGoalsEditStatus = async () => {
		try {
			goals.forEach((goal: GoalState) => {
				if (goal.isSelectedEdit === true) {
					data.objetivos.forEach((goalData: Goal) => {
						if (goalData.id === goal.id) {
							setEditGoal({
								id: goalData.id,
								idRecurso: goalData.idRecurso,
								pergunta: goalData.pergunta,
								resposta: goalData.resposta,
							});

							setShowModalEdit(true);
							return;
						}
					});
				}
			});
		} catch (err) {
			console.warn(err);
		}
	};

	const checkGoalsDeleteStatus = async () => {
		try {
			let index: any = [];

			goals.forEach((goal: GoalState) => {
				if (goal.isSelectedDelete === true) {
					index.push(goal.id);
				}
			});

			if (
				(isMultipleSelected === true && wasConfirmDeletion === false) ||
				(isMultipleSelected === false && wasConfirmDeletion === true)
			) {
				return;
			}

			handleDelete(index);
		} catch (err) {
			console.warn(err);
		}
	};

	const handleDelete = async (index: [number]) => {
		let data: deleteData = {
			metas: [],
		};

		index.forEach((id: number) => {
			let item = {
				id: id,
			};

			data.metas.push(item);
		});

		if (data.metas.length !== 0) {
			try {
				const [_Response, _Error] = await new MacroGoalsService().delete(data);

				if (!!_Error || !_Response?.status) {
					toastSettings(_Error || _Response?.message, "bottom-center", "error");
					return false;
				} else {
					handleList().then();
					toastSettings(
						"Objetivo deletado com sucesso!",
						"bottom-center",
						"success",
					);

					if (viewDelete === true) {
						setViewDelete(false);
					}
				}
			} catch (err) {
				console.warn(err);
			}
		}

		setWasConfirmDeletion(false);
	};

	const arrayIsEmpty = (array: []) => {
		if (!Array.isArray(array)) {
			return false;
		}

		if (array.length === 0) {
			return true;
		}

		return false;
	};

	const renderUelfGoal = () => {
		if (!arrayIsEmpty(data.objetivoUELF)) {
			let goal = {
				id: data?.objetivoUELF.id,
				resourceId: data?.objetivoUELF.idRecurso,
				category: data?.objetivoUELF.descricaoRecurso,
				goal: data?.objetivoUELF.resposta,
				why: data?.objetivoUELF.pergunta,
				icone: data?.objetivoUELF.iconeRecurso,
			};

			return <MacroGoalsItem data={goal} uelfGoal={true} />;
		}
	};

	const renderMacroGoals = () => {
		return (
			<div className="macro-goals">
				<div className="image">
					{theme === "dark" ? (
						<img src="/assets/img/objetivos.png" alt="Objetivos" />
					) : (
						<img src="/assets/img/objetivo-white.png" alt="Objetivos" />
					)}
				</div>
				<div className="actions">
					<Button onClick={() => setShowModalAdd(true)}>
						<FontAwesomeIcon icon={faFileCirclePlus} />
						<span>Incluir mais objetivos</span>
					</Button>
					<Button
						onClick={() => {
							setViewDelete(!viewDelete);
						}}
					>
						<FontAwesomeIcon icon={faFileCircleMinus} />
						<span>Excluir meus objetivos</span>
					</Button>
				</div>
				<div className="items">
					{data?.objetivoUELF ? renderUelfGoal() : ""}
					{data?.objetivos?.map((goal: Goal, index: number) => {
						let data = {
							id: goal.id,
							resourceId: goal.idRecurso,
							category: goal.descricaoRecurso,
							goal: goal.resposta,
							why: goal.pergunta,
							icone: goal.iconeRecurso,
						};
						return <MacroGoalsItem key={index} data={data} />;
					})}
				</div>
				{viewDelete ? (
					<Button
						className="floating-button"
						onClick={() => setShowModalConfirm(true)}
					>
						<FontAwesomeIcon icon={faMinusCircle} />
						<span>Excluir selecionados</span>
					</Button>
				) : (
					""
				)}
			</div>
		);
	};

	return (
		<PainelMasterPage
			title={"Objetivos"}
			subtitle={
				"Com base no seu objetivo pessoal enriqueça a lista abaixo com objetivos de suporte e descreva a sua importância"
			}
			icon={<FontAwesomeIcon icon={faCrosshairs} />}
			onClick={() => {}}
		>
			{!isLoading ? (
				<>
					{data ? (
						renderMacroGoals()
					) : (
						<>
							<h1 className="title">Algo deu errado!</h1>
							<div className="text">
								<p>Aguarde mais alguns momentos e recarregue a pagina!</p>
							</div>
						</>
					)}
				</>
			) : (
				<Loading margin="my-6" />
			)}
			<MacroGoalsAddModal
				show={showModalAdd}
				onClose={() => {
					handleList().then();
					setShowModalAdd(false);
				}}
			/>
			<MacroGoalsEditModal
				show={showModalEdit}
				onClose={() => {
					handleList().then();
					setShowModalEdit(false);
				}}
			/>
			<MacroGoalsConfirmModal
				show={showModalConfirm}
				onClose={setShowModalConfirm}
			/>
			<ButtonNextScreen text={"Voltar"} linkScreen={"/performance-painel"} />
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</PainelMasterPage>
	);
};

export default MacroGoals;
