import { faBolt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
// @ts-ignore
import ReactStars from "react-rating-stars-component";
import Select from "react-select";
import {
	Button,
	Col,
	Container,
	Form,
	FormGroup,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row,
} from "reactstrap";
import { customModalStyles } from "../../models/selectConfig";
import { toastSettings } from "../../models/toastConfig";
//API
import FeedbackService from "../../services/FeedbackService";
// CSS
import "./ModalFilterTimeline.scss";

interface IModalFilterTimeline {
	show: boolean;
	onClose?: any;
	functionGetTimeline?: any;
}

const ModalFilterTimeline = ({
	show,
	onClose,
	functionGetTimeline,
}: IModalFilterTimeline) => {
	const [modal, setModal] = useState<boolean>(false);
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [motivacao, setMotivacao] = useState<number>(0);
	const [idTempo, setIdTempo] = useState<any>([]);
	const [idTempoSelected, setIdTempoSelected] = useState<number>(0);

	const toggle = () => setModal(!modal);

	const handleMotivacao = (value: number) => setMotivacao(value);

	const handleOnClose = () => {
		onClose(false);
	};

	const getlistTempo = async () => {
		try {
			let data: object = {};

			const [_Response, _Error] = await new FeedbackService().statusTempoList();

			if (!!_Error || !_Response?.status) {
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
				return;
			}

			let arrayTempo = _Response.resultSet?.map((item: any) => {
				return { label: item.descricao, value: item.id };
			});

			setIdTempo(arrayTempo);
		} catch (err) {
			console.warn(err);
		}
	};

	const handleTimeline = () => {
		/**
		 * Está função está vindo da tela Time line
		 * é a função getTimeline
		 * mandei ela junto no compoenente de modal
		 */
		functionGetTimeline({
			zonaMotivacao: motivacao,
			tempoNecessario: idTempoSelected,
		});

		toggle();
	};

	useEffect(() => {
		if (show) {
			setIsSubmitting(false);
			setModal(show);
			getlistTempo().then();
		}
	}, [show]);

	return (
		<>
			<Modal
				centered
				scrollable
				size="lg"
				className="modal-timeline-filter"
				data-testid="TimelineFilterModal"
				isOpen={modal}
				toggle={toggle}
				onClosed={handleOnClose}
			>
				<ModalHeader toggle={toggle}> Filtros Cronograma </ModalHeader>

				<ModalBody className="px-2">
					<Form id="filter-cronograma" key={1} style={{ height: "100px" }}>
						<Container>
							<Row className="justify-content-center">
								<Col className="col-12 col-md-3 form-group">
									<div className="fieldset">
										<Label for="nivel"> Como me sinto hoje? </Label>
										<ReactStars
											count={5}
											size={24}
											isHalf={false}
											emptyIcon={<FontAwesomeIcon icon={faBolt} />}
											halfIcon={<FontAwesomeIcon icon={faBolt} />}
											fullIcon={<FontAwesomeIcon icon={faBolt} />}
											activeColor="#99dc3b"
											onChange={handleMotivacao}
										/>
									</div>
								</Col>

								<Col className="col-12 col-md-9">
									<div className="fieldset">
										<label htmlFor="idTempo"> Escolha o Tempo: </label>
										<Select
											name="idTempo"
											isSearchable={false}
											isClearable={false}
											options={idTempo}
											placeholder="Selecione..."
											className={`select`}
											onChange={(val: any) => {
												setIdTempoSelected(val.value);
											}}
											noOptionsMessage={() => "Não há registros"}
											styles={customModalStyles}
											menuPosition={"fixed"}
										/>
									</div>
								</Col>
							</Row>
						</Container>
					</Form>
				</ModalBody>

				<ModalFooter>
					<Button type="button" disabled={isSubmitting} onClick={toggle}>
						Cancelar
					</Button>
					<Button
						type="button"
						disabled={isSubmitting}
						onClick={() => handleTimeline()}
						className="btn-rastreador"
					>
						Filtrar
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
};

export default ModalFilterTimeline;
