import "./Payments.scss";

import React, { useEffect, useState } from "react";

import CardFinishPayments from "../../components/CardFinishPayments/CardFinishPayments";
import CardPurchaseConfirmation from "../../components/CardPurchaseConfirmation/CardPurchaseConfirmation";
import ModalProfileConfirmation from "../../components/ModalProfileConfirmation/ModalProfileConfirmation";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { usePayments } from "../../providers/PaymentsProviders";

const Payment = () => {
	const { plano, isPago } = usePayments();
	const navigate = useNavigate();
	const [showModalProfileConfirmation, setShowModalProfileConfirmation] = useState<boolean>(true);

	useEffect(() => {
		if (plano === 0) {
			navigate("/journey-map");
		}
	}, [plano]);

	return (
		<div className="Payments">
			<div className="container mt-2 mt-md-5">
				{plano > 0 && plano !== 1 && !isPago && <CardFinishPayments />}
				{plano !== 1 && isPago && <CardPurchaseConfirmation />}
			</div>
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
			<ModalProfileConfirmation show={showModalProfileConfirmation} onClose={setShowModalProfileConfirmation} />
		</div>
	);
};

export default Payment;
