const MenuRoute = [
	{
		path: "",
		name: "Home",
		icon: "home",
		layout: "/home",

		cardHome: {
			title: "Vamos começar uma nova jornada?",
			description: "você pode começar uma nova jornada.",
			image: "assets/img/dashboard-img-1.png",
		},
	},
	{
		path: "",
		name: "AUTOCONHECIMENTO",
		icon: "purpose",
		layout: "/purpose",

		cardHome: {
			title: "Vamos definir o objetivo?",
			description: "agora você precisa definir os seus objetivo à cumprir.",
			image: "assets/img/dashboard-img-1.png",
		},
	},
	{
		path: "",
		name: "QUESTIONÁRIO",
		icon: "survey",
		layout: "/survey",

		cardHome: {
			title: "Vamos responder o questionário?",
			description: "agora você precisa responder ao questionário.",
			image: "assets/img/dashboard-img-1.png",
		},
	},
	{
		path: "",
		name: "CONCLUSÃO",
		icon: "survey-finish",
		layout: "/survey-finish",

		cardHome: {
			title: "Vamos responder o questionário?",
			description: "agora você precisa responder ao questionário.",
			image: "assets/img/dashboard-img-1.png",
		},
	},
	{
		path: "",
		name: "PONTOS DE ATENÇÃO",
		icon: "diagnose",
		layout: "/diagnose",

		cardHome: {
			title: "Veja o resumo da jornada",
			description: "dê uma olhada no resumo da sua jornada.",
			image: "assets/img/dashboard-img-1.png",
		},
	},
	{
		path: "",
		name: "LISTA DE EVITAR E FAZER",
		icon: "todoavoid",
		layout: "/todoavoid",

		cardHome: {
			title: "Vamos definir as listas de evitar e fazer?",
			description: "agora você precisa definir as listas de evitar e fazer.",
			image: "assets/img/dashboard-img-1.png",
		},
	},
	{
		path: "",
		name: "FOCO",
		icon: "goals",
		layout: "/goals",

		cardHome: {
			title: "Vamos definir as metas?",
			description: "agora você precisa definir o objetivo e metas à cumprir.",
			image: "assets/img/dashboard-img-1.png",
		},
	},
	{
		path: "",
		name: "EDUCATION",
		icon: "education",
		layout: "/journey-education",

		cardHome: {
			title: "",
			description: "",
			image: "",
		},
	},
	{
		path: "",
		name: "PLANO DE PERFORMANCE",
		icon: "evolution",
		layout: "/journey-evolution",

		cardHome: {
			title: "",
			description: "",
			image: "",
		},
	},
	{
		path: "",
		name: "DASHBOARD FINAL",
		icon: "performance",
		layout: "/performance",

		cardHome: {
			title: "Veja como foi sua jornada",
			description: "pronto sua jornada foi finalizada.",
			image: "assets/img/dashboard-img-1.png",
		},
	},
];

export default MenuRoute;
