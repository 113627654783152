import "./SuccessRoadmapItem.scss";

import { Button, Card, Input } from "reactstrap";
import React, { useState } from "react";
import { faEye, faPlus } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormataData from "../../utils/FormataDate";
import SuccessRoadmapDate from "../SuccessRoadmapDate/SuccessRoadmapDate";
import SuccessRoadmapService from "../../services/SuccessRoadmapService";
import SuccessRoadmapStars from "../SuccessRoadmapStars/SuccessRoadmapStars";
import SuccessRoadmapStatusSelect from "../SuccessRoadmapStatusSelect/SuccessRoadmapStatusSelect";
import { faEdit } from "@fortawesome/free-regular-svg-icons";
import { toastSettings } from "../../models/toastConfig";
import { useSuccessRoadmap } from "../../providers/SuccessRoadmapProvider";

export interface Props {
	data: ObjetivoUELF | Objetivo | any;
	uelfGoal: boolean;
	index: number;
}

export interface ObjetivoUELF {
	acaoCorreta: string;
	avalicaoGeral: number;
	dataFinal: string;
	dataInicial: string;
	descricaoProximoPasso: string;
	descricaoRecurso: string;
	descricaoStatus: string;
	id: number;
	idJornada: number;
	idProximoPasso: number;
	idRecurso: number;
	idUsuario: number;
	modify: boolean;
	opiniaoAmigos: string;
	percentualProgresso: number;
	pergunta: string;
	prioridade: number;
	proximoPasso: string;
	resposta: string;
	status: number;
	subobjetivos: Subobjetivo[];
}

export interface Objetivo {
	ativo: number;
	dataFinal?: any;
	dataInicial: string;
	descricaoRecurso: string;
	id: number;
	idRecurso: number;
	idUsuario: number;
	percentualProgresso: number;
	pergunta: string;
	prioridade?: any;
	resposta: string;
	status: number;
	subobjetivos: any[];
}

export interface Subobjetivo {
	alvo: string;
	dataFinal: string;
	dataInicial: string;
	descricao: string;
	descricaoStatus: string;
	descricaoTempoNecessario: string;
	esforcoFisico: number;
	esforcoMental: number;
	id: number;
	idMetasSecundarias: number;
	idObjetivoUelf: number;
	idTempoNecessario: number;
	importancia: number;
	motivacao: number;
	status: number;
}

const SuccessRoadmapItem = ({ data, uelfGoal, index }: Props) => {
	const {
		ID,
		setID,
		isUELF,
		setIsUELF,
		goalID,
		setGoalID,

		description,
		setDescription,
		target,
		setTarget,

		importance,
		setImportance,
		motivation,
		setMotivation,
		mentalEffort,
		setMentalEffort,
		physicalEffort,
		setPhysicalEffort,
		idTempo,
		setIdTempo,
		idTempoSelected,
		setIdTempoSelected,

		initialDate,
		setInitialDate,
		finalDate,
		setFinalDate,

		showModal,
		setShowModal,
	} = useSuccessRoadmap();

	const animateContentVisibility = (id: number, index: number) => {
		let element = document.querySelector(
			"#item-" + id + "-" + index,
		) as HTMLElement;

		let contentPanel = element.querySelector(".roadmap-wrapper") as HTMLElement;
		contentPanel.classList.toggle("is-active");
	};

	const renderStatusSelect = (
		level: number,
		isUELF: boolean,
		status: number,
		primaryID: number,
		secondaryID?: number,
	) => {
		if (secondaryID) {
			return (
				<SuccessRoadmapStatusSelect
					level={level}
					isUELF={isUELF}
					primaryID={primaryID}
					secondaryID={secondaryID}
					status={status}
				/>
			);
		} else {
			return (
				<SuccessRoadmapStatusSelect
					level={level}
					isUELF={isUELF}
					primaryID={primaryID}
					status={status}
				/>
			);
		}
	};

	const renderStars = (
		isUELF: boolean = false,
		prevRating: number,
		ID: number,
		editable: boolean = false,
	) => {
		return (
			<SuccessRoadmapStars
				isUELF={isUELF}
				prevRating={prevRating}
				ID={ID}
				editable={editable}
			/>
		);
	};

	const renderFinalDate = (
		isUELF: boolean,
		id: number,
		date: string,
		canModify: boolean = true,
	) => {
		return (
			<SuccessRoadmapDate
				isUELF={isUELF}
				id={id}
				date={date}
				canModify={canModify}
			/>
		);
	};

	const updateGoal = async (isUELF: boolean, id: number, response: string) => {
		let data = {
			id: id,
			respostaMeta: response,
		};

		if (isUELF) {
			try {
				const [_Response, _Error] =
					await new SuccessRoadmapService().updateGoalUELF(data);

				if (!!_Error || !_Response?.status) {
					toastSettings(_Error || _Response?.message, "bottom-center", "error");
					return false;
				}

				toastSettings("Meta atualizada com sucesso.", "bottom-center", "success");
			} catch (err) {
				console.warn(err);
			}
		} else {
			try {
				const [_Response, _Error] = await new SuccessRoadmapService().updateGoal(
					data,
				);

				if (!!_Error || !_Response?.status) {
					toastSettings(_Error || _Response?.message, "bottom-center", "error");
					return false;
				}

				toastSettings("Meta atualizada com sucesso.", "bottom-center", "success");
			} catch (err) {
				console.warn(err);
			}
		}
	};

	const renderItem = () => {
		return (
			<div
				id={"item-" + data.id + "-" + index}
				className={`success-roadmap-item${uelfGoal ? " uelf" : ""}`}
				data-testid="SuccessRoadmapItem"
			>
				<Card>
					<div className="item-row">
						<div className="item-col">
							<div className="item-row main-row">
								<div className="item-row title-row">
									<div className="item-col">
										<span className="title">Objetivos</span>
									</div>
									<div className="item-col">
										<span className="title">Metas</span>
									</div>
									<div className="item-col">
										<span className="title">Status</span>
									</div>
									<div className="item-col">
										<span className="title">Prioridade</span>
									</div>
									<div className="item-col">
										<span className="title">Revisão</span>
									</div>
								</div>
								<div className="item-row content-row">
									<div className="item-col">
										<span className="mb-label">Objetivos</span>
										<span className="content">{data.pergunta}</span>
									</div>
									<div className="item-col">
										<span className="mb-label">Metas</span>
										<span className="content">
											<Input
												type="text"
												defaultValue={data.respostaMeta}
												onBlur={(e: any) => {
													if (e.target.value.length > 0) {
														updateGoal(uelfGoal, data.id, e.target.value);
													}
												}}
												maxLength={100}
												required
											/>
										</span>
									</div>
									<div className="item-col">
										<span className="mb-label">Status</span>
										{renderStatusSelect(1, uelfGoal, data.status, data.id)}
									</div>
									<div className="item-col">
										<span className="mb-label">Prioridade</span>
										{renderStars(uelfGoal, data.prioridade, data.id, true)}
									</div>
									<div className="item-col">
										<span className="mb-label">Revisão</span>
										<div className="content">
											{renderFinalDate(uelfGoal, data.id, data.dataFinal, data.modify)}
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="actions-wrapper">
							{data?.subobjetivos && (
								<Button onClick={() => animateContentVisibility(data.id, index)}>
									<FontAwesomeIcon icon={faEye} />
									<span className="vh">Ver roteiro</span>
								</Button>
							)}

							{data?.subobjetivos?.length < 3 && (
								<Button
									onClick={() => {
										setIsUELF(uelfGoal);
										setGoalID(data.id);
										setShowModal(true);
									}}
								>
									<FontAwesomeIcon icon={faPlus} />
									<span className="vh">Adicionar</span>
								</Button>
							)}
						</div>
					</div>
				</Card>
				<div className="item-row roadmap-wrapper">
					{data?.subobjetivos?.map((subobjetivo: Subobjetivo, index: number) => (
						<Card key={index}>
							<div className="item-row">
								<div className="item-col">
									<div className="overflow-x">
										<div className="item-row">
											<div className="item-row title-row">
												<div className="item-col">
													<span className="title">Tarefa</span>
												</div>
												<div className="item-col">
													<span className="title">Alvo</span>
												</div>
												<div className="item-col">
													<span className="title">Status</span>
												</div>
												<div className="item-col">
													<span className="title">Data início</span>
												</div>
												<div className="item-col">
													<span className="title">Data fim</span>
												</div>
												<div className="item-col">
													<span className="title">Importância</span>
												</div>
												<div className="item-col">
													<span className="title">Minha motivação</span>
												</div>
												<div className="item-col">
													<span className="title">Esforço mental</span>
												</div>
												<div className="item-col">
													<span className="title">Esforço físico</span>
												</div>
												<div className="item-col">
													<span className="title">Tempo nescessário</span>
												</div>
											</div>
											<div className="item-row content-row">
												<div className="item-col">
													<span className="mb-label">Sub-Meta</span>
													<span className="content">{subobjetivo.descricao}</span>
												</div>
												<div className="item-col">
													<span className="mb-label">Alvo</span>
													<span className="content">{subobjetivo.alvo}</span>
												</div>
												<div className="item-col">
													<span className="mb-label">Status</span>
													<span className="content">
														{uelfGoal
															? renderStatusSelect(
																	2,
																	uelfGoal,
																	subobjetivo.status,
																	subobjetivo.id,
																	subobjetivo.idObjetivoUelf,
															  )
															: renderStatusSelect(
																	2,
																	uelfGoal,
																	subobjetivo.status,
																	subobjetivo.id,
																	subobjetivo.idMetasSecundarias,
															  )}
													</span>
												</div>
												<div className="item-col">
													<span className="mb-label">Data início</span>
													<span className="content">
														{FormataData(subobjetivo?.dataInicial)}
													</span>
												</div>
												<div className="item-col">
													<span className="mb-label">Data fim</span>
													<span className="content">
														{FormataData(subobjetivo?.dataFinal)}
													</span>
												</div>
												<div className="item-col">
													<span className="mb-label">Importância</span>
													{renderStars(
														uelfGoal,
														subobjetivo.importancia,
														subobjetivo.id,
														false,
													)}
												</div>
												<div className="item-col">
													<span className="mb-label">Minha motivação</span>
													{renderStars(
														uelfGoal,
														subobjetivo.motivacao,
														subobjetivo.id,
														false,
													)}
												</div>
												<div className="item-col">
													<span className="mb-label">Esforço mental</span>
													{renderStars(
														uelfGoal,
														subobjetivo.esforcoMental,
														subobjetivo.id,
														false,
													)}
												</div>
												<div className="item-col">
													<span className="mb-label">Esforço físico</span>
													{renderStars(
														uelfGoal,
														subobjetivo.esforcoFisico,
														subobjetivo.id,
														false,
													)}
												</div>
												<div className="item-col">
													<span className="mb-label">Tempo nescessário</span>
													<span className="content">
														{subobjetivo.descricaoTempoNecessario}
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="actions-wrapper">
									<Button
										onClick={() => {
											setID(subobjetivo.id);
											setIsUELF(uelfGoal);
											setGoalID(data.id);

											setDescription(subobjetivo.descricao);
											setTarget(subobjetivo.alvo);

											setImportance(subobjetivo.importancia);
											setMotivation(subobjetivo.motivacao);
											setMentalEffort(subobjetivo.esforcoMental);
											setPhysicalEffort(subobjetivo.esforcoFisico);

											setIdTempoSelected(subobjetivo.idTempoNecessario);

											setInitialDate(subobjetivo.dataInicial);
											setFinalDate(subobjetivo.dataFinal);

											setShowModal(true);
										}}
									>
										<FontAwesomeIcon icon={faEdit} />
										<span className="vh">Editar</span>
									</Button>
								</div>
							</div>
						</Card>
					))}

					{data?.subobjetivos?.length === 0 && <p>Não há registros disponíveis!</p>}
				</div>
			</div>
		);
	};

	return <>{renderItem()}</>;
};

export default SuccessRoadmapItem;
