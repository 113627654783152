import * as yup from "yup";

export const schema = yup.object().shape({
	block01: yup.string().required().min(4).max(4),
	block02: yup.string().required().min(4).max(4),
	block03: yup.string().required().min(4).max(4),
	block04: yup.string().required().min(4).max(4),
	expirationDate: yup.string().required().min(1).max(7),
	cvc: yup.string().required().min(3).max(3),
	cardName: yup.string().required().min(3).max(50),
	cpf: yup.string().required().min(1).max(24),
});
