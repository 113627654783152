import React, { useEffect, useState } from "react";

export const SuccessRoadmapContext = React.createContext<any>({});

export const SuccessRoadmapProvider = (props: any) => {
	const [ID, setID] = useState<number | null>();
	const [isUELF, setIsUELF] = useState<boolean>(false);
	const [goalID, setGoalID] = useState<number | null>();

	const [description, setDescription] = useState<string>("");
	const [target, setTarget] = useState<string>("");

	const [importance, setImportance] = useState<number>(0);
	const [motivation, setMotivation] = useState<number>(0);
	const [mentalEffort, setMentalEffort] = useState<number>(0);
	const [physicalEffort, setPhysicalEffort] = useState<number>(0);
	const [idTempo, setIdTempo] = useState<any>([]);
	const [idTempoSelected, setIdTempoSelected] = useState<number>(0);

	const [initialDate, setInitialDate] = useState<any>("");
	const [finalDate, setFinalDate] = useState<any>("");

	const [showModal, setShowModal] = useState<boolean>(false);

	useEffect(() => {
		if (showModal === false) {
			setID(null);
			setIsUELF(false);
			setGoalID(null);

			setDescription("");
			setTarget("");

			setImportance(0);
			setMotivation(0);
			setMentalEffort(0);
			setPhysicalEffort(0);

			setInitialDate("");
			setFinalDate("");
		}
	}, [showModal]);

	return (
		<SuccessRoadmapContext.Provider
			value={{
				ID,
				setID,
				isUELF,
				setIsUELF,
				goalID,
				setGoalID,

				description,
				setDescription,
				target,
				setTarget,

				importance,
				setImportance,
				motivation,
				setMotivation,
				mentalEffort,
				setMentalEffort,
				physicalEffort,
				setPhysicalEffort,
				idTempo,
				setIdTempo,
				idTempoSelected,
				setIdTempoSelected,

				initialDate,
				setInitialDate,
				finalDate,
				setFinalDate,

				showModal,
				setShowModal,
			}}
		>
			{props.children}
		</SuccessRoadmapContext.Provider>
	);
};

export const useSuccessRoadmap = () => React.useContext(SuccessRoadmapContext);
