import axios, { AxiosResponse } from "axios";

export const handleResponse = (response: AxiosResponse<any>) => {
	try {
		return [response.data, undefined];
	} catch (error) {
		return [undefined, error];
	}
};

class SignUpService {
	async getCep(cep: string) {
		try {
			const _cep = cep?.replace(/[^\w\s]/gi, "");
			const response = await axios.get(
				`https://brasilapi.com.br/api/cep/v2/${_cep}`,
			);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				e?.response?.data,
				e?.response?.data?.message || "Houve um erro inesperado.",
			];
		}
	}

	async getStates() {
		try {
			const response = await axios.get(`https://brasilapi.com.br/api/ibge/uf/v1`);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				e?.response?.data,
				e?.response?.data?.message || "Houve um erro inesperado.",
			];
		}
	}

	async getCities(state: string) {
		try {
			const response = await axios.get(
				`https://brasilapi.com.br/api/ibge/municipios/v1/${state}`,
			);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				e?.response?.data,
				e?.response?.data?.message || "Houve um erro inesperado.",
			];
		}
	}
}

export default SignUpService;
