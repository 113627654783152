import * as yup from "yup";

import {
	validateCNPJ,
	validateCPF,
	validateEmail,
	validatePhone,
} from "validations-br";

import differenceInYears from "date-fns/differenceInYears";

export const schema = yup.object().shape({
	name: yup.string().required().min(3).max(100),
	CPF: yup
		.string()
		.required()
		.test(
			"CPF",
			"O CPF digitado é inválido.",
			(value: string | undefined) =>
				validateCPF(value || "") || validateCNPJ(value || ""),
		),
	email: yup
		.string()
		.required()
		.max(150)
		.test("email", "O Email digitado é inválido.", (value: string | undefined) =>
			validateEmail(value || ""),
		),
	password: yup
		.string()
		.required()
		.matches(
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
			"A Senha não atinge os requisitos mínimos.",
		)
		.min(8)
		.max(50),
	passwordConfirmation: yup
		.string()
		.oneOf([yup.ref("password"), null], "As senhas não conferem."),
	// occupation: yup.string().min(3).max(100),
	// birthDate: yup.mixed().required(),
	// .test(
	// 	"birthDate",
	// 	"De acordo com os termos de uso não permitido o cadastro nem o acesso ao UELF no Brasil por menores de 18 anos, ou qualquer outra idade abaixo da maioridade legal do país de origem do acesso.",
	// 	(value) => {
	// 		return differenceInYears(new Date(Date.now()), new Date(value)) > 17;
	// 	},
	// ),
	phone: yup
		.string()
		.required()
		.test(
			"phone",
			"O Celular digitado é inválido.",
			(value: string | undefined) => validatePhone(value || ""),
		),
});
