import TagManager, { TagManagerArgs } from "react-gtm-module";
import Slugify from "../utils/Slugify";

export enum EventEnum {
    Marketing = "marketing_event"
}

class GoogleTagManager {
    
    private lastAction: string = "";
    private tagManagerArgs: TagManagerArgs = {
        gtmId: "GTM-MHJTRZP"
    };

    init() : void {
        TagManager.initialize(this.tagManagerArgs);
    }

    event(eventName: string, eventProps = { action: "" }) : void {
        if(this.lastAction === eventProps.action) return;

        TagManager.dataLayer({
            dataLayer: {
                event: eventName,
                eventProps
            }
        });

        this.lastAction = eventProps.action; 
    }

}

export default new GoogleTagManager();