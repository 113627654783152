import api from "./api";

class DiagnoseService {
	async list() {
		try {
			const response = await api.get(`app/subrecurso/listaPioresSubRecursos`);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}

	async listProvisorio() {
		try {
			const response = await api.get(`app/processojornada/dashboard`);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}

	async listGradesRecurso(idRecurso: number) {
		try {
			const response = await api.get(
				`app/processojornada/nota/recurso/${idRecurso}`,
			);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}
}

export default DiagnoseService;
