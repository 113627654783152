import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../providers/AuthProvider";
import "./CardProfile.scss";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
	editprofile?: boolean;
}

const CardProfile = ({ editprofile = true }: Props) => {
	const { user } = useAuth();

	return (
		<div className="card profile-card CardProfile" data-testid="CardProfile">
			<div className="pic">
				{user.imagem ? (
					<img
						src={`${process.env.REACT_APP_UELF_API}/${user.imagem}`}
						alt="Foto de perfil"
					/>
				) : (
					<img src="assets/img/1.png" alt="Foto de perfil" />
				)}
			</div>
			<p className="name futura-bold">{user?.nome}</p>
			<div className="info">
				<div className="item">
					<div className="label">
						<i className="bi bi-briefcase-fill"></i>
						<span>Profissão:</span>
					</div>
					<span className="answer">{user?.ocupacao || "Não disponível"}</span>
				</div>
				<div className="item">
					<div className="label">
						<i className="bi bi-emoji-laughing-fill"></i>
						<span>Idade (anos):</span>
					</div>
					<span className="answer">{user?.idade || "Não disponível"}</span>
				</div>
				<div className="item">
					<div className="label">
						<i className="bi bi-geo-alt-fill"></i>
						<span>Localização:</span>
					</div>
					<span className="answer">
						{user?.cidade && user?.estado ? `${user?.cidade} - ${user?.estado}` : "Não disponível"}
					</span>
				</div>
				<a className="solicitation" href="mailto:contato@uelf.com.br">
					Solicitar Atendimento
				</a>
			</div>

			<div className={`configs ${!editprofile && "border-0"}`}>
				{editprofile && (
					<Link to={"/edit-profile"}>
						<i className="bi bi-pencil-square"></i>
						<span>Editar o meu perfil</span>
					</Link>
				)}
			</div>
		</div>
	);
};

export default CardProfile;
