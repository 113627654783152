import api from "./api";

class CardPaymentsService {
	async list() {
		try {
			const response = await api.get("app/plano/uelf/lista");
			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}

	async create(data: any) {
		try {
			let response: any = null;

			if (data.idPlano === 1) {
				response = await api.get("app/cielo/contratar/gratuito");
			} else {
				response = await api.post("app/cielo/save/payment", data);
			}
			return [response.data, undefined, response.status];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}

	async consultBrand(cardNumber: string) {
		try {
			const response = await api.post("app/cielo/consulta/bandeira", {
				cardNumber: cardNumber,
			});

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}
}

export default CardPaymentsService;
