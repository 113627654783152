import api from "./api";

class AccountRecoveryService {
	async SendRecoveryEmail(data: { login: string }) {
		try {
			const response = await api.post(`app/usuario/recuperar/senha`, data);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}

	async validateToken(data: { token: string | undefined }) {
		try {
			const response = await api.post(
				`app/usuario/validar/recuperacao/senha`,
				data,
			);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}

	async sendRecoveryCode(data: {
		idUsuario: number;
		novaSenha: string;
		confirmacaoSenha: string;
		codigoRecuperacao?: string;
	}) {
		try {
			const response = await api.post(`app/usuario/trocar/senha`, data);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}
}

export default AccountRecoveryService;
