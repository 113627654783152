import api from "./api";

interface Note {
	id?: number;
	idObjetivo: number;
	idRecurso: number;
	humor: number;
	anotacao: string;
	isUelf: number;
}

class NotesService {
	async list(data: object) {
		try {
			const response = await api.post(`app/anotacoes/lista`, data);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}

	async create(obj: any) {
		try {
			let data: Note = {
				id: obj?.id,
				idRecurso: obj.idRecurso.value,
				humor: obj.humor.value,
				anotacao: obj.anotacao,
				isUelf: obj.isUelf,
				idObjetivo: obj.idObjetivo,
			};

			const response = await api.post(`app/anotacoes/create`, data);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}

	async edit(data: {
		id: number;
		idRecurso: number;
		pergunta: string;
		resposta: string;
	}) {
		try {
			const response = await api.post(`app/anotacoes/edit`, data);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}

	async delete(data: any) {
		try {
			const response = await api.post(`app/anotacoes/delete`, data);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}
}

export default NotesService;
