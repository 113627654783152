import { yupResolver } from "@hookform/resolvers/yup";
import React, { FC, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { customModalStyles } from "../../models/selectConfig";
import { toastSettings } from "../../models/toastConfig";
import { useJourneyBackpack } from "../../providers/JourneyBackpackProvider";
import JourneyBackpackService from "../../services/JourneyBackpackService";
import "./JourneyBackpackAddModal.scss";
import { schema } from "./Validate/Validate";

interface JourneyBackpackAddModalProps {
	show: boolean;
	onClose?: any;
}

type FormValues = {
	idObjetivo: string;
	link: string;
	tempoEstimado: string;
	isUelf: number;
};

const JourneyBackpackAddModal: FC<JourneyBackpackAddModalProps> = ({
	show,
	onClose,
}) => {
	const [modal, setModal] = useState<boolean>(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [objetivos, setObjetivos] = useState<any[]>([]);
	const [objetivoSelected, setObjetivoSelected] = useState<any>(null);

	const { itemSelected, handleList } = useJourneyBackpack();

	const toggle = () => setModal(!modal);
	const handleOnClose = () => onClose(false);

	let defaultValues = {
		idObjetivo: "",
		link: "",
		tempoEstimado: "",
		isUelf: 0,
	} as FormValues;

	const {
		register,
		handleSubmit,
		control,
		setValue,
		reset,
		formState: { errors },
	} = useForm<FormValues>({
		mode: "onChange",
		defaultValues,
		resolver: yupResolver(schema),
	});

	const handleListObjetivos = async () => {
		try {
			const [_Response, _Error] =
				await new JourneyBackpackService().listObjetivos();

			if (!!_Error || !_Response?.status) {
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
				return;
			}

			let arratObjetivos = _Response.resultSet?.map((item: any) => {
				return { label: item.objetivo, value: item.id, isUelf: item?.isUELF };
			});

			setObjetivos(arratObjetivos);
		} catch (err) {
			console.warn(err);
		}
	};

	const createNewItem = async (data: any) => {
		try {
			setIsSubmitting(true);

			if (itemSelected?.id) {
				data.id = itemSelected?.id;
			}

			const [_Response, _Error] = await new JourneyBackpackService().create(data);

			if (!!_Error || !_Response?.status) {
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
				setTimeout(() => setIsSubmitting(false), 1000);
				return false;
			}

			toastSettings(
				`${!itemSelected ? "Criado novo" : "Atualizado"} item com sucesso!`,
				"bottom-center",
				"success",
			);

			handleList();
		} catch (err) {
			console.warn(err);
		}

		setIsSubmitting(false);
		toggle();
	};

	useEffect(() => {
		handleListObjetivos().then();

		if (show) {
			if (!itemSelected) {
				setValue("idObjetivo", "");
				setObjetivoSelected(null);
				reset();
			}
			setIsSubmitting(false);
			setModal(show);
		}
	}, [show]);

	useEffect(() => {
		setValue("idObjetivo", objetivoSelected || "");
	}, [objetivoSelected]);

	useEffect(() => {
		if (itemSelected) {
			setValue("link", itemSelected?.link || "");
			setValue("tempoEstimado", itemSelected?.tempoEstimado || "");

			let objetivo = objetivos.find(
				(x: any) => x.value === itemSelected?.idObjetivo,
			);
			setValue("idObjetivo", objetivo);
			setObjetivoSelected(objetivo);
		}
	}, [itemSelected]);

	return (
		<Modal
			centered
			scrollable
			size="lg"
			isOpen={modal}
			toggle={toggle}
			onClosed={handleOnClose}
			className="JourneyBackpackAddModal"
			data-testid="JourneyBackpackAddModal"
		>
			<form onSubmit={handleSubmit(createNewItem)}>
				<ModalHeader toggle={toggle}>
					{!itemSelected ? "Adicionar" : "Atualizar"} um Item na Mochila
				</ModalHeader>

				<ModalBody>
					<h2 className="title s">
						Vamos abaixo {!itemSelected ? "adicionar" : "atualizar"} um item na sua
						mochila da jornada.
					</h2>

					<div className="text">
						<p className="text">Pense e preencha os campos abaixo.</p>
					</div>

					<div className="fieldset">
						<label htmlFor="idObjetivo">Escolha um objetivo:</label>
						<Controller
							name="idObjetivo"
							control={control}
							render={({ field: { onChange, name, ref } }) => (
								<Select
									ref={ref}
									name={name}
									isSearchable={false}
									isClearable={false}
									options={objetivos}
									placeholder="Selecione..."
									className={`select ${!errors.idObjetivo || "invalid"}`}
									value={objetivoSelected}
									onChange={(val: any) => {
										onChange(val || null);
										setObjetivoSelected(val);
									}}
									noOptionsMessage={() => "Não há registros"}
									styles={customModalStyles}
									menuPosition={"fixed"}
								/>
							)}
						/>

						{errors.idObjetivo?.type === "required" && (
							<p className="error">Objetivo é obrigatório</p>
						)}
					</div>

					<div className="fieldset">
						<label htmlFor="link">Qual é o link do item?</label>
						<input
							type="text"
							id="link"
							className={`${!errors.link || "invalid"}`}
							{...register("link")}
							maxLength={100}
						/>

						{errors.link?.type === "required" && (
							<p className="error">Link do item é obrigatório</p>
						)}
						{errors.link?.type === "min" && (
							<p className="error">Deve conter no mínimo 3 caracteres</p>
						)}
						{errors.link?.type === "max" && (
							<p className="error">Deve conter no máximo 100 caracteres</p>
						)}
					</div>

					<div className="fieldset">
						<label htmlFor="tempoEstimado">Qual o tempo estimado?</label>
						<input
							type="text"
							id="tempoEstimado"
							className={`${!errors.tempoEstimado || "invalid"}`}
							{...register("tempoEstimado")}
							maxLength={100}
						/>

						{errors.tempoEstimado?.type === "required" && (
							<p className="error">Tempo estimado é obrigatório</p>
						)}
						{errors.tempoEstimado?.type === "min" && (
							<p className="error">Deve conter no mínimo 3 caracteres</p>
						)}
						{errors.tempoEstimado?.type === "max" && (
							<p className="error">Deve conter no máximo 100 caracteres</p>
						)}
					</div>
				</ModalBody>

				<ModalFooter>
					<Button type="button" disabled={isSubmitting} onClick={toggle}>
						Cancelar
					</Button>
					<Button type="submit" disabled={isSubmitting} color="primary">
						{!itemSelected ? "Criar novo" : "Atualizar"} item
					</Button>
				</ModalFooter>
			</form>
		</Modal>
	);
};

export default JourneyBackpackAddModal;
