import api from "./api";

class DashboardService {
	async resumo() {
		try {
			const response = await api.get(`app/usuario/home`);
			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}

	async list() {
		try {
			const response = await api.get(`app/processojornada/dashboard/final`);
			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}
}

export default DashboardService;
