import React, { useEffect, useState } from "react";

import moment from "moment";

export const ExternalPaymentContext = React.createContext<any>({});

export const ExternalPaymentProvider = (props: any) => {
	const [step, setStep] = useState("Registration");
	const [loading, setLoading] = useState(false);

	const [name, setName] = useState("");
	const [CPF, setCPF] = useState("");
	const [phone, setPhone] = useState("");
	const [birthDate, setBirthDate] = useState("");
	const [occupation, setOccupation] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	const [plan, setPlan] = useState("");

	const [valueBlock01, setValueBlock01] = useState("");
	const [valueBlock02, setValueBlock02] = useState("");
	const [valueBlock03, setValueBlock03] = useState("");
	const [valueBlock04, setValueBlock04] = useState("");

	const [expirationDate, setExpirationDate] = useState("");
	const [cardName, setCardName] = useState("");
	const [cvc, setCvc] = useState("");
	const [cpf, setCpf] = useState("");

	const [paymentOption, setPaymentOption] = useState("recurrent");

	const [cardData, setCardData] = useState({});

	const [successfullyPaid, setSuccessfullyPaid] = useState(false);

	// Steps
	useEffect(() => {
		const savedStep = localStorage.getItem("savedEPStep");
		savedStep && setStep(savedStep);
	}, []);

	useEffect(() => {
		step && window.localStorage.setItem("savedEPStep", step);
	}, [step]);

	// Name
	useEffect(() => {
		const savedName = localStorage.getItem("savedEPName");
		savedName && setName("savedEPName");
	}, []);

	useEffect(() => {
		name && window.localStorage.setItem("savedEPName", name);
	}, [name]);

	// CPF
	useEffect(() => {
		const savedCPF = localStorage.getItem("savedEPCPF");
		savedCPF && setCPF("savedEPCPF");
	}, []);

	useEffect(() => {
		CPF && window.localStorage.setItem("savedEPCPF", CPF);
	}, [CPF]);

	// Phone
	useEffect(() => {
		const savedPhone = localStorage.getItem("savedEPPhone");
		savedPhone && setPhone("savedEPPhone");
	}, []);

	useEffect(() => {
		phone && window.localStorage.setItem("savedEPPhone", phone);
	}, [phone]);

	// Occupation
	useEffect(() => {
		const savedOccupation = localStorage.getItem("savedEPOccupation");
		savedOccupation && setOccupation("savedEPOccupation");
	}, []);

	useEffect(() => {
		occupation && window.localStorage.setItem("savedEPOccupation", occupation);
	}, [occupation]);

	// Email
	useEffect(() => {
		const savedEmail = localStorage.getItem("savedEPEmail");
		savedEmail && setOccupation("savedEPEmail");
	}, []);

	useEffect(() => {
		email && window.localStorage.setItem("savedEPEmail", email);
	}, [email]);

	return (
		<ExternalPaymentContext.Provider
			value={{
				step,
				setStep,

				loading,
				setLoading,

				name,
				setName,
				CPF,
				setCPF,
				phone,
				setPhone,
				birthDate,
				setBirthDate,
				occupation,
				setOccupation,
				email,
				setEmail,
				password,
				setPassword,

				plan,
				setPlan,

				valueBlock01,
				setValueBlock01,
				valueBlock02,
				setValueBlock02,
				valueBlock03,
				setValueBlock03,
				valueBlock04,
				setValueBlock04,
				expirationDate,
				setExpirationDate,
				cardName,
				setCardName,
				cvc,
				setCvc,
				cpf,
				setCpf,

				paymentOption,
				setPaymentOption,

				cardData,
				setCardData,

				successfullyPaid,
				setSuccessfullyPaid,
			}}
		>
			{props.children}
		</ExternalPaymentContext.Provider>
	);
};

export const useExternalPayment = () =>
	React.useContext(ExternalPaymentContext);
