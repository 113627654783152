import "./EPConfirmation.scss";

import { Button } from "reactstrap";
import React from "react";
import { useExternalPayment } from "../../providers/ExternalPaymentProvider";
import { useNavigate } from "react-router-dom";

const EPConfirmation = () => {
	const { setStep, successfullyPaid } = useExternalPayment();
	const navigate = useNavigate();

	return (
		<div className="ep-confirmation step-card">
			{successfullyPaid ? (
				<>
					<div className="image">
						<img src="/assets/img/ep-payment/success.png" alt="Sucesso" />
					</div>
					<h1 className="title">Pagamento realizado com sucesso.</h1>
					<div className="text">
						<p>Agora você pode utilizar nossa plataforma UELF Education.</p>
						<p>
							Em instantes, você receberá um email com o seu acesso, clique no botão
							abaixo para acessar a plataforma.
						</p>
					</div>
					<Button
						onClick={() => {
							localStorage.clear();
							navigate("/login");
						}}
					>
						<span>Acessar a plataforma</span>
					</Button>
				</>
			) : (
				<>
					<div className="image">
						<img src="/assets/img/ep-payment/failure.png" alt="Fracasso" />
					</div>
					<h1 className="title">
						Infelizmente não conseguimos concluir seu pagamento.
					</h1>
					<div className="text">
						<p>
							Por favor, revise suas informações e faça uma nova tentativa de
							pagamento, clicando no botão abaixo.
						</p>
					</div>
					<Button
						onClick={() => {
							setStep("Registration");
						}}
					>
						<span>Tentar novamente</span>
					</Button>
				</>
			)}
		</div>
	);
};

export default EPConfirmation;
