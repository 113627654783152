import React, { FC, useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../providers/AuthProvider";

interface Props {
	element?: any;
	path?: string;
}

export const AuthenticationGate: FC<Props> = () => {
	const [isLoading, setIsLoading] = useState(true);
	const { user } = useAuth();

	useEffect(() => {
		const disposer = () => setTimeout(() => setIsLoading(false), 0);
		disposer();
	});

	if (isLoading) {
		return <></>;
	}

	// If the user is not authenticated, redirect them to a login page
	if (!user) {
		return <Navigate to="/login" />;
	}

	// If they're a user, go ahead and continue to what they wanted to see
	return (
		<div>
			<Outlet />
		</div>
	);
};
