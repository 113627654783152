import * as yup from "yup";

import {
	validateCNPJ,
	validateCPF,
	validateCep,
	validateEmail,
	validatePhone,
} from "validations-br";

import differenceInYears from "date-fns/differenceInYears";

export const schema = yup.object().shape({
	nomeRazao: yup.string().required().min(3).max(100),
	cpfCnpj: yup
		.string()
		.notRequired()
		.test(
			"cpfCnpj",
			"CPF é inválido",
			(value: string | undefined) =>
				!value?.length || validateCPF(value || "") || validateCNPJ(value || ""),
		),
	email: yup
		.string()
		.required()
		.max(150)
		.test("email", "Email é inválido", (value: string | undefined) =>
			validateEmail(value || ""),
		),
	ocupacao: yup.string().max(100),
	dataNascimentoAbertura: yup.mixed().notRequired(),
	// .test(
	// 	"dataNascimentoAbertura",
	// 	"De acordo com os termos de uso não permitido o cadastro nem o acesso ao UELF no Brasil por menores de 18 anos, ou qualquer outra idade abaixo da maioridade legal do país de origem do acesso.",
	// 	(value) => {
	// 		return differenceInYears(new Date(Date.now()), new Date(value)) > 17;
	// 	},
	// ),
	celular: yup
		.string()
		.notRequired()
		.test("phone", "Celular/Whatsapp é inválido", (value: string | undefined) =>
			validatePhone(value || "") || value === "",
		),
	cep: yup
		.string()
		.notRequired()
		// @ts-ignore
		.test("cep", "CEP é inválido", (value: any) => {
			if (value) {
				return validateCep(
					value?.replace(/\D/g, "").replace(/(\d{5})(\d)/, "$1-$2") || "",
				);
			} else {
				return true;
			}
		}),
	logradouro: yup.string().max(150),
	numero: yup.string().max(10),
	bairro: yup.string().max(150),
	cidade: yup.string().max(150),
	estado: yup.string(),
	imagem: yup
		.mixed()
		.notRequired()
		.test("size", "Tamanho máximo permitido 1mb.", (file: File[]) => {
			if (file) {
				const sizeInBytes = 1000000;
				// @ts-ignore
				return file[0]?.size < sizeInBytes;
			} else return true;
		}),
});
