import api from "./api";

class FeedbackService {
	async list() {
		try {
			let response = await api.get("/app/plano/performance/feedback");

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}

	async statusList() {
		try {
			let response = await api.get("/app/proximo/passo/lista");

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}

	async statusTempoList() {
		try {
			let response = await api.get("/app/tempo/necessario/lista");

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}

	async create(obj: any) {
		try {
			let rota = obj.isObjUelf
				? "/app/objetivo/uelf/insert/feedback"
				: "/app/metas/secundarias/insert/feedback";

			let data: any = {
				id: obj.idObjetivo,
				idProximoPasso: obj.idProximoPasso.value,
				proximoPasso: obj.proximoPasso,
				acaoCorreta: obj.acaoCorreta,
				opiniaoAmigos: obj.opiniaoAmigos,
				avaliacaoGeral: obj.avaliacaoGeral,
			};

			let response = await api.post(rota, data);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}
}

export default FeedbackService;
