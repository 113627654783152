import PropTypes from "prop-types";
import React from "react";

type TYPES = {
	CPF: string;
	CNPJ: string;
};

interface Props extends React.HTMLAttributes<HTMLInputElement> {
	type: string;
	name: string;
	value: string;
	onChange: any;
	disabled?: boolean;
}

const CpfCnpj = ({
	onChange,
	type,
	name,
	value,
	disabled = false,
	...rest
}: Props) => {
	const TYPES = {
		CPF: "999.999.999-999",
		CNPJ: "99.999.999/9999-99",
	} as TYPES;

	const MAX_LENGTH = clear(TYPES.CNPJ).length;

	let v = clear(value);

	if (v) {
		v = applyMask(v, TYPES[getMask(v)]);
	}

	function onLocalChange(ev: any) {
		let v = clear(ev.target.value);
		const mask = getMask(v);

		let nextLength = v.length;

		if (nextLength > MAX_LENGTH) return;

		v = applyMask(v, TYPES[mask]);

		ev.target.value = v;

		onChange(ev, mask);
	}

	function getMask(value: string) {
		return value.length > 11 ? "CNPJ" : "CPF";
	}

	function applyMask(value: string, mask: any) {
		let result = "";

		let inc = 0;
		Array.from(value).forEach((letter, index) => {
			if (!mask[index + inc].match(/[0-9]/)) {
				result += mask[index + inc];
				inc++;
			}
			result += letter;
		});
		return result;
	}

	function clear(value: string) {
		return value && value.replace(/[^0-9]/g, "");
	}

	return (
		<input
			{...rest}
			type={type}
			name={name}
			value={value}
			onChange={onLocalChange}
			disabled={disabled}
		/>
	);
};

CpfCnpj.propTypes = {
	value: PropTypes.string,
	onChange: PropTypes.func,
};

CpfCnpj.defaultProps = {
	type: "tel",
	value: "",
	onChange: () => {},
};

export default CpfCnpj;
