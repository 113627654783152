import api from "./api";

class CardFeelingService {
	async list() {
		try {
			const response = await api.get("app/sentimento/lista/2");

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}

	async recordResponses(data: any) {
		try {
			const response = await api.post(`app/sentimento/create/resposta`, {
				...data,
			});

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}
}

export default CardFeelingService;
