import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { toastSettings } from "../../models/toastConfig";
import { useMacroGoals } from "../../providers/MacroGoalsProvider";
import MacroGoalsService from "../../services/MacroGoalsService";
import "./MacroGoalsEditModal.scss";

interface ModalNewsProps {
	show: boolean;
	onClose?: any;
}

interface Inputs {
	resourceID: string;
	goal: string;
	why: string;
}

const MacroGoalsEditModal = ({ show, onClose }: ModalNewsProps) => {
	const [resources, setResources] = useState<any>({});

	const [modal, setModal] = useState<boolean>(false);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const { editGoal, goals, setGoals, setEditGoal } = useMacroGoals();

	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm<Inputs>();

	const toggle = () => {
		setGoals(
			[...goals].map((goal: any) => {
				if (goal.id === editGoal.id) {
					goal.isSelectedEdit = false;
				}

				return goal;
			}),
		);

		setModal(!modal);
	};

	const handleOnClose = () => {
		setEditGoal(null);
		onClose(false);
	};

	useEffect(() => {
		handleList().then();

		if (show) {
			setValue("goal", editGoal.pergunta);
			setValue("why", editGoal.resposta);
			setValue("resourceID", editGoal.idRecurso);

			setIsSubmitting(false);
			setModal(show);
		}
	}, [show]);

	const handleList = async () => {
		try {
			const [_Response, _Error] = await new MacroGoalsService().listResources();

			if (!!_Error || !_Response?.status) {
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
				return;
			}

			setResources(_Response.resultSet);
		} catch (err) {
			console.warn(err);
		}
	};

	const handleEdit = async (data: Inputs) => {
		const newGoalData = {
			id: editGoal.id,
			idRecurso: parseInt(data.resourceID),
			pergunta: data.goal,
			resposta: data.why,
		};

		try {
			setIsSubmitting(true);
			const [_Response, _Error] = await new MacroGoalsService().edit(newGoalData);

			if (!!_Error || !_Response?.status) {
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
				setTimeout(() => setIsSubmitting(false), 1000);
				return false;
			} else {
				toastSettings("Editado objetivo com sucesso!", "bottom-center", "success");
			}
		} catch (err) {
			console.warn(err);
		}

		toggle();
		setIsSubmitting(false);
	};

	return (
		<Modal
			centered
			scrollable
			size="lg"
			isOpen={modal}
			toggle={toggle}
			onClosed={handleOnClose}
			className="modal-macro-goals-edit"
			data-testid="MacroGoalsEditModal"
		>
			{editGoal ? (
				<form key={1} onSubmit={handleSubmit(handleEdit)}>
					<ModalHeader toggle={toggle}>Editar Objetivo Macro</ModalHeader>
					<ModalBody>
						<h2 className="title s">Vamos abaixo editar este objetivo macro.</h2>
						<div className="text">
							<p>Pense e preencha os campos abaixo.</p>
						</div>
						<div className="fieldset">
							<label htmlFor="resource-id">Escolha a categoria:</label>
							<select id="resource-id" {...register("resourceID", { required: true })}>
								{resources?.map((resource: any, index: number) => (
									<option key={index} value={resource.id}>
										{resource.descricao}
									</option>
								))}
							</select>
							{errors.resourceID && (
								<span className="error">Este campo é obrigatório</span>
							)}
						</div>
						<div className="fieldset">
							<label htmlFor="goal">Qual é o objetivo?</label>
							<input
								type="text"
								id="goal"
								maxLength={100}
								{...register("goal", { required: true })}
							/>
							{errors.goal && <span className="error">Este campo é obrigatório</span>}
						</div>
						<div className="fieldset">
							<label htmlFor="why">Porque isso é importante para você?</label>
							<input
								type="text"
								id="why"
								maxLength={100}
								{...register("why", { required: true })}
							/>
							{errors.why && <span className="error">Este campo é obrigatório</span>}
						</div>
					</ModalBody>
					<ModalFooter>
						<Button type="button" disabled={isSubmitting} onClick={toggle}>
							Cancelar
						</Button>
						<Button type="submit" disabled={isSubmitting} color="primary">
							Editar objetivo
						</Button>
					</ModalFooter>
				</form>
			) : (
				<>
					<ModalHeader toggle={toggle}>Algo deu errado.</ModalHeader>
					<ModalBody>
						<h2 className="title s">Não foi possivel realizar esta ação.</h2>
						<div className="text">
							<p>Tente fechar o modal e recarregar a pagina.</p>
						</div>
					</ModalBody>
				</>
			)}
		</Modal>
	);
};

export default MacroGoalsEditModal;
