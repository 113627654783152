import "./ModalProfileConfirmation.scss";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Button, Form, Input, Modal, ModalBody, ModalHeader } from "reactstrap";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import { schema } from "./Validate/Validate";
import { yupResolver } from "@hookform/resolvers/yup";
import CpfCnpj from "../CpfCnpj/CpfCnpj";
import { faCheckSquare, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../../providers/AuthProvider";
import Loading from "../Loading/Loading";
import AuthService from "../../services/AuthService";
import { toastSettings } from "../../models/toastConfig";
import ptBR from "date-fns/locale/pt-BR";
import moment from "moment";
import InputMask from "react-input-mask";
import SignUpService from "../../services/SignUpService";
import { validateCep } from "validations-br";
import { toast } from "react-toastify";
import RemoveAcento from "../../utils/RemoveAcento";
import Select from "react-select";
import { customLoginStyles } from "../../models/selectConfig";

interface IModalProfileConfirmation {
	show: boolean;
	onClose?: any;
}

type FormValues = {
	cpfCnpj: string;
	occupation: string;
	birthdate: null | string;
	cep: string;
	street: string;
	addressNumber: string;
	neighborhood: string;
	complement: string;
	state: string;
	city: string;
};

const ModalProfileConfirmation = ({
	show,
	onClose,
}: IModalProfileConfirmation) => {
	const [modal, setModal] = useState<boolean>(false);
	const [cpfCnpj, setCpfCnpj] = useState<string>("");
	const [occupation, setOccupation] = useState<string>("");
	const [birthdate, setBirthdate] = useState<null | string>(null);

	const [cep, setCep] = useState<string>("");
	const [street, setStreet] = useState<string>("");
	const [addressNumber, setAddressNumber] = useState<string>("");
	const [neighborhood, setNeighborhood] = useState<string>("");
	const [complement, setComplement] = useState<string>("");
	const [selectedState, setSelectedState] = useState<any>("");
	const [selectedCity, setSelectedCity] = useState<any>("");

	const [states, setStates] = useState<any[]>([]);
	const [cities, setCities] = useState<any[]>([]);

	const { updatePartialProfile } = useAuth();
	const [isLoading, setLoading] = useState<boolean>(false);
	const [dataUser, setDataUser] = useState<any>(null);
	const [dateNow] = useState<Date>(new Date());

	const modalToggle = () => setModal(!modal);
	const handleOnClose = () => onClose(false);
	const {
		handleSubmit,
		control,
		setValue,
		formState: { errors },
	} = useForm<FormValues>({
		mode: "onChange",
		resolver: yupResolver(schema),
	});

	useEffect(() => {
		registerLocale("pt-br", ptBR);
		setDefaultLocale("pt-br");
		show && setModal(show);
	}, [show]);

	useEffect(() => {
		if (!dataUser) {
			!isLoading && getDados();
			populateStateSelect();
		} else {
			let _cpfCnpj: string =
				dataUser.cpfCnpj !== null
					? dataUser.cpfCnpj.toString().replace(/-/g, "/")
					: "";
			_cpfCnpj =
				_cpfCnpj.length === 11
					? _cpfCnpj.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")
					: _cpfCnpj.replace(
							/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
							"$1 $2 $3/$4-$5",
					  );
			setCpfCnpj(_cpfCnpj);
			setOccupation(dataUser?.ocupacao);
			if (dataUser?.dataNascimentoAbertura) {
				setBirthdate(
					moment(dataUser?.dataNascimentoAbertura, "YYYY-MM-DD").format(
						"DD/MM/YYYY",
					),
				);
			}

			setCep(dataUser?.cep || "");
			setStreet(dataUser?.logradouro || "");
			setAddressNumber(dataUser?.numero || "");
			setNeighborhood(dataUser?.bairro || "");
			setComplement(dataUser?.complemento || "");

			setValue("cep", dataUser?.cep || "");
			setValue("street", dataUser?.logradouro || "");
			setValue("addressNumber", dataUser?.numero || "");
			setValue("neighborhood", dataUser?.bairro || "");
			setValue("complement", dataUser?.complemento || "");

			setValue("state", dataUser?.estado || "");
			setValue("city", dataUser?.cidade || "");

			if (states.length) {
				setSelectedState(
					states.find(
						(c: any) =>
							RemoveAcento(c.value) === RemoveAcento(dataUser?.estado || ""),
					),
				);
			}

			populateCitiesSelect(dataUser?.estado, dataUser?.cidade);
		}
	}, [dataUser]);

	useEffect(() => {
		if (cpfCnpj || occupation || birthdate || cep) {
			setValue("cpfCnpj", cpfCnpj);
			setValue("occupation", occupation);
			setValue("birthdate", birthdate);
			setValue("cep", cep);
		}
	}, [cpfCnpj, occupation, birthdate, setValue, cep]);

	useEffect(() => {
		if (complement || addressNumber) {
			setValue("complement", complement);
			setValue("addressNumber", addressNumber);
		}
	}, [complement, addressNumber, setValue]);

	const handleUpdate = async (data: any) => {
		if (isLoading) return;
		setLoading(true);

		const parameters = {
			ocupacao: data.occupation,
			cpfCnpj: data.cpfCnpj,
			dataNascimento: moment(data.birthdate, "DD/MM/YYYY").format("YYYY-MM-DD"),
			logradouro: street,
			bairro: neighborhood,
			numero: addressNumber,
			cidade: selectedCity.value,
			estado: selectedState.value,
			cep: cep,
			complemento: complement,
		};

		await updatePartialProfile(parameters).then((ret: any) => {
			if (ret) {
				modalToggle();
			}
			setLoading(false);
		});
	};

	const getDados = async () => {
		try {
			setLoading(true);
			const [_Response, _Error] = await new AuthService().getDados();
			if (!!_Error || !_Response?.status) {
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
				return;
			}
			setDataUser(_Response.resultSet);
			setLoading(false);
		} catch (err) {
			console.error(err);
		}
	};

	const handleCEP = async (cep: string) => {
		setValue("cep", cep);

		if (cep === "" || !cep) {
			setValue("street", "");
			setValue("neighborhood", "");
			setValue("state", "");
			setValue("complement", "");
			setValue("addressNumber", "");
			setValue("city", "");

			setCep("");

			setStreet("");
			setSelectedState("");
			setSelectedCity("");
			setNeighborhood("");
			setAddressNumber("");
			setComplement("");

			setCities([]);
			return;
		}

		if (!validateCep(cep)) {
			return;
		}

		setLoading(true);

		const [_Response, _Error] = await new SignUpService().getCep(cep);

		if (!!_Error || _Response?.errors) {
			setCep("");
			setStreet("");
			setSelectedState("");
			setSelectedCity("");
			setCities([]);

			const _error =
				"Seu CEP não foi encontrado, favor preencher manualmente o endereço";
			throwsAnToastError(_error);
		} else {
			setCep(_Response.cep || "");
			setStreet(_Response.street || "");
			setNeighborhood(_Response?.neighborhood || "");
			setSelectedCity(_Response.city.toUpperCase() || "");

			setValue("street", _Response.street || "");
			setValue("neighborhood", _Response?.neighborhood || "");

			if (_Response?.state) {
				setSelectedState(
					states.find(
						(c: any) =>
							RemoveAcento(c.value) === RemoveAcento(_Response?.state || ""),
					),
				);
				populateCitiesSelect(_Response.state, _Response.city);
				setValue("state", _Response.state);
				setValue("city", _Response.city);
			}
		}

		setLoading(false);
	};

	const throwsAnToastError = (error: string) => {
		toast.error(
			error ||
				"Desculpe! Parece que há um problema com nosso servidor! Por favor, tente novamente em alguns instantes!",
		);
	};

	const populateStateSelect = async () => {
		const [_Response, _Error] = await new SignUpService().getStates();

		if (!!_Error || _Response?.errors) {
			const _error =
				_Response?.message ||
				"Um erro inesperado ocorreu, tente novamente mais tarde.";
			throwsAnToastError(_error);
			return;
		}

		let estados = _Response?.map((state: any) => {
			return { label: state.nome.toUpperCase(), value: state.sigla };
		});

		estados = estados.sort((a: any, b: any) => (a.label > b.label ? 1 : -1));

		setStates(estados);
	};

	const populateCitiesSelect = async (state: string, city: string) => {
		const [_Response, _Error] = await new SignUpService().getCities(state);

		if (_Error || _Response?.errors) {
			const _error =
				_Response?.message ||
				"Um erro inesperado ocorreu, tente novamente mais tarde.";
			throwsAnToastError(_error);
			return;
		}

		let cidades = _Response?.map((city: any) => {
			return { label: city.nome.toUpperCase(), value: city.nome.toUpperCase() };
		});

		// adicionado por conta da api não trazer essa cidade
		if (state?.toUpperCase() === "SP") {
			cidades.push({ label: "São Paulo", value: "São Paulo" });
		}

		cidades = cidades.sort((a: any, b: any) => (a.label > b.label ? 1 : -1));

		setCities(cidades);

		if (city) {
			setSelectedCity(
				cidades.find((c: any) => RemoveAcento(c.value) === RemoveAcento(city)),
			);
		}

		setLoading(false);
	};

	return (
		<Modal
			centered
			scrollable
			size="md"
			isOpen={modal}
			//toggle={toggle}
			// onClosed={handleOnClose}
		>
			<ModalHeader>Confirmação de Detalhes Pessoais</ModalHeader>
			{isLoading && <Loading />}
			<ModalBody>
				<div className="ModalProfileConfirmation">
					<Form onSubmit={handleSubmit(handleUpdate)}>
						<div className="col col-12 form-group">
							<div className="fieldset">
								<label htmlFor="cpfCnpj"> CPF: </label>
								<Controller
									name="cpfCnpj"
									control={control}
									render={({ field: { name } }) => (
										<CpfCnpj
											name={name}
											type="text"
											value={cpfCnpj}
											placeholder="000.000.000-00"
											className={`${!errors.cpfCnpj || "invalid"}`}
											onChange={(event: any) => {
												setCpfCnpj(event.target.value);
											}}
										/>
									)}
								/>
								{errors.cpfCnpj?.type === "required" && (
									<span className="error">Informe um CPF válido.</span>
								)}
							</div>
						</div>
						<div className="col col-12 form-group">
							<div className="fieldset">
								<label htmlFor="occupation"> Ocupação: </label>
								<Controller
									name="occupation"
									control={control}
									render={({ field: { name } }) => (
										<Input
											type="text"
											name={name}
											value={occupation}
											placeholder="Sua ocupação atual"
											className={`${!errors.occupation || "invalid"}`}
											onChange={(event: any) => {
												setOccupation(event.target.value);
											}}
										/>
									)}
								/>
								{errors.occupation?.type === "max" && (
									<span className="error">
										A ocupação só pode ter até 50 caracteres.
									</span>
								)}
								{errors.occupation?.type === "required" && (
									<span className="error">Informe uma ocupação válida.</span>
								)}
							</div>
						</div>
						<div className="col col-12 form-group">
							<div className="fieldset">
								<label htmlFor="birthdate"> Data de Nascimento: </label>
								<Controller
									name="birthdate"
									control={control}
									render={({ field: { name, ref } }) => (
										// <Controller
										//     name={name}
										//     control={control}
										//     render={({ field: { name, ref } }) => (
										//         <DatePicker
										//             ref={ref}
										//             name={name}
										//             maxDate={dateNow}
										//             selected={birthdate}
										//             className={`${!errors.birthdate || "invalid"}`}
										//             onChange={(date) => setBirthdate(date || null)}
										//             dateFormat="dd/MM/yyyy"
										//             locale="pt-br"
										//         />
										//     )}
										// />
										<InputMask
											ref={ref}
											name={name}
											value={birthdate || ""}
											id="birthdate"
											className={`input ${!errors.birthdate || "invalid"}`}
											mask="99/99/9999"
											// value={birthdate}
											onChange={(e: any) => {
												setBirthdate(e.target.value);
											}}
										/>
									)}
								/>
								{errors.birthdate?.type === "required" && (
									<span className="error">Informe uma data de nascimento válida.</span>
								)}
							</div>
						</div>
						<div className="col col-12 form-group">
							<div className="fieldset">
								<label htmlFor="cep">CEP: </label>
								<Controller
									name="cep"
									control={control}
									render={({ field: { name, ref } }) => (
										<InputMask
											ref={ref}
											name={name}
											id="cep"
											className={`input ${!errors.cep || "invalid"}`}
											mask="99999-999"
											value={cep || ""}
											onBlur={(e) => handleCEP(e.target.value)}
											onChange={(e) => setCep(e.target.value)}
										/>
									)}
								/>
								{errors.cep?.type === "max" && (
									<span className="error">O CEP só pode ter até 8 números.</span>
								)}
								{errors.cep?.type === "required" && (
									<span className="error">Informe o CEP.</span>
								)}
							</div>
						</div>
						<div className="col col-12 form-group">
							<div className="fieldset">
								<label htmlFor="street">Rua: </label>
								<Controller
									name="street"
									control={control}
									render={({ field: { name } }) => (
										<Input
											type="text"
											name={name}
											value={street}
											className={`${!errors.street || "invalid"}`}
											onChange={(event: any) => {
												setStreet(event.target.value);
											}}
										/>
									)}
								/>
								{errors.street?.type === "required" && (
									<span className="error">Informe uma rua válida.</span>
								)}
							</div>
						</div>
						<div className="col col-12 form-group">
							<div className="fieldset">
								<label htmlFor="addressNumber">Número: </label>
								<Controller
									name="addressNumber"
									control={control}
									render={({ field: { name } }) => (
										<Input
											type="text"
											name={name}
											value={addressNumber}
											className={`${!errors.addressNumber || "invalid"}`}
											onChange={(event: any) => {
												setAddressNumber(event.target.value);
											}}
										/>
									)}
								/>
								{errors.addressNumber?.type === "required" && (
									<span className="error">Informe um número válido.</span>
								)}
							</div>
						</div>
						<div className="col col-12 form-group">
							<div className="fieldset">
								<label htmlFor="neighborhood">Bairro: </label>
								<Controller
									name="neighborhood"
									control={control}
									render={({ field: { name } }) => (
										<Input
											type="text"
											name={name}
											value={neighborhood}
											className={`${!errors.neighborhood || "invalid"}`}
											onChange={(event: any) => {
												setNeighborhood(event.target.value);
											}}
										/>
									)}
								/>
								{errors.neighborhood?.type === "required" && (
									<span className="error">Informe um bairro válido.</span>
								)}
							</div>
						</div>
						<div className="col col-12 form-group">
							<div className="fieldset">
								<label htmlFor="complement">Complemento: </label>
								<Controller
									name="complement"
									control={control}
									render={({ field: { name } }) => (
										<Input
											type="text"
											name={name}
											value={complement}
											className={`${!errors.complement || "invalid"}`}
											onChange={(event: any) => {
												setComplement(event.target.value);
											}}
										/>
									)}
								/>
								{errors.complement?.type === "required" && (
									<span className="error">Informe um bairro válido.</span>
								)}
							</div>
						</div>
						<div className="col col-12 form-group">
							<div className="fieldset">
								<label htmlFor="estado">Estado: </label>
								<Controller
									name="state"
									control={control}
									render={({ field: { onChange, name, ref } }) => (
										<Select
											ref={ref}
											name={name}
											isClearable
											isSearchable={false}
											options={states}
											placeholder="Selecione..."
											className={`select ${isLoading && "disabled"} ${
												!errors.state || "invalid"
											} p-0`}
											value={selectedState || ""}
											onChange={(val: any) => {
												onChange(val?.value || "");
												setSelectedState(val);
												setSelectedCity("");
												populateCitiesSelect(val?.value, "");
											}}
											isDisabled={isLoading}
											noOptionsMessage={() => "Não há registros"}
											styles={customLoginStyles}
										/>
									)}
								/>
							</div>
						</div>
						<div className="col col-12 form-group">
							<div className="fieldset">
								<label htmlFor="city">Cidade: </label>
								<Controller
									name="city"
									control={control}
									render={({ field: { onChange, name, ref } }) => (
										<Select
											ref={ref}
											name={name}
											isClearable
											isSearchable={false}
											options={cities}
											placeholder="Selecione..."
											className={`select ${isLoading && "disabled"} ${
												!errors.city || "invalid"
											} p-0`}
											value={selectedCity || ""}
											onChange={(val: any) => {
												onChange(val?.value || "");
												setSelectedCity(val);
											}}
											isDisabled={isLoading}
											noOptionsMessage={() => "Não há registros"}
											styles={customLoginStyles}
										/>
									)}
								/>
							</div>
						</div>
						<div className="row">
							<div className="col col-12 d-flex justify-content-end">
								<Button type="submit">
									<FontAwesomeIcon icon={faCheckSquare} className="mr-2" />
									<span>Confirmar</span>
								</Button>
							</div>
						</div>
					</Form>
				</div>
			</ModalBody>
		</Modal>
	);
};

export default ModalProfileConfirmation;
