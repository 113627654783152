import React, { useEffect, useState } from "react";

import CardPaymentsService from "../services/CardPaymentsService";
import { toastSettings } from "../models/toastConfig";
import { useActiveJourney } from "./ActiveJourneyProvider";
import { useAuth } from "./AuthProvider";
import { useNavigate } from "react-router-dom";

export const PaymentsContext = React.createContext<any>({});

export const PaymentsProviders = (props: any) => {
	const [data, setData] = useState([]);
	const [plano, setPlano] = useState<number>(0);
	const [isPago, setIsPago] = useState<boolean>(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [planoEscolhido, setPlanoEscolhido] = useState<any>([]);
	const [cardData, setCardData] = useState<any>([]);
	const { recoverActiveJourney } = useActiveJourney();
	const { setPlanoActive } = useAuth();
	const navigate = useNavigate();

	useEffect(() => {
		list().then();
	}, []);

	const list = async () => {
		try {
			const [_Response, _Error] = await new CardPaymentsService().list();

			if (!!_Error || !_Response?.status) {
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
				return false;
			}

			setData(_Response.resultSet);
		} catch (err) {
			console.warn(err);
		}
	};

	const create = async (data?: any) => {
		const [_, idPlano] = data.installments.split("-");

		try {
			setIsSubmitting(true);
			let obj: any = {
				brand: cardData?.brand,
				holder: data?.cardName?.toUpperCase() || null,
				cpf: data?.cpf || null,
				cardNumber:
					data?.block01 + data?.block02 + data?.block03 + data?.block04 || null,
				expirationDate: data?.expirationDate || null,
				securityCode: data?.cvc || null,
				idPlano: Number(idPlano) > 0 ? Number(idPlano) : plano,
				tipoAssinatura: Number(idPlano) === 3 ? "Monthly" : "Annual",
				idTipoPagamento: Number(idPlano) === 3 ? 1 : 2,
			};

			if (obj?.idPlano === 0) {
				toastSettings("Selecione um plano!", "bottom-center", "error");
			}

			setPlano(obj?.idPlano);

			const [_Response, _Error, _Status] = await new CardPaymentsService().create(
				obj,
			);

			if (!!_Error || !_Response?.status) {
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
				setIsSubmitting(false);
				return false;
			}

			setIsSubmitting(false);

			setIsPago(_Response?.resultSet);

			setPlanoActive(obj?.idPlano);

			recoverActiveJourney();
			if (_Response?.resultSet && obj?.idPlano === 1) {
				setPlano(0);
				setIsPago(false);
				navigate("/home");
			}
			if (_Status == 200) {
				setPlano(3);
				setIsPago(true);
				navigate("/home");
			}
		} catch (err) {
			setIsSubmitting(false);
			console.warn(err);
		}
	};

	const getDadosPlano = () => {
		data.forEach((item: any) => {
			if (item.id === plano) {
				setPlanoEscolhido(item);
			}
		});
	};

	useEffect(() => {
		getDadosPlano();
	}, [plano]);

	return (
		<PaymentsContext.Provider
			value={{
				data,
				setData,
				plano,
				setPlano,
				isPago,
				setIsPago,
				isSubmitting,
				setIsSubmitting,
				create,
				planoEscolhido,
				setPlanoEscolhido,
				cardData,
				setCardData,
			}}
		>
			{props.children}
		</PaymentsContext.Provider>
	);
};

export const usePayments = () => React.useContext(PaymentsContext);
